import { BsModalRef } from 'ngx-bootstrap/modal';
import { Error } from '@app/enums/errors';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '@app/shared/services/auth.service';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { SpinnerButtonOptions } from '@app/models/spinnerButtonOptions';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
  background = 'linear-gradient(to left, #389bff, #e5f0fb 100%)';
  passwordStrColors = [this.background, this.background, this.background, this.background, this.background];
  private destroy$ = new Subject<null>();
  submittedNewPassword = false;
  submittedRepeatPassowrd = false;
  submittedOldPassword = false;
  passwordData: {
    OldPassword: string,
    NewPassword: string
  };
  changePasswordForm: FormGroup;
  spinnerButtonOptions: SpinnerButtonOptions = {
    active: false,
    text: 'Save password',
    fullWidth: false,
    disabled: true,
  };

  constructor(public changePasswordModal: BsModalRef,
              private formBuilder: FormBuilder,
              private toast: ToastrService,
              private authService: AuthService) { }

  ngOnInit() {
    this.createForm();
    this.changePasswordForm.valueChanges.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      takeUntil(this.destroy$)
    ).subscribe(() => {
      if (!this.changePasswordForm.invalid) {
        this.spinnerButtonOptions.disabled = false;
      } else {
        this.spinnerButtonOptions.disabled = true;
      }
    });
  }

  createForm() {
    this.changePasswordForm = this.formBuilder.group({
      OldPassword: ['', Validators.required],
      NewPassword: ['', { validators: [Validators.required, Validators.minLength(6)] }],
      RepeatPassword: ['', Validators.required],
    }, {
      validator: this.checkPasswords()
    });
  }

  get f() {
    return this.changePasswordForm.controls;
  }

  checkPasswords() {
    return (formGroup: FormGroup) => {
     const passwordName = formGroup.controls.NewPassword;
     const confirmPasswordName = formGroup.controls.RepeatPassword;

     if (confirmPasswordName.errors && !confirmPasswordName.errors.checkPassword) {
       return;
     }

     if (passwordName.value !== confirmPasswordName.value) {
       confirmPasswordName.setErrors({checkPassword: true});
     } else {
       confirmPasswordName.setErrors(null);
       }
     };
    }

 changePassword() {
  if (this.changePasswordForm.invalid) {
      return;
  }
  this.passwordData = {
    OldPassword: this.f.OldPassword.value,
    NewPassword: this.f.NewPassword.value
  };
  const userUID = this.authService.currentUser.UserUID;
  this.authService.changePassword(userUID, this.passwordData).subscribe( () => {
    this.spinnerButtonOptions.disabled = false;
    this.changePasswordModal.hide();
  }, error => {
     if (error.error.ErrorCode === Error.ErrorCodeWrongCredentials) {
      this.toast.warning('Wrong old password', 'Warning!');
     }
  });
 }

 ngOnDestroy() {
   this.destroy$.next();
 }

}
