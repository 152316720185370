import { ToastrService } from "ngx-toastr";
import { TaskService } from "@app/shared/services/task.service";
import { ReminderService } from "@app/shared/services/reminder.service";
import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { ReminderData } from "@app/models/reminder";

@Component({
  selector: "app-task-progress",
  templateUrl: "./task-progress.component.html",
  styleUrls: ["./task-progress.component.scss"],
})
export class TaskProgressComponent implements OnInit, OnChanges {
  @Input() data: ReminderData;
  isOpen = false;
  textMessage: string;
  isReminderDelete = false;
  reminderValue: number;
  initialProgress: number;
  isDoneStatusDisabled = false;
  isNextStatusDisabled = false;

  constructor(
    private reminderService: ReminderService,
    private taskService: TaskService,
    private toast: ToastrService
  ) {}

  ngOnInit(): void {}

  ngOnChanges() {
    this.isReminderDelete = false;
    this.initialProgress = this.data.progress;
    this.isOpen = false;
  }

  sendProgress(progress: number, message: string) {
    if (this.initialProgress === progress && !message) {
      this.data.showReminder = false;
      return;
    }
    const data = {
      Progress: progress,
      Message: message,
    };
    this.reminderService
      .reportProgress(this.data.projectKey, this.data.taskKey, data)
      .subscribe((res) => {
        this.data.showReminder = false;
      });
  }

  sendReminder() {
    if (this.data.isStatusReminder) {
      this.setReminder();
    } else {
      this.updateReminder();
    }
  }

  updateReminder() {
    if (!this.isReminderDelete) {
      this.reminderService
        .updateReminderForTask(
          this.data.projectKey,
          this.data.taskKey,
          +this.reminderValue
        )
        .subscribe(() => {
          this.isOpen = false;
          this.data.showReminder = false;
        });
    } else {
      this.deleteReminder();
    }
  }

  setReminder() {
    const firstReminder = new Date(this.data.firstReminder);
    if (this.isReminderDelete) {
      this.reminderValue = 0;
    }
    this.reminderService
      .setReminderForTask(
        this.data.projectKey,
        this.data.taskKey,
        null,
        this.reminderValue,
        firstReminder
      )
      .subscribe(() => {
        this.data.showReminder = false;
      });
  }

  deleteReminder() {
    this.reminderService
      .deleteReminderForTask(this.data.projectKey, this.data.taskKey)
      .subscribe(() => {
        this.data.showReminder = false;
      });
  }

  updateTaskStatus(statusID: number, status: string) {
    this.taskService
      .updateStatusForTask(this.data.projectKey, this.data.taskKey, statusID)
      .subscribe(
        () => {
          this.toast.success("Status changed successfully");
          if (status === 'doneStatus') {
            this.isDoneStatusDisabled = true;
            this.isNextStatusDisabled = false;
          } else {
            this.isDoneStatusDisabled = false;
            this.isNextStatusDisabled = true;
          }
        },
        (error) =>
          this.toast.warning("Something went wrong. Please try again later")
      );
  }

  openReminder() {
    this.isOpen = !this.isOpen;
    if (this.isOpen) {
      this.reminderValue = this.data.DayFrequency;
    }
  }
}
