import { Project } from '@app/models/project';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(value: Project[], filterString: string, propName: string, propLead: string, propDesc: string): Project[] {
    if (value.length === 0 || filterString === '') {
      return value;
    }
    const resultArray = [];
    value.map(item => {
      if (
        item[propName].toLowerCase().includes(filterString.toLowerCase(), 0) ||
        item[propLead].toLowerCase().includes(filterString.toLowerCase())
      ) {
        resultArray.push(item);
      }
    });

    return resultArray;
  }
}
