import { TaskToday } from "@app/models/todayList";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "countProgress",
})
export class CountProgressPipe implements PipeTransform {
  transform(value: TaskToday[], isProgress: boolean): any {
    if (!value) {
      return null;
    }
    let progressValue: number | string
    const sumCheckedTasks = value.reduce((total, currentValue) => (!currentValue.IsDoneToday ? total : total += 1), 0);
    if (isProgress) {
      progressValue = Math.floor((sumCheckedTasks / value.length) * 100);
    } else {
      progressValue = `${sumCheckedTasks}/${value.length}`;
    }
    return progressValue;
  }
}
