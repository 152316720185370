<ng-container>
  <div class="task-progress-wrapper">
    <div class="d-flex align-items-center mb-3">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="mr-2"
        id="Group_1964"
        width="21.934"
        height="21.934"
        viewBox="0 0 21.934 21.934"
      >
        <defs>
          <style>
            .cls-progress {
              fill: #fff;
            }
          </style>
        </defs>
        <g id="Group_494">
          <g id="Group_110">
            <circle
              id="Ellipse_42"
              cx="10.967"
              cy="10.967"
              r="10.967"
              fill="#389bff"
            />
          </g>
        </g>
        <g id="exclamation" transform="translate(8.906 3.742)">
          <path
            id="Path_872"
            d="M138.243 45.25a2.007 2.007 0 0 0-1.61-.8h-.04a2.023 2.023 0 0 0-1.954 2.55l1.472 5.492a.5.5 0 0 0 .476.367.5.5 0 0 0 .477-.363l1.517-5.48a2.007 2.007 0 0 0-.338-1.766zm-.614 1.5l-1.029 3.73-1-3.738a1.033 1.033 0 0 1 1-1.3h.04a1.033 1.033 0 0 1 1 1.309z"
            class="cls-progress"
            transform="translate(-134.569 -44.452)"
          />
          <path
            id="Path_873"
            d="M137.583 187a1.984 1.984 0 1 0 1.984 1.984 1.987 1.987 0 0 0-1.984-1.984zm0 2.979a.995.995 0 1 1 .995-.995 1 1 0 0 1-.995.992z"
            class="cls-progress"
            transform="translate(-135.531 -177.596)"
          />
        </g>
      </svg>
      <span class="font-14 text-gray-500">
        Update progress for task
        <a
          class="text-primary"
          [routerLink]="['/task', data.projectKey + '-' + data.taskKey]"
        >
          {{ data.projectKey }}-{{ data.taskKey }}.
        </a>
      </span>
    </div>
    <div class="d-flex align-items-center mb-20">
      <img
        src="/assets/images/types/{{ data.taskIconID }}.png"
        width="20"
        height="20"
        class="mr-2"
        alt="type"
      />
      <span class="text-primary font-15"> {{ data.taskTitle }} </span>
    </div>
    <ng-container *ngIf="!isOpen">
      <app-range-slider
        [progressCount]="data.progress"
        (progressCountEvent)="data.progress = $event"
      >
      </app-range-slider>
      <p class="font-12 text-gray-600 mb-1 mt-3">Move to</p>
      <div class="btn-actions d-flex align-items-center">
        <button
          *ngIf="data.nextStatusID !== data.doneStatusID && data.nextStatusID"
          type="button"
          class="btn-xs btn-primary"
          [ngClass]="{
            'btn-default': isNextStatusDisabled,
            'mr-2': !isNextStatusDisabled
          }"
          [disabled]="isNextStatusDisabled"
          (click)="updateTaskStatus(data.nextStatusID, 'nextStatus')"
        >
          {{ data.nextStatusName }}
        </button>
        <div class="checkmark" *ngIf="isNextStatusDisabled">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12.5"
            height="6.2"
            viewBox="0 0 12.193 9.568"
          >
            <path
              fill="none"
              stroke="#fff"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2px"
              d="M-4202.344 8037.665l5.046 4.205 7.147-7.568"
              transform="translate(4203.344 -8033.302)"
            />
          </svg>
        </div>
        <button
          *ngIf="data.doneStatusID"
          type="button"
          class="btn-xs btn-primary"
          [ngClass]="{ 'btn-default': isDoneStatusDisabled }"
          [disabled]="isDoneStatusDisabled"
          (click)="updateTaskStatus(data.doneStatusID, 'doneStatus')"
        >
          {{ data.doneStatusName }}
        </button>
        <div class="checkmark" *ngIf="isDoneStatusDisabled">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12.5"
            height="6.2"
            viewBox="0 0 12.193 9.568"
          >
            <path
              fill="none"
              stroke="#fff"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2px"
              d="M-4202.344 8037.665l5.046 4.205 7.147-7.568"
              transform="translate(4203.344 -8033.302)"
            />
          </svg>
        </div>
      </div>
      <div class="progress-comment">
        <input
          [(ngModel)]="textMessage"
          type="text"
          class="form-control font-15"
          placeholder="Add comment..."
        />
      </div>
    </ng-container>
    <ng-container *ngIf="isOpen">
      <div class="mb-2">
        <svg
          (click)="isOpen = !isOpen"
          class="cursor-pointer mr-3"
          xmlns="http://www.w3.org/2000/svg"
          width="17.672"
          height="17.557"
          viewBox="0 0 17.672 17.557"
        >
          <defs>
            <style>
              .reminder {
                fill: #818181;
                stroke: #818181;
                stroke-width: 0.3px;
              }
            </style>
          </defs>
          <g id="time-left" transform="translate(.15 .16)">
            <g id="Layer_2_16_">
              <g id="Group_1947">
                <path
                  [ngClass]="{ 'reminder-active': isOpen }"
                  id="Path_2976"
                  d="M253.673 423.176c-.143.036-.289.067-.434.094a.7.7 0 0 0 .254 1.373c.173-.032.347-.07.517-.112a.7.7 0 0 0-.337-1.355z"
                  class="reminder"
                  transform="translate(-243.232 -407.417)"
                />
                <path
                  [ngClass]="{ 'reminder-active': isOpen }"
                  id="Path_2977"
                  d="M412.662 137.586a.7.7 0 0 0 1.326-.439c-.055-.167-.117-.334-.182-.5a.7.7 0 1 0-1.3.518c.059.135.11.28.156.421z"
                  class="reminder"
                  transform="translate(-397.058 -131.187)"
                />
                <path
                  [ngClass]="{ 'reminder-active': isOpen }"
                  id="Path_2978"
                  d="M321.594 394.027c-.123.081-.25.16-.378.234a.7.7 0 1 0 .7 1.21c.152-.088.3-.181.45-.278a.7.7 0 0 0-.77-1.165z"
                  class="reminder"
                  transform="translate(-308.885 -379.265)"
                />
                <path
                  [ngClass]="{ 'reminder-active': isOpen }"
                  id="Path_2979"
                  d="M429.03 207.982a.7.7 0 1 0-1.4.055c.006.147.007.3 0 .444a.7.7 0 1 0 1.4.031c.009-.176.007-.354 0-.53z"
                  class="reminder"
                  transform="translate(-411.666 -199.633)"
                />
                <path
                  [ngClass]="{ 'reminder-active': isOpen }"
                  id="Path_2980"
                  d="M378.178 344.208a.7.7 0 0 0-.978.14c-.089.118-.182.235-.278.347a.7.7 0 0 0 .079.984l.051.039a.7.7 0 0 0 .933-.118c.114-.134.225-.273.331-.415a.7.7 0 0 0-.138-.977z"
                  class="reminder"
                  transform="translate(-362.687 -331.284)"
                />
                <path
                  [ngClass]="{ 'reminder-active': isOpen }"
                  id="Path_2981"
                  d="M414.819 279.384a.7.7 0 0 0-.875.458c-.044.141-.093.282-.146.42a.7.7 0 0 0 1.3.5c.063-.164.121-.333.173-.5a.7.7 0 0 0-.452-.878z"
                  class="reminder"
                  transform="translate(-398.302 -268.983)"
                />
                <path
                  [ngClass]="{ 'reminder-active': isOpen }"
                  id="Path_2982"
                  d="M7.394 17.547a7.21 7.21 0 0 1-1.794-.569l-.02-.011c-.134-.063-.267-.13-.4-.2a7.45 7.45 0 0 1-.695-.434A7.287 7.287 0 0 1 4.52 4.395l.025-.017a7.3 7.3 0 0 1 8.181-.066l-.546.788c-.152.219-.058.379.207.355l2.37-.212a.39.39 0 0 0 .353-.511l-.636-2.292c-.071-.257-.254-.288-.405-.068l-.547.79A8.624 8.624 0 0 0 7.2 1.818q-.335.058-.661.141l-.025.007a8.611 8.611 0 0 0-4.775 3.2l-.03.037c-.04.053-.079.108-.117.163-.063.089-.125.181-.184.273l-.02.033A8.609 8.609 0 0 0 .009 10.75v.018c.008.175.022.353.041.528 0 .011 0 .022.005.033.019.176.044.352.075.528a8.618 8.618 0 0 0 2.43 4.674l.009.009a8.8 8.8 0 0 0 1.113.93 8.61 8.61 0 0 0 3.463 1.449.7.7 0 0 0 .246-1.375z"
                  class="reminder"
                  transform="translate(0 -1.688)"
                />
                <path
                  [ngClass]="{ 'reminder-active': isOpen }"
                  id="Path_2983"
                  d="M206.552 83.2a.565.565 0 0 0-.565.565v5.629l5.148 2.661a.565.565 0 0 0 .519-1l-4.537-2.355v-4.937a.565.565 0 0 0-.565-.563z"
                  class="reminder"
                  transform="translate(-198.295 -80.154)"
                />
              </g>
            </g>
          </g>
        </svg>
        <span class="font-14 text-gray-600 font-weight-normal">
          Remind me every:
        </span>
      </div>
      <div class="d-flex align-items-center pl-37 mb-3">
        <ng-container *ngIf="!isReminderDelete">
          <input
            [(ngModel)]="reminderValue"
            type="number"
            min="1"
            step="1"
            oninput="validity.valid||(value='');"
            class="form-control number-of-days-input mr-12"
          />
          <span class="font-15 text-black font-weight-normal mr-5">
            Day(s)
          </span>
        </ng-container>
        <ng-container *ngIf="isReminderDelete">
          <span class="font-13 text-black font-weight-normal mr-4">
            Notifications muted
          </span>
        </ng-container>
        <a
          (click)="isReminderDelete = !isReminderDelete"
          class="cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12.211"
            height="13.303"
            viewBox="0 0 12.211 13.303"
          >
            <g>
              <g>
                <g fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path
                    d="M30.134 10.9a1.093 1.093 0 0 0 1.09-1.09h-2.181a1.093 1.093 0 0 0 1.091 1.09zm3.543-6.269A3.508 3.508 0 0 0 30.951 1.2V.818a.818.818 0 1 0-1.635 0V1.2a3.152 3.152 0 0 0-.763.273L33.677 6.6zm-.164 4.634l1.09 1.09.709-.709-9.1-9.1-.709.709 1.581 1.581a3.431 3.431 0 0 0-.491 1.8v3L25.5 8.722v.545z"
                    transform="translate(-25.621 -.267) translate(25.5) translate(-24.18 1.467)"
                  />
                  <path
                    fill="#818181"
                    [ngClass]="{ 'turn-off-reminder': isReminderDelete }"
                    d="M30.134 10.903c.6 0 1.09-.49 1.09-1.09h-2.18c0 .6.49 1.09 1.09 1.09m4.47-.545l.708-.709L26.21.545l-.709.709 1.58 1.58c-.326.546-.49 1.146-.49 1.8v2.998l-1.09 1.09v.545h8.014l1.09 1.09m-.927-3.76V4.633c0-1.69-1.145-3.053-2.726-3.435V.818c0-.436-.381-.818-.817-.818-.436 0-.818.382-.818.818v.381c-.273.055-.545.164-.763.273l5.124 5.124m-3.543 5.507c-1.036 0-1.913-.69-2.195-1.636H25.5c-.663 0-1.2-.537-1.2-1.2v-.545c0-.318.126-.623.351-.848l.74-.74v-2.5c0-.538.084-1.053.252-1.54l-.992-.992c-.468-.468-.468-1.228 0-1.697l.71-.708c.224-.225.53-.352.848-.352.318 0 .623.127.848.352l.799.798c.05-.036.104-.068.16-.096.05-.025.105-.052.165-.079.228-.862 1.03-1.52 1.953-1.52.924 0 1.728.66 1.954 1.526.682.307 1.287.77 1.753 1.346.678.84 1.036 1.864 1.036 2.962v1.962c0 .256-.081.498-.223.697L36.16 8.8c.469.47.469 1.229 0 1.697l-.709.71c-.234.233-.541.35-.848.35-.307 0-.614-.117-.849-.35l-.739-.74h-.687c-.283.945-1.16 1.636-2.195 1.636z"
                    transform="translate(-25.621 -.267) translate(25.5) translate(-24.18 1.467)"
                  />
                </g>
              </g>
            </g>
          </svg>
        </a>
      </div>
    </ng-container>
    <div
      [ngClass]="{
        'justify-content-end': isOpen,
        'justify-content-between ': !isOpen
      }"
      class="d-flex align-items-center cursor-pointer"
    >
      <svg
        *ngIf="!isOpen"
        (click)="openReminder()"
        xmlns="http://www.w3.org/2000/svg"
        width="17.672"
        height="17.557"
        viewBox="0 0 17.672 17.557"
      >
        <defs>
          <style>
            .reminder {
              fill: #818181;
              stroke: #818181;
              stroke-width: 0.3px;
            }
          </style>
        </defs>
        <g id="time-left" transform="translate(.15 .16)">
          <g id="Layer_2_16_">
            <g id="Group_1947">
              <path
                [ngClass]="{ 'reminder-active': isOpen }"
                id="Path_2976"
                d="M253.673 423.176c-.143.036-.289.067-.434.094a.7.7 0 0 0 .254 1.373c.173-.032.347-.07.517-.112a.7.7 0 0 0-.337-1.355z"
                class="reminder"
                transform="translate(-243.232 -407.417)"
              />
              <path
                [ngClass]="{ 'reminder-active': isOpen }"
                id="Path_2977"
                d="M412.662 137.586a.7.7 0 0 0 1.326-.439c-.055-.167-.117-.334-.182-.5a.7.7 0 1 0-1.3.518c.059.135.11.28.156.421z"
                class="reminder"
                transform="translate(-397.058 -131.187)"
              />
              <path
                [ngClass]="{ 'reminder-active': isOpen }"
                id="Path_2978"
                d="M321.594 394.027c-.123.081-.25.16-.378.234a.7.7 0 1 0 .7 1.21c.152-.088.3-.181.45-.278a.7.7 0 0 0-.77-1.165z"
                class="reminder"
                transform="translate(-308.885 -379.265)"
              />
              <path
                [ngClass]="{ 'reminder-active': isOpen }"
                id="Path_2979"
                d="M429.03 207.982a.7.7 0 1 0-1.4.055c.006.147.007.3 0 .444a.7.7 0 1 0 1.4.031c.009-.176.007-.354 0-.53z"
                class="reminder"
                transform="translate(-411.666 -199.633)"
              />
              <path
                [ngClass]="{ 'reminder-active': isOpen }"
                id="Path_2980"
                d="M378.178 344.208a.7.7 0 0 0-.978.14c-.089.118-.182.235-.278.347a.7.7 0 0 0 .079.984l.051.039a.7.7 0 0 0 .933-.118c.114-.134.225-.273.331-.415a.7.7 0 0 0-.138-.977z"
                class="reminder"
                transform="translate(-362.687 -331.284)"
              />
              <path
                [ngClass]="{ 'reminder-active': isOpen }"
                id="Path_2981"
                d="M414.819 279.384a.7.7 0 0 0-.875.458c-.044.141-.093.282-.146.42a.7.7 0 0 0 1.3.5c.063-.164.121-.333.173-.5a.7.7 0 0 0-.452-.878z"
                class="reminder"
                transform="translate(-398.302 -268.983)"
              />
              <path
                [ngClass]="{ 'reminder-active': isOpen }"
                id="Path_2982"
                d="M7.394 17.547a7.21 7.21 0 0 1-1.794-.569l-.02-.011c-.134-.063-.267-.13-.4-.2a7.45 7.45 0 0 1-.695-.434A7.287 7.287 0 0 1 4.52 4.395l.025-.017a7.3 7.3 0 0 1 8.181-.066l-.546.788c-.152.219-.058.379.207.355l2.37-.212a.39.39 0 0 0 .353-.511l-.636-2.292c-.071-.257-.254-.288-.405-.068l-.547.79A8.624 8.624 0 0 0 7.2 1.818q-.335.058-.661.141l-.025.007a8.611 8.611 0 0 0-4.775 3.2l-.03.037c-.04.053-.079.108-.117.163-.063.089-.125.181-.184.273l-.02.033A8.609 8.609 0 0 0 .009 10.75v.018c.008.175.022.353.041.528 0 .011 0 .022.005.033.019.176.044.352.075.528a8.618 8.618 0 0 0 2.43 4.674l.009.009a8.8 8.8 0 0 0 1.113.93 8.61 8.61 0 0 0 3.463 1.449.7.7 0 0 0 .246-1.375z"
                class="reminder"
                transform="translate(0 -1.688)"
              />
              <path
                [ngClass]="{ 'reminder-active': isOpen }"
                id="Path_2983"
                d="M206.552 83.2a.565.565 0 0 0-.565.565v5.629l5.148 2.661a.565.565 0 0 0 .519-1l-4.537-2.355v-4.937a.565.565 0 0 0-.565-.563z"
                class="reminder"
                transform="translate(-198.295 -80.154)"
              />
            </g>
          </g>
        </g>
      </svg>
      <div *ngIf="!isOpen">
        <button
          type="button"
          class="btn btn-primary"
          (click)="sendProgress(data.progress, textMessage)"
        >
          Save changes
        </button>
        <button
          type="button"
          class="btn btn-cancel text-gray-500"
          (click)="data.showReminder = false"
        >
          Cancel
        </button>
      </div>
      <div *ngIf="isOpen">
        <button type="button" class="btn btn-primary" (click)="sendReminder()">
          Update
        </button>
        <button
          type="button"
          class="btn btn-cancel text-gray-500"
          (click)="data.showReminder = false"
        >
          Back
        </button>
      </div>
    </div>
  </div>
</ng-container>
