import { AuthService } from '@app/shared/services/auth.service';
import { NotificationsService } from '@app/shared/services/notifications.service';
import { BotResponseGroup } from '@app/models/chat';
import { Component, OnInit, Input } from '@angular/core';
import { NotificationGroups, Notification, Content } from '@app/models/notification';
import { NotificationType } from '@app/enums/notifications';
import { BsModalRef } from 'ngx-bootstrap/modal';


@Component({
  selector: 'app-chat-notifications',
  templateUrl: './chat-notifications.component.html',
  styleUrls: ['./chat-notifications.component.scss']
})
export class ChatNotificationsComponent implements OnInit {
  @Input() botMessages: BotResponseGroup;
  notificationGroups: NotificationGroups[];
  notifications: Notification[] = [];
  notificationDays = [
    {
      name: 'Today',
      start: -1,
      end: 1
    },
    {
      name: 'Yesterday',
      start: 0,
      end: 2
    },
    {
      name: 'Older',
      start: 1,
      end: 14
    }
  ];
  userId: string;
  unreadCount: number;

  constructor(private notificationsService: NotificationsService, private authService: AuthService, public activeModal: BsModalRef) {

   }

  ngOnInit(): void {
    this.notifications = this.botMessages.botResponse.Data.data;
    this.userId = this.authService.currentUser.UserUID;
    this.createNotificationGroups();
  }

  readAllNotif(event: Event) {
    event.stopPropagation();
    this.notificationsService.readAllNotif(this.userId, this.notifications).subscribe(() => {
      this.notificationsService.readAllNotif$.next(event);
    });
  }

  markAsRead(notification: Content, id: number) {
    this.notificationsService.markAsRead(this.userId, this.notifications, notification, id).subscribe(() => {
      this.notificationsService.readNotif$.next({notification, id });
    });
  }

  get notificationType() {
    return NotificationType;
  }

  openHeaderNotif() {
    this.activeModal.hide();
    this.notificationsService.openNotif$.next();
  }

  createNotificationGroups() {
  this.notificationGroups = this.notificationsService.createNotifGroups(this.notifications);
    }
}
