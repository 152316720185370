import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Reminder } from '@app/models/reminder';
import { Subject, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Error } from '@app/enums/errors';
import { add, format, set } from 'date-fns';

@Injectable({
  providedIn: 'root',
})
export class ReminderService {
  updateDays$ = new Subject<{ StatusID: number; days: number }>();
  constructor(private http: HttpClient, private toastService: ToastrService) {}

  setReminderForStatus(
    projectUID: string,
    taskStatusUID: number,
    statusName: string,
    days: number
  ) {
    const data = this.setReminderTime(statusName, days, null);
    return this.http
      .post(
        `${environment.baseUrl}api/project/${projectUID}/taskStatus/${taskStatusUID}/reminder`,
        data
      )
      .pipe(
        tap(() => {
          this.updateDays$.next({ StatusID: taskStatusUID, days });
        }),
        catchError(this.handleError.bind(this))
      );
  }

  setReminderForTask(
    projectUID: string,
    taskKey: number | string,
    statusName: string,
    days: number,
    firstReminder: Date
  ) {
    const data = this.setReminderTime(statusName, days, firstReminder);
    return this.http
      .post(
        `${environment.baseUrl}api/project/${projectUID}/task/${taskKey}/reminder`,
        data
      )
      .pipe(catchError(this.handleError.bind(this)));
  }

  updateReminderForTask(projectUID: string, taskKey: number | string, days: number) {
    const pattern = `yyyy-MM-dd'T'HH:mm:ssxxx`;
    const outputTime = format(new Date(), pattern);
    return this.http.patch(
      `${environment.baseUrl}api/project/${projectUID}/task/${taskKey}/reminder`,
      { Day: days, CurrentTime: outputTime }
    );
  }

  updateReminderForStatus(projectUID: string, taskStatusUID: number, days: number) {
    const pattern = `yyyy-MM-dd'T'HH:mm:ssxxx`;
    const outputTime = format(new Date(), pattern);
    return this.http.patch(
      `${environment.baseUrl}api/project/${projectUID}/taskStatus/${taskStatusUID}/reminder`,
      { Day: days, CurrentTime: outputTime }
    ).pipe(
      tap(() => {
        this.updateDays$.next({ StatusID: taskStatusUID, days });
      }));
  }

  reportProgress(
    projectKey: string,
    taskKey: number | string,
    data: { Progress: number; Message: string }
  ) {
    return this.http.post(
      `${environment.baseUrl}api/project/${projectKey}/task/${taskKey}/progress`,
      data
    );
  }

  deleteReminderForStatus(projectUID: string, taskStatusUID: number) {
    return this.http
      .delete<null>(
        `${environment.baseUrl}api/project/${projectUID}/taskStatus/${taskStatusUID}/reminder`
      )
      .pipe(
        tap(() => {
          this.updateDays$.next({ StatusID: taskStatusUID, days: null });
        })
      );
  }

  deleteReminderForTask(projectUID: string, taskStatusUID: number | string) {
    return this.http.delete<null>(
      `${environment.baseUrl}api/project/${projectUID}/task/${taskStatusUID}/reminder`
    );
  }

  setReminderTime(statusName: string, days: number, currentReminder: Date) {
    let data: Reminder;
    const currentDate = new Date();
    const newDate = add(currentDate, { days });
    let firstReminder: Date;
    if (!currentReminder) {
      switch (statusName) {
        case 'IN PROGRESS':
          firstReminder = set(newDate, { hours: 17, minutes: 0, seconds: 0 });
          break;
        case 'TODO':
          firstReminder = set(newDate, { hours: 9, minutes: 0, seconds: 0 });
          break;
        default:
          firstReminder = set(newDate, { hours: 12, minutes: 0, seconds: 0 });
      }
    } else {
      firstReminder = currentReminder;
    }
    const pattern = `yyyy-MM-dd'T'HH:mm:ssxxx`;
    const futureDate = format(firstReminder, pattern);
    data = {
      FirstReminder: futureDate,
      Year: 0,
      Month: 0,
      Day: days,
      Hour: 0,
    };
    return data;
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    switch (error?.error.ErrorCode) {
      case Error.ErrorCodeNotAllowed:
        errorMessage = 'Not enough permissions to set reminder';
        break;
      default:
        errorMessage = 'Something went wrong';
    }
    this.toastService.warning(errorMessage, 'Warning');
    return throwError(errorMessage);
  }
}
