import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-google-calendar-redirect',
  templateUrl: './google-calendar-redirect.component.html',
  styleUrls: ['./google-calendar-redirect.component.scss']
})
export class GoogleCalendarRedirectComponent implements OnInit {

  constructor(private route: ActivatedRoute) {
    this.route.queryParamMap.subscribe(params => {
      const state = params.get('state');
      const authCode = params.get('code');
      window.location.href = `https://${state}.jarvis.management/googleAuth?code=${authCode}`;
    });
  }

  ngOnInit(): void {
  }

}
