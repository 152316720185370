import { Error } from '@app/enums/errors';
import { Type } from '@app/enums/types';
import { Bubble } from '@app/models/chat';
import { InvitedUsers } from '@app/models/invitedUsers';
import { Role } from '@app/enums/role';
import { ChatTypes, StateCode } from '@app/enums/chat';
import { TaskService } from '@app/shared/services/task.service';
import { ProjectService } from '@app/shared/services/project.service';
import { ChatService } from '@app/shared/services/chat.service';
import {
  UserMessageObj,
  BotResponseGroup,
  BotResponse,
} from '@app/models/chat';
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Renderer2,
  AfterViewInit,
  ViewChildren,
  QueryList,
} from '@angular/core';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { Priority } from '@app/enums/priorities';
import { Groups } from '@app/models/task';
import { UserInviteService } from '@app/shared/services/user-invite.service';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  animations: [
    trigger('firstMsg', [
      state(
        'normal',
        style({
          opacity: 0,
          transform: 'translateX(-100px)',
        })
      ),
      state(
        'fade-in',
        style({
          opacity: 1,
          transform: 'translateX(0)',
        })
      ),
      transition('normal => fade-in', animate(400)),
    ]),
    trigger('messageBot', [
      state(
        'in',
        style({
          opacity: 1,
          transform: 'translateX(0)',
        })
      ),
      transition('void => *', [
        style({
          opacity: 0,
          transform: 'translateX(-100px)',
        }),
        animate(400),
      ]),
    ]),
    trigger('messageUser', [
      state(
        'in',
        style({
          opacity: 1,
          transform: 'translateX(0)',
        })
      ),
      transition('void => *', [
        style({
          opacity: 0,
          transform: 'translateX(100px)',
        }),
        animate(400),
      ]),
    ]),
  ],
})
export class ChatComponent implements OnInit, AfterViewInit {
  @ViewChild('modalImg') modalImg: ElementRef;
  @ViewChild('showImg') showImg: ElementRef;
  @ViewChild('descRef') descRef: ElementRef;
  @ViewChild('sendMsg') sendMsg: ElementRef;
  @ViewChild('btnSend', { static: true }) btnSend: ElementRef;
  state = 'normal';
  isOpen: boolean;
  groups: Groups[];
  isExpanded = false;
  isCanceledInvitation = false;
  isDescription: number;
  showExpandBtn = false;
  accordionStatus: string;
  botAnswer = true;
  botMessage = '';
  report: number;
  oldDescription: string[] = [];
  botResponseObj: BotResponseGroup;
  botMessages: BotResponseGroup[] = [];
  botResponse: BotResponse;
  message = '';
  userMessageObj: UserMessageObj;
  userMessages: UserMessageObj[] = [];
  reportMessages: [BotResponse, UserMessageObj];
  bubblesList: Bubble[] = [];
  dots = false;
  private NoBubbles: Bubble[] = [];
  private EmptyStateBubbles: Bubble[] = [
   { text: 'Create task', shouldSend: true },
   { text: 'Get tasks', shouldSend: true },
   { text: 'Create project', shouldSend: true },
   { text: 'Get projects', shouldSend: true },
   { text: 'Get users', shouldSend: true },
   { text: 'Invite user', shouldSend: true },
   { text: 'Get invited users', shouldSend: true },
   { text: 'Get notifications', shouldSend: true },
   { text: 'Help', shouldSend: true }
  ];
  private taskBubbles: Bubble[] = [
    { text: 'Set description to: ', shouldSend: false },
    { text: 'Move to: ', shouldSend: false },
    { text: 'Assign to: ', shouldSend: false },
    { text: 'Set title to: ', shouldSend: false },
    { text: 'Set priority to: ', shouldSend: false },
    { text: 'Set due date to: ', shouldSend: false },
    { text: 'Add attachment', shouldSend: true, shouldShow: true },
    { text: 'Add comment', shouldSend: true, shouldShow: true },
  ];
  private projectBubbles: Bubble[] = [
    { text: 'Get tasks', shouldSend: true },
    { text: 'Set description to: ', shouldSend: false },
    { text: 'Set lead to: ', shouldSend: false },
    { text: 'Set title to: ', shouldSend: false },
    { text: 'Confirm', shouldSend: true },
    { text: 'Cancel', shouldSend: true },
  ];
  private userBubbles: Bubble[] = [
    { text: 'Add to project: ', shouldSend: false },
    { text: 'Cancel', shouldSend: true },
  ];
  private commentBubbles: Bubble[] = [
    { text: 'Add comment: ', shouldSend: false },
    { text: 'Cancel', shouldSend: true },
  ];
  private assuranceBubbles: Bubble[] = [
    { text: 'Confirm', shouldSend: true },
    { text: 'Cancel', shouldSend: true },
  ];
  private cancelBubbles: Bubble[] = [
    { text: 'Cancel', shouldSend: true },
  ]
  constructor(
    private renderer: Renderer2,
    public modalRef: BsModalRef,
    private chatService: ChatService,
    private projectService: ProjectService,
    private taskService: TaskService,
    private inviteUserService: UserInviteService
  ) {
    this.bubblesList = this.EmptyStateBubbles;
    this.userMessageObj = {
      Message: '',
      Type: 1,
      StateCode: null,
      Data: null,
    };
  }
  ngOnInit() {
    this.chatService.uploadAttachment.subscribe((res) => {
      this.userMessageObj = {
        Message: null,
        Type: 1,
        StateCode: null,
        Data: null,
      };
      this.botResponse = {
        Type: ChatTypes.ATTACHMENT,
        StateCode: StateCode.STORED,
        Data: res.task
      };
      this.userMessageObj = { ...this.userMessageObj, Message: this.message };
      this.userMessages = [...this.userMessages, this.userMessageObj];
      this.botResponseObj = {
        botMessage: `The attachment ${res.fileName} has been uploaded to ${res.task.ProjectKey}-${res.task.TaskKey}.
        You can access it here.`,
        botResponse: this.botResponse,
      };
      this.botMessages = [...this.botMessages, this.botResponseObj];
      this.createSessionStorage();
    });
  }

  ngAfterViewInit() {
    const elem = document.getElementById('msg-reverse');
    elem.scrollTop = elem.scrollHeight;
  }

  get taskPriority() {
    return Priority;
  }

  get role() {
    return Role;
  }

  get taskTypes() {
    return Type;
  }

  get chatType() {
    return ChatTypes;
  }

  get stateCode() {
    return StateCode;
  }

  send() {
    if (this.message !== '' && this.botAnswer) {
      this.userMessageObj = { ...this.userMessageObj, Message: this.message };
      this.userMessages = [...this.userMessages, this.userMessageObj];
      this.botAnswer = false;
      this.dots = true;
      this.message = '';
      setTimeout(() => {
        const elem = document.getElementById('msg-reverse');
        elem.scrollTop = elem.scrollHeight;
      }, 0);
      this.chatService.sendMessage(this.userMessageObj).subscribe(
        (res) => {
          this.botResponse = res;
          this.handleChatTypes(res);
          this.handleRefreshData(res);
          this.handleStateBubbles(res);
          this.createBotResponseGroup();
          this.botAnswer = true;
          this.dots = false;
          this.createSessionStorage();
          setTimeout(() => {
            const elem = document.getElementById('msg-reverse');
            elem.scrollTop = elem.scrollHeight;
          }, 0);
        },
        (error) => {
          this.dots = false;
          this.botAnswer = true;
          if (error?.error?.ErrorCode === Error.ErrorCodeNothingToConfirm) {
            this.userMessageObj = {
              Message: '',
              StateCode: 0,
              Type: 1,
              Data: null,
            };
            this.botResponse = null;
            this.botMessage = 'Nothing to confirm';
            this.createBotResponseGroup();
          } else {
            this.userMessageObj = {...this.userMessageObj, Message: ''};
            this.botResponse = null;
            this.botMessage = `Your message could not be sent. Please check network connection.`;
            this.createBotResponseGroup();
          }
          this.createSessionStorage();
        }
      );
    }
    this.changeButton();
  }

  sendBubbleMsg(bubbleMsg: Bubble) {
    this.message = bubbleMsg.text;
    this.sendMsg.nativeElement.focus();
    if (bubbleMsg.shouldSend) {
      this.send();
    }
  }
  sendAttachComments(message: string) {
    this.message = message;
    this.send();
  }

  handleRefreshData(result: BotResponse) {
    switch (true) {
      case result.Type === ChatTypes.PROJECT &&
        result.StateCode === StateCode.STORED:
        this.projectService.createSubject.next();
        break;
      case result.Type === ChatTypes.TASK &&
        result.StateCode === StateCode.STORED:
        this.taskService.getTaskSubject.next();
        break;
        case result.Type === ChatTypes.INVITEDUSER &&
        result.StateCode === StateCode.STORED:
        this.inviteUserService.inviteUser$.next(result.Data);
        break;
        case result.Type === ChatTypes.INVITEDUSER &&
        result.StateCode === StateCode.ADDED:
        this.projectService.createSubject.next();
        break;
        case result.StateCode === StateCode.REMOVED:
        this.inviteUserService.getInviteUser$.next();
        break;
    }
  }

  handleChatTypes(result: BotResponse) {
    switch (true) {
      case result.StateCode === StateCode.INIT ||
        result.StateCode === StateCode.ADDED:
        this.userMessageObj = {
          Message: '',
          StateCode: 0,
          Type: 1,
          Data: null,
        };
        this.botMessage = this.handleStateCode(result);
        break;
      case result.Type === ChatTypes.PROJECTLIST ||
      result.Type === ChatTypes.TASKLIST ||
      result.Type === ChatTypes.HELP ||
      result.Type === ChatTypes.INVITEDUSERLIST ||
      result.Type === ChatTypes.USERLIST ||
      result.Type === ChatTypes.NOTIFICATIONS:
        this.userMessageObj = {
          Message: '',
          StateCode: result.StateCode,
          Type: 1,
          Data: null,
        };
        this.botMessage = this.handleStateCode(result);
        break;
      case result.Type === ChatTypes.PLAINTEXT ||
      result.Type === ChatTypes.TASK ||
      result.Type === ChatTypes.PROJECT ||
      result.Type === ChatTypes.USER ||
      result.Type === ChatTypes.INVITEDUSER:
        this.userMessageObj = {
          Message: '',
          StateCode: result.StateCode,
          Type: result.Type,
          Data: result.Data,
        };
        this.botMessage = this.handleStateCode(result);
        break;
        case result.Type === ChatTypes.COMMENT ||
        result.Type === ChatTypes.ATTACHMENT ||
        result.Type === ChatTypes.COMMENTLIST ||
        result.Type === ChatTypes.ATTACHMENTLIST:
          this.userMessageObj = {
            Message: '',
            StateCode: result.StateCode,
            Type: result.Type,
            Data: result.Data?.Task,
          };
          this.botMessage = this.handleStateCode(result);
          break;
      default:
        this.userMessageObj = {
          Message: '',
          StateCode: result.StateCode,
          Type: 1,
          Data: null,
        };
        this.botMessage = this.handleStateCode(result);
    }
  }

  handleStateCode(res: BotResponse) {
    let message = '';
    switch (res.StateCode) {
      case StateCode.ASSIGNEEFORBIDDEN:
        message = `This person isn’t in this project.`;
        break;
      case StateCode.USERFORBIDDEN:
        message = `You are not allowed to access this project.`;
        break;
      case StateCode.EMAILALREADYINVITED:
        message = 'User with this email has already been invited to this workspace.';
        break;
      case StateCode.EMAILALREADYEXISTS:
        message = 'User with this email already exists in this workspace.';
        break;
      case StateCode.INVALIDUPDATESOURCE:
        message = 'Invalid Update Source.';
        break;
      case StateCode.INVALIDNAME:
        message = 'Invalid name.';
        break;
      case StateCode.INVALIDEMAIL:
        message = 'Invalid email.';
        break;
      case StateCode.INVALIDKEY:
        message = 'Invalid project key.';
        break;
      case StateCode.TASKNOTCREATEDYET:
        message = 'Finish creating a task before using attachments/comments';
        break;
      case StateCode.UNKNOWTASKSTATUS:
        message = 'Unknown task status.';
        break;
      case StateCode.UNKNOWNTASKTYPE:
        message = 'Unknown task type.';
        break;
      case StateCode.UNKNOWNPRIORITY:
        message = 'Unknown task priority.';
        break;
      case StateCode.UNKNOWNPERSON:
        message = 'Unknown person.';
        break;
      case StateCode.UNKNOWNPROJECT:
        message = 'Unknown project.';
        break;
      case StateCode.EMPTYCOMMENT:
        message = 'What would you like to comment?';
        break;
      case StateCode.EMPTYLEAD:
        message = 'Who would you like to set as a lead on this project?';
        break;
      case StateCode.EMPTYNAME:
        message = 'What is the name of the new member?';
        break;
      case StateCode.EMPTYEMAIL:
        message = 'What is the email of the new member?';
        break;
      case StateCode.EMPTYPROJECT:
        message = 'Which project should this task be put in?';
        break;
      case StateCode.EMPTYTITLE:
        message = 'There’s no title set. What do you want it to be?';
        break;
      case StateCode.NODATA:
        message = this.checkNoDataType(res.Type);
        break;
      case StateCode.INVALID:
        message = 'Sorry, I did not understand this.';
        break;
      case StateCode.NOMESSAGE:
        message = null;
        break;
      case StateCode.INIT:
        message = 'What can I help you with?';
        break;
      case StateCode.ASSURANCE:
        message = 'Is this what you meant?';
        break;
      case StateCode.STORED:
        message = 'Done. You can access it here.';
        break;
      case StateCode.SELECTED:
        message = 'What do you want to do with this?';
        break;
      case StateCode.REMOVED:
        message = 'Done. What can I help you with next?';
        break;
      case StateCode.ADDED:
        message = 'Done. This person is added to the project.';
        break;
      case StateCode.NOTSUPPORTED:
        message = 'This action is currently not supported';
        break;
      default:
        message = 'Sorry, I did not understand this.';
    }
    return message;
  }

  checkNoDataType(type: number) {
    let msg = '';
    switch (type) {
      case ChatTypes.PROJECTLIST:
        msg = 'There are no projects for the given search parameters.';
        break;
      case ChatTypes.TASKLIST:
        msg = 'There are no such tasks in the selected project.';
        break;
      case ChatTypes.USERLIST:
        msg = 'There are no users for the given search parameters.';
        break;
      case ChatTypes.INVITEDUSERLIST:
        msg = 'There are no invited users for the given search parameters.';
        break;
      case ChatTypes.COMMENTLIST:
          msg = 'There are no such comments in the selected task.';
          break;
      case ChatTypes.ATTACHMENTLIST:
          msg = 'There are no such attachments in the selected task.';
          break;
      case ChatTypes.NOTIFICATIONS:
          msg = 'There are no entries.';
          break;
      default:
         msg = 'Sorry, I did not understand this.';
    }
    return msg;
  }

  handleStateBubbles(res: BotResponse) {
     switch (true) {
      case res.StateCode <= StateCode.EMPTYTITLE &&
      res.StateCode >= StateCode.EMPTYCOMMENT:
        this.bubblesList = this.cancelBubbles;
        break;
      case res.StateCode === StateCode.STORED && res.Type !== ChatTypes.TASK:
        this.bubblesList = this.EmptyStateBubbles;
        break;
      case res.Type === ChatTypes.PROJECT:
        this.bubblesList = this.projectBubbles;
        break;
      case res.Type === ChatTypes.TASK && (res.StateCode === StateCode.USERFORBIDDEN || res.StateCode === StateCode.INVALIDKEY):
        this.bubblesList = this.EmptyStateBubbles;
        break;
      case res.Type === ChatTypes.TASK:
          if (res?.Data?.UID && (res.StateCode === StateCode.SELECTED || res.StateCode === StateCode.STORED)) {
            this.bubblesList = [...this.taskBubbles, ...this.cancelBubbles];
          } else if (res?.Data?.UID) {
            this.bubblesList = [...this.taskBubbles, ...this.assuranceBubbles];
          } else {
            this.bubblesList = [...this.taskBubbles.filter( item => !item.shouldShow), ...this.assuranceBubbles];
          }
          break;
        case res.Type === ChatTypes.INVITEDUSER:
          this.bubblesList = this.assuranceBubbles;
          if (res.StateCode === StateCode.ADDED) {
            this.bubblesList = this.EmptyStateBubbles;
          }
          if (res.StateCode < StateCode.NOMESSAGE) {
            this.bubblesList = this.cancelBubbles;
          }
          break;
        case res.Type === ChatTypes.USER:
          this.bubblesList = this.userBubbles;
          break;
        case res.Type === ChatTypes.COMMENTLIST:
          this.bubblesList = this.commentBubbles;
          break;
        default:
          this.bubblesList = this.EmptyStateBubbles;
          break;
     }
  }

  selectElement(message: string) {
    this.message = message;
    this.send();
  }

  createSessionStorage() {
    sessionStorage.setItem('userMessages', JSON.stringify(this.userMessages));
    sessionStorage.setItem('bubblesList', JSON.stringify(this.bubblesList));
    sessionStorage.setItem('botMessages', JSON.stringify(this.botMessages));
    sessionStorage.setItem('userMessageObj', JSON.stringify(this.userMessageObj));
  }

  createBotResponseGroup() {
    this.botResponseObj = {
      botMessage: this.botMessage,
      botResponse: this.botResponse,
    };

    this.botMessages = [...this.botMessages, this.botResponseObj];
  }

  reportMessage(i: number) {
    const lastReceivedMessage = this.botMessages[i].botResponse;
    const lastSentMessage = this.userMessages[i];
    this.reportMessages = [lastReceivedMessage, lastSentMessage];
    this.chatService.reportMessage(this.reportMessages).subscribe(() => {
      this.report = i;
    });
  }

  cancelInvitation(id: number, i: number, isObject: string) {
    if (isObject === 'true') {
      this.message = 'cancel';
      this.userMessageObj = {
        Message: '',
        StateCode: 0,
        Type: 1,
        Data: null,
      };
      this.bubblesList = this.EmptyStateBubbles;
      this.send();
      } else {
        this.inviteUserService.deleteInvitedUser(id).subscribe(() => {
        this.botMessages[i].botResponse.Data = this.botMessages[i].botResponse.Data.filter( (item: InvitedUsers) => item.ID !== id);
        this.inviteUserService.deleteinviteUser$.next(id);
      });
      }
  }

  changeButton() {
    if (this.message === '') {
      this.renderer.setStyle(
        this.btnSend.nativeElement,
        'background-color',
        'lightgray'
      );
      this.renderer.setStyle(
        this.btnSend.nativeElement,
        'border-color',
        'lightgray'
      );
    } else {
      this.renderer.setStyle(
        this.btnSend.nativeElement,
        'background-color',
        '#389bff'
      );
      this.renderer.setStyle(
        this.btnSend.nativeElement,
        'border-color',
        '#389bff'
      );
    }
  }

  isOpenChange(isOpen: boolean, accStatus: string) {
    this.isOpen = isOpen;
    this.accordionStatus = accStatus;
  }

  expandText(
    desc: HTMLParagraphElement,
    i: number,
    event: Event,
    newClass: string
  ) {
    const limitChar = 70;
    const hasClass = (event.target as HTMLButtonElement).classList.contains(
      newClass
    );
    setTimeout(() => {
      if (!hasClass) {
        this.renderer.addClass(event.target, newClass);
        (event.target as HTMLButtonElement).innerHTML = 'Read less';
        const newExpandText = this.botMessages[i].botResponse.Type === ChatTypes.USER ?
        this.botMessages[i].botResponse.Data.Bio :
        this.botMessages[i].botResponse.Data.Description;
        this.renderer.setProperty(
          desc,
          'innerHTML',
          newExpandText
        );
      } else {
        this.renderer.removeClass(event.target, newClass);
        (event.target as HTMLButtonElement).innerHTML = 'Read more';
        const text = desc.textContent.substring(0, limitChar);
        this.renderer.setProperty(desc, 'innerHTML', text);
      }
    });
  }
}
