import { Pipe, PipeTransform } from "@angular/core";
import { Task } from "@app/models/task";

@Pipe({
  name: "sortTasksList",
})
export class SortTasksListPipe implements PipeTransform {
  transform(value: Task[], key: string, isActive: boolean): Task[] {
    let tasks: Task[];
    if (!value) {
      return;
    }
    if (!key) {
      return value.sort((a, b) => {
        const dateA = new Date(a.CreatedAt);
        const dateB = new Date(b.CreatedAt);
        return dateB.getTime() - dateA.getTime();
      });
    }
    if (isActive) {
      switch (true) {
        case key === "Title" || key === "AssigneeName":
          tasks = value.sort((a, b) => {
            const nameA = a[key].toUpperCase();
            const nameB = b[key].toUpperCase();
            if (nameA < nameB) {
              return 1;
            }
            if (nameA > nameB) {
              return -1;
            }
            return 0;
          });
          break;
        case key === "Priority":
          tasks = value.sort((a, b) => {
            return b.Priority - a.Priority;
          });
          break;
        case key === "DueDate":
          tasks = value.sort((a, b) => {
            const dateA = new Date(a[key]);
            const dateB = new Date(b[key]);
            return dateB.getTime() - dateA.getTime();
          });
          break;
      }
    } else {
      switch (true) {
        case key === "Title" || key === "AssigneeName":
          tasks = value.sort((a, b) => {
            const nameA = a[key].toUpperCase();
            const nameB = b[key].toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          });
          break;
        case key === "Priority":
          tasks = value.sort((a, b) => {
            return a.Priority - b.Priority;
          });
          break;
        case key === "DueDate":
          tasks = value.sort((a, b) => {
            const dateA = new Date(a[key]);
            const dateB = new Date(b[key]);
            return dateA.getTime() - dateB.getTime();
          });
          break;
      }
    }
    return tasks;
  }
}
