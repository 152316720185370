import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RecursService {

  constructor(private http: HttpClient) { }


  sendRecurs(projectUID: string, taskUID: string, data: {Period: string, Start: string}) {
   return this.http.post(`${environment.baseUrl}api/project/${projectUID}/task/${taskUID}/recur`, data);
  }

  deleteRecurs(projectUID: string, taskUID: string) {
   return this.http.delete(`${environment.baseUrl}api/project/${projectUID}/task/${taskUID}/recur`);
   }
}
