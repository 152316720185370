<div
class="project-list cursor-pointer"
(click)="
  selectElement(project.Key)
"
*ngFor="let project of projects"
>
<div class="d-flex align-items-center">
  <div
    class="project-list-color"
    [ngStyle]="{
      'background-color': project.Icon
    }"
  >
    {{ project.Key }}
  </div>
  <span class="project-list-label">Project:</span>
  <p class="mb-0">
    {{ project.Name }}
  </p>
</div>
<div class="project-list-lead">
  {{ project.LeadName | userAcronym }}
</div>
</div>
