import { UserService } from '@app/shared/services/user.service';
import { UserProjectService } from '@app/shared/services/user-project.service';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { User } from '@app/models/user';
import { Error } from '@app/enums/errors';
import { Role } from '@app/enums/role';
import { ToastrService } from 'ngx-toastr';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-add-to-project',
  templateUrl: './add-to-project.component.html',
  styleUrls: ['./add-to-project.component.scss']
})
export class AddToProjectComponent implements OnInit {
  @Input() projectUID: string;
  isDropup = true;
  showDropdown = true;
  users: User[];
  chooseUsers: string[] = [];
  @Input() selectedUsers: User[];
  @Output() selectedUserEvent = new EventEmitter<User[]>();
  @Output() removeFilterEvent = new EventEmitter<string>()
  dropdownUsers: User[];
  currentUser = JSON.parse(localStorage.getItem('currentUser'));

  constructor(private toast: ToastrService, private usersToProject: UserProjectService,  private userService: UserService) { }

  ngOnInit() {
    this.chooseUsers = this.selectedUsers.map(user => user.UID);
    this.getAllUsers();
  }

  chooseUsersList(event: Event, user: User) {
    const isChecked = (event.target as HTMLInputElement).checked;
    if (isChecked) {
       this.addUserToProject(user);
    } else {
      this.removeUserfromProject(user.UID);
  }
}

  removeUserfromProject(userUID: string) {
    this.usersToProject.removeUsersfromProject(this.projectUID, userUID).subscribe( () => {
      this.chooseUsers = this.chooseUsers.filter((item) => item !== userUID);
      this.selectedUsers = this.selectedUsers.filter(
        (item) => item.UID !== userUID
      );
      this.removeFilterEvent.emit(userUID);
      this.selectedUserEvent.emit(this.selectedUsers);
      this.onShown();
    },
    error => {
      if (error.error.ErrorCode === Error.ErrorCodeLeadExist) {
        this.toast.warning(
          `You can’t remove this person from project until you set someone else as a lead`
        );
      }
      if (error.error.ErrorCode === Error.ErrorCodeNotAllowed) {
          this.toast.warning('Not enough permissions to remove User', 'Warning!');
      }
      this.onShown();
  });
  }

  addUserToProject(user: User) {
    this.chooseUsers = [...this.chooseUsers, user.UID];
    this.usersToProject.addUserstoProject(this.projectUID, this.chooseUsers).subscribe( () => {
      this.selectedUsers = [...this.selectedUsers, user];
      this.selectedUserEvent.emit(this.selectedUsers);
      this.onShown();
    }, error => {
    if (error.error.ErrorCode === Error.ErrorCodeNotAllowed) {
      this.toast.warning('Not enough permissions to add User', 'Warning!');
      }
    this.onShown();
      }
    );
  }

  onShown(): void {
    setTimeout(() => {
      this.selectedUsers.forEach((item) => {
        const checkUser = document.getElementById(
          'check' + item.UID
        ) as HTMLInputElement;
        if (checkUser) {
          checkUser.checked = true;
        }
      });
    }, 0);
  }

  filterUsers(usersArray: User[]) {
    let result: User[];
    switch (true) {
        case this.currentUser.Role === Role.Member:
          result = usersArray.filter(user => !this.selectedUsers.some(selectedUser =>
            user.UID === selectedUser.UID &&
            user.Role !== Role.Member));
          break;
        case this.currentUser.Role === Role.Admin:
          result = usersArray.filter(user => !this.selectedUsers.some(selectedUser =>
            user.UID === selectedUser.UID &&
            (selectedUser.Role !== Role.Member ||
            selectedUser.IsLead === true) &&
            user.UID !== this.currentUser.UserUID));
          break;
        case this.currentUser.Role === Role.SuperAdmin:
          result = usersArray.filter(user => !this.selectedUsers.some(selectedUser =>
            user.UID === selectedUser.UID &&
            user.Role === Role.SuperAdmin &&
            user.UID !== this.currentUser.UserUID));
          break;
      }
    return result;
  }

  getAllUsers() {
    const getAllUsers$ =  this.userService.getPersons().pipe(map(result => {
      this.users = this.filterUsers(result.data);
      if (
        this.currentUser.Role === Role.Member
      ) {
        this.showDropdown = false;
      }
    }));

    getAllUsers$.subscribe();
  }

}
