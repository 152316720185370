import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-sort-icon',
  templateUrl: './sort-icon.component.html',
  styleUrls: ['./sort-icon.component.scss']
})
export class SortIconComponent implements OnInit {
  @Input() isSortActive: boolean;
  @Input() key: string;
  @Input() type: string;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
  }

}
