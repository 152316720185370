<div class="modal-header border-bottom-none">
<button type="button" class="close" aria-label="Close">
  <span aria-hidden="true" class="closeModal">&times;</span>
</button>
</div>
  <div class="modal-body search-content">
      <input [appFocus] = "true" type="text" class="form-control search-input" placeholder="Search Jarvis">
      <div class="project-search mt-5">
        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <title>14C98176-453E-4D2E-90BF-84ECA3831AE8</title>
          <desc>Created with sketchtool.</desc>
          <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="task-manager" transform="translate(-36.000000, -174.000000)" fill="#C0C4CA">
                  <g id="Tasks">
                      <g id="Sidebar" transform="translate(0.000000, 74.375000)">
                          <g class="active-svg" transform="translate(36.125000, 99.875000)">
                              <path d="M9.49615684,8.76558516 L9.49615684,4.98803536 L13.2737066,8.76558516 L9.49615684,8.76558516 Z M13.8789494,21.9139629 L1.46103711,21.9139629 L1.46103711,4.38279258 L8.03522598,4.38279258 L8.03522598,10.226516 L13.8789494,10.226516 L13.8789494,21.9139629 Z M1.46103711,2.92186172 C0.654394571,2.92186172 0.00010625,3.57615004 0.00010625,4.38279258 L0.00010625,21.9139629 C0.00010625,22.7206054 0.654394571,23.3748938 1.46103711,23.3748938 L13.8789494,23.3748938 C14.685592,23.3748938 15.3398803,22.7206054 15.3398803,21.9139629 L15.3398803,8.76558516 L9.49615684,2.92186172 L1.46103711,2.92186172 Z M17.5312766,5.84372344 L17.5312766,2.06617364 L21.3088264,5.84372344 L17.5312766,5.84372344 Z M17.5312766,0 L9.49615684,0 C8.6895143,0 8.03522598,0.654288321 8.03522598,1.46093086 L9.49615684,1.46093086 L16.0703457,1.46093086 L16.0703457,7.3046543 L21.9140691,7.3046543 L21.9140691,18.9921012 L16.8008111,18.9921012 L16.8008111,20.453032 L21.9140691,20.453032 C22.7207117,20.453032 23.375,19.7987437 23.375,18.9921012 L23.375,5.84372344 L17.5312766,0 Z" id="Icon"></path>
                          </g>
                      </g>
                  </g>
              </g>
          </g>
      </svg>
      <span class="ml-2 text-gray"> Projects </span>
         <ul>
           <li>
             <div class="project-icon"> </div>
             <span> Projects1 </span>
           </li>
           <li>
            <div class="project-icon2"> </div>
            <span> Projects2 </span>
          </li>
         </ul>
      </div>
      <div class="task-search mt-5">
        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <title>6F929AFF-FBA3-4788-A186-999E40864C1B</title>
          <desc>Created with sketchtool.</desc>
          <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="task-manager" transform="translate(-36.000000, -248.000000)" fill="#C0C4CA">
                  <g id="Tasks">
                      <g id="Sidebar" transform="translate(0.000000, 74.375000)">
                          <g id="Tasks-(Active)" transform="translate(0.000000, 148.750000)">
                              <g  class="active-svg" transform="translate(36.124912, 25.095611)">
                                  <path d="M23.2815881,16.3593145 L21.1905881,15.331877 L19.5968381,16.190377 L21.3404006,17.042502 L11.7885256,22.189252 L2.34715059,17.048877 L4.19058809,16.2148145 L2.60215059,15.348877 L0.410213093,16.358252 C0.162650593,16.481502 0.00433809342,16.7333145 8.80934197e-05,17.010627 C-0.00416190658,17.286877 0.145650593,17.5429395 0.387900593,17.675752 L11.4304631,23.6884395 C11.5409631,23.749002 11.6631506,23.7798145 11.7864006,23.7798145 C11.9075256,23.7798145 12.0286506,23.7500645 12.1391506,23.6905645 L23.2975256,17.6768145 C23.5408381,17.546127 23.6917131,17.2900645 23.6885256,17.0138145 C23.6842756,16.736502 23.5280881,16.4846895 23.2815881,16.3593145 L23.2815881,16.3593145 Z" id="Icon-4"></path>
                                  <path d="M23.2815881,11.1530645 L21.0737131,10.032127 L19.4810256,10.8916895 L21.3404006,11.836252 L11.7885256,16.983002 L2.34715059,11.842627 L4.33083809,10.846002 L2.74133809,9.98006449 L0.410213093,11.152002 C0.162650593,11.2763145 0.00433809342,11.5270645 8.80934197e-05,11.804377 C-0.00416190658,12.080627 0.145650593,12.3366895 0.387900593,12.469502 L11.4304631,18.4821895 C11.5409631,18.542752 11.6631506,18.5735645 11.7864006,18.5735645 C11.9075256,18.5735645 12.0286506,18.5438145 12.1391506,18.4843145 L23.2975256,12.4705645 C23.5408381,12.339877 23.6917131,12.0838145 23.6885256,11.8075645 C23.6842756,11.530252 23.5280881,11.2784395 23.2815881,11.1530645 L23.2815881,11.1530645 Z" id="Icon-3"></path>
                                  <path d="M11.6950256,0.0786269922 C11.9075256,-0.0265605078 12.1561506,-0.0265605078 12.3665256,0.0807519922 L12.3665256,0.0807519922 L23.4090881,5.68862699 C23.6555881,5.81400199 23.8128381,6.06475199 23.8160256,6.34312699 C23.8192131,6.61937699 23.6683381,6.87543949 23.4250256,7.00612699 L23.4250256,7.00612699 L12.2666506,13.0188145 C12.1561506,13.0783145 12.0350256,13.1080645 11.9139006,13.1080645 C11.7906506,13.1080645 11.6695256,13.077252 11.5579631,13.017752 L11.5579631,13.017752 L0.515400593,7.00400199 C0.273150593,6.87225199 0.123338093,6.61618949 0.127588093,6.33887699 C0.132900593,6.06262699 0.290150593,5.81081449 0.537713093,5.68650199 L0.537713093,5.68650199 Z M12.0275881,1.57675199 L2.47465059,6.37712699 L11.9160256,11.5185645 L21.4679006,6.37075199 L12.0275881,1.57675199 Z" id="Combined-Shape"></path>
                              </g>
                          </g>
                      </g>
                  </g>
              </g>
          </g>
      </svg>
      <span class="ml-2 text-gray"> Tasks </span>
      </div>
  </div>
