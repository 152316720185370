import { Task } from '@app/models/task';
import { Comment } from '@app/models/comment';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-chat-comments',
  templateUrl: './chat-comments.component.html',
  styleUrls: ['./chat-comments.component.scss']
})
export class ChatCommentsComponent implements OnInit {
  @Input() comments: Comment[];
  @Input() task: Task;
  constructor() { }

  ngOnInit(): void {
  }

}
