import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Project } from '@app/models/project';

@Component({
  selector: 'app-chat-project-list',
  templateUrl: './chat-project-list.component.html',
  styleUrls: ['./chat-project-list.component.scss']
})
export class ChatProjectListComponent implements OnInit {
 @Input() projects: Project[];
 @Output() getProject: EventEmitter<string> = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }


  selectElement(projectKey: string) {
    const message = `select project ${projectKey}`;
    this.getProject.emit(message);
  }
}
