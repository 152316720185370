<svg xmlns="http://www.w3.org/2000/svg" width="30.171" height="30.17" viewBox="0 0 30.171 30.17">
  <defs>
      <style>
          .cls-1{fill:#96989b;stroke:#96989b;stroke-width:.4px}
      </style>
  </defs>
  <g id="Group_792" transform="translate(-1765.679 -18.758)">
      <path id="Path_886" d="M10.843 3.434l.9.129a6.275 6.275 0 0 1 5.373 6.2v3.023a8.583 8.583 0 0 0 3.043 6.563.576.576 0 0 1-.367 1.02H1.9a.576.576 0 0 1-.376-1.012 8.6 8.6 0 0 0 3.053-6.571V9.759a6.274 6.274 0 0 1 5.373-6.2l.9-.129m0-3.434a1.084 1.084 0 0 0-1.091 1.088v1.171a7.6 7.6 0 0 0-6.506 7.5v3.023a7.27 7.27 0 0 1-2.589 5.57A1.9 1.9 0 0 0 1.9 21.686h17.889a1.9 1.9 0 0 0 1.224-3.347 7.263 7.263 0 0 1-2.579-5.557V9.759a7.6 7.6 0 0 0-6.506-7.5V1.084A1.084 1.084 0 0 0 10.843 0z" class="cls-1" transform="rotate(-30 938.722 -3280.374)"/>
      <path id="Path_887" d="M3.18 2.6A3.251 3.251 0 0 0 6.36 0H0a3.251 3.251 0 0 0 3.18 2.6z" class="cls-1" transform="rotate(-30 977.993 -3306.226)"/>
  </g>
</svg>
