<div class="video-wrapper">
  <video [hidden]="isPreviewVideo" #video class="video"></video>

  <video
    [hidden]="!isPreviewVideo"
    class="previewVideo"
    #videoPreview
    controls
  ></video>
  <button
    type="button"
    *ngIf="!isRecordingStoped"
    (click)="stopRecording()"
    class="btn btn-primary btn-stop-recording"
  >
    <span *ngIf="isRecording">
      <i class="fas fa-stop-circle"></i>
      RECORDING {{ videoDuration }}
    </span>
    <span *ngIf="!isRecording">
      {{ countDownDuration }}
    </span>
  </button>
  <div class="text-center mt-4">
    <ng-container *ngIf="isRecordingStoped">
      <button (click)="uploadRecordedFile()" class="btn btn-primary mr-3">
        Upload
      </button>
      <button (click)="CancelRecording()" class="btn btn-cancel text-gray-600">Cancel</button>
    </ng-container>
  </div>
</div>
