<div
  class="screen-recording cursor-pointer"
  tooltip="Screen recording"
  [delay] = "800"
  placement="bottom"
  (click)="startRecording()"
>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19.152"
    height="19.152"
    viewBox="0 0 19.152 19.152"
  >
    <g
      id="Group_1137"
      data-name="Group 1137"
      transform="translate(-1407.098 -311.098)"
    >
      <circle
        id="Ellipse_135"
        cx="6.094"
        cy="6.094"
        r="6.094"
        fill="#389bff"
        class="circle-active"
        data-name="Ellipse 135"
        transform="translate(1410.58 314.58)"
      />
      <g
        id="Ellipse_136"
        fill="none"
        stroke="#389bff"
        stroke-width="1.5px"
        data-name="Ellipse 136"
        class="g-active"
        transform="translate(1407.098 311.098)"
      >
        <circle cx="9.576" cy="9.576" r="9.576" stroke="none" />
        <circle cx="9.576" cy="9.576" r="8.826" />
      </g>
    </g>
  </svg>
</div>
