import { ToastrService } from 'ngx-toastr';
import { TypeService } from '@app/shared/services/type.service';
import { MentionReplacePipe } from '@app/shared/pipes/mention-replace.pipe';
import { TaskShareDetailsComponent } from '@app/layout/tasks/task-share-details/task-share-details.component';
import {
  ActivatedRoute,
  Router,
  NavigationEnd,
  RouterEvent,
} from '@angular/router';
import {
  Component,
  OnInit,
  Renderer2,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { Task } from '@app/models/task';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ProjectService } from '@app/shared/services/project.service';
import { UserService } from '@app/shared/services/user.service';
import { TaskService } from '@app/shared/services/task.service';
import {  filter,
} from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { MentionService } from '@app/shared/services/mention.service';
import { GoogleCalendarService } from '@app/shared/services/google-calendar.service';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-task-detail-full',
  templateUrl: './task-detail-full.component.html',
  styleUrls: ['./task-detail-full.component.scss'],
  providers: [MentionReplacePipe]
})
export class TaskDetailFullComponent extends TaskShareDetailsComponent
  implements OnInit, AfterViewInit, OnDestroy {
  loading = false;
  isNotFound = false;
  ARCHIVE = 1;
  initialTitle: string;
  subscription: Subscription[] = [];
  isShopify: boolean;
  task: Task;
  taskForm: FormGroup;
  constructor(
    private projectService: ProjectService,
    private route: ActivatedRoute,
    public userService: UserService,
    public taskService: TaskService,
    public typeService: TypeService,
    public modalService: BsModalService,
    public formBuilder: FormBuilder,
    public renderer: Renderer2,
    public router: Router,
    public mentionService: MentionService,
    private mentionReplace: MentionReplacePipe,
    public googleCalendarService: GoogleCalendarService,
    public toast: ToastrService
  ) {
    super(taskService, typeService, userService, formBuilder, modalService, renderer, router, mentionService, googleCalendarService, toast);
    this.createForm();
    this.subscription.push(
      this.route.params.subscribe((params) => {
        this.isNotFound = false;
        const splitParam = params.taskId.split('-');
        this.projectId = splitParam[0];
        this.taskId = splitParam[1];
      })
    );
  }

  ngOnInit() {
    this.taskService.taskMenuSubject.next(false);
    this.taskService.taskCreateBtnSubject.next(true);
    this.projectService.projectMenuSubject.next(true);
    this.subscription.push(
      this.router.events
        .pipe(filter((event: RouterEvent) => event instanceof NavigationEnd))
        .subscribe(() => {
          this.fetchData();
        })
    );
    this.subscription.push(this.typeService.createType$.subscribe(res => {
      this.types = [...this.types, res];
   }));
    this.subscription.push(this.typeService.deleteType$.subscribe(id => {
    this.types = this.types.filter( item => item.ID !== id );
   }));
   this.subscription.push(
    this.typeService.updateType$.subscribe((res) => {
      const updatedTypes =  this.types.map(type => {
        if (type.ID === res.id) {
          const updatedType = {...type, Name: res.Name};
          return updatedType;
        }
        return type;
      });
      this.types = updatedTypes;
    })
  );
  this.subscription.push(
    this.taskService.updateTaskStatus$.subscribe((res) => {
     this.taskForm.get('Status').setValue(res.statusID);
    })
  )
    this.fetchData();
  }

  ngAfterViewInit() {
    this.onChanges();
  }

  fetchData() {
    this.getTaskTypes();
    this.getTaskStatus();
    this.getPersons();
    this.taskDetail();
  }

  archiveTask(id: string) {
    this.taskService.archiveTasks(this.projectId, id).subscribe(() => {
      this.router.navigate(['/project', this.projectId]);
    });
  }

  unarchiveTask(id: string) {
    this.taskService.unarchiveTasks(this.projectId, id).subscribe(() => {
      this.router.navigate(['/project', this.projectId]);
    });
  }

  deleteTask() {
    this.taskService.deleteTask(this.taskId, this.projectId).subscribe(() => {
      this.router.navigate(['/project', this.projectId]);
    });
  }

  taskDetail() {
    this.loading = true;
    this.taskService.getTask(this.taskId, this.projectId).subscribe(
      (data) => {
        this.task = data;
        this.isShopify = this.task.IsShopifyCreated;
        this.task.Day ? this.isReminderActive = true : this.isReminderActive = false;
        this.IsEventAdded = this.task.IsEventAdded;
        this.currentCalendarDate = this.task.EventDueDate;
        this.isGCalLoggedIn = this.task.IsGCalLoggedIn;
        const desc = this.mentionReplace.transform(this.task.Description);
        this.initialTitle = this.task.Title;
        this.editorInitialValue = desc ? desc : 'Add description';
        this.editorValue = desc;
        this.typeId = this.task.TaskTypeIconID;
        this.taskTypeName = this.task.TaskTypeName;
        this.taskForm.patchValue({
          Title: this.task.Title.trim(),
          ProjectUID: this.task.ProjectUID,
          Status: this.task.Status,
          Priority: this.task.Priority,
          DueDate:  this.task.DueDate,
          TaskType: this.task.TaskType,
          AssigneeUID: this.task.AssigneeUID,
          CreatorUID: this.task.CreatorUID,
          Description: desc,
        }, {emitEvent: false});
        this.dataRecurs = {
          Start: this.task.RecurrenceStart,
          Period: this.task.RecurrencePeriod
        };
        this.getAssignee(this.task);
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        this.isNotFound = true;
      }
    );
  }

  ngOnDestroy() {
    this.subscription.forEach((subscription) => subscription.unsubscribe());
  }
}
