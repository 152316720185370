<nav class="navbar navbar-expand-lg navbar-light home-placeholder">
  <a class="navbar-brand mr-0" [routerLink]= "!isGuest ? '/today' : null">
    <img
      [src]=" isPremium ? './assets/images/logo-gold.png' : './assets/images/logo-image.png'"
      width="36px"
      height="36px"
      alt="logo"
    />
  </a>
  <button
    class="navbar-toggler"
    type="button"
    (click)="isCollapsed = !isCollapsed"
    [attr.aria-expanded]="!isCollapsed" aria-controls="collapseBasic"
  >
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse nav-collapse" id="collapseBasic" [collapse]="!isCollapsed" [isAnimated]="true">
    <ul class="navbar-nav header-icons" *ngIf = "!isGuest">
      <app-topbar-icons> </app-topbar-icons>
    </ul>
    <ul class="navbar-nav mx-auto header-filters" *ngIf="showTaskMenu">
      <app-task-menu> </app-task-menu>
    </ul>
    <ul class="navbar-nav ml-auto header-projects">
      <li *ngIf="showCreateBtn && !isGuest">
        <button class="btn btn-create-task" (click)="openCreateTask()">
          Create task
        </button>
      </li>
      <ng-container *ngIf="showProjects && projects">
        <li
          *ngFor="let project of projects | filterTemplates: false | projectsSlice: width: 'carousel'"
          class="project-list"
        >
          <a
            [tooltip] = "project.Name"
            [delay] = "800"
            routerLinkActive="project-active"
            class="d-flex flex-column align-items-center cursor-pointer"
          >
            <div
              class="project-box"
            >
            <div class="project-details"  (click) ="openProjectDetails(project)" [ngStyle]="{ 'background-color': project.Icon }">
              <img class="d-none" src="/assets/images/dots.png" width="16" height="4" alt="dots" />
            </div>
            <div class="project-key" [ngStyle]="{ 'background-color': project.Icon }">
              <a class="d-none"
              (click) ="changeProject(project.UID)"
              [routerLink]="['/project', project.Key]">
              {{ project.Key }}
            </a>
            </div>
          </div>
            <div class="project-name">{{ project.Key }}</div>
          </a>
        </li>
      </ng-container>
    </ul>
    <ul class="navbar-nav navbar-right flex-row">
      <div class="search-input d-none" (click)="openSearch()">
        <span class="ion-ios-search search-icon"></span>
      </div>
      <li class="nav-item dropdown notifications-dropdown" dropdown #dropdown="bs-dropdown" *ngIf="!isGuest">
        <a
          dropdownToggle (click)="false"
          class="dropdown-toggle"
          id="basic-link"
          aria-controls="basic-link-dropdown"
          tooltip="Notifications"
          [delay] = "800"
          placement="bottom"
        >
          <div class="unreadCount" *ngIf="unreadNotif">
          </div>
           <app-notification-svg> </app-notification-svg>
        </a>
        <div
          class="dropdown-menu dropdown-menu-right notifications-dropdown-menu" *dropdownMenu
          id="basic-link-dropdown"
          aria-labelledby="basic-link"
        >
          <div
            class="search-results scroll scroll-auto"
            infiniteScroll
            [infiniteScrollDistance]="1"
            [infiniteScrollThrottle]="50"
            [scrollWindow]="false"
            (scrolled)="onScroll()"
          >
            <div class="mark-all-as-read" (click)="readAllNotif($event)">
              Mark all as read
            </div>
            <ng-container
              *ngFor="
                let notificationGroup of notificationGroups;
                let first = last
              "
            >
              <div
                class="notifications-content my-3"
                *ngIf="notificationGroup.data.length > 0"
              >
                <div class="notifications-date">
                  {{ notificationGroup.name }}
                </div>
              </div>
              <div
                *ngFor="let notification of notificationGroup.data"
                class="notifications position-relative"
                [ngStyle]="{
                  'background-color': notification.IsRead
                    ? '#fbfbfb'
                    : '#f5faff'
                }"
              >
                <a
                  (click)="markAsRead(notification.Content, notification.ID, true)"
                  class="d-flex"
                >
                  <diV class="notification-user-img mr-3"
                  *ngIf = "notification.Content.Type !== notificationType.REMINDER && notification.Content.Type !== notificationType.REMINDERNOTMODIFIED">
                    <app-no-avatar
                      [avatarURL]="notification.Content.UserAvatarURL"
                      [userName]="notification.Content.UserName"
                      [width]="32"
                      [height]="32"
                    >
                    </app-no-avatar>
                  </diV>
                  <div class="notifications-info">
                    <ng-container [ngSwitch]="notification.Content.Type">
                      <div *ngSwitchCase="notificationType.COMMENT">
                        <p class="mb-2">
                          {{ notification.Content.UserName }} commented on
                          task
                          {{ notification.Content.ProjectKey }}
                          -
                          {{ notification.Content.TaskKey }}
                        </p>
                      </div>
                      <div *ngSwitchCase="notificationType.ATTACHMENT">
                        <p class="mb-2">
                          {{ notification.Content.UserName }} added attachment
                          on task {{ notification.Content.ProjectKey }} -
                          {{ notification.Content.TaskKey }}
                        </p>
                      </div>
                      <div *ngSwitchCase="notificationType.UPDATE">
                        <p class="mb-2">
                          {{ notification.Content.UserName }} updated task
                          {{ notification.Content.ProjectKey }} -
                          {{ notification.Content.TaskKey }}
                        </p>
                      </div>
                      <div *ngSwitchCase="notificationType.ASSIGNEE">
                        <p class="mb-2">
                          {{ notification.Content.UserName }} assigned task
                          {{ notification.Content.ProjectKey }} -
                          {{ notification.Content.TaskKey }} to you
                        </p>
                      </div>
                      <div *ngSwitchCase="notificationType.CHANGESTATUS">
                        <p class="mb-2">
                          {{ notification.Content.UserName }} moved task from
                          {{ notification.Content.PrevStatusName }} to
                          {{ notification.Content.NewStatusName }}
                        </p>
                      </div>
                      <div *ngSwitchCase="notificationType.MENTION">
                        <p class="mb-2">
                          {{ notification.Content.UserName }} mentioned you in
                          task {{ notification.Content.ProjectKey }} -
                          {{ notification.Content.TaskKey }}
                        </p>
                      </div>
                      <div *ngSwitchCase="notificationType.MENTIONDESC">
                        <p class="mb-2">
                          {{ notification.Content.UserName }} mentioned you in
                          task description
                          {{ notification.Content.ProjectKey }} -
                          {{ notification.Content.TaskKey }}
                        </p>
                      </div>
                      <div *ngSwitchCase="notificationType.REMINDER">
                        <p class="mb-2">
                          What’s your progress on this task?
                        </p>
                      </div>
                      <div *ngSwitchCase="notificationType.REMINDERNOTMODIFIED">
                        <p class="mb-2">
                          Task <span class="text-primary">{{ notification.Content.TaskTitle }}</span> has not been modified recently. Do you want to do something with it?
                        </p>
                      </div>
                    </ng-container>
                    <div class="d-flex align-items-center mb-1 line-height-1">
                      <img
                        [src]="[
                          '/assets/images/types/' +
                            notification.Content.TaskTypeIconID +
                            '.png'
                        ]"
                        width="17px"
                        height="17px"
                        class="mr-2"
                        alt="type-icon"
                      />
                      <span class="text-primary font-14 pr-3">
                        {{ notification.Content.ProjectKey }} -
                        {{ notification.Content.TaskKey }}
                        {{ notification.Content.TaskTitle }}
                      </span>
                    </div>
                    <span class="time d-block">{{
                      notification.CreatedAt | dateAgo
                    }}</span>
                  </div>
                  <div
                    *ngIf="!notification.IsRead"
                    (click)="
                      markAsRead(notification.Content, notification.ID, false);
                      $event.stopPropagation();
                      $event.preventDefault()
                    "
                    class="radio-item"
                  ></div>
                </a>
              </div>
            </ng-container>
            <app-loading-notif-spinner *ngIf="loading">
            </app-loading-notif-spinner>
          </div>
        </div>
      </li>
      <li class="nav-item dropdown">
        <span dropdown>
          <a href id="basic-link" dropdownToggle (click)="false"
             aria-controls="basic-link-dropdown">
             <app-no-avatar
             [avatarURL]="avatar"
             [userName]="currentName"
             [width]="36"
             [height]="36"
           >
           </app-no-avatar>
          </a>
          <ng-container  *ngIf="!isGuest">
          <ul id="basic-link-dropdown" *dropdownMenu class="dropdown-menu dropdown-menu-right"
              role="menu" aria-labelledby="basic-link">
                <li>
                  <a class="dropdown-item cursor-pointer" (click)="openModal()"
               >My account</a
             >
             <a class="dropdown-item cursor-pointer" (click)="openFeedbackModal()"
               >Feedback</a
             >
             <a *ngIf = "currentRole === roleType.SuperAdmin && !isPremium"
                routerLink = "/pricing"
                class="dropdown-item cursor-pointer text-primary"
               >Go Premium
             </a>
             <a class="dropdown-item cursor-pointer" (click)="logout()">Log out</a>
               </li>
          </ul>
        </ng-container>
        </span>
      </li>
    </ul>
  </div>
</nav>

<app-task-progress
  *ngIf="reminderData?.showReminder"
  [data] = "reminderData"
>
</app-task-progress>
