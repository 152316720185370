import { ToastrService } from "ngx-toastr";
import { Error } from "@app/enums/errors";
import { FormGroup, FormControl, Validators, FormArray } from "@angular/forms";
import { Component, Input, OnInit } from "@angular/core";
import { UserInviteService } from "@app/shared/services/user-invite.service";
import { SpinnerButtonOptions } from "@app/models/spinnerButtonOptions";

@Component({
  selector: "app-invite-user-footer",
  templateUrl: "./invite-user-footer.component.html",
  styleUrls: ["./invite-user-footer.component.scss"],
})
export class InviteUserFooterComponent implements OnInit {
  inviteUserForm: FormGroup;
  submitted = false;
  @Input() projectUID: string;
  spinnerButtonOptions: SpinnerButtonOptions = {
    active: false,
    text: "Invite",
    fullWidth: false,
    disabled: false,
  };
  constructor(
    private inviteUserService: UserInviteService,
    private toast: ToastrService
  ) {}

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.inviteUserForm = new FormGroup({
      Name: new FormControl(""),
      Email: new FormControl("", {
        validators: [Validators.required, Validators.email],
      }),
      ProjectUIDs: new FormArray([new FormControl(this.projectUID)]),
    });
  }

  get f() {
    return this.inviteUserForm.controls;
  }

  submitForm() {
    this.submitted = true;
    if (this.inviteUserForm.invalid) {
      return;
    }
    this.spinnerButtonOptions.active = true;
    this.inviteUserForm.get('Name').setValue(this.inviteUserForm.value.Email);
    const data = this.inviteUserForm.value;
    this.inviteUserService.inviteUser(data).subscribe(
      () => {
        this.spinnerButtonOptions.active = false;
        this.submitted = false;
        this.toast.success("User is successfully invited", "Success!");
      },
      (error) => {
        this.spinnerButtonOptions.active = false;
        switch (error.error.ErrorCode) {
          case Error.ErrorCodeDatabaseUniqueKeyViolation:
            this.toast.warning("This email is invited already", "Warning!");
            break;
          case Error.ErrorCodeDatabaseUniqueKeyViolation:
            this.toast.warning("User already exists", "Warning!");
            break;
          default:
            this.toast.warning("Something went wrong", "Warning!");
        }
      }
    );
  }
}
