import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class TenantInterceptor implements HttpInterceptor {
  constructor() {}
  // tslint:disable-next-line: no-any
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const domain = window.location.hostname;
    let tenant: string;
    const tenatArray =  domain.split('.');
    if (tenatArray[0] === 'www') {
      tenant = tenatArray[1];
    } else {
      tenant = tenatArray[0];
    }
    req = req.clone({
         setHeaders: {
           'X-Tenant': `${tenant}`
         }
       });
    return next.handle(req);
  }
}
