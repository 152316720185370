import { GuestService } from './guest.service';
import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Comment, CommentResponse } from '@app/models/comment';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { retry, catchError, tap } from 'rxjs/operators';
import { throwError, Subject, BehaviorSubject } from 'rxjs';
import { Error } from '@app/enums/errors';

@Injectable({
  providedIn: 'root'
})
export class CommentsService {
  updateCommentSubject = new Subject<null>();
  isCommentSubject = new Subject<boolean>();
  showCommentIcon$ = new Subject<string>();
  hideCommentIcon$ = new Subject<string>();

  constructor(private http: HttpClient, private toastService: ToastrService, private guestService: GuestService) { }


  createComment(projectId: string, taskId: string, comments: {Comment: string}) {
    const link = window.location.pathname.slice(1, window.location.pathname.length);
    const path = this.guestService.isGuest ? link : `api/project/${projectId}`;
    return this.http.post<Comment>(`${environment.baseUrl}${path}/task/${taskId}/comment`, comments
    );
  }

  getComments(projectId: string, taskId: string) {
    const link = window.location.pathname.slice(1, window.location.pathname.length);
    const path = this.guestService.isGuest ? link : `api/project/${projectId}`;
    return this.http.get<CommentResponse>(`${environment.baseUrl}${path}/task/${taskId}/comment`
    );
  }

  deleteComment(projectId: string, taskId: string, commentId: string) {
    const link = window.location.pathname.slice(1, window.location.pathname.length);
    const path = this.guestService.isGuest ? link : `api/project/${projectId}`;
    return this.http.delete<null>(`${environment.baseUrl}${path}/task/${taskId}/comment/${commentId}`
    ).pipe(
      retry(1),
      catchError(this.handleError.bind(this))
    );
  }

  updateComment(projectId: string, taskId: string, commentId: string, comment: {Comment: string}) {
    const link = window.location.pathname.slice(1, window.location.pathname.length);
    const path = this.guestService.isGuest ? link : `api/project/${projectId}`;
    return this.http.put<null>(`${environment.baseUrl}${path}/task/${taskId}/comment/${commentId}`, comment
    ).pipe( tap(() => {
      this.updateCommentSubject.next();
    }));
  }
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    switch (error?.error.ErrorCode) {
      case Error.ErrorCodeNotAllowed:
      errorMessage = 'Can\'t delete comment from another user';
      break;
      case Error.ErrorCodeNotFound:
        errorMessage = 'Task comment with given UID doesn\'t exist or can not be deleted';
    }
    this.toastService.warning(errorMessage, 'Warning');
    return throwError(errorMessage);
  }
}


