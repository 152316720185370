import { ChatService } from '@app/shared/services/chat.service';
import { ToastrService } from 'ngx-toastr';
import { Task } from '@app/models/task';
import { Component, OnInit, Input } from '@angular/core';
import { UploadFilesService } from '@app/shared/services/upload-files.service';
import { concatMap } from 'rxjs/operators';
import { AttachmentsService } from '@app/shared/services/attachments.service';
import { Attachment } from '@app/models/attachment';

@Component({
  selector: 'app-chat-add-attachment',
  templateUrl: './chat-add-attachment.component.html',
  styleUrls: ['./chat-add-attachment.component.scss']
})
export class ChatAddAttachmentComponent implements OnInit {
  @Input() attachment: Attachment;
  @Input() task: Task;
  @Input() isList = false;
  loading = false;
  constructor(private fileUpload: UploadFilesService,
              private toastService: ToastrService,
              private chatService: ChatService,
              private toast: ToastrService,
              private attachmentService: AttachmentsService) { }

  ngOnInit(): void {
  }

  async addFile(event) {
    const file = event.files[0] as File;
    try {
      await this.fileUpload.maxFileSize(file);
      this.uploadAttachment(file);
    } catch (error) {
      this.toast.warning(error);
    }
  }

  uploadAttachment(file: File) {
    this.loading = true;
    this.fileUpload.fileUpload(file).pipe(
      concatMap(res => {
        const attachmentObj = {
          AttachmentURL: res.URL,
          AttachmentFileName: file.name
        };
        const Attachments = [];
        Attachments.push(attachmentObj);
        return this.attachmentService.uploadAttachment(this.task.ProjectUID, this.task.UID, Attachments);
      }))
        .subscribe(result => {
         const attachmentObj = {
           fileName: file.name,
           task:  this.task,
         };
         this.chatService.uploadAttachment.next(attachmentObj);
         this.loading = false;
         },
          error => {
            this.toastService.warning('That file is already uploaded');
            this.loading = false;
          });
  }


}
