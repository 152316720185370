<div class="dropdown dropdown-recurs mr-4" tooltip="Recurrence" [delay] = "800">
  <span
    dropdown
    #dropdown="bs-dropdown"
    [insideClick]="true"
    [dropup]="isDropup"
    [autoClose]="false"
    (onHidden)="checkIsActive()"
  >
    <a
      href
      id="basic-link"
      dropdownToggle
      (click)="(false)"
      aria-controls="basic-link-dropdown"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18.367"
        height="18.463"
        viewBox="0 0 18.367 18.463"
      >
        <defs>
          <style>
            .cls-6 {
              fill: #96999b;
              stroke: #96999b;
              stroke-width: 0.7px;
            }
          </style>
        </defs>
        <g id="Group_2143" transform="translate(.35 .35)">
          <path
            id="Path_2994"
            d="M17.3 14.857h-1.223a.909.909 0 0 1-.907-.907V3.809a.909.909 0 0 1 .907-.907h8.279L23.279 3.98a.494.494 0 0 0 .346.841.474.474 0 0 0 .346-.146l1.92-1.92a.5.5 0 0 0 .146-.346.482.482 0 0 0-.146-.346L23.971.145a.492.492 0 0 0-.7.7l1.078 1.078h-8.272a1.893 1.893 0 0 0-1.89 1.89v10.141a1.893 1.893 0 0 0 1.89 1.89H17.3a.49.49 0 0 0 .492-.492.5.5 0 0 0-.492-.495z"
            class="cls-6"
            [ngClass]="{ 'recurs-active': isActive }"
            transform="translate(-14.187)"
          />
          <path
            id="Path_2995"
            d="M158.149 52.675h-1.228a.492.492 0 0 0 0 .983h1.228a.909.909 0 0 1 .907.907V64.71a.909.909 0 0 1-.907.907h-8.279l1.078-1.078a.492.492 0 0 0-.7-.7l-1.92 1.92a.5.5 0 0 0-.146.346.482.482 0 0 0 .146.346l1.92 1.92a.5.5 0 0 0 .346.146.48.48 0 0 0 .346-.146.49.49 0 0 0 0-.7l-1.074-1.071h8.279a1.893 1.893 0 0 0 1.89-1.89V54.565a1.886 1.886 0 0 0-1.886-1.89z"
            class="cls-6"
            [ngClass]="{ 'recurs-active': isActive }"
            transform="translate(-142.37 -50.756)"
          />
        </g>
      </svg>
    </a>
    <ul
      id="basic-link-dropdown"
      *dropdownMenu
      class="dropdown-menu dropdown-menu-right dropdown-recurs-menu"
      role="menu"
      aria-labelledby="basic-link"
    >
      <div class="p-3">
        <form [formGroup]="recursForm">
          <div class="form-group">
            <label class="label">Recurs every</label>
            <div class="d-flex">
              <input
                type="number"
                min="1"
                step="1"
                oninput="validity.valid||(value='');"
                pattern="^[0-9]+"
                formControlName="PeriodOffset"
                class="form-control recurs-input mr-1"
                [ngClass]="{ 'd-none': isHidden }"
                placeholder="Num"
              />
              <ng-select
                container="body"
                formControlName="Period"
                appearance="outline"
                [items]="recursTime"
                bindLabel="label"
                (change)="changePeriod($event)"
                bindValue="id"
                appendTo="body"
                placeholder="Choose time"
                [searchable]="false"
                [clearable]="false"
                class="select-recurs"
              >
              </ng-select>
            </div>
            <p class="font-10 text-gray-500" [ngClass]="{ 'd-none': isHidden }">
              This task will be recurrent according to the set options.
            </p>
          </div>
          <div class="form-group" [ngClass]="{ 'd-none': isHidden }">
            <label class="label"> Start day: </label>
            <input
              formControlName="Start"
              class="form-control datepicker"
              placeholder="{{ currentDate | date: 'yyyy-MM-dd' }}"
              type="text"
              [minDate]="currentDate"
              bsDatepicker
              [bsConfig]="{
                dateInputFormat: 'YYYY-MM-DD',
                isAnimated: true,
                containerClass: 'theme-default',
                adaptivePosition: true,
                showWeekNumbers: false,
                customTodayClass: 'custom-today-class'
              }"
            />
          </div>
          <div class="d-flex align-items-center">
            <button
              type="button"
              [disabled]="recursForm.invalid || recursForm.pristine"
              class="btn btn-primary py-2"
              (click)="saveRecurs()"
            >
              Save
            </button>
            <button
              type="button"
              class="btn btn-cancel text-gray-500 py-1"
              (click)="dropdown.hide()"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </ul>
  </span>
</div>
