import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-google-calendar-svg',
  templateUrl: './google-calendar-svg.component.html',
  styleUrls: ['./google-calendar-svg.component.scss']
})
export class GoogleCalendarSvgComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
