import { ProjectService } from './shared/services/project.service';
import { JarvisModule } from './chat/jarvis.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomepageComponent } from './layout/homepage/homepage.component';
import { HeaderComponent } from './layout/shared/header/header.component';
import { FooterComponent } from './layout/shared/footer/footer.component';
import { TopbarIconsComponent } from './layout/shared/topbar-icons/topbar-icons.component';
import { SharedModule } from './shared/shared.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './shared/interceptors/jwt.interceptor';
import { ErrorInterceptor } from './shared/interceptors/errors.interceptor';
import { ProfileComponent } from './layout/shared/profile/profile.component';
import { SearchComponent } from './layout/shared/search/search.component';
import { ChangePasswordComponent } from './layout/shared/change-password/change-password.component';
import { TenantInterceptor } from './shared/interceptors/tenant.interceptor';
import { FeedbackComponent } from './layout/shared/feedback/feedback.component';
import { TaskProgressComponent } from './layout/shared/task-progress/task-progress.component';
import { TaskMenuComponent } from '@app/layout/tasks/tasks-menu/task-menu.component';
import { TasksUsersFilterComponent } from '@app/layout/tasks/tasks-users-filter/tasks-users-filter.component';

@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    HeaderComponent,
    FooterComponent,
    TopbarIconsComponent,
    ProfileComponent,
    SearchComponent,
    ChangePasswordComponent,
    FeedbackComponent,
    TaskProgressComponent,
    TaskMenuComponent,
    TasksUsersFilterComponent
    ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    JarvisModule,
    SharedModule,
  ],
  providers: [ProjectService,
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: TenantInterceptor, multi: true}
  ],
  bootstrap: [AppComponent],
  entryComponents: [ProfileComponent, SearchComponent, ChangePasswordComponent, FeedbackComponent]
})
export class AppModule { }
