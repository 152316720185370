import { format } from 'date-fns';

export function TransformDate(dateValue: Date) {
   if (!dateValue) {
     return null;
   } else {
    const transformedDate = format(new Date(dateValue), 'yyyy-MM-dd');
    return transformedDate;
   }
}
