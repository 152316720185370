<div class="lds-ring" [ngStyle] = "{'top': top + '%',
                                    'left': left + '%',
                                    'width': width + 'px',
                                    'height': height + 'px' }">
  <div [ngStyle] = "{'width': width + 'px',
  'height': height + 'px'}"></div>
  <div [ngStyle] = "{'width': width + 'px',
  'height': height + 'px'}"></div>
  <div [ngStyle] = "{'width': width + 'px',
  'height': height + 'px'}"></div>
  <div [ngStyle] = "{'width': width + 'px',
  'height': height + 'px'}"></div>
</div>
