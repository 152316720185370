import { Directive, ElementRef, Input, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[appFocus]'
})
export class FocusDirective implements AfterViewInit  {
  @Input('appFocus')
  private focused = false;
  constructor(private el: ElementRef) { }


  ngAfterViewInit(): void {
    if (this.focused) {
      setTimeout(() => this.el.nativeElement.focus(), 0);
    }
  }

}
