import { Pipe, PipeTransform } from '@angular/core';
import { Task } from '@app/models/task';

@Pipe({
  name: 'archive'
})
export class ArchivePipe implements PipeTransform {
  transform(value: Task[], archive: boolean): Task[] {
    if (archive) {
      value = value.filter(val => val.Status === 1);
    }
    if (!archive) {
      value = value.filter(val => val.Status !== 1);
    }
    return value;
  }
}
