import { GuestService } from './../../../shared/services/guest.service';
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { TaskService } from "@app/shared/services/task.service";
import { UserService } from "@app/shared/services/user.service";
import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { User } from "@app/models/user";

@Component({
  selector: "app-tasks-users-filter",
  templateUrl: "./tasks-users-filter.component.html",
  styleUrls: ["./tasks-users-filter.component.scss"],
})
export class TasksUsersFilterComponent implements OnInit, OnDestroy {
  @Input() projectUID: string;
  @ViewChildren("checkedRef") checkedRef: QueryList<ElementRef>;
  private subscription: Subscription[] = [];
  currentUser: User;
  userUID: string;
  isDropup = true;
  users: User[];

  constructor(
    private userService: UserService,
    private taskService: TaskService,
    private router: Router,
    private route: ActivatedRoute,
    private guestService: GuestService
  ) {
    this.currentUser = this.guestService.getUserFromLocalStorage();
  }

  ngOnInit(): void {
    this.subscription.push(
      this.route.queryParamMap.subscribe( params => {
        this.userUID = params.get('name');
      })
    )
    this.subscription.push(
      this.taskService.projectDataSubject.subscribe((res) => {
        this.projectUID = res;
        this.getUsers();
      })
    );
  }

  getUsers() {
    this.userService.getusersProject(this.projectUID).subscribe((res) => {
      this.users = res.data;
    });
  }

  checkUser(checkbox: HTMLInputElement) {
    const isChecked = checkbox.checked;
    const clickedCheckboxId = checkbox.id;
    this.resetCheckboxes(clickedCheckboxId);
    this.updateQueryParams(isChecked, clickedCheckboxId);
  }

  resetCheckboxes(clickedCheckboxId: string) {
    this.checkedRef.toArray().forEach((el) => {
      if (el.nativeElement.id !== clickedCheckboxId) {
        el.nativeElement.checked = false;
      }
    });
  }

  updateQueryParams(isChecked: boolean, clickedCheckboxId: string = null) {
    this.router.navigate([], {
      queryParams: { name: isChecked ? clickedCheckboxId : null },
      queryParamsHandling: "merge",
      relativeTo: this.route,
    });
  }

  updateUsersArray(users: User[]) {
    this.users = users;
  }

  clearFilter(userUID: string) {
    if (this.userUID === userUID) {
      this.updateQueryParams(false)
    }
  }

  ngOnDestroy() {
    this.subscription.forEach((sub) => sub.unsubscribe());
  }
}
