<form class="d-flex flex-column overflow-hidden" [formGroup]="projectForm">
  <accordion
    [closeOthers]="true"
    [isAnimated]="true"
    class="d-flex flex-column overflow-hidden"
  >
    <accordion-group
      [panelClass]="'project-accordion'"
      class="scroll-project-edit project-sroll"
      [isOpen]="true"
      (isOpenChange)="isOpenChange($event, 'project-edit')"
      [isDisabled]="isOpen === true && accordionStatus === 'project-edit'"
    >
      <div class="d-flex justify-content-between" accordion-heading>
        <input
          [(colorPicker)]="iconColor"
          [cpAlphaChannel]="'disabled'"
          (colorPickerClose)="changeColor($event)"
          #colorPicker
          [style.background]="iconColor"
          [value]="project.Key"
          class="project-key cursor-pointer"
          readonly
        />
        <div class="form-group mb-0 w-100">
          <div
            #title
            class="project-title non-edit-title"
            [hidden]="isEditMode"
            (click)="editTitle(title)"
            [innerHTML]="newTitle"
          ></div>
          <textarea
            type="text"
            (input)="autoHeight(titleRef)"
            formControlName="Name"
            pattern="([^-]+)"
            [hidden]="!isEditMode"
            #titleRef
            id="input-title"
            class="form-control project-title"
            [ngClass]="{
              'is-invalid': f['Name'].errors && submitted
            }"
          ></textarea>
          <div *ngIf="f['Name'].errors && submitted" class="invalid-feedback">
            <div *ngIf="f['Name'].errors.required">
              Project name is required
            </div>
            <div *ngIf="f['Name'].errors.pattern">
              Can't use - in project name
            </div>
          </div>
          <div *ngIf="isEditMode" class="mt-3 text-right">
            <button
              type="button"
              class="btn btn-primary"
              (click)="saveTitle($event)"
            >
              Save
            </button>
            <button
              class="border-none btn btn-outline-secondary"
              (click)="closeEditTitle()"
            >
              Cancel
            </button>
          </div>
        </div>
        <div class="dropdown ml-sm-5 ml-2 mt-3">
          <span dropdown>
            <a
              href
              id="basic-link"
              dropdownToggle
              (click)="(false)"
              aria-controls="basic-link-dropdown"
            >
              <img src="/assets/images/dots.png" alt="dots" />
            </a>
            <ul
              id="basic-link-dropdown"
              *dropdownMenu
              class="dropdown-menu dropdown-menu-right"
              role="menu"
              aria-labelledby="basic-link"
            >
              <li>
                <a
                  [swal]="swalOptions"
                  class="dropdown-item cursor-pointer"
                  (confirm)="deleteProject(project.UID)"
                >
                  Delete project
                </a>
              </li>
            </ul>
          </span>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label class="label"> Project type </label>
            <ng-select
              appearance="outline"
              [searchable]="false"
              [readonly]="true"
              [clearable]="false"
              class="select inline-edit"
              formControlName="Type"
              placeholder="Choose"
            >
              <ng-option [value]="1">
                <div class="d-flex align-items-center">
                  <img
                    src="/assets/images/logo-image.png"
                    width="31px"
                    height="31px"
                    class="mr-3"
                  />
                  Jarvis Modern
                </div>
              </ng-option>
              <ng-option [value]="2">
                <div class="d-flex align-items-center">
                  <img src="/assets/images/shopify.png" class="mr-3" />
                  Shopify
                </div>
              </ng-option>
            </ng-select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label class="label"> Lead </label>
            <ng-select
              appearance="outline"
              [searchable]="false"
              [clearable]="false"
              appendTo="body"
              class="select inline-edit"
              (change)="changeLeadUser($event)"
              formControlName="LeadUID"
              placeholder="Choose"
              [ngClass]="{ 'is-invalid': submitted && f['LeadUID']?.errors }"
            >
              <ng-option [value]="lead.UID" *ngFor="let lead of users">
                <div class="d-flex align-items-center">
                  <app-no-avatar
                    class="mr-2"
                    [avatarURL]="lead.AvatarURL"
                    [userName]="lead.Name"
                    [width]="32"
                    [height]="32"
                  >
                  </app-no-avatar>
                  {{ lead.Name }}
                </div>
              </ng-option>
            </ng-select>
            <div
              *ngIf="submitted && f['LeadUID']?.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f['LeadUID']?.errors.required">Lead is required</div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="label">New project template</label>
            <app-project-create-templates
              [projectUID]="project.UID"
            ></app-project-create-templates>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label class="label"> Description: </label>
            <div
              class="non-edit-title cursor-pointer"
              [ngClass]="{
                'editor-placeholder': editorInitialValue === editorPlaceholder
              }"
              *ngIf="!showDescEditor"
              (click)="showEditor($event)"
              [innerHTML]="editorInitialValue"
            ></div>
            <ckeditor
              *ngIf="showDescEditor"
              [config]="configDesc"
              [editor]="Editor"
              (change)="onChange($event)"
              class="editorDesc"
              #editor
              formControlName="Description"
            ></ckeditor>
            <div *ngIf="showDescEditor" class="mt-3">
              <button
                type="submit"
                class="btn btn-primary"
                (click)="saveDesc($event)"
              >
                Save
              </button>
              <button
                class="border-none btn btn-outline-secondary"
                (click)="closeEditor()"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-md-12">
          <div class="form-group mb-0">
            <label class="label mb-3"> Members: </label>
            <div class="d-flex align-items-center flex-wrap">
              <ng-container *ngFor="let user of selectedUsers">
                <app-no-avatar
                  class="mr-1 mb-2"
                  [avatarURL]="user.AvatarURL"
                  [userName]="user.Name"
                  [tooltip]="user.Name"
                  [delay]="800"
                  [width]="38"
                  [height]="38"
                >
                </app-no-avatar>
              </ng-container>

              <div
                *ngIf="showCreateMember"
                class="choose-users d-md-block d-none mb-2"
                dropdown
                [insideClick]="true"
                container="body"
                [dropup]="isDropup"
                (onShown)="onShown()"
              >
                <a
                  href
                  id="basic-link"
                  dropdownToggle
                  (click)="(false)"
                  aria-controls="basic-link-dropdown"
                  class="user"
                >
                  <app-plus-sign-svg></app-plus-sign-svg>
                </a>

                <ul
                  id="basic-link-dropdown"
                  *dropdownMenu
                  class="dropdown-menu dropdown-users scroll-thin"
                  role="menu"
                  aria-labelledby="basic-link"
                >
                  <div class="dropdown-users-content scroll-thin">
                    <li
                      *ngFor="
                        let user of dropdownUsers
                          | currentUser: currentUser.UserUID;
                        let i = index
                      "
                      class="dropdown-item"
                    >
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          #userCheckbox
                          class="custom-control-input"
                          (change)="chooseUsersList($event, user)"
                          id="check{{ user.UID }}"
                        />
                        <label
                          class="custom-control-label"
                          for="check{{ user.UID }}"
                        >
                          <app-no-avatar
                            class="mr-2"
                            [avatarURL]="user.AvatarURL"
                            [userName]="user.Name"
                            [width]="38"
                            [height]="38"
                          >
                          </app-no-avatar>
                          <span class="cursor-pointer"> {{ user.Name }} </span>
                        </label>
                      </div>
                    </li>
                  </div>
                 <app-invite-user-footer [projectUID]="project.UID"></app-invite-user-footer>
                </ul>
              </div>
            </div>
            <div
              *ngIf="showCreateMember"
              class="choose-users d-md-none d-block"
              dropdown
              [insideClick]="true"
              container="body"
              [dropup]="isDropup"
              (onShown)="onShown()"
            >
              <a
                href
                id="basic-link"
                dropdownToggle
                (click)="(false)"
                aria-controls="basic-link-dropdown"
                class="user"
              >
                <app-plus-sign-svg></app-plus-sign-svg>
              </a>
              <ul
                id="basic-link-dropdown"
                *dropdownMenu
                class="dropdown-menu dropdown-users"
                role="menu"
                aria-labelledby="basic-link"
              >
                <div class="dropdown-users-content scroll-thin">
                  <li
                    *ngFor="
                      let user of dropdownUsers
                        | currentUser: currentUser.UserUID;
                      let i = index
                    "
                    class="dropdown-item"
                  >
                    <div class="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        #userCheckbox
                        class="custom-control-input"
                        (change)="chooseUsersList($event, user)"
                        id="check{{ user.UID }}"
                      />
                      <label
                        class="custom-control-label"
                        for="check{{ user.UID }}"
                      >
                        <app-no-avatar
                          class="mr-2"
                          [avatarURL]="user.AvatarURL"
                          [userName]="user.Name"
                          [width]="38"
                          [height]="38"
                        >
                        </app-no-avatar>
                        <span class="cursor-pointer"> {{ user.Name }} </span>
                      </label>
                    </div>
                  </li>
                </div>
                <app-invite-user-footer [projectUID]="project.UID"></app-invite-user-footer>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal-footer modal-mobile-footer d-md-none d-block text-right"
      >
        <button class="btn btn-cancel pl-0" (click)="activeModal.hide()">
          Cancel
        </button>
      </div>
    </accordion-group>
    <div class="separate-modal"></div>
    <accordion-group
      *ngIf="project.Type === projectType.SHOPIFY"
      [panelClass]="'project-accordion'"
      class="scroll-shopify project-sroll"
      (isOpenChange)="isOpenChange($event, 'shopify')"
    >
      <div
        accordion-heading
        class="d-flex justify-content-between align-items-center"
      >
        <p class="font-18 text-shopify mb-0">Shopify options</p>
        <app-arrow-down-svg
          [ngClass]="{
            'rotate-arrow': isOpen === true && accordionStatus === 'shopify'
          }"
        >
        </app-arrow-down-svg>
      </div>
      <div class="shopify-wrapper mb-3" *ngIf="isShopifyLogin">
        <div class="text-center">
          <p class="font-14 text-gray-600">
            To continue using Jarvis, log in to your Shopify account:
          </p>
          <button
            type="button"
            class="btn btn-shopify px-5"
            disabled
            (click)="openShopifyModal()"
          >
            Log in to Shopify
          </button>
        </div>
      </div>
      <app-shopify-details
        *ngIf="!isShopifyLogin && shopifyID"
        [projectForm]="conditionsForm"
        [items]="items"
        [isEditMode]="true"
        [shopifyID]="shopifyID"
        [projectID]="project.UID"
      >
      </app-shopify-details>
    </accordion-group>
    <div
      class="separate-modal"
      *ngIf="project.Type === projectType.SHOPIFY"
    ></div>
    <accordion-group
      (isOpenChange)="isOpenChange($event, 'shareLink')"
      [panelClass]="'project-accordion'"
      class="project-sroll"
      *ngIf="
        currentUser.UserUID === project.LeadUID ||
        currentUser.Role !== roleType.Member
      "
    >
      <div
        class="d-flex justify-content-between align-items-center"
        accordion-heading
      >
        <p class="font-18 text-primary mb-0">Invite a guest to the project</p>
        <app-arrow-down-svg
          [ngClass]="{
            'rotate-arrow': isOpen === true && accordionStatus === 'shareLink'
          }"
        >
        </app-arrow-down-svg>
      </div>
      <app-shareable-link
        [projectUID]="this.project.UID"
        [isProject]="true"
      ></app-shareable-link>
    </accordion-group>
  </accordion>
</form>
