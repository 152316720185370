<div class="mb-1">
  <label class="label mb-2"> Collaborators: </label>
  <div class="d-sm-flex d-block align-items-center">
    <div class="d-flex align-items-center">
      <ng-container *ngFor="let user of collaborators | slice: 0:4">
        <app-no-avatar
          class="avatar-img"
          [avatarURL]="user.UserAvatarURL"
          [userName]="user.UserName"
          [width]="33"
          [height]="33"
        >
        </app-no-avatar>
      </ng-container>
    </div>
    <div
      *ngIf="!isGuest"
      class="choose-users"
      dropdown
      placement="bottom"
      [insideClick]="true"
      container="body"
      (onShown)="onShown()"
    >
      <a
        href
        id="basic-link"
        dropdownToggle
        (click)="(false)"
        aria-controls="basic-link-dropdown"
        class="user"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="9.033"
          height="9.033"
          viewBox="0 0 9.033 9.033"
        >
          <defs>
            <style>
              .cls-add {
                fill: #fff;
                stroke: #fff;
                stroke-linecap: round;
                stroke-linejoin: round;
                stroke-width: 0.5px;
              }
            </style>
          </defs>
          <g id="Group_1942" transform="translate(.25 .25)">
            <path
              id="Rectangle_555"
              d="M0 0H0.922V8.533H0z"
              class="cls-add"
              transform="rotate(90 2.364 6.169)"
            />
            <path
              id="Rectangle_556"
              d="M0 0H0.922V8.533H0z"
              class="cls-add"
              transform="translate(3.805)"
            />
          </g>
        </svg>
      </a>

      <ul
        id="basic-link-dropdown"
        *dropdownMenu
        class="dropdown-menu dropdown-users dropdown-menu-left"
        role="menu"
        aria-labelledby="basic-link"
      >
        <div class="dropdown-users-content scroll-thin">
          <li
            *ngFor="
              let user of persons | slice: 1:persons.length;
              let i = index
            "
            class="dropdown-item"
          >
            <div class="custom-control custom-checkbox">
              <input
                type="checkbox"
                #userCheckbox
                class="custom-control-input"
                (change)="chooseUsersList($event, user)"
                id="check{{ user.UID }}"
              />
              <label class="custom-control-label" for="check{{ user.UID }}">
                <app-no-avatar
                  class="mr-2"
                  [avatarURL]="user.AvatarURL"
                  [userName]="user.Name"
                  [width]="33"
                  [height]="33"
                >
                </app-no-avatar>
                <span class="cursor-pointer"> {{ user.Name }} </span>
              </label>
            </div>
          </li>
        </div>
      </ul>
    </div>
  </div>
</div>
