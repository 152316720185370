<svg
xmlns="http://www.w3.org/2000/svg"
[style.width.px]="width"
[style.height.px]="height"
viewBox="0 0 11.459 11.653"
>
<g>
  <g>
    <g
      fill="none"
      stroke="#96989b"
      transform="translate(-813 -376) translate(813 376)"
    >
      <ellipse
        cx="5.729"
        cy="5.826"
        stroke="none"
        rx="5.729"
        ry="5.826"
      />
      <ellipse cx="5.729" cy="5.826" rx="5.229" ry="5.326" />
    </g>
  </g>
  <text
    fill="#96989b"
    font-family="Roboto-Regular, Roboto"
    font-size="8px"
    transform="translate(-813 -376) translate(817.182 376.664)"
  >
    <tspan x="-.561" y="8">?</tspan>
  </text>
</g>
</svg>
