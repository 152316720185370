import { Pipe, PipeTransform } from '@angular/core';
import { Task } from '@app/models/task';

@Pipe({
  name: 'filterTasks'
})
export class FilterTasksPipe implements PipeTransform {

  transform(value: Task[], userUID: string): Task[] {
   if (!value) {
     return null;
   }

    if (!userUID) {
      return value;
    }

    const tasks = value.filter(({AssigneeUID}) => AssigneeUID === userUID);
    return tasks;

  }

}
