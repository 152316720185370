<div class="modal-header border-none justify-content-center">
  <p class="modal-title text-primary">Manage task type</p>
  <img src="/assets/images/x.png" (click)="activeModal.hide()" class="cursor-pointer close-modal" alt="close" />
</div>
<div class="modal-body">
  <div class="task-type-list w-75 mb-5">
    <ul class="list-unstyled">
      <li
        *ngFor="let type of types"
        class="mb-3 d-flex justify-content-between align-items-center"
      >
        <div class="d-flex align-items-center w-100 mr-1">
          <img
            class="mr-3 type-icons"
            src="/assets/images/types/{{ type.IconID }}.png"
            alt="type"
          />
          <app-inline-edit [data] = "type.Name" (focusOut) = "updateType($event, type.IconID, type.ID)" class="w-100"> </app-inline-edit>
        </div>
        <img
          src="/assets/images/x.png"
          (click)="deleteType(type.ID)"
          class="cursor-pointer"
          width="7px"
          height="7px"
          alt="delete"
        />
      </li>
    </ul>
  </div>
  <form [formGroup]="typeForm" (ngSubmit)="submitForm()">
    <div class="form-group">
      <label class="label mb-2">Choose icon</label>
      <div class="d-flex flex-wrap">
        <div *ngFor="let typeIcon of typeIcons" class="mr-4">
          <label for="icon_{{ typeIcon.IconID }}" class="cursor-pointer">
            <input
              #typeIconRef
              hidden
              id="icon_{{ typeIcon.IconID }}"
              [value]="typeIcon.IconID"
              type="radio"
              name="IconID"
              formControlName="IconID"
              [ngClass]="{ 'is-invalid': submitted && f['IconID']?.errors }"
            />
            <img
              src="/assets/images/types/{{ typeIcon.IconID }}.png"
              class="icon-img"
              [ngClass]="{ 'checked-icon': typeIconRef.checked }"
              width="33px"
              height="33px"
              alt="icon"
            />
          </label>
        </div>
      </div>
      <div *ngIf="submitted && f['IconID']?.errors" class="invalid-feedback">
        <div *ngIf="f['IconID']?.errors.required">Icon is required</div>
      </div>
    </div>
    <div class="form-group">
      <label class="label">Type</label>
      <div class="d-flex">
        <input
          type="text"
          class="form-control"
          name="typeName"
          formControlName="Name"
          [ngClass]="{ 'is-invalid': submitted && f['Name']?.errors }"
          placeholder="Enter type name"
        />
        <app-spinner-button [options]="spinnerButtonOptions" class="ml-3"> </app-spinner-button>
      </div>
      <div *ngIf="submitted && f['Name']?.errors" class="invalid-feedback">
        <div *ngIf="f['Name']?.errors.required">Name is required</div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer border-none justify-content-center">
</div>
