<div  [ngClass] = "{'attachment-list-add': isList}" class="bot-add-attachment">
  <div
    class="uploadfilecontainer"
    (click)="files.click()"
    appDragDrop
    (fileDropped)="addFile($event)"
  >
    <input
      hidden
      type="file"
      #files
      (change)="addFile($event.target)"
      [disabled]="loading"
    />
    <div class="spinner-border text-white" role="status" *ngIf="loading">
      <span class="sr-only">Loading...</span>
    </div>
    <p class="upload-text" *ngIf="!loading">Choose files to upload</p>
  </div>
</div>
