import {
  Directive,
  HostListener,
  ElementRef,
  Renderer2,
  Output,
  EventEmitter,
  Input,
  QueryList,
} from '@angular/core';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[clickOutside]',
})
export class DropdownDirective {
  constructor(private elementRef: ElementRef) {
  }

  @Output()
  public clickOutside = new EventEmitter<MouseEvent>();
  @Input() el: QueryList<ElementRef>;

  @HostListener('document:click', ['$event', '$event.target'])
  public onClick(event: MouseEvent, targetElement: HTMLElement): void {
      if (!targetElement) {
          return;
      }
      const clickedInside = this.elementRef.nativeElement.contains(targetElement);
      if (!clickedInside) {
          this.clickOutside.emit(clickedInside);
      }
  }
}
