import { Subject } from 'rxjs';
import { InvitedUsersResponse, InvitedUsers } from '@app/models/invitedUsers';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserInviteService {
  inviteUser$ = new Subject<InvitedUsers>();
  deleteinviteUser$ = new Subject<number>();
  getInviteUser$ = new Subject<void>();

  constructor(private http: HttpClient) { }


  inviteUser( data: {Email: string, Name: string}) {
    return this.http.post<InvitedUsers>(`${environment.baseUrl}api/user/invite`, data).pipe(
      tap( res => {
        this.inviteUser$.next(res);
      })
    );
  }

  getInvitedUsers() {
    return this.http.get<InvitedUsersResponse>(`${environment.baseUrl}api/user/invite`);
  }

  deleteInvitedUser(id: number) {
    return this.http.delete(`${environment.baseUrl}api/user/invite/${id}`);
  }
}
