import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userAcronym'
})
export class UserAcronymPipe implements PipeTransform {

  transform(value: string): string {
    let acronym = '';
    if (!value) {
      return;
    }
    if (value.trim().indexOf(' ') !== -1) {
      acronym = value.split(/\s/).slice(0, 2).reduce((response, word) => response += word.slice(0, 1), '');
      return acronym;
    } else {
      acronym = value.substring(0, 2);
    }
    return acronym;
  }

}
