import { Component, Input, OnInit } from '@angular/core';
import { SpinnerButtonOptions } from '@app/models/spinnerButtonOptions';

@Component({
  selector: 'app-spinner-button',
  templateUrl: './spinner-button.component.html',
  styleUrls: ['./spinner-button.component.scss']
})
export class SpinnerButtonComponent implements OnInit {
@Input() options: SpinnerButtonOptions;

  constructor() { }

  ngOnInit(): void {
  }

}
