import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { GoogleCalendarSvgComponent } from "./svg-icons/google-calendar-svg/google-calendar-svg.component";
import { GoogleLogoSvgComponent } from "./svg-icons/google-logo-svg/google-logo-svg.component";
import { PlusSignSvgComponent } from "./svg-icons/plus-sign-svg/plus-sign-svg.component";
import { ArrowDownSvgComponent } from "./svg-icons/arrow-down-svg/arrow-down-svg.component";
import { NotificationSvgComponent } from "./svg-icons/notification-svg/notification-svg.component";
import { TodayDefaultViewSvgComponent } from "./svg-icons/today-default-view-svg/today-default-view-svg.component";
import { TodayManagerViewSvgComponent } from "./svg-icons/today-manager-view-svg/today-manager-view-svg.component";
import { HintIconComponent } from './svg-icons/hint-icon/hint-icon.component';
import { WarningIconComponent } from './svg-icons/warning-icon/warning-icon.component';
import { SortIconComponent } from './svg-icons/sort-icon/sort-icon.component';

@NgModule({
  declarations: [
    GoogleCalendarSvgComponent,
    GoogleLogoSvgComponent,
    PlusSignSvgComponent,
    ArrowDownSvgComponent,
    NotificationSvgComponent,
    TodayDefaultViewSvgComponent,
    TodayManagerViewSvgComponent,
    HintIconComponent,
    WarningIconComponent,
    SortIconComponent,
  ],
  imports: [CommonModule],
  exports: [
    GoogleCalendarSvgComponent,
    GoogleLogoSvgComponent,
    PlusSignSvgComponent,
    ArrowDownSvgComponent,
    NotificationSvgComponent,
    TodayDefaultViewSvgComponent,
    TodayManagerViewSvgComponent,
    HintIconComponent,
    WarningIconComponent,
    SortIconComponent
  ],
})
export class SvgIconsModule {}
