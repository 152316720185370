import { GuestService } from '@app/shared/services/guest.service';
import { Error } from '@app/enums/errors';
import { ToastrService } from 'ngx-toastr';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { UploadFilesService } from '@app/shared/services/upload-files.service';
import { Component, OnInit, Input, OnDestroy, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { AttachmentsService } from '@app/shared/services/attachments.service';
import { Attachment } from '@app/models/attachment';
import { concatMap, filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss']
})
export class AttachmentsComponent implements OnInit, OnDestroy {
  @ViewChild('files') filesRef: ElementRef<HTMLInputElement>;
  attachmentId: string;
  attachemntsExt: {attachmentId: string , extensionName: string}[] = [];
  attachments: Attachment[];
  subscription: Subscription[] = [];
  loading = false;
  isGuest = false;
  @Input() taskId: string;
  @Input() projectId: string;
  @Input() size: number;

  constructor(private attachmentService: AttachmentsService,
              private fileUpload: UploadFilesService,
              private router: Router,
              private renderer: Renderer2,
              private guestService: GuestService,
              private toast: ToastrService) { }

  ngOnInit(): void {
    this.isGuest = this.guestService.isGuest;
    this.subscription.push(this.router.events
    .pipe(filter((event: RouterEvent) => event instanceof NavigationEnd))
    .subscribe(() => {
      setTimeout(() => {
        this.getAttachments();
      }, 0);
    }));
    this.subscription.push(this.fileUpload.recordVideoUpload.subscribe(res => {
      this.uploadAttachment(res);
    }));
    this.getAttachments();
  }

  async addFile(event) {
    const file = event.files[0] as File;
    try {
      await this.fileUpload.maxFileSize(file);
      this.uploadAttachment(file);
    } catch (error) {
      this.toast.warning(error);
    }
  }

  uploadAttachment(file: File) {
    this.loading = true;
    this.fileUpload.fileUpload(file).pipe(
      concatMap(res => {
        const attachmentObj = {
          AttachmentURL: res.URL,
          AttachmentFileName: file.name
        };
        const Attachments = [];
        Attachments.push(attachmentObj);
        return this.attachmentService.uploadAttachment(this.projectId, this.taskId, Attachments);
      }))
        .subscribe(result => {
          this.getExtension(result.data);
          this.attachments = [...this.attachments, ...result.data];
          this.attachmentService.showAttachIcon$.next(this.taskId);
          this.loading = false;
          this.renderer.setProperty(this.filesRef.nativeElement, 'value', '');
         },
          error => {
            if (error.error.ErrorCode === Error.ErrorCodeDatabaseUniqueKeyViolation) {
              this.toast.warning(`Attachment is already uploaded`, 'Warning!');
            }
            this.loading = false;
            this.renderer.setProperty(this.filesRef.nativeElement, 'value', '');
          });
  }

  getAttachments() {
    this.attachmentService.getAttachments(this.projectId, this.taskId).subscribe( res => {
       this.attachments = res.data;
       this.getExtension(res.data);
    });
  }

  getExtension(attachments: Attachment[]) {
    const defaultExtension = 'bin';
    attachments.map( item => {
      const extension =  item.AttachmentFileName.split('.').pop().slice(0, 4);
      if (!item.AttachmentFileName.includes('.')) {
        this.attachemntsExt.push({attachmentId: item.UID, extensionName: defaultExtension});
      } else {
        this.attachemntsExt.push({attachmentId: item.UID, extensionName: extension});
      }
   });
  }

  deleteAttachment(attachmentId: string) {
    this.attachmentService.deleteAttachment(this.projectId, this.taskId, attachmentId).subscribe( () => {
      this.attachemntsExt = this.attachemntsExt.filter(item => item.attachmentId !== attachmentId);
      this.attachments = this.attachments.filter(item => item.UID !== attachmentId);
      if (this.attachments.length < 1) {
        this.attachmentService.hideAttachIcon$.next(this.taskId);
      }
    },
    error => {
      if (error.error.ErrorCode === Error.ErrorCodeNotAllowed) {
        this.toast.warning(`Can't delete attachment from another user`, 'Warning!');
         }
    }
    );
  }

  ngOnDestroy() {
    this.subscription.forEach(subscription => subscription.unsubscribe());
  }

}
