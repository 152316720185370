<div
class="choose-users"
*ngIf = "showDropdown"
dropdown
[insideClick]="true"
container="body"
(onShown)="onShown()"
>
<a
  href
  id="basic-link"
  dropdownToggle
  (click)="(false)"
  aria-controls="basic-link-dropdown"
  class="user"
>
  <app-plus-sign-svg color="#ffffff"></app-plus-sign-svg>
</a>

<ul
  id="basic-link-dropdown"
  *dropdownMenu
  class="dropdown-menu dropdown-users scroll-thin"
  role="menu"
  aria-labelledby="basic-link"
>
  <div class="dropdown-users-content scroll-thin">
    <li
      *ngFor="
        let user of users
        let i = index
      "
      class="dropdown-item"
    >
      <div class="custom-control custom-checkbox">
        <input
          type="checkbox"
          #userCheckbox
          class="custom-control-input"
          (change)="chooseUsersList($event, user)"
          id="check{{ user.UID }}"
        />
        <label
          class="custom-control-label"
          for="check{{ user.UID }}"
        >
          <app-no-avatar
            class="mr-2"
            [avatarURL]="user.AvatarURL"
            [userName]="user.Name"
            [width]="38"
            [height]="38"
          >
          </app-no-avatar>
          <span class="cursor-pointer"> {{ user.Name }} </span>
        </label>
      </div>
    </li>
  </div>
 <app-invite-user-footer [projectUID]="projectUID"></app-invite-user-footer>
</ul>
</div>
