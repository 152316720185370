import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-today-manager-view-svg',
  templateUrl: './today-manager-view-svg.component.html',
  styleUrls: ['./today-manager-view-svg.component.scss']
})
export class TodayManagerViewSvgComponent implements OnInit {
  @Input() isManagerMode: boolean;
  constructor() { }

  ngOnInit(): void {
  }

}
