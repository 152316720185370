<div class="d-flex align-items-center">
  <div class="bot-text" *ngIf="botMessages.botMessage">
    <p>{{ botMessages.botMessage }}</p>
  </div>
  <a
    *ngIf="botMessages.botResponse.StateCode === stateCode.STORED"
    [routerLink]="
      botMessages.botResponse.Type === chatType.PROJECT
        ? ['/project', projectKey]
        : botMessages.botResponse.Type === chatType.INVITEDUSER
        ? ['/users']
        : ['/task', projectKey + '-' + taskKey]
    "
    class="arrow-link"
    (click)="hideModal()"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.978"
      height="16"
      viewBox="0 0 19.978 16"
    >
      <defs>
        <style>
          .prefix__cls-1 {
            fill: none;
            stroke: #fff;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-miterlimit: 10;
            stroke-width: 2px;
          }
        </style>
      </defs>
      <g
        id="prefix__Group_10"
        data-name="Group 10"
        transform="rotate(90 8.989 9.989)"
      >
        <path
          id="prefix__Stroke_349"
          d="M.5 17.977V0"
          class="prefix__cls-1"
          data-name="Stroke 349"
          transform="translate(6.5)"
        />
        <path
          id="prefix__Stroke_350"
          d="M7 7L0 0"
          class="prefix__cls-1"
          data-name="Stroke 350"
          transform="translate(7)"
        />
        <path
          id="prefix__Stroke_351"
          d="M7 0L0 7"
          class="prefix__cls-1"
          data-name="Stroke 351"
        />
      </g>
    </svg>
  </a>
</div>
