<div class="d-flex align-items-center">
  <span class="text-gray-400 font-12 mr-2">
    {{ progressCount }}%
  </span>
  <input
    [(ngModel)] = "progressCount"
    type="range"
    [value]="progressCount"
    [disabled] = "isGuest"
    (ngModelChange)="changeProgress()"
    class="w-100 text-primary"
    id="taskProgress"
    name="progress"
    min="0"
    max="100"
  />
</div>
