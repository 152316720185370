import { AfterViewInit, Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appChangeColor]'
})


export class ChangeColorDirective implements AfterViewInit {
  @Input('appChangeColor') backgroundColor = '#389bff';

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {
  }

  ngAfterViewInit() {
    if (this.elementRef) {
      const taskStatus = this.elementRef.nativeElement.firstChild;
      this.renderer.setStyle(taskStatus, 'background-color', this.backgroundColor);
    }
  }

}
