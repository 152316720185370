<div class="d-flex align-items-center flex-wrap" *ngIf="users">
  <div *ngFor="let user of users | sortCurrentUserAsFirst : currentUser.UserUID" class="users mr-1">
    <input
      type="checkbox"
      #checkedRef
      [checked]="user.UID === userUID"
      class="d-none users-toggle"
      (change)="checkUser(checkedRef)"
      id="{{ user.UID }}"
    />
    <label for="{{ user.UID }}">
      <app-no-avatar
        [avatarURL]="user.AvatarURL"
        [userName]="user.Name"
        [tooltip]="user.Name"
        [delay]="800"
        [width]="29"
        [height]="29"
        background="#a5a5a5"
      >
      </app-no-avatar>
    </label>
  </div>
  <!--  -->
  <app-add-to-project
    [projectUID]="projectUID"
    [selectedUsers]="users"
    (removeFilterEvent) = "clearFilter($event)"
    (selectedUserEvent)="updateUsersArray($event)"
  >
  </app-add-to-project>
</div>
