import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-topbar-icons',
  templateUrl: './topbar-icons.component.html',
  styleUrls: ['./topbar-icons.component.scss']
})
export class TopbarIconsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
