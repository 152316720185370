<div class="shareable-link" [ngClass] = "{'project-padding': isProject}">
  <div class="shareable-link-body">
    <div class="form-group" *ngIf ="!isProject">
      <label class="label mb-2">Choose one project</label>
      <div class="d-flex flex-wrap">
        <div
          *ngFor="let project of projects | filterTemplates: false; let i = index; let isFirst = first"
          class="mr-4"
        >
          <label for="shareIcon_{{ project.UID }}" class="cursor-pointer" [tooltip] = "project.Name" placement="bottom">
            <input
              #projectIcon
              hidden
              [checked]="isFirst"
              id="shareIcon_{{ project.UID }}"
              [value]="project.UID"
              type="radio"
              (change)="changeProjectUID($event)"
              name="shareIcon"
            />
            <div class="d-flex flex-column">
              <div
                class="project-icon"
                [ngStyle]="{ 'background-color': project.Icon }"
                [ngClass]="{ 'checked-icon': projectIcon.checked }"
              ></div>
              <div
                class="project-key"
                [ngClass]="{ 'text-primary': projectIcon.checked }"
              >
                {{ project.Key }}
              </div>
            </div>
          </label>
        </div>
      </div>
    </div>
    <p>
      With this link, invited user can see project tasks and comment on them.
    </p>
    <form>
      <div class="form-group mb-0 w-100">
        <label class="label"> Name </label>
        <div class="d-flex align-items-center">
          <input
            #name
            type="text"
            class="form-control"
            placeholder="Enter full name"
          />
          <button
            *ngIf="!isGeneratedLink"
            type="submit"
            class="btn shareable-link-button"
            (click)="generateLink(name.value)"
          >
            Generate link
          </button>
          <span
            *ngIf="isGeneratedLink"
            class="font-12 text-gray-600 text-nowrap ml-3"
          >
            The link has been generated!
          </span>
        </div>
      </div>
      <div
        class="d-flex align-items-center justify-content-between mt-3 position-relative"
        *ngIf="isGeneratedLink"
      >
        <div class="shareable-link-genereted scroll scroll-auto">
          {{ generatedLink }}
        </div>
        <button type ="button" class="btn btn-copy ml-sm-3 text-nowrap" (click)="copyGeneratedLink()">
          Copy link
       </button>
        <span *ngIf="isCopy" class="text-primary font-12 link-copy">
          Link copied!
        </span>
      </div>
    </form>
  </div>
</div>
