import { ShopifyService } from '@app/shared/services/shopify.service';
import { Error } from '@app/enums/errors';
import { UserProjectService } from '@app/shared/services/user-project.service';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { ProjectService } from '@app/shared/services/project.service';
import { Project } from '@app/models/project';
import { Component, OnInit, ViewChild, ElementRef, Input, Renderer2, OnDestroy } from '@angular/core';
import { User } from '@app/models/user';
import { UserService } from '@app/shared/services/user.service';
import { ToastrService } from 'ngx-toastr';
import { Role } from '@app/enums/role';
import * as InlineEditor from '@app/ckEditor/build/ckeditor';
import {
  ChangeEvent,
  CKEditorComponent,
} from '@ckeditor/ckeditor5-angular/ckeditor.component';
import { distinctUntilChanged, concatMap, catchError, map, debounceTime, takeUntil } from 'rxjs/operators';
import { Task } from '@app/models/task';
import { SweetAlertArrayOptions } from 'sweetalert2';
import { of, EMPTY, Subject } from 'rxjs';
import { ShareLinkService } from '@app/shared/services/share-link.service';
import { ShopifyLoginComponent } from '@app/layout/shopify/shopify-login/shopify-login.component';
import { ProjectType } from '@app/enums/projectType';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss'],
})
export class ProjectDetailsComponent implements OnInit, OnDestroy {
  public Editor = InlineEditor;
  @ViewChild('content') content: ElementRef;
  @ViewChild('editor') editor: CKEditorComponent;
  @ViewChild('titleRef') titleRef: ElementRef;
  swalOptions: SweetAlertArrayOptions = [
    'Delete project',
    'Are you sure you want to delete this project? You will delete all it’s tasks and guest users'];
  projectForm: FormGroup;
  conditionsForm: FormGroup;
  items: FormArray;
  newTitle: string;
  destroy$ = new Subject<void>();
  isShopifyLogin: boolean;
  @Input() project: Project;
  public configDesc = {
    placeholder: 'Enter description',
    link: {
      addTargetToExternalLinks: true,
    },
  };
  editorPlaceholder = 'Add description';
  showEditorBtn = false;
  showDescEditor = false;
  public editorValue: string;
  public editorInitialValue: string;
  initialTitle: string;
  isGeneratedLink = false;
  isCopy = false;
  isFirstLeadChange = true;
  isShopify = false;
  shopifyID: string;
  isOpen: boolean;
  accordionStatus: string;
  generatedLink: string;
  users: User[];
  dropdownUsers: User[];
  iconColor: string;
  submitted = false;
  loading = false;
  isEditMode = false;
  isDropup = true;
  prevLead = null;
  showCreateMember = true;
  currentLead: string;
  chooseUsers: string[] = [];
  selectedUsers: User[] = [];
  selectedUsersInit: User[] = [];
  currentUser = JSON.parse(localStorage.getItem('currentUser'));
  public onChange({ editor }: ChangeEvent) {
    this.editorValue = editor.getData();
  }
  constructor(
    private projectService: ProjectService,
    private usersToProject: UserProjectService,
    private formBuilder: FormBuilder,
    private shareLinkService: ShareLinkService,
    private userService: UserService,
    public activeModal: BsModalRef,
    public modalService: BsModalService,
    private renderer: Renderer2,
    private toast: ToastrService,
    private shopifyService: ShopifyService
  ) {

  }

  get roleType() {
    return Role;
  }

  get projectType() {
    return ProjectType;
  }

  get f() {
    return this.projectForm.controls;
  }

  get t() {
    return this.projectForm.get('Conditions') as FormArray;
  }

  selectProjectType(type: object | number) {
    const typeNumber  = type as number;
    if (typeNumber === 2) {
      this.isShopify = true;
      this.shopifyService.checkShopifyAcc().subscribe( res => {
     });
    } else {
      this.isShopify = false;
    }
  }

  changeLeadUser(userUID: {}) {
    const userId = userUID as string;
    if (this.isFirstLeadChange) {
      this.addCurrentLead(userId);
    } else {
      const isProjectMember = this.selectedUsersInit.some( item => item.UID === this.currentLead);
      if (isProjectMember) {
        this.currentLead = this.projectForm.get('LeadUID').value;
        this.addCurrentLead(userId);
      } else {
        this.prevLead = this.projectForm.get('LeadUID').value;
        this.addCurrentLead(userId);
      }
    }
    this.isFirstLeadChange = false;
    this.dropdownUsers = this.filterUsers(this.users);
  }

  chooseUsersList(event: Event, user: User) {
    const isChecked = (event.target as HTMLInputElement).checked;
    if (isChecked) {
       this.addUserToProject(user);
    } else {
      this.removeUserfromProject(user.UID);
  }
}

  removeUserfromProject(userUID: string) {
    this.usersToProject.removeUsersfromProject(this.project.UID, userUID).subscribe( () => {
      this.chooseUsers = this.chooseUsers.filter((item) => item !== userUID);
      this.selectedUsers = this.selectedUsers.filter(
        (item) => item.UID !== userUID
      );
      this.dropdownUsers = this.filterUsers(this.users);
      this.onShown();
    },
    error => {
      if (error.error.ErrorCode === Error.ErrorCodeLeadExist) {
        this.toast.warning(
          `You can’t remove this person from project until you set someone else as a lead`
        );
      }
      if (error.error.ErrorCode === Error.ErrorCodeNotAllowed) {
          this.toast.warning('Not enough permissions to remove User', 'Warning!');
      }
      this.dropdownUsers = this.filterUsers(this.users);
      this.onShown();
  });
  }

  addUserToProject(user: User) {
    this.chooseUsers = [...this.chooseUsers, user.UID];
    this.usersToProject.addUserstoProject(this.project.UID, this.chooseUsers).subscribe( () => {
      this.selectedUsers = [...this.selectedUsers, user];
      this.dropdownUsers = this.filterUsers(this.users);
      this.onShown();
    }, error => {
    if (error.error.ErrorCode === Error.ErrorCodeNotAllowed) {
      this.toast.warning('Not enough permissions to add User', 'Warning!');
      }
    this.dropdownUsers = this.filterUsers(this.users);
    this.onShown();
      }
    );
  }

  onShown(): void {
    setTimeout(() => {
      this.selectedUsers.forEach((item) => {
        const checkUser = document.getElementById(
          'check' + item.UID
        ) as HTMLInputElement;
        if (checkUser) {
          checkUser.checked = true;
        }
      });
    }, 0);
  }

  ngOnInit() {
    this.createForm();
    this.getShopifyID();
    this.createConditionsForm();
    this.getUsersforProject();
    this.editorInitialValue = this.project.Description ? this.project.Description : this.editorPlaceholder;
    this.newTitle = this.project.Name;
    this.onProjectFormChanges();
    this.iconColor = this.project.Icon;
  }

  filterUsers(usersArray: User[]) {
      let result: User[];
      switch (true) {
          case this.currentUser.Role === Role.Member:
            result = usersArray.filter(user => !this.selectedUsers.some(selectedUser =>
              user.UID === selectedUser.UID &&
              user.Role !== Role.Member));
            break;
          case this.currentUser.Role === Role.Admin:
            result = usersArray.filter(user => !this.selectedUsers.some(selectedUser =>
              user.UID === selectedUser.UID &&
              (selectedUser.Role !== Role.Member ||
              selectedUser.IsLead === true) &&
              user.UID !== this.currentUser.UserUID));
            break;
          case this.currentUser.Role === Role.SuperAdmin:
            result = usersArray.filter(user => !this.selectedUsers.some(selectedUser =>
              user.UID === selectedUser.UID &&
              user.Role === Role.SuperAdmin &&
              user.UID !== this.currentUser.UserUID));
            break;
        }
      return result;
    }

  deleteProject(id: string) {
    this.projectService.deleteProject(id).subscribe( () => {
      this.activeModal.hide();
    });
  }

  generateLink(name: string) {
    if (name === '') {
      return;
    }
    this.shareLinkService.getLink(this.project.UID, name).subscribe((res: string) => {
       this.generatedLink = res;
       this.isGeneratedLink = true;
    }, error => {
      if (error.error.ErrorCode === Error.ErrorCodeUserNameExists) {
        this.toast.warning('User with this name already exists');
      }
    });
  }

  copyGeneratedLink() {
    this.isCopy = true;
    this.shareLinkService.copyGeneratedLink(this.generatedLink);
    setTimeout(() => {
      this.isCopy = false;
    }, 2000);
  }

  getAllUsers() {
    const getAllUsers$ =  this.userService.getPersons().pipe(map(result => {
      this.users = result.data;
      this.projectForm.get('LeadUID').setValue(this.project.LeadUID, {emitEvent: false});
      this.dropdownUsers = this.filterUsers(result.data);
      const lead = this.project.LeadUID;
      if (
        this.currentUser.Role === Role.Member &&
        this.currentUser.UserUID !== lead
      ) {
        this.projectForm.disable({  onlySelf: true,
          emitEvent: false});
        this.showCreateMember = false;
      } else if (
        this.currentUser.Role === Role.Admin &&
        this.currentUser.UserUID !== lead
      ) {
        this.projectForm.get('LeadUID').disable({  onlySelf: true,
          emitEvent: false});
      }
    }));

    return getAllUsers$;
  }

  getUsersforProject() {
    this.userService.getusersProject(this.project.UID).pipe(concatMap( (res => {
      this.selectedUsers = res.data;
      this.selectedUsersInit = res.data;
      this.selectedUsers.map((item) => {
        this.chooseUsers = [...this.chooseUsers, item.UID];
      });
      return this.getAllUsers();
    }))).subscribe();
  }

  createForm() {
    this.projectForm = this.formBuilder.group({
      Name: [{value: this.project.Name, disabled: !this.isOpen && this.accordionStatus === 'project-edit' },
      { validators: [Validators.required], updateOn: 'submit' }],
      Key: [this.project.Key, { validators: [Validators.required], updateOn: 'blur' }],
      LeadUID: [null, { validators: [Validators.required], updateOn: 'blur' }],
      Description: [this.project.Description, {updateOn: 'submit'}],
      Icon: [this.project.Icon, {updateOn: 'blur'}],
      Type: [this.project.Type],
    });
  }

  createConditionsForm() {
    this.conditionsForm = this.formBuilder.group({
      Conditions: this.formBuilder.array([])
    });
  }

  getShopifyID() {
      this.shopifyService.checkShopifyAcc().subscribe( res => {
        this.isShopifyLogin = false;
        this.shopifyID = res;
      }, error => {
        this.isShopifyLogin = true;
      });
  }

  changeColor(newColor: string) {
    const currentColor =  this.projectForm.get('Icon').value;
    if (newColor !== currentColor) {
      this.projectForm.get('Icon').setValue(this.iconColor, {emitEvent: false});
      const data = this.projectForm.getRawValue() as Project;
      this.projectService.updateProject(data, this.project.UID).subscribe();
    }
  }

  isOpenChange(isOpen: boolean, accStatus: string) {
    this.isOpen = isOpen;
    this.accordionStatus = accStatus;
  }



  openShopifyModal() {
    this.modalService.show(ShopifyLoginComponent, {
      class: 'custom-modal modal-md modal-dialog-scrollable shopify-login'
    });
    document.getElementsByClassName('shopify-login')[0].parentElement.style.backgroundColor = 'rgba(100, 112, 130, 0.4)';
  }

  showEditor(event: Event) {
    const link = event.target as HTMLElement;
    if (link.hasAttribute('rel')) {
      event.stopPropagation();
    } else {
      this.showDescEditor = true;
      if (this.editorInitialValue !== this.editorPlaceholder) {
        this.f.Description.setValue(this.editorInitialValue, {emitEvent: false});
        } else {
          this.f.Description.setValue('', {emitEvent: false});
        }
      setTimeout(() => {
        this.editor.editorInstance.editing.view.focus();
      });
    }
  }

  closeEditor() {
    this.showDescEditor = false;
  }

  closeEditTitle() {
    this.isEditMode = false;
    this.projectForm.get('Name').setValue(this.initialTitle, {emitEvent: false});
  }

  autoHeight(elem: HTMLTextAreaElement) {
    elem.style.height = '48px';
    elem.style.height = (elem.scrollHeight) + 'px';
  }

  editTitle(el: HTMLDivElement) {
    if (this.isOpen !== false && this.accordionStatus === 'project-edit') {
    this.isEditMode = true;
    if (this.titleRef.nativeElement) {
      this.titleRef.nativeElement.value = this.projectForm.get('Name').value.trim();
      this.initialTitle = this.projectForm.get('Name').value.trim();
      this.renderer.setStyle(this.titleRef.nativeElement, 'height', el.scrollHeight + 'px');
    }
    setTimeout(() => {
      this.titleRef.nativeElement.focus();
    }, 0);
   }
  }

  saveDesc(event: Event) {
    event.preventDefault();
    if (this.editorValue !== this.editorInitialValue && this.editorValue !== this.editorPlaceholder) {
      this.projectForm.get('Description').setValue(this.editorValue, {emitEvent: false});
      const data = this.projectForm.getRawValue() as Project;
      this.projectService
        .updateProject(data, this.project.UID)
        .subscribe(() => {
          this.editorInitialValue = this.editorValue;
          this.showDescEditor = false;
        });
    } else {
      this.editorInitialValue !== this.editorPlaceholder ? this.editorInitialValue = this.editorValue :
      this.editorInitialValue = this.editorPlaceholder;
      this.showDescEditor = false;
    }
  }

  saveTitle(event: Event) {
    event.preventDefault();
    this.submitted = true;
    const newTitleValue = this.titleRef.nativeElement.value;
    if (this.newTitle !== newTitleValue && newTitleValue !== '') {
      this.projectForm.get('Name').setValue(newTitleValue,  {emitEvent: false});
      this.submitted = true;
      if (this.projectForm.get('Name').invalid) {
        return;
      }
      const data = this.projectForm.getRawValue() as Project;
      this.projectService
        .updateProject(data, this.project.UID)
        .subscribe(() => {
          this.newTitle = newTitleValue;
          this.isEditMode = false;
        });
    } else {
      this.isEditMode = false;
    }
  }

  onProjectFormChanges() {
    this.projectForm.valueChanges
      .pipe(
        distinctUntilChanged(
          (a: Task, b: Task) => JSON.stringify(a) === JSON.stringify(b)
        ),
        debounceTime(300),
        concatMap((res) => {
          if (this.projectForm.invalid) {
            this.newTitle = this.projectForm.get('Name').value;
            return of();
          }
          this.currentLead = this.projectForm.get('LeadUID').value;
          const data = this.projectForm.getRawValue() as Project;
          return this.projectService.updateProject(data, this.project.UID);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        if (this.prevLead) {
          this.removeUserfromProject(this.prevLead);
          this.prevLead = null;
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  addCurrentLead(currentLeadUID: string) {
    this.users.map((item) => {
      if (item.UID === currentLeadUID) {
        const index = this.selectedUsers.findIndex((el) => el.UID === currentLeadUID);
        if (index === -1) {
          this.selectedUsers = [...this.selectedUsers, item];
          this.chooseUsers = [...this.chooseUsers, item.UID];
        }
      }
    });
  }
}
