import {
  Component,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  EventEmitter,
} from '@angular/core';
import { GuestService } from '@app/shared/services/guest.service';

@Component({
  selector: 'app-range-slider',
  templateUrl: './range-slider.component.html',
  styleUrls: ['./range-slider.component.scss'],
})
export class RangeSliderComponent implements OnInit, OnChanges {
  isGuest = false;
  @Input() progressCount: number;
  @Output() progressCountEvent: EventEmitter<number> = new EventEmitter<
    number
  >();

  constructor(private guestService: GuestService) {}

  ngOnInit(): void {
    this.isGuest = this.guestService.isGuest;
    if (!this.progressCount) {
      this.progressCount = 0;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.changeProgress();
  }

  changeProgress() {
    const taskProgress = document.getElementById('taskProgress');
    taskProgress.style.background =
      'linear-gradient(to right, #389bff 0%, #389bff ' +
      this.progressCount +
      '%, #eff0f4 ' +
      this.progressCount +
      '%, #eff0f4 100%)';
    this.progressCountEvent.emit(this.progressCount);
  }
}
