import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProductResponse, Conditions, LocationResponse, LocationForm } from '@app/models/shopify';
import { shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ShopifyService {

  constructor(private http: HttpClient) { }


  loginShopify(username: string) {
    const apiKey = 'e81219245170c2e602e85e0d5e3ecbac';
    const scopes = 'read_products,write_products,read_inventory,write_inventory,read_locations';
    const redirectApi = 'https://dev.jarvis.management/shopify/connect';
    const randomString = this.randomString(10);
    const tenantKey = this.getTenantKey();
    const Nonce = `${tenantKey}.${randomString}`;
    this.http.post(`${environment.baseUrl}api/shopify`, {Nonce}).subscribe(() => {
     // tslint:disable-next-line: max-line-length
     window.location.href = `https://${username}/admin/oauth/authorize?client_id=${apiKey}&scope=${scopes}&redirect_uri=${redirectApi}&state=${Nonce}`;
    });
  }

  connectShopify(params: {
    code: string;
    hmac: string;
    shop: string;
    state: string;
    timestamp: string;
  }) {
    return this.http.get(`${environment.baseUrl}open/shopify/connected`, {params});
    }


  checkShopifyAcc() {
    return this.http.get<string>(`${environment.baseUrl}api/shopify`);
  }

  randomString(length: number) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    const charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 }

 getShopifyProduct(shopifyID: string) {
   return this.http.get<ProductResponse>(`${environment.baseUrl}api/shopify/product/${shopifyID}`).pipe(
     shareReplay()
   );
 }

 getShopifyConditions(projectID: string) {
  return this.http.get<Conditions[]>(`${environment.baseUrl}api/shopify/project/${projectID}/condition`);
 }

 deleteShopifyConditions(projectKey: string | number, conditionID: string) {
  return this.http.delete<null>(`${environment.baseUrl}api/shopify/project/${projectKey}/condition/${conditionID}`);
 }

 addShopifyConditions(projectKey: string | number, conditions: Conditions[]) {
  return this.http.put
  <{Index: number, UID: string}[]>(`${environment.baseUrl}api/shopify/project/${projectKey}/condition`, {Conditions: conditions});
 }

 getShopifyLocations(projectID: string, taskID: string) {
  return this.http.get<LocationResponse>(`${environment.baseUrl}api/project/${projectID}/task/${taskID}/shopifyLocations`);
 }

 sendShopifyLocations(data: LocationForm) {
  return this.http.post(`${environment.baseUrl}api/shopify/product`, data);
 }


 getTenantKey() {
  const domain = window.location.hostname;
  let tenant: string;
  const tenatArray =  domain.split('.');
  if (tenatArray[0] === 'www') {
    tenant = tenatArray[1];
  } else {
    tenant = tenatArray[0];
  }
  return tenant;
 }

}
