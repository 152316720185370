import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss'],
})
export class LoadingSpinnerComponent implements OnInit {
@Input() top: number;
@Input() left: number;
@Input() height: number;
@Input() width: number;
  constructor() { }

  ngOnInit() {
  }

}
