<div class="users-list">
  <p class="paragraph users-list-title">All Users</p>
  <div
    class="users-list-content cursor-pointer"
    (click)="
      selectElement(
        user.Name
      )
    "
    *ngFor="let user of users"
  >
    <div class="users-list-avatar d-flex align-items-center">
      <app-no-avatar
      [avatarURL] = "user.AvatarURL"
      [userName] = "user.Name"
      [width]= "60"
      [height] = "60"
      class="mr-3">
      </app-no-avatar>
    </div>
    <div class="users-list-info">
      <p class="mb-1 font-weight-bold paragraph">
        {{ user.Name }}
      </p>
      <p class="mb-1 paragraph">{{ user.Position }}</p>
      <p class="font-weight-bold text-break paragraph mb-0">
        {{ user.Email }}
      </p>
    </div>
  </div>
</div>
