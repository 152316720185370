
<svg xmlns="http://www.w3.org/2000/svg" width="22.848" height="18.5" viewBox="0 0 22.848 18.5"  [ngClass] = "{'default-view-active': !isManagerMode}">
  <defs>
      <style>
          .default-view{fill:none;stroke:#96989b;stroke-linecap:round;stroke-width:2.5px}
      </style>
  </defs>
  <g id="Group_2192" transform="translate(-427.25 -28.25)">
      <g id="Group_2125" transform="translate(428.5 29.5)">
          <path id="Line_142" d="M0 0L20.348 0" class="default-view"/>
          <path id="Line_143" d="M0 0L14 0" class="default-view" transform="translate(0 8)"/>
          <path id="Line_144" d="M0 0L9 0" class="default-view" transform="translate(0 16)"/>
      </g>
  </g>
</svg>
