import { Directive, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appDisableControl]'
})
export class DisableControlDirective {

  @Input() set disableControl( condition: boolean ) {
    const action = condition ? 'enable' : 'disable';
    this.ngControl.control[action]();
  }

  constructor( private ngControl: NgControl ) {
  }
}
