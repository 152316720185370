import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ShareLinkService {

  constructor(private http: HttpClient) { }

  getLink(projectUID: string, Name: string) {
    return this.http.post(`${environment.baseUrl}api/project/${projectUID}/share`, {Name});
  }

  copyGeneratedLink(generetedLink: string) {
    const el = document.createElement('textarea');
    el.value = generetedLink;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }
}
