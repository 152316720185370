<form
  class="d-flex flex-column overflow-hidden"
  [formGroup]="profileForm"
  (ngSubmit)="submitForm()"
>
  <div class="modal-header border-none">
    <h4 class="modal-title" id="modal-basic-title">My account</h4>
  </div>
  <div class="modal-body">
    <input
      type="file"
      class="d-none"
      (change)="addImage($event)"
      #fileInput
      accept="image/*"
    />
    <div class="img-wrap mb-4">
      <div class="image-container text-center" (click)="fileInput.click()">
        <app-loading-image-spinner *ngIf="loading"></app-loading-image-spinner>
        <app-no-avatar
          [avatarURL]="avatar"
          [userName]="currentName"
          [width]="82"
          [height]="82"
          [font]="36"
        >
        </app-no-avatar>
        <div class="overlay"></div>
      </div>
    </div>
    <div class="form-group">
      <div class="row">
        <div class="col-sm-9">
          <label class="label"> Your name </label>
          <input
            type="text"
            class="form-control"
            pattern="([^{}/\[/\]%]+)"
            formControlName="Name"
            placeholder="Type name"
            [ngClass]="{ 'is-invalid': f['Name']?.errors && submitted }"
          />
          <div *ngIf="submitted && f['Name']?.errors" class="invalid-feedback">
            <div *ngIf="f['Name']?.errors.required">
              This field can not be empty
            </div>
            <div *ngIf="f['Name'].errors.pattern">
              Can't use {{ specialChar }} special characters in name
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <div class="col-sm-9">
            <label class="label"> Email </label>
            <input
              type="text"
              readonly
              class="form-control"
              formControlName="Email"
              placeholder="Type email"
              [ngClass]="{ 'is-invalid': f['Email']?.errors && submitted }"
            />
            <div
              *ngIf="submitted && f['Email']?.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f['Email']?.errors.required">
                This field can not be empty
              </div>
              <div *ngIf="f['Email']?.errors.email">
                Email must be a valid email address
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group">
      <div class="row">
        <div class="col-sm-9">
          <label class="label"> Position </label>
          <input
            type="text"
            class="form-control"
            formControlName="Position"
            placeholder="Software developer "
          />
        </div>
      </div>
    </div>
    <div class="form-group">
      <div class="row">
        <div class="col-sm-12">
          <label class="label"> Bio </label>
          <textarea
            type="text"
            class="form-control"
            formControlName="Bio"
            rows="4"
            placeholder="Type something about you"
          >
          </textarea>
        </div>
      </div>
    </div>
    <div class="changePassword">
      <a class="cursor-pointer text-primary" (click)="changePassword()">
        Change your password
      </a>
    </div>
    <div class="form-group">
      <div class="row">
        <div class="col-md-4">
          <label class="label"> Send reminders on weekends? </label>
          <ng-select
            appearance="outline"
            appendTo="body"
            labelForId="NotifyOnWeekends"
            [searchable]="false"
            [clearable]="false"
            formControlName="NotifyOnWeekends"
          >
            <ng-option [value]="true">On</ng-option>
            <ng-option [value]="false">Off</ng-option>
          </ng-select>
        </div>
        <div class="col-md-3">
          <label class="label"> Start of work day? </label>
          <ng-select
            appearance="outline"
            appendTo="body"
            [items]="timeValues"
            [readonly]="isReminderDeleted"
            bindLabel="name"
            bindValue="id"
            [clearable]="false"
            [searchable]="false"
            formControlName="WorkdayStart"
          >
          </ng-select>
          <app-hint-icon
            [tooltip]="
              'We may send notifications of your prospective tasks for that day.'
            "
            class="mr-2 mt-1"
          ></app-hint-icon>
        </div>
        <div class="col-md-3">
          <label class="label"> End of work day? </label>
          <ng-select
            appearance="outline"
            appendTo="body"
            [items]="timeValues"
            [readonly]="isReminderDeleted"
            bindLabel="name"
            bindValue="id"
            #myselect
            [clearable]="false"
            [searchable]="false"
            formControlName="WorkdayEnd"
          >
          </ng-select>
          <app-hint-icon
            [tooltip]="
              'We may send you notifications to update your progress at the end of the work day.'
            "
            class="mr-2 mt-1"
          ></app-hint-icon>
        </div>
        <div class="col-md-2">
          <div class="d-flex align-items-center h-100">
            <a (click)="clearReminder()" class="cursor-pointer mr-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12.211"
                height="13.303"
                viewBox="0 0 12.211 13.303"
              >
                <g>
                  <g>
                    <g
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path
                        d="M30.134 10.9a1.093 1.093 0 0 0 1.09-1.09h-2.181a1.093 1.093 0 0 0 1.091 1.09zm3.543-6.269A3.508 3.508 0 0 0 30.951 1.2V.818a.818.818 0 1 0-1.635 0V1.2a3.152 3.152 0 0 0-.763.273L33.677 6.6zm-.164 4.634l1.09 1.09.709-.709-9.1-9.1-.709.709 1.581 1.581a3.431 3.431 0 0 0-.491 1.8v3L25.5 8.722v.545z"
                        transform="translate(-25.621 -.267) translate(25.5) translate(-24.18 1.467)"
                      />
                      <path
                        fill="#818181"
                        [ngClass]="{ 'turn-off-reminder': isReminderDeleted }"
                        d="M30.134 10.903c.6 0 1.09-.49 1.09-1.09h-2.18c0 .6.49 1.09 1.09 1.09m4.47-.545l.708-.709L26.21.545l-.709.709 1.58 1.58c-.326.546-.49 1.146-.49 1.8v2.998l-1.09 1.09v.545h8.014l1.09 1.09m-.927-3.76V4.633c0-1.69-1.145-3.053-2.726-3.435V.818c0-.436-.381-.818-.817-.818-.436 0-.818.382-.818.818v.381c-.273.055-.545.164-.763.273l5.124 5.124m-3.543 5.507c-1.036 0-1.913-.69-2.195-1.636H25.5c-.663 0-1.2-.537-1.2-1.2v-.545c0-.318.126-.623.351-.848l.74-.74v-2.5c0-.538.084-1.053.252-1.54l-.992-.992c-.468-.468-.468-1.228 0-1.697l.71-.708c.224-.225.53-.352.848-.352.318 0 .623.127.848.352l.799.798c.05-.036.104-.068.16-.096.05-.025.105-.052.165-.079.228-.862 1.03-1.52 1.953-1.52.924 0 1.728.66 1.954 1.526.682.307 1.287.77 1.753 1.346.678.84 1.036 1.864 1.036 2.962v1.962c0 .256-.081.498-.223.697L36.16 8.8c.469.47.469 1.229 0 1.697l-.709.71c-.234.233-.541.35-.848.35-.307 0-.614-.117-.849-.35l-.739-.74h-.687c-.283.945-1.16 1.636-2.195 1.636z"
                        transform="translate(-25.621 -.267) translate(25.5) translate(-24.18 1.467)"
                      />
                    </g>
                  </g>
                </g>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer border-none">
    <app-spinner-button [options]="spinnerButtonOptions"> </app-spinner-button>
    <button type="button" class="btn btn-cancel" (click)="activeModal.hide()">
      Cancel
    </button>
  </div>
</form>
