<div class="topbar-nav">
  <ul>
    <li>
      <a tooltip="Today"  placement="bottom" [delay] = "800" class="topbar-links"
      routerLink="/today"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }">
      <svg xmlns="http://www.w3.org/2000/svg" width="29.055" height="22.903" viewBox="0 0 29.055 22.903">
        <defs>
            <style>
                .cls-4{fill:#96989b}.cls-5{fill:none;stroke:#96989b;stroke-linecap:round;stroke-width:2px}
            </style>
        </defs>
        <g id="Group_2126" transform="translate(-418.316 -26)">
            <path id="Path_961" d="M3.268 24.3a3.268 3.268 0 1 0 3.268 3.268A3.268 3.268 0 0 0 3.268 24.3zm-.008 4.908a1.638 1.638 0 1 1 0-3.277 1.638 1.638 0 0 1 0 3.277z" class="cls-4 active-svg-today" transform="translate(418.316 1.699)"/>
            <path id="Path_962" d="M3.268 144.6a3.268 3.268 0 1 0 3.268 3.268 3.268 3.268 0 0 0-3.268-3.268zm-.008 4.907a1.638 1.638 0 1 1 0-3.277 1.638 1.638 0 1 1 0 3.277z" class="cls-4 active-svg-today" transform="translate(418.316 -110.413)"/>
            <path id="Path_963" d="M3.268 264.667a3.268 3.268 0 1 0 3.268 3.268 3.268 3.268 0 0 0-3.268-3.268zm-.008 4.907a1.638 1.638 0 1 1 0-3.277 1.638 1.638 0 1 1 0 3.277z" class="cls-4 active-svg-today" transform="translate(418.316 -222.3)"/>
            <g id="Group_2125">
                <path id="Line_142" d="M0 0L17.871 0" class="cls-5 active-svg-circle" transform="translate(428.5 29.5)"/>
                <path id="Line_143" d="M0 0L13 0" class="cls-5 active-svg-circle" transform="translate(428.5 37.5)"/>
                <path id="Line_144" d="M0 0L9 0" class="cls-5 active-svg-circle" transform="translate(428.5 45.5)"/>
            </g>
        </g>
    </svg>
        <span class="d-none"> Today </span>
      </a>
    </li>

    <li>
      <a
        routerLink="/project"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        placement="bottom"
        tooltip="Projects"
        [delay] = "800"
        placement="bottom"
        class="topbar-links"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="22.522"
          height="26.925"
          viewBox="0 0 22.522 26.925"
        >
          <defs>
            <style>
              .prefix__cls-projects {
                fill: #96989b;
                stroke: #96989b;
                stroke-width: 0.5px;
              }
            </style>
          </defs>
          <g
            id="prefix__Group_789"
            data-name="Group 789"
            transform="translate(.25 .25)"
          >
            <path
              id="prefix__Path_954"
              d="M835.018 340.112h-11.543a2.478 2.478 0 0 1-2.475-2.475v-16.662a2.478 2.478 0 0 1 2.475-2.475h11.543a2.478 2.478 0 0 1 2.475 2.475v16.662a2.478 2.478 0 0 1-2.475 2.475zm-11.543-20.222a1.086 1.086 0 0 0-1.085 1.085v16.662a1.086 1.086 0 0 0 1.085 1.085h11.543a1.086 1.086 0 0 0 1.085-1.085v-16.662a1.086 1.086 0 0 0-1.085-1.085z"
              class="prefix__cls-projects active-svg"
              data-name="Path 954"
              transform="translate(-821 -313.687)"
            />
            <path
              id="prefix__Path_955"
              d="M872.018 308.779a.7.7 0 1 1 0-1.39 1.086 1.086 0 0 0 1.082-1.089v-16.658a1.086 1.086 0 0 0-1.085-1.085h-11.54a1.086 1.086 0 0 0-1.085 1.085.7.7 0 1 1-1.39 0 2.478 2.478 0 0 1 2.475-2.475h11.543a2.478 2.478 0 0 1 2.475 2.475V306.3a2.478 2.478 0 0 1-2.475 2.479z"
              class="prefix__cls-projects active-svg"
              data-name="Path 955"
              transform="translate(-852.471 -287.167)"
            />
          </g>
        </svg>
        <span class="d-none"> Projects </span>
      </a>
    </li>
    <li class="d-none">
      <a
        routerLinkActive="active"
        tooltip="Tasks"
        [delay] = "800"
        placement="bottom"
        class="topbar-links"
      >
        <svg
          width="24px"
          height="24px"
          viewBox="0 0 24 24"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <g
            id="Page-1"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g
              id="task-manager"
              transform="translate(-36.000000, -248.000000)"
              fill="#C0C4CA"
            >
              <g id="Tasks">
                <g id="Sidebar" transform="translate(0.000000, 74.375000)">
                  <g
                    id="Tasks-(Active)"
                    transform="translate(0.000000, 148.750000)"
                  >
                    <g
                      class="active-svg"
                      transform="translate(36.124912, 25.095611)"
                    >
                      <path
                        d="M23.2815881,16.3593145 L21.1905881,15.331877 L19.5968381,16.190377 L21.3404006,17.042502 L11.7885256,22.189252 L2.34715059,17.048877 L4.19058809,16.2148145 L2.60215059,15.348877 L0.410213093,16.358252 C0.162650593,16.481502 0.00433809342,16.7333145 8.80934197e-05,17.010627 C-0.00416190658,17.286877 0.145650593,17.5429395 0.387900593,17.675752 L11.4304631,23.6884395 C11.5409631,23.749002 11.6631506,23.7798145 11.7864006,23.7798145 C11.9075256,23.7798145 12.0286506,23.7500645 12.1391506,23.6905645 L23.2975256,17.6768145 C23.5408381,17.546127 23.6917131,17.2900645 23.6885256,17.0138145 C23.6842756,16.736502 23.5280881,16.4846895 23.2815881,16.3593145 L23.2815881,16.3593145 Z"
                        id="Icon-4"
                      ></path>
                      <path
                        d="M23.2815881,11.1530645 L21.0737131,10.032127 L19.4810256,10.8916895 L21.3404006,11.836252 L11.7885256,16.983002 L2.34715059,11.842627 L4.33083809,10.846002 L2.74133809,9.98006449 L0.410213093,11.152002 C0.162650593,11.2763145 0.00433809342,11.5270645 8.80934197e-05,11.804377 C-0.00416190658,12.080627 0.145650593,12.3366895 0.387900593,12.469502 L11.4304631,18.4821895 C11.5409631,18.542752 11.6631506,18.5735645 11.7864006,18.5735645 C11.9075256,18.5735645 12.0286506,18.5438145 12.1391506,18.4843145 L23.2975256,12.4705645 C23.5408381,12.339877 23.6917131,12.0838145 23.6885256,11.8075645 C23.6842756,11.530252 23.5280881,11.2784395 23.2815881,11.1530645 L23.2815881,11.1530645 Z"
                        id="Icon-3"
                      ></path>
                      <path
                        d="M11.6950256,0.0786269922 C11.9075256,-0.0265605078 12.1561506,-0.0265605078 12.3665256,0.0807519922 L12.3665256,0.0807519922 L23.4090881,5.68862699 C23.6555881,5.81400199 23.8128381,6.06475199 23.8160256,6.34312699 C23.8192131,6.61937699 23.6683381,6.87543949 23.4250256,7.00612699 L23.4250256,7.00612699 L12.2666506,13.0188145 C12.1561506,13.0783145 12.0350256,13.1080645 11.9139006,13.1080645 C11.7906506,13.1080645 11.6695256,13.077252 11.5579631,13.017752 L11.5579631,13.017752 L0.515400593,7.00400199 C0.273150593,6.87225199 0.123338093,6.61618949 0.127588093,6.33887699 C0.132900593,6.06262699 0.290150593,5.81081449 0.537713093,5.68650199 L0.537713093,5.68650199 Z M12.0275881,1.57675199 L2.47465059,6.37712699 L11.9160256,11.5185645 L21.4679006,6.37075199 L12.0275881,1.57675199 Z"
                        id="Combined-Shape"
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
        <span class="d-lg-none"> Tasks </span>
      </a>
    </li>
    <li>
      <a
        routerLink="/users"
        routerLinkActive="active"
        tooltip="Users"
        [delay] = "800"
        placement="bottom"
        class="topbar-links"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="38.572"
          height="28.11"
          viewBox="0 0 38.572 28.11"
        >
          <defs>
            <style>
              .prefix__cls-users,
              .prefix__cls-3 {
                fill: #96989b;
                stroke-width: 0.5px;
                stroke: #96989b;
              }
              .prefix__cls-3 {
                fill: none;
                stroke-width: 1.5px;
                stroke-linecap: round;
              }
            </style>
          </defs>
          <g
            id="prefix__Group_801"
            data-name="Group 801"
            transform="translate(.25 .25)"
          >
            <g id="prefix__Group_794" data-name="Group 794">
              <path
                id="prefix__Path_939"
                d="M60.038 58.075A5.038 5.038 0 1 0 55 53.038a5.043 5.043 0 0 0 5.038 5.037zm0-8.866a3.829 3.829 0 1 1-3.829 3.829 3.833 3.833 0 0 1 3.829-3.829z"
                class="prefix__cls-users active-svg"
                data-name="Path 939"
                transform="translate(-50.567 -48)"
              />
              <path
                id="prefix__Path_940"
                d="M225.038 58.075A5.038 5.038 0 1 0 220 53.038a5.043 5.043 0 0 0 5.038 5.037zm0-8.866a3.829 3.829 0 1 1-3.829 3.829 3.833 3.833 0 0 1 3.829-3.829z"
                class="prefix__cls-users active-svg"
                data-name="Path 940"
                transform="translate(-202.267 -48)"
              />
              <path
                id="prefix__Path_941"
                d="M225.6 206.424a.6.6 0 1 0 .957-.739 9.474 9.474 0 0 0-12.794-2.067.6.6 0 1 0 .677 1 8.265 8.265 0 0 1 11.16 1.8z"
                class="prefix__cls-users active-svg"
                data-name="Path 941"
                transform="translate(-196.289 -189.587)"
              />
              <path
                id="prefix__Path_942"
                d="M9.471 202A9.482 9.482 0 0 0 0 211.471a.6.6 0 0 0 1.209 0 8.262 8.262 0 0 1 16.524 0 .6.6 0 0 0 1.209 0A9.482 9.482 0 0 0 9.471 202z"
                class="prefix__cls-users active-svg"
                data-name="Path 942"
                transform="translate(0 -189.587)"
              />
            </g>
            <g
              id="prefix__Group_795"
              data-name="Group 795"
              transform="translate(28.179 17.718)"
            >
              <g
                id="prefix__Ellipse_117"
                data-name="Ellipse 117"
                style="stroke-width: 1.5px; fill: none; stroke: #96989b;"
              >
                <circle cx="5.071" cy="5.071" r="5.071" style="stroke: none;" />
                <circle
                  cx="5.071"
                  cy="5.071"
                  r="4.321"
                  style="fill: none;"
                  class="active-svg-circle"
                />
              </g>
              <g
                id="prefix__Group_793"
                data-name="Group 793"
                transform="translate(3.381 3.381)"
              >
                <path
                  id="prefix__Line_55"
                  d="M0 0L0 3.381"
                  class="prefix__cls-3 active-svg"
                  data-name="Line 55"
                  transform="translate(1.69)"
                />
                <path
                  id="prefix__Line_56"
                  d="M0 0L3.381 0"
                  class="prefix__cls-3 active-svg"
                  data-name="Line 56"
                  transform="translate(0 1.69)"
                />
              </g>
            </g>
          </g>
        </svg>
        <span class="d-none"> Users </span>
      </a>
    </li>
    <li class="d-none">
      <a tooltip="Comments"  placement="bottom" [delay] = "800" class="topbar-links">
        <svg
          width="24px"
          height="23px"
          viewBox="0 0 24 23"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <g
            id="Page-1"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g
              id="task-manager"
              transform="translate(-36.000000, -397.000000)"
              fill="#C0C4CA"
            >
              <g id="Tasks">
                <g id="Sidebar" transform="translate(0.000000, 74.375000)">
                  <g id="Chat" transform="translate(36.125000, 323.000000)">
                    <path
                      d="M9.7186875,0.37559375 C4.3509375,0.37559375 0,3.59284375 0,7.61759375 C0,10.1261563 1.2293125,12.7983438 3.7984375,14.1115938 C3.7984375,14.1264688 3.793125,14.1381563 3.793125,14.1551563 C3.793125,15.2442188 2.8868125,16.7380938 2.52875,17.3543438 L2.530875,17.3543438 C2.5021875,17.4212813 2.4851875,17.4935313 2.4851875,17.5732188 C2.4851875,17.8770938 2.730625,18.1214688 3.0355625,18.1214688 C3.08125,18.1214688 3.1545625,18.1119063 3.181125,18.1119063 C3.1875,18.1119063 3.1906875,18.1119063 3.189625,18.1140313 C5.0883125,17.8037813 7.8911875,15.6681563 8.3055625,15.1624063 C8.7326875,15.2261563 9.020625,15.2357188 9.377625,15.2357188 C9.5285,15.2357188 9.6910625,15.2335938 9.88125,15.2335938 C15.249,15.2335938 19.4086875,12.1672188 19.3449375,7.61759375 C19.3449375,3.59284375 15.0864375,0.37559375 9.7186875,0.37559375 M9.7186875,1.86309375 C14.2576875,1.86309375 17.8574375,4.41946875 17.8574375,7.61759375 C17.8425625,11.1652813 14.467,13.7684063 9.928,13.7684063 C9.928,13.7684063 9.23525,13.7960313 8.523375,13.6897813 L7.6935625,13.6324063 L6.7415625,14.4239688 C6.5205625,14.6779063 5.561125,15.4800938 4.7345,15.9996563 C5.238125,14.5652813 5.2158125,14.1115938 5.2158125,14.1115938 L5.2859375,13.2020938 L4.4741875,12.7877188 C2.414,11.7326563 1.4875,9.53965625 1.4875,7.61759375 C1.4875,4.41946875 5.1796875,1.86309375 9.7186875,1.86309375"
                      id="Icon-2"
                    ></path>
                    <path
                      d="M23.8160438,13.6257125 C23.8160438,11.61015 23.0287313,10.0727125 21.0631063,8.9188375 C21.0152938,9.4554 20.8622938,10.0302125 20.6965438,10.5359625 C21.9662313,11.41465 22.3285438,12.3167125 22.3285438,13.6257125 C22.3285438,15.2300875 21.5805438,16.393525 19.8433563,17.2828375 L19.1038562,17.6504625 C19.1038562,17.6504625 19.1814188,19.5565875 19.2951063,20.0379 C17.8426688,18.9743375 17.3443563,18.0860875 17.3443563,18.0860875 L16.5623563,18.20615 C16.2531688,18.2518375 15.4159188,18.2529 15.4159188,18.2529 C13.3950438,18.2529 11.8979813,17.7715875 10.6208563,16.771775 C10.9672313,16.7494625 8.54791875,16.750525 8.49373125,16.7983375 C9.97060625,18.6024625 12.3856688,19.7404 15.4159188,19.7404 C15.5816688,19.7404 15.7208563,19.7414625 15.8515438,19.7414625 C16.1596688,19.7414625 16.4093563,19.7329625 16.7780438,19.678775 C17.1371688,20.1154625 19.1527313,22.0385875 20.7942938,22.3063375 C20.7932313,22.3042125 20.7953563,22.3042125 20.8027938,22.3042125 C20.8240438,22.3042125 20.8888563,22.3127125 20.9271063,22.3127125 C21.1906063,22.3127125 21.4031063,22.101275 21.4031063,21.8367125 C21.4031063,21.769775 21.3882313,21.706025 21.3637938,21.64865 L21.3648563,21.64865 C21.0567313,21.1174 20.5722313,19.5629625 20.5722313,18.6215875 C20.5722313,18.6067125 20.5679813,18.59715 20.5679813,18.5833375 C22.7875438,17.4485875 23.8160438,15.79215 23.8160438,13.6257125"
                      id="Icon-1"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
        <span class="d-lg-none"> Comments </span>
      </a>
    </li>
    <li class="d-none">
      <a tooltip="Messages"  placement="bottom" [delay] = "800" class="topbar-links">
        <svg
          width="24px"
          height="17px"
          viewBox="0 0 24 17"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <title>Messages</title>
          <g
            id="Page-1"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g
              id="task-manager"
              transform="translate(-36.000000, -474.000000)"
              fill="#C0C4CA"
            >
              <g id="Tasks">
                <g id="Sidebar" transform="translate(0.000000, 74.375000)">
                  <g id="Mail" transform="translate(36.125000, 399.500000)">
                    <path
                      d="M21.9408375,0.637075 L2.6033375,0.637075 C1.3708375,0.637075 0.0002125,1.26395 0.0002125,2.49645 L0.0002125,14.39645 C0.0002125,15.62895 1.3708375,16.999575 2.6033375,16.999575 L21.9408375,16.999575 C23.1733375,16.999575 23.8002125,15.62895 23.8002125,14.39645 L23.8002125,2.49645 C23.8002125,1.26395 23.1733375,0.637075 21.9408375,0.637075 M21.9408375,2.124575 C22.3509625,2.124575 22.3127125,2.086325 22.3127125,2.49645 L22.3127125,14.39645 C22.3127125,14.806575 22.3509625,15.512075 21.9408375,15.512075 L2.6033375,15.512075 C2.1932125,15.512075 1.4877125,14.806575 1.4877125,14.39645 L1.4877125,2.49645 C1.4877125,2.086325 2.1932125,2.124575 2.6033375,2.124575 L21.5689625,2.124575"
                      id="Icon-2"
                    ></path>
                    <path
                      d="M11.9,11.8629187 C11.7268125,11.8629187 11.5525625,11.8023562 11.4123125,11.6812312 L0.2560625,2.75623125 C-0.0541875,2.48741875 -0.087125,2.01779375 0.1816875,1.70754375 C0.4515625,1.39623125 0.920125,1.36329375 1.230375,1.63210625 L11.9,10.1352937 L22.5685625,1.63210625 C22.8788125,1.36435625 23.3484375,1.39623125 23.6183125,1.70754375 C23.887125,2.01779375 23.853125,2.48741875 23.542875,2.75623125 L12.386625,11.6812312 C12.2474375,11.8023562 12.07425,11.8629187 11.9,11.8629187"
                      id="Icon-1"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
        <span class="d-lg-none"> Messages </span>
      </a>
    </li>

    <li class="d-none">
      <a tooltip="Statistics"  placement="bottom" [delay] = "800" class="topbar-links">
        <svg
          width="28px"
          height="25px"
          viewBox="0 0 28 25"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <g
            id="Page-1"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g
              id="task-manager"
              transform="translate(-34.000000, -545.000000)"
              stroke="#C0C4CA"
              stroke-width="1.0625"
            >
              <g id="Tasks">
                <g id="Sidebar" transform="translate(0.000000, 74.375000)">
                  <g
                    id="Statistics"
                    transform="translate(35.062500, 471.750000)"
                  >
                    <polygon
                      id="Icon"
                      points="24.778778 0 18.6070822 20.9784146 14.2136565 3.95382345 14.0189489 4.00662233 13.3794504 3.53244782 8.10358838 15.7036039 4.94092923 8.99510066 4.6819145 9.15146656 4.42379293 8.85904202 0 13.8881349 0.53767885 14.4993834 4.53275775 9.9566494 7.78294601 16.8509641 8.118772 16.6468761 8.44834591 16.8611177 13.6536488 4.8493736 18.1506804 22.2750331 18.5990438 22.1552203 19.0152537 22.3126015 25.4995534 0.274148005"
                    ></polygon>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
        <span class="d-lg-none"> Statistic </span>
      </a>
    </li>
  </ul>
</div>
