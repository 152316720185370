<div class="wrapper" [ngStyle]="{ 'background-color': backgroundColor }">
  <div class="container text-center">
    <div class="not-found">
      <img src="/assets/images/logo-image.png" />
      <p class="mb-3 not-found-status">{{ title }}</p>
      <div class="font-weight-light mb-0">
        {{ text }} <br />
      </div>
      <div class="font-weight-light mb-5">
        {{ subtext }}
      </div>
    </div>
  </div>
</div>
