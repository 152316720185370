import { Pipe, PipeTransform } from '@angular/core';
import { TaskToday } from '@app/models/todayList';

@Pipe({
  name: 'todaySort'
})
export class TodaySortPipe implements PipeTransform {

  transform(value: TaskToday[], key: string, isActive: boolean): TaskToday[] {
    let taskToday: TaskToday[];
    if (!value) {
      return;
    }
    if (!key) {
      return value.sort((a, b) => {
        const dateA = new Date(a.CreatedAt);
        const dateB = new Date(b.CreatedAt);
        return dateB.getTime() - dateA.getTime();
      });
    }
    if (isActive) {
      switch (true) {
        case key === "TaskTitle" || key === "ProjectName" || key === "TaskStatusName":
          taskToday = value.sort((a, b) => {
            const nameA = a[key].toUpperCase();
            const nameB = b[key].toUpperCase();
            if (nameA < nameB) {
              return 1;
            }
            if (nameA > nameB) {
              return -1;
            }
            return 0;
          });
          break;
        case key === "TaskPriority" || key === "TaskType":
          taskToday = value.sort((a, b) => {
            return b[key] - a[key];
          });
          break;
        case key === "TaskDueDate":
          taskToday = value.sort((a, b) => {
            const dateA = new Date(a[key]);
            const dateB = new Date(b[key]);
            return dateB.getTime() - dateA.getTime();
          });
          break;
      }
    } else {
      switch (true) {
        case key === "TaskTitle" || key === "ProjectName" || key === "TaskStatusName":
          taskToday = value.sort((a, b) => {
            const nameA = a[key].toUpperCase();
            const nameB = b[key].toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          });
          break;
        case key === "TaskPriority" || key === "TaskType":
          taskToday = value.sort((a, b) => {
            return a[key] - b[key];
          });
          break;
        case key === "TaskDueDate":
          taskToday = value.sort((a, b) => {
            const dateA = new Date(a[key]);
            const dateB = new Date(b[key]);
            return dateA.getTime() - dateB.getTime();
          });
          break;
      }
    }
    return taskToday;
  }
}
