import { Pipe, PipeTransform } from '@angular/core';
import { differenceInCalendarDays, differenceInDays, endOfWeek, isThisWeek, isTomorrow, startOfWeek, format, isPast, isToday, toDate, parse, parseJSON } from 'date-fns';

@Pipe({
  name: 'dateTransform',
})
export class DateTransformPipe implements PipeTransform {
  transform(value: string): Date | string {
    if (value) {
      const days = differenceInCalendarDays(+new Date(value), new Date());
      switch (true) {
        case isToday(new Date(value)):
          value = 'Today';
          break;
        case isTomorrow(new Date(value)):
          value = 'Tomorrow';
          break;
        case days > 1 && days  < 8:
          value = 'Next seven days';
          break;
        case isPast(new Date(value)):
          value = 'Overdue';
          break;
        default:
          value = null;
      }
      return value;
    }
  }
}
