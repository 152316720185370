<app-loading-spinner *ngIf="loading"> </app-loading-spinner>
<ng-container *ngIf="!isNotFound; else notFoundTemplate">
  <div *ngIf="!loading">
    <div class="task-details-sidebar">
      <div
        class="
          task-details-sidebar-content
          justify-content-between justify-content-sm-start
        "
      >
        <a
          [routerLink]="['/project', projectId]"
          class="d-flex align-self-start"
        >
          <div class="arrow-back">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10.581"
              height="19.216"
              viewBox="0 0 10.581 19.216"
            >
              <g
                id="prefix__Group_521"
                data-name="Group 521"
                transform="translate(-161.135 -131.242)"
              >
                <g id="prefix__next" transform="rotate(180 85.858 75.229)">
                  <path
                    id="prefix__Chevron_Right_1_"
                    d="M10.3 8.922L1.658.284a.97.97 0 0 0-1.374 1.37L8.24 9.608.285 17.561a.971.971 0 0 0 1.374 1.372l8.641-8.639a.979.979 0 0 0 0-1.372z"
                    style="fill: #c0c4ca"
                  />
                </g>
              </g>
            </svg>
          </div>
        </a>
        <div class="project-title">
          <h4>{{ task.ProjectName | shorten: 50 }}</h4>
        </div>
        <div class="dropdown mr-1 d-md-none d-flex align-self-start">
          <span dropdown>
            <a
              href
              id="basic-link"
              dropdownToggle
              (click)="(false)"
              aria-controls="basic-link-dropdown"
            >
              <img src="/assets/images/dots.png" alt="dots" />
            </a>
            <ul
              id="basic-link-dropdown"
              *dropdownMenu
              class="dropdown-menu dropdown-menu-right"
              role="menu"
              aria-labelledby="basic-link"
            >
              <li>
                <a
                  [swal]="swalOptionsArchive"
                  (confirm)="archiveTask(task.UID)"
                  class="dropdown-item cursor-pointer"
                >
                  Archive task
                </a>
                <a
                  [swal]="swalOptionsDelete"
                  (confirm)="deleteTask()"
                  class="dropdown-item cursor-pointer"
                >
                  Delete task
                </a>
              </li>
            </ul>
          </span>
        </div>
      </div>
    </div>
    <div
      class="
        task-details
        d-md-flex
        task-details-container
        scroll-tasks
        position-relative
      "
    >
      <div class="task-content-left overflow-hidden d-flex flex-column">
        <form [formGroup]="taskForm" class="d-flex flex-column overflow-hidden">
          <div class="task-header-left pb-3">
            <div class="form-group mb-0 d-sm-flex align-items-center w-100">
              <div class="align-self-end">
                <ng-select
                  [appChangeColor]="task.ProjectIcon"
                  appearance="outline"
                  [searchable]="false"
                  [clearable]="false"
                  class="status-select mr-3"
                  formControlName="Status"
                  placeholder="Choose"
                  [ngClass]="{
                    'is-invalid': f['Status']?.errors
                  }"
                >
                  <ng-option *ngIf="task?.Status === ARCHIVE" [value]="ARCHIVE">
                    {{ "ARCHIVED" }}
                  </ng-option>
                  <ng-option
                    [value]="status.ID"
                    *ngFor="let status of taskStatus"
                  >
                    {{ status.Name | uppercase }}
                  </ng-option>
                </ng-select>
                <div *ngIf="f['Status']?.errors" class="invalid-feedback">
                  <div *ngIf="f['Status']?.errors.required">
                    Status is required
                  </div>
                </div>
              </div>
              <div class="mb-1">
                <label class="label mb-2"> Assignee: </label>
                <ng-select
                  #assigneeSelect
                  appearance="outline"
                  [searchable]="true"
                  (change)="assignee($event)"
                  [clearable]="false"
                  class="select-assignee ml-4"
                  formControlName="AssigneeUID"
                  placeholder="Choose"
                >
                  <ng-option [value]="person" *ngFor="let person of persons">
                    <div class="d-flex align-items-center">
                      <app-no-avatar
                        class="mr-2"
                        [avatarURL]="person.AvatarURL"
                        [userName]="person.Name"
                        [width]="32"
                        [height]="32"
                      >
                      </app-no-avatar>
                      {{ person.Name }}
                    </div>
                  </ng-option>
                </ng-select>
                <div class="d-flex align-items-center">
                  <app-no-avatar
                    class="d-flex justify-content-center mr-xl-0 mr-3"
                    (click)="assigneeSelect.open()"
                    [avatarURL]="assigneeObj.AvatarURL"
                    [assigneeUID]="assigneeObj.UID"
                    [userName]="assigneeObj.Name"
                    [width]="33"
                    [height]="33"
                  >
                  </app-no-avatar>
                  <span class="ml-2 d-none d-xl-block text-nowrap mr-3">
                    {{ assigneeObj.Name }}
                  </span>
                </div>
              </div>
              <app-task-collaborators
                class="ml-auto"
                [taskId]="taskId"
                [projectId]="projectId"
                [persons]="persons"
              ></app-task-collaborators>
            </div>
          </div>
          <div class="task-body-left mt-3 scroll scroll-auto">
            <div
              class="d-flex align-items-center justify-content-between"
              style="min-height: 70px"
            >
              <div class="form-group w-100 mr-3 mt-3">
                <div
                  #title
                  class="task-title non-edit-title"
                  [hidden]="isEditMode"
                  (click)="editTitle(title)"
                  [innerHTML]="initialTitle"
                ></div>
                <textarea
                  type="text"
                  [hidden]="!isEditMode"
                  (input)="autoHeight(titleRef)"
                  formControlName="Title"
                  #titleRef
                  class="form-control task-title"
                  [ngClass]="{
                    'is-invalid': f['Title'].errors
                  }"
                ></textarea>
                <div *ngIf="f['Title'].errors" class="invalid-feedback">
                  <div *ngIf="f['Title'].errors.required">
                    Title is required
                  </div>
                </div>
              </div>
              <div
                class="
                  form-group
                  mb-0
                  text-nowrap
                  d-flex
                  align-self-start
                  mt-4
                  position-relative
                "
              >
                <ng-select
                  #selectType
                  (change)="changeType($event)"
                  appearance="outline"
                  [searchable]="false"
                  [clearable]="false"
                  class="type-select"
                  formControlName="TaskType"
                  [ngClass]="{
                    'is-invalid': f['TaskType']?.errors
                  }"
                >
                  <ng-template ng-footer-tmp>
                    <a
                      class="text-primary create-new"
                      (click)="openTaskTypeModal()"
                      >Manage task types</a
                    >
                  </ng-template>
                  <ng-option [value]="type.ID" *ngFor="let type of types">
                    <div class="task-types-options">
                      <img
                        class="mr-2 type-icons"
                        [src]="['/assets/images/types/' + type.IconID + '.png']"
                      />
                      <span class="type-name"> {{ type.Name }} </span>
                    </div>
                  </ng-option>
                </ng-select>
                <img
                  class="cursor-pointer type-icons"
                  (click)="selectType.open()"
                  [src]="['/assets/images/types/' + typeId + '.png']"
                />
                <div *ngIf="f['TaskType']?.errors" class="invalid-feedback">
                  <div *ngIf="f['TaskType']?.errors.required">
                    Type is required
                  </div>
                </div>
                <span
                  *ngIf="task"
                  class="task-key ml-2"
                  [tooltip]="taskTypeName"
                  [delay]="800"
                  placement="bottom"
                >
                  {{ task.ProjectKey | uppercase }}-{{ task.TaskKey }}
                </span>
              </div>
            </div>
            <div *ngIf="isEditMode" class="mb-3">
              <button
                type="button"
                class="btn btn-primary"
                (click)="saveTitle($event)"
              >
                Save
              </button>
              <button
                class="border-none btn btn-outline-secondary"
                (click)="closeEditTitle()"
              >
                Cancel
              </button>
            </div>
            <div class="form-group">
              <label class="label"> Description: </label>
              <div
                class="non-edit-title cursor-pointer"
                [ngClass]="{
                  'editor-placeholder': editorInitialValue === editorPlaceholder
                }"
                *ngIf="!showDescEditor"
                (click)="showEditor($event)"
                [innerHTML]="editorInitialValue | mentionReplace"
              ></div>
              <ckeditor
                *ngIf="showDescEditor"
                [config]="configDesc"
                [editor]="Editor"
                (change)="onChange($event)"
                class="editorDesc"
                #editor
                formControlName="Description"
              ></ckeditor>
              <div *ngIf="showDescEditor" class="mt-3">
                <button
                  type="button"
                  class="btn btn-primary"
                  (click)="saveDesc($event)"
                >
                  Save
                </button>
                <button
                  type="button"
                  class="border-none btn btn-outline-secondary"
                  (click)="closeEditor()"
                >
                  Cancel
                </button>
              </div>
            </div>
            <div class="row mb-5">
              <div class="col-12">
                <app-task-checklist
                  [currentStatusID]="task?.Status"
                  [statuses]="taskStatus"
                  [progressCount]="task?.Progress"
                  [projectId]="projectId"
                  [currentStatusCategory]="task?.StatusCategory"
                  [taskId]="taskId"
                ></app-task-checklist>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-4">
                <div class="form-group">
                  <label class="label"> Priority </label>
                  <ng-select
                    appearance="outline"
                    [searchable]="false"
                    [clearable]="false"
                    appendTo="body"
                    class="select inline-edit"
                    formControlName="Priority"
                    placeholder="Choose"
                    [ngClass]="{ 'is-invalid': f['Priority']?.errors }"
                  >
                    <ng-option
                      [value]="priority.UID"
                      *ngFor="let priority of priorities"
                    >
                      <div class="d-flex align-items-center">
                        <div
                          class="mr-2 priority-color"
                          [ngStyle]="{
                            'background-color':
                              priority.UID === taskPriority.LOW
                                ? '#a6db43'
                                : priority.UID === taskPriority.HIGH
                                ? 'red'
                                : 'orange'
                          }"
                        ></div>
                        {{ priority.Name }}
                      </div>
                    </ng-option>
                  </ng-select>
                  <div *ngIf="f['Priority']?.errors" class="invalid-feedback">
                    <div *ngIf="f['Priority']?.errors.required">
                      Priority is required
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-md-4">
                <div class="form-group">
                  <label class="label"> Due to: </label>
                  <input
                    formControlName="DueDate"
                    class="form-control inline-edit"
                    placeholder="{{ currentDate | date: 'yyyy-MM-dd' }}"
                    type="text"
                    [minDate]="currentDate"
                    bsDatepicker
                    (bsValueChange)="toggleDueDate()"
                    [bsConfig]="{
                      dateInputFormat: 'YYYY-MM-DD',
                      showWeekNumbers: false,
                      isAnimated: true,
                      containerClass: 'theme-default',
                      adaptivePosition: true,
                      customTodayClass: 'custom-today-class'
                    }"
                  />
                </div>
              </div>
              <div class="col-xl-4 col-md-6">
                <label class="label">Add to Google Calendar</label>
                <button
                  *ngIf="!isGCalLoggedIn"
                  type="button"
                  class="google-calendar btn d-flex align-items-center"
                  [disabled]="isCalendarDisabled"
                  (click)="addGoogleCalendar()"
                >
                  <app-google-logo-svg></app-google-logo-svg>
                  <span class="font-14 text-gray-600 ml-2"
                    >Sign in with Google</span
                  >
                </button>
                <button
                  *ngIf="isGCalLoggedIn"
                  type="button"
                  class="google-calendar btn d-flex align-items-center"
                  [disabled]="isCalendarDisabled"
                  (click)="addGoogleCalendar()"
                >
                  <app-google-calendar-svg></app-google-calendar-svg>
                </button>
                <span
                  *ngIf="showCalendarMessage"
                  class="text-primary text-right font-14 position-absolute"
                >
                  Added!
                </span>
              </div>
            </div>
            <div class="row" *ngIf="isShopify">
              <div class="col-md-12">
                <div class="form-group">
                  <label class="label"> Update shopify quantity </label>
                  <div class="d-flex flex-nowrap">
                    <input
                      class="form-control shopify-input"
                      type="number"
                      (input)="toggleDisableBtn($event)"
                      #quantity
                    />
                    <button
                      type="button"
                      class="
                        btn btn-shopify
                        text-white text-nowrap
                        ml-3
                        d-flex
                        align-items-center
                      "
                      [disabled]="isBtnDisabled"
                      (click)="openShopifyModal(quantity.value, false)"
                    >
                      <img
                        src="./assets/images/shopify-logo.png"
                        class="mr-2"
                        width="25px"
                        height="25px"
                      />
                      <span class="line-height-1-6"> Add to inventory </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row distance">
              <div class="col-xl-6">
                <div class="form group mt-md-0 mt-3">
                  <label class="label">Creator</label>
                  <div class="d-flex align-items-center">
                    <app-no-avatar
                      class="mr-3"
                      [avatarURL]="task.CreatorAvatarURL"
                      [userName]="task.CreatorName"
                      [width]="44"
                      [height]="44"
                    >
                    </app-no-avatar>
                    <p class="text-gray-300 mb-0">{{ task.CreatorName }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="task-details-right overflow-hidden d-flex flex-column">
        <div class="task-details-right-header pb-3">
          <div>
            <a
              (click)="displayComments()"
              tooltip="Comments"
              [delay]="800"
              placement="bottom"
              class="btn-details mr-5"
              [ngClass]="{ 'btn-details-darker': mode === modeValue.COMMENTS }"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18.649"
                height="17.909"
                viewBox="0 0 18.649 17.909"
              >
                <defs>
                  <style>
                    .cls-1 {
                      fill: #96999b;
                      stroke: #96999b;
                      stroke-width: 0.4px;
                    }
                  </style>
                </defs>
                <g id="Group_168" transform="translate(-162.8 -258.8)">
                  <path
                    id="Path_14"
                    d="M178.05 259H166.2a3.2 3.2 0 0 0-3.2 3.2v7.309a3.2 3.2 0 0 0 3.2 3.2h7.548l3.613 3.613a.64.64 0 0 0 1.093-.453v-3.186a3.2 3.2 0 0 0 2.8-3.174V262.2a3.2 3.2 0 0 0-3.2-3.2zm1.92 10.509a1.922 1.922 0 0 1-1.92 1.92h-.237a.64.64 0 0 0-.64.64v2.256l-2.708-2.708a.64.64 0 0 0-.452-.187H166.2a1.922 1.922 0 0 1-1.92-1.92v-7.31a1.922 1.922 0 0 1 1.92-1.92h11.85a1.922 1.922 0 0 1 1.92 1.92zm0 0"
                    class="cls-1 svg-comment"
                  />
                  <path
                    id="Path_15"
                    d="M232.051 329.808h-9.42a.64.64 0 1 0 0 1.28h9.42a.64.64 0 0 0 0-1.28zm0 0"
                    class="cls-1 svg-comment"
                    transform="translate(-55.217 -66.277)"
                  />
                  <path
                    id="Path_16"
                    d="M232.051 385.808h-9.42a.64.64 0 1 0 0 1.28h9.42a.64.64 0 0 0 0-1.28zm0 0"
                    class="cls-1 svg-comment"
                    transform="translate(-55.217 -118.694)"
                  />
                </g>
              </svg>
            </a>
            <a
              (click)="displayAttachment()"
              tooltip="Attachments"
              [delay]="800"
              placement="bottom"
              class="btn-details mr-3"
              [ngClass]="{
                'btn-details-darker': mode === modeValue.ATTACHMENTS
              }"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12.193"
                height="19.508"
                viewBox="0 0 12.193 19.508"
              >
                <path
                  class="svg-attachment"
                  fill="#96999b"
                  d="M6.1 19.508a6.1 6.1 0 0 1-6.1-6.1V4.877a.813.813 0 0 1 1.626 0v8.535a4.471 4.471 0 1 0 8.941 0V4.471a2.845 2.845 0 0 0-5.69 0V12.6a1.219 1.219 0 1 0 2.439 0V4.877a.813.813 0 0 1 1.626 0V12.6a2.845 2.845 0 0 1-5.69 0V4.471a4.471 4.471 0 0 1 8.941 0v8.941a6.1 6.1 0 0 1-6.1 6.1zm0 0"
                />
              </svg>
            </a>
          </div>
          <div class="d-flex align-items-center">
            <span
              dropdown
              class="mr-4 position-relative"
              tooltip="Task reminder"
              [delay]="800"
              [insideClick]="true"
              #dropdown="bs-dropdown"
            >
              <a
                href
                id="basic-link"
                dropdownToggle
                (click)="(false)"
                aria-controls="basic-link-dropdown"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18.055"
                  height="20.969"
                  viewBox="0 0 13.055 12.969"
                >
                  <defs>
                    <style>
                      .reminder-default {
                        fill: #818181;
                        stroke: #818181;
                        stroke-width: 0.3px;
                      }
                      .reminder-active {
                        fill: #389bff !important;
                        stroke: #389bff;
                      }
                    </style>
                  </defs>
                  <g id="time-left" transform="translate(.15 -1.53)">
                    <g id="Layer_2_16_" transform="translate(0 1.688)">
                      <g id="Group_1947">
                        <path
                          id="Path_2976"
                          d="M253.405 423.171c-.105.026-.212.05-.319.069a.513.513 0 0 0 .187 1.008c.127-.024.254-.051.38-.082a.513.513 0 0 0-.248-.995z"
                          class="reminder-default"
                          [ngClass]="{ 'reminder-active': isReminderActive }"
                          transform="translate(-245.74 -411.601)"
                        />
                        <path
                          id="Path_2977"
                          d="M412.608 137.22a.513.513 0 0 0 .973-.322c-.041-.122-.086-.245-.133-.365a.513.513 0 1 0-.952.381c.041.1.078.203.112.306z"
                          class="reminder-default"
                          [ngClass]="{ 'reminder-active': isReminderActive }"
                          transform="translate(-401.152 -132.522)"
                        />
                        <path
                          id="Path_2978"
                          d="M321.4 394c-.09.06-.184.117-.278.172a.513.513 0 1 0 .512.888c.112-.064.223-.133.331-.2a.513.513 0 0 0-.565-.86z"
                          class="reminder-default"
                          [ngClass]="{ 'reminder-active': isReminderActive }"
                          transform="translate(-312.07 -383.159)"
                        />
                        <path
                          id="Path_2979"
                          d="M428.659 207.8a.513.513 0 1 0-1.025.04c0 .108.005.218 0 .326a.513.513 0 1 0 1.025.023c.006-.125.005-.256 0-.389z"
                          class="reminder-default"
                          [ngClass]="{ 'reminder-active': isReminderActive }"
                          transform="translate(-415.911 -201.674)"
                        />
                        <path
                          id="Path_2980"
                          d="M377.8 344.171a.513.513 0 0 0-.718.1c-.065.087-.134.172-.2.255a.513.513 0 0 0 .058.723l.038.029a.513.513 0 0 0 .685-.087c.084-.1.166-.2.243-.3a.513.513 0 0 0-.106-.72z"
                          class="reminder-default"
                          [ngClass]="{ 'reminder-active': isReminderActive }"
                          transform="translate(-366.427 -334.682)"
                        />
                        <path
                          id="Path_2981"
                          d="M414.535 279.375a.513.513 0 0 0-.643.336c-.032.1-.068.207-.107.308a.513.513 0 0 0 .958.365c.046-.121.089-.244.127-.367a.513.513 0 0 0-.335-.642z"
                          class="reminder-default"
                          [ngClass]="{ 'reminder-active': isReminderActive }"
                          transform="translate(-402.409 -271.74)"
                        />
                        <path
                          id="Path_2982"
                          d="M5.428 13.331a5.294 5.294 0 0 1-1.315-.418l-.013-.008c-.1-.046-.2-.1-.291-.148a5.469 5.469 0 0 1-.51-.319 5.35 5.35 0 0 1 .022-8.763l.018-.013a5.359 5.359 0 0 1 6.006-.048l-.4.579c-.111.161-.043.278.152.261l1.74-.156a.287.287 0 0 0 .259-.375l-.469-1.683c-.052-.189-.186-.211-.3-.05l-.4.58a6.331 6.331 0 0 0-4.64-.987q-.246.043-.485.1l-.018.005a6.322 6.322 0 0 0-3.509 2.354l-.022.027-.086.119c-.046.066-.092.133-.135.2l-.014.025A6.321 6.321 0 0 0 .007 8.341v.013c.006.129.016.259.03.387v.024c.014.129.032.258.055.388a6.327 6.327 0 0 0 1.788 3.432l.007.007a6.46 6.46 0 0 0 .817.683 6.321 6.321 0 0 0 2.544 1.065.513.513 0 0 0 .181-1.009z"
                          class="reminder-default"
                          [ngClass]="{ 'reminder-active': isReminderActive }"
                          transform="translate(0 -1.688)"
                        />
                        <path
                          id="Path_2983"
                          d="M206.4 83.2a.415.415 0 0 0-.415.415v4.133l3.78 1.954a.415.415 0 0 0 .381-.737l-3.331-1.722v-3.63a.415.415 0 0 0-.415-.413z"
                          class="reminder-default"
                          [ngClass]="{ 'reminder-active': isReminderActive }"
                          transform="translate(-200.34 -80.963)"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </a>
              <ul
                id="basic-link-dropdown"
                *dropdownMenu
                class="dropdown-menu p-0"
                role="menu"
                aria-labelledby="basic-link"
              >
                <app-task-reminder
                  class="position-relative"
                  [isTask]="true"
                  [isReminderActive]="isReminderActive"
                  [projectID]="this.projectId"
                  [statusID]="task.TaskKey"
                  [isStatusReminder]="task.IsStatusReminder"
                  [days]="task.Day"
                  (toggleReminder)="toggleReminder($event)"
                ></app-task-reminder>
              </ul>
            </span>
            <app-task-recurs
              [taskUID]="taskId"
              [projectUID]="projectId"
              [data]="dataRecurs"
            >
            </app-task-recurs>
            <app-video-record
              *ngIf="mode === modeValue.ATTACHMENTS"
              class="d-md-block d-none"
            ></app-video-record>
            <div class="dropdown mr-1 d-md-block d-none">
              <span dropdown>
                <a
                  href
                  id="basic-link"
                  dropdownToggle
                  (click)="(false)"
                  aria-controls="basic-link-dropdown"
                >
                  <img src="/assets/images/dots.png" alt="dots" />
                </a>
                <ul
                  id="basic-link-dropdown"
                  *dropdownMenu
                  class="dropdown-menu dropdown-menu-right"
                  role="menu"
                  aria-labelledby="basic-link"
                >
                  <li>
                    <a
                      *ngIf="task?.Status === ARCHIVE"
                      [swal]="swalOptionsUnarchive"
                      (confirm)="unarchiveTask(task.UID)"
                      class="dropdown-item cursor-pointer"
                    >
                      Unarchive task
                    </a>
                    <a
                      *ngIf="task?.Status !== ARCHIVE"
                      [swal]="swalOptionsArchive"
                      (confirm)="archiveTask(task.UID)"
                      class="dropdown-item cursor-pointer"
                    >
                      Archive task
                    </a>
                    <a
                      [swal]="swalOptionsDelete"
                      (confirm)="deleteTask()"
                      class="dropdown-item cursor-pointer"
                    >
                      Delete task
                    </a>
                  </li>
                </ul>
              </span>
            </div>
          </div>
        </div>
        <div class="task-details-right-content pb-md-0 pb-5">
          <app-attachments
            [hidden]="mode !== modeValue.ATTACHMENTS"
            [taskId]="taskId"
            [size]="18"
            [projectId]="projectId"
          >
          </app-attachments>
          <app-comments
            (showComments)="showComments($event)"
            [hidden]="mode !== modeValue.COMMENTS"
            [users]="persons"
            [size]="18"
            [taskId]="taskId"
            [projectId]="projectId"
          >
          </app-comments>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #notFoundTemplate>
  <app-not-found
    title="This task does not exist"
    text="This task may have been changed or removed. If this task actually exists in a project, please try again later."
    subtext=""
    backgroundColor="white"
  >
  </app-not-found>
</ng-template>
