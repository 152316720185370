import { GuestService } from './guest.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AttachmentResponse } from '@app/models/attachment';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AttachmentsService {
  showAttachIcon$ = new Subject<string>();
  hideAttachIcon$ = new Subject<string>();
  constructor(
    private http: HttpClient,
    private guestService: GuestService
  ) {}

  uploadAttachment(projectId: string, taskId: string, Attachments: {AttachmentURL: string, AttachmentFileName: string}[]) {
        return this.http.post<AttachmentResponse>(
          environment.baseUrl +
            'api/project/' +
            projectId +
            '/task/' +
            taskId +
            '/attachment',
           { Attachments }
        );
  }

  getAttachments(projectId: string, taskId: string) {
    const link = window.location.pathname.slice(1, window.location.pathname.length);
    const path = this.guestService.isGuest ? link : `api/project/${projectId}`;
    return this.http.get<AttachmentResponse>(
      `${environment.baseUrl}${path}/task/${taskId}/attachment`
    );
  }

  deleteAttachment(projectId: string, taskId: string, attachmentId: string) {
    return this.http.delete<null>(
      environment.baseUrl +
        'api/project/' +
        projectId +
        '/task/' +
        taskId +
        '/attachment/' +
        attachmentId
    );
  }
}
