<accordion [isAnimated]="true" [closeOthers]="true">
  <accordion-group
    *ngFor="
      let groupedTask of tasks
        .GroupedTasks;
      let ii = index
    "
    panelClass="accordion-list"
    (isOpenChange)="
      isOpenChange($event, groupedTask.StatusName)
    "
  >
    <div
      accordion-heading
      class="d-flex justify-content-between align-items-center"
    >
      <div class="d-flex align-items-center">
        <div
          class="task-list-project-key"
          [ngStyle]="{
            'background-color':
            tasks.ProjectIcon
          }"
        >
          {{ tasks.ProjectKey }}
        </div>
        <span class="accordion-title">
          {{ groupedTask.StatusName }}
        </span>
      </div>
      <i
        [ngClass]="{
          'rotate-arrow':
            isOpen === true &&
            accordionStatus === groupedTask.StatusName
        }"
        class="fas fa-caret-right font-18"
      ></i>
    </div>
    <div
      class="accordion-content cursor-pointer"
      (click)="
        selectElement(
          task.ProjectKey,
          task.TaskKey
        )
      "
      *ngFor="let task of groupedTask.Tasks"
    >
      <p class="mb-2 font-12">
        {{ task.ProjectKey }}-{{ task.TaskKey }}
      </p>
      <p class="mb-2 font-15">{{ task.Title }}</p>
      <div
        class="d-flex align-items-center justify-content-between"
      >
        <div class="d-flex align-items-center">
          <img
            [tooltip]="task.TypeName"
            placement= "bottom"
            class="type-icons"
            src="/assets/images/types/{{
              task.TypeIconID
            }}.png"
          />
          <div
            [tooltip]="
              task.Priority === taskPriority.LOW
                ? 'Low'
                : task.Priority === taskPriority.HIGH
                ? 'High'
                : 'Modarate'
            "
            placement="bottom"
            class="ml-3 priority-color"
            [ngStyle]="{
              'background-color':
                task.Priority === taskPriority.LOW
                  ? '#a6db43'
                  : task.Priority === taskPriority.HIGH
                  ? 'red'
                  : 'orange'
            }"
          ></div>
        </div>
        <app-no-avatar
          [tooltip]="task.AssigneeName"
          placement="bottom"
          [avatarURL]="task.AssigneeAvatarURL"
          [userName]="task.AssigneeName"
          [assigneeUID]="task.AssigneeUID"
          [width]="29"
          [height]="29"
        >
        </app-no-avatar>
      </div>
    </div>
  </accordion-group>
</accordion>
