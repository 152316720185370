<div class="dropdown-users-footer">
  <p class="font-12 text-gray-600 mb-2 line-height-1-2">Invite new member</p>
  <form [formGroup]="inviteUserForm" (ngSubmit)="submitForm()">
    <div class="d-flex align-items-start">
      <div class="d-flex flex-column">
        <input
          type="text"
          formControlName="Email"
          class="form-control form-control-small"
          [ngClass]="{
            'is-invalid': submitted && f['Email'].errors
          }"
          placeholder="Enter email address"
        />
        <div
          *ngIf="submitted && f['Email'].errors"
          class="invalid-feedback"
        >
          <div *ngIf="f['Email'].errors.required">Email is required</div>
          <div *ngIf="f['Email'].errors.email">
            Email must be a valid email address
          </div>
        </div>
      </div>
      <app-spinner-button [options]="spinnerButtonOptions" class="custom-spinner-btn ml-2">
      </app-spinner-button>
    </div>
  </form>
</div>
