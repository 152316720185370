import { TypeService } from '@app/shared/services/type.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { TaskType } from '@app/models/type';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SpinnerButtonOptions } from '@app/models/spinnerButtonOptions';

@Component({
  selector: 'app-task-types',
  templateUrl: './task-types.component.html',
  styleUrls: ['./task-types.component.scss'],
})
export class TaskTypesComponent implements OnInit {
  spinnerButtonOptions: SpinnerButtonOptions = {
    active: false,
    text: 'Add',
    fullWidth: false,
    disabled: false,
  };
  types: TaskType[];
  typeIcons = [{ IconID: 0 }, { IconID: 1 }, { IconID: 2 }, { IconID: 3 }, { IconID: 4 }, { IconID: 5 },
    { IconID: 6 }, { IconID: 7 }, { IconID: 8 }];
  typeForm: FormGroup;
  submitted = false;
  constructor(
    public activeModal: BsModalRef,
    private formBuilder: FormBuilder,
    private typeService: TypeService
  ) {}

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.typeForm = this.formBuilder.group({
      Name: ['', Validators.required],
      IconID: ['', Validators.required],
    });
  }

  get f() {
    return this.typeForm.controls;
  }

  updateType(Name: string, IconID: number, typeID: number) {
    const data = {Name, IconID};
    this.typeService.updateType(typeID, data).subscribe();
  }

  deleteType(id: number) {
    this.typeService.deleteType(id).subscribe( () => {
      this.types = this.types.filter( item => item.ID !== id );
    });
  }

  submitForm() {
    this.submitted = true;
    if (this.typeForm.invalid) {
      return;
    }
    this.spinnerButtonOptions.active = true;
    const data = this.typeForm.value;
    this.typeService.createType(data).subscribe( res => {
      this.types = [...this.types, res];
      this.spinnerButtonOptions.active = false;
      this.submitted = false;
      this.typeForm.reset();
    }, error => {
      this.spinnerButtonOptions.active = false;
      this.submitted = false;
    });
  }
}
