<svg xmlns="http://www.w3.org/2000/svg" width="22.842" height="21.623" viewBox="0 0 22.842 21.623" [ngClass] = "{'manager-view-active': isManagerMode}">
  <defs>
      <style>
          .manager-view{fill:#96989b}
      </style>
  </defs>
  <g id="Group_2193" transform="rotate(-30 -35.86 556.325)">
      <path id="Path_3004" d="M4.084 24.3a4.083 4.083 0 1 0 4.084 4.083A4.083 4.083 0 0 0 4.084 24.3zm-.01 6.13a2.047 2.047 0 1 1 0-4.094 2.047 2.047 0 0 1 0 4.094z" class="manager-view" transform="translate(271.316 71.699)"/>
      <path id="Path_3005" d="M4.084 144.6a4.083 4.083 0 1 0 4.084 4.083 4.083 4.083 0 0 0-4.084-4.083zm-.01 6.13a2.047 2.047 0 1 1 0-4.094 2.047 2.047 0 1 1 0 4.094z" class="manager-view" transform="translate(271.316 -36.519)"/>
      <path id="Path_3006" d="M4.084 264.667a4.083 4.083 0 1 0 4.084 4.083 4.083 4.083 0 0 0-4.084-4.083zm-.01 6.13a2.047 2.047 0 1 1 0-4.094 2.047 2.047 0 1 1 0 4.094z" class="manager-view" transform="translate(281.311 -162.61)"/>
  </g>
</svg>
