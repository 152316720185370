import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-no-avatar',
  templateUrl: './no-avatar.component.html',
  styleUrls: ['./no-avatar.component.scss']
})
export class NoAvatarComponent implements OnInit {
@Input() avatarURL: string;
@Input() assigneeUID: string;
@Input() userName: string;
@Input() width: number;
@Input() height: number;
@Input() background = "#389bff";
@Input() font: number;
  constructor() { }

  ngOnInit(): void {
  }

}
