import { GuestService } from '@app/shared/services/guest.service';
import { ChecklistService } from '@app/shared/services/checklist.service';
import { Subscription } from 'rxjs';
import { EventEmitter, Input, Output, OnDestroy } from '@angular/core';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inline-edit',
  templateUrl: './inline-edit.component.html',
  styleUrls: ['./inline-edit.component.scss']
})
export class InlineEditComponent implements OnInit, OnDestroy {
  @Input() isAdd: boolean;
  @Input() placeholder: string;
  @Input() isGrid: boolean;
  @Input() isCheked: boolean;
  @Input() data: string;
  @Output() focusOut: EventEmitter<string> = new EventEmitter<string>();
  @Output() focusOn: EventEmitter<boolean> = new EventEmitter<boolean>();
  isGuest: boolean;
  editMode = false;
  currentValue: string;
  subscription: Subscription;
  constructor(private checklistService: ChecklistService, private guestService: GuestService) { }



  ngOnInit(): void {
    this.isGuest = this.guestService.isGuest;
    this.subscription = this.checklistService.focusAddChecklist$.subscribe(res => {
      this.editMode = res;
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onFocus() {
    if (!this.isAdd) {
      this.currentValue = this.data;
      this.focusOn.emit(this.editMode);
    } else {
      this.data = null;
    }
  }

  onFocusOut() {
    if ((this.data !== '' &&  this.data !== this.currentValue)) {
    this.focusOut.emit(this.data);
    } else {
      this.data = this.currentValue;
    }
    this.focusOn.emit(false);
  }

  submitTitle(event: Event) {
    (event.target as HTMLInputElement).blur();
  }
}
