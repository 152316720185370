<div
  class="remind-wrapper"
  [ngClass] = "{'task-reminder': isTask}"
>
   <div class="font-14 text-gray-600 mr-12 font-weight-normal mb-2" *ngIf="isTask">
    Remind about this task every:
   </div>
   <div class="font-14 text-gray-600 mr-12 font-weight-normal mb-2"  *ngIf="!isTask">
     Remind task assignees every:
   </div>
  <div class="d-flex align-items-center">
   <ng-container *ngIf = "!isReminderDelete">
    <input
      [(ngModel)] = "days"
      [appFocus]="focusReminder"
      type="number"
      min="1"
      step="1"
      oninput="validity.valid||(value='');"
      pattern="^[0-9]"
      (keyup.enter) = "setReminder(days)"
      placeholder="Num"
      class="form-control number-of-days-input mr-12 font-14"
    />
    <span class="font-14 text-black font-weight-normal mr-4"> Day(s) </span>
  </ng-container>
  <ng-container  *ngIf = "isReminderDelete">
    <span class="font-14 text-black font-weight-normal mr-4"> Reminders muted </span>
  </ng-container>
    <a (click) = "deleteReminder()" class="cursor-pointer mr-4">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12.211"
        height="13.303"
        viewBox="0 0 12.211 13.303"
      >
        <g>
          <g>
            <g fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path
                d="M30.134 10.9a1.093 1.093 0 0 0 1.09-1.09h-2.181a1.093 1.093 0 0 0 1.091 1.09zm3.543-6.269A3.508 3.508 0 0 0 30.951 1.2V.818a.818.818 0 1 0-1.635 0V1.2a3.152 3.152 0 0 0-.763.273L33.677 6.6zm-.164 4.634l1.09 1.09.709-.709-9.1-9.1-.709.709 1.581 1.581a3.431 3.431 0 0 0-.491 1.8v3L25.5 8.722v.545z"
                transform="translate(-25.621 -.267) translate(25.5) translate(-24.18 1.467)"
              />
              <path
                fill="#818181"
                [ngClass] = "{'turn-off-reminder': isReminderDelete}"
                d="M30.134 10.903c.6 0 1.09-.49 1.09-1.09h-2.18c0 .6.49 1.09 1.09 1.09m4.47-.545l.708-.709L26.21.545l-.709.709 1.58 1.58c-.326.546-.49 1.146-.49 1.8v2.998l-1.09 1.09v.545h8.014l1.09 1.09m-.927-3.76V4.633c0-1.69-1.145-3.053-2.726-3.435V.818c0-.436-.381-.818-.817-.818-.436 0-.818.382-.818.818v.381c-.273.055-.545.164-.763.273l5.124 5.124m-3.543 5.507c-1.036 0-1.913-.69-2.195-1.636H25.5c-.663 0-1.2-.537-1.2-1.2v-.545c0-.318.126-.623.351-.848l.74-.74v-2.5c0-.538.084-1.053.252-1.54l-.992-.992c-.468-.468-.468-1.228 0-1.697l.71-.708c.224-.225.53-.352.848-.352.318 0 .623.127.848.352l.799.798c.05-.036.104-.068.16-.096.05-.025.105-.052.165-.079.228-.862 1.03-1.52 1.953-1.52.924 0 1.728.66 1.954 1.526.682.307 1.287.77 1.753 1.346.678.84 1.036 1.864 1.036 2.962v1.962c0 .256-.081.498-.223.697L36.16 8.8c.469.47.469 1.229 0 1.697l-.709.71c-.234.233-.541.35-.848.35-.307 0-.614-.117-.849-.35l-.739-.74h-.687c-.283.945-1.16 1.636-2.195 1.636z"
                transform="translate(-25.621 -.267) translate(25.5) translate(-24.18 1.467)"
              />
            </g>
          </g>
        </g>
      </svg>
    </a>
    <button *ngIf = "!isReminderDelete" type="button" [disabled] = "!days"
            class="btn btn-primary ml-auto py-2 px-20" (click)="setReminder(days)"> Save </button>
  </div>
</div>
