<button
  type="button"
  class="btn btn-template w-100"
  (click)="isTemplateEdit = true"
  *ngIf="!isTemplateEdit"
>
  <img src="./assets/images/templates.png" alt="templates" />
  <span> Save project as template </span>
</button>

<div *ngIf="isTemplateEdit">
  <input
    type="text"
    class="form-control"
    [appFocus]="isTemplateEdit"
    [disabled]="isTemplateSaved"
    placeholder="Template Name"
    #templateName
    [ngClass]="{ 'template-saved': isTemplateSaved }"
  />
  <div class="btn-actions" *ngIf="!isTemplateSaved">
    <button type="button" (click)="saveTemplate(templateName.value)" [disabled] ="isDisabled" class="btn btn-primary">
      Save
    </button>
    <button
      (click)="isTemplateEdit = false"
      class="border-none btn btn-outline-secondary"
    >
      Cancel
    </button>
  </div>
  <div *ngIf="isTemplateSaved" class="text-gray-400 font-10"> Template has been saved! </div>
</div>
