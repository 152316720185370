<div class="modal-body">
  <p class="inventory-title"> Do you want to update the quantity?</p>
  <form [formGroup] = "locationsForm">
     <div class="form-group">
          <label class="label"> Choose inventory</label>
          <ng-select
          [clearable] = "false"
          [items]= "(locations | async)?.inventory_levels"
          placeholder = "Choose inventory"
          bindLabel = "LocationName"
          (change) = "changeLocation($event)"
          bindValue = "location_id"
          formControlName = "location_id"
          [ngClass]="{ 'is-invalid': submitted && f['location_id']?.errors }"
          >
          </ng-select>
          <div
          *ngIf="submitted && f['location_id']?.errors"
          class="invalid-feedback"
        >
          <div *ngIf="f['location_id']?.errors.required">Location name is required</div>
        </div>
     </div>
     <div class="actions">
        <app-spinner-button [options]="spinnerButtonOptions" (click)="submitLocations(true)"> </app-spinner-button>
        <button type ="submit" class="btn text-primary mx-4" (click)="submitLocations(false)"> Yes </button>
        <button type ="button" class="btn btn-cancel text-gray-600" (click) ="activeModal.hide()"> Cancel </button>
     </div>
  </form>
</div>
