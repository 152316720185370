import { Pipe, PipeTransform } from "@angular/core";
import { Project } from "@app/models/project";

@Pipe({
  name: "projectsSlice",
})
export class ProjectsSlicePipe implements PipeTransform {
  transform(value: Project[], width: number, type: string): Project[] {
    switch (true) {
      case width < 800:
        if (type === "grid") {
          return value;
        } else {
          value = value.slice(0, 3);
        }
        break;
      case width < 1100:
        value = value.slice(0, 3);
        break;
      case width < 1100:
        value = value.slice(0, 3);
        break;
      case width < 1300:
        value = value.slice(0, 4);
        break;
      case width < 1400:
        value = value.slice(0, 5);
        break;
      case width < 1700:
        value = value.slice(0, 6);
        break;
      case width < 1950:
        value = value.slice(0, 8);
        break;
      case width < 2050:
        value = value.slice(0, 9);
        break;
      default:
        value = value.slice(0, 10);
        break;
    }

    return value;
  }
}
