import { Category } from '@app/enums/statusCategory';
import { TaskStatus } from '@app/models/task';
import { TodayService } from '@app/shared/services/today.service';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { TaskService } from '@app/shared/services/task.service';

@Component({
  selector: 'app-task-inprogress-popup',
  templateUrl: './task-inprogress-popup.component.html',
  styleUrls: ['./task-inprogress-popup.component.scss']
})
export class TaskInprogressPopupComponent implements OnInit {
  @Input() projectUID: string;
  @Input() taskUID: string;
  @Input() statusID: number;
  @Input() isInProgress: boolean;
  @Output() hidePopupEvent = new EventEmitter<boolean>();
  @Output() updateCurrentStatusID = new EventEmitter<number>();
  @Output() savePopupToStorage = new EventEmitter<boolean>();

  constructor(private taskService: TaskService) { }

  ngOnInit(): void {

  }

  updateTaskStatus() {
     this.taskService.updateStatusForTask(this.projectUID, this.taskUID, this.statusID).subscribe( () => {
      this.hidePopupEvent.emit(false);
      this.updateCurrentStatusID.emit(this.statusID);
     })
  }

  hidePopupDialog() {
    this.hidePopupEvent.emit(false);
    this.savePopupToStorage.emit();
  }

}
