import { GuestService } from './guest.service';
import { environment } from 'src/environments/environment';
import { User, UserResponse, UserProjectResponse } from '@app/models/user';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import {Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public userStorageUpdate: Subject<User>;
  public userUpdate$: Subject<User>;

  constructor(private http: HttpClient, private guestService: GuestService) {
     this.userStorageUpdate = new Subject<User>();
     this.userUpdate$ = new Subject<User>();
  }

  getPersons() {
    return this.http.get<UserResponse>(environment.baseUrl + 'api/user');
  }

  getusersProject(projectUID: string) {
    const link = window.location.pathname.slice(1, window.location.pathname.length);
    const path = this.guestService.isGuest ? link : `api/project/${projectUID}`;
    return this.http.get<UserResponse>(`${environment.baseUrl}${path}/user`);
  }

  getProjectUsers() {
    return this.http.get<UserProjectResponse>(environment.baseUrl + 'api/projectUser');
  }

  getUser(id: string) {
    return this.http.get<User>(environment.baseUrl + 'api/user/' + id);
  }

  updateUserProfile(id: string, data: User) {
    return this.http.put(environment.baseUrl + 'api/user/' + id, data).pipe(tap( () => {
      this.userStorageUpdate.next();
      this.userUpdate$.next();
    }));
  }

  deleteUser(id: string, Password: string) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {Password}
    };
    return this.http.delete(`${environment.baseUrl}api/user/${id}`, options);
  }

  changeRole( role: {NewRole: number}, userId: string) {
    return this.http.post<null>(`${environment.baseUrl}api/user/${userId}/role`, role);
  }
}
