import { Router } from '@angular/router';
import { TaskService } from '@app/shared/services/task.service';
import { concatMap } from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable, EMPTY } from 'rxjs';
import { ShopifyService } from '@app/shared/services/shopify.service';
import { Component, OnInit } from '@angular/core';
import { LocationResponse, Location } from '@app/models/shopify';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SpinnerButtonOptions } from '@app/models/spinnerButtonOptions';

@Component({
  selector: 'app-shopify-inventory',
  templateUrl: './shopify-inventory.component.html',
  styleUrls: ['./shopify-inventory.component.scss']
})
export class ShopifyInventoryComponent implements OnInit {
  projectID: string;
  taskID: string;
  quantity: number;
  isModal: boolean;
  locationsForm: FormGroup;
  submitted = false;
  locations: Observable<LocationResponse>;
  spinnerButtonOptions: SpinnerButtonOptions = {
    active: false,
    text: ' Yes, and archive this task',
    disabled: false,
    fullWidth: false,
  };

  constructor(private shopifyService: ShopifyService,
              private fb: FormBuilder,
              public activeModal: BsModalRef,
              private router: Router,
              private taskService: TaskService) { }

  get f() {
    return this.locationsForm.controls;
  }

  ngOnInit(): void {
    this.createForm();
    this.locations = this.shopifyService.getShopifyLocations(this.projectID, this.taskID);
  }

  changeLocation(value: {}) {
    const location = value as Location;
    this.locationsForm.get('inventory_item_id').setValue(location.inventory_item_id);
  }

  createForm() {
    this.locationsForm = this.fb.group({
      location_id: ['', Validators.required],
      inventory_item_id: [''],
      available_adjustment: [this.quantity]
    });
  }

  submitLocations(isArchive: boolean) {
    this.submitted = true;
    if (this.locationsForm.invalid) {
      return;
    }
    const data = this.locationsForm.value;
    this.shopifyService.sendShopifyLocations(data).pipe(concatMap( () => {
         if (isArchive) {
           this.spinnerButtonOptions.active = true;
           return this.taskService.archiveTasks(this.projectID, this.taskID);
         } else {
           this.activeModal.hide();
           return EMPTY;
         }
    })).subscribe( () => {
      if (!this.isModal) {
        this.router.navigate(['/project', this.projectID]);
      }
      this.spinnerButtonOptions.active = false;
      this.activeModal.hide();
    });
  }
}
