import { environment } from './../../../environments/environment';
import { AuthService } from '@app/shared/services/auth.service';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor( private authService: AuthService) {}
  // tslint:disable-next-line: no-any
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
     const user = this.authService.currentUser;
     const isLoggedIn = user && user.Token;
     const isApiUrl = req.url.startsWith(environment.baseUrl);
     if (isLoggedIn && isApiUrl) {
       req = req.clone({
         withCredentials: true,
         setHeaders: {
           Authorization: `Bearer ${user.Token}`
         }
       });
     }
     return next.handle(req);
  }
}
