import { Task } from '@app/models/task';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BotResponse, UserMessageObj } from '@app/models/chat';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  uploadAttachment = new Subject<{fileName: string, task: Task}>();
  constructor(private http: HttpClient) {}

  sendMessage(data: { Message: string; Type: number; Data: any }) {
    return this.http.post<BotResponse>(
      `${environment.baseUrl}api/jarvis`,
      data
    );
  }

  reportMessage(Data: [BotResponse, UserMessageObj]) {
    return this.http.post<null>(`${environment.baseUrl}api/jarvis/report`, {
      Data,
    });
  }
}
