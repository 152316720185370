import { AfterViewInit, Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { isPast, isToday } from 'date-fns';

@Directive({
  selector: '[appChangeDateColor]'
})


export class ChangeDateColorDirective implements AfterViewInit {
  @Input('appChangeDateColor') value: Date | string;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {
  }

  ngAfterViewInit() {
    const date = new Date(this.value);
    if (isPast(date) && !isToday(date)) {
      const element = this.elementRef.nativeElement;
      this.renderer.setStyle(element, 'color', 'red');
      this.renderer.setStyle(element, 'fill', 'red');
    }
  }
}
