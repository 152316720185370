<div class="bot-attachments scroll-chat scroll-auto">
  <div class="bot-attachments-title">
    Attachments for task {{ task.ProjectKey }}-{{ task.TaskKey }}
  </div>
  <div
    class="bot-attachments-box"
    *ngFor="let attachment of attachments; let i = index"
  >
    <div class="bot-attachments-content">
      <img
        src="/assets/images/attach-icon.png"
        alt="icon"
        class="bot-attachments-icon"
      />
      <div class="bot-attachments-extension">{{ attachemntsExt[i].extensionName | uppercase }}</div>
      <div class="bot-attachments-link">
        <a
          href="{{ attachment.AttachmentURL }}"
          target="blank"
          class="text-white"
        >
          {{ attachment.AttachmentFileName | shorten: 25 }}
        </a>
      </div>
    </div>
  </div>
  <app-chat-add-attachment [isList]= "true" [task] = "task"></app-chat-add-attachment>
</div>
