import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-chat-count-attach-notif',
  templateUrl: './chat-count-attach-notif.component.html',
  styleUrls: ['./chat-count-attach-notif.component.scss']
})
export class ChatCountAttachNotifComponent implements OnInit {
  @Output() sendMessageEvent = new EventEmitter<string>();
  @Input() taskData: {taskUID: string, numOfAttach: number, numOfComments: number}
  constructor() { }

  ngOnInit(): void {
  }


  sendMessage(newMessage: string) {
    this.sendMessageEvent.emit(newMessage);
  }
}
