import { Component, OnInit, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-jarvis',
  templateUrl: './jarvis.component.html',
  styleUrls: ['./jarvis.component.scss']
})
export class JarvisComponent implements OnInit, AfterViewInit {

  ngOnInit() {
  }

 ngAfterViewInit(): void {
 }
}
