export enum ChatTypes {
  PLAINTEXT = 1,
  TASK,
  TASKLIST,
  PROJECT,
  PROJECTLIST,
  INVITEDUSER,
  USER,
  INVITEDUSERLIST,
  USERLIST,
  HELP,
  NOTIFICATIONS,
  COMMENT,
  COMMENTLIST,
  ATTACHMENT,
  ATTACHMENTLIST
}

export enum StateCode {
  ASSIGNEEFORBIDDEN = -51,
  USERFORBIDDEN = -50,
  EMAILALREADYINVITED = -41,
  EMAILALREADYEXISTS = -40,
  INVALIDUPDATESOURCE = -33,
  INVALIDNAME = -32,
  INVALIDEMAIL = -31,
  INVALIDKEY = -30,
  TASKNOTCREATEDYET = -25,
  UNKNOWTASKSTATUS = -24,
  UNKNOWNTASKTYPE = -23,
  UNKNOWNPRIORITY = -22,
  UNKNOWNPERSON = -21,
  UNKNOWNPROJECT = -20,
  EMPTYCOMMENT = -15,
  EMPTYLEAD = -14,
  EMPTYNAME = -13,
  EMPTYEMAIL = -12,
  EMPTYPROJECT = -11,
  EMPTYTITLE = -10,
  NODATA = -2,
  INVALID = -1,
  NOMESSAGE = 0,
  INIT = 1,
  ASSURANCE = 2,
  STORED = 3,
  SELECTED = 4,
  REMOVED = 6,
  ADDED = 7,
  NOTSUPPORTED = 99
}
