import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UploadResponse } from '@app/models/user';
import { environment } from 'src/environments/environment';
import { concatMap } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UploadFilesService {
  recordVideoUpload = new Subject<File>();
  constructor(private http: HttpClient) {}

  fileUpload(data: File) {
    const replaceFile = data.name.replace(/[?*'\"/\\\r\n\t\b\a\x00]/g, '');
    let fileId: string;
    const fileName = {
      FileName: replaceFile
    };
    const fileSize = {
      ObjectSize: data?.size
    };
    return this.http
      .post<UploadResponse>(environment.baseUrl + 'api/storage', fileName)
      .pipe(
        concatMap(res => {
          fileId = res.UID;
          return this.http.put(res.UploadURL, data);
        }),
        concatMap(() => {
          return this.http.put<{ URL: string }>(
            environment.baseUrl + 'api/storage/' + fileId,
            fileSize,
          );
        })
      );
  }

  maxFileSize(file: File) {
    const maxFileSize = 104857600;
    const promise = new Promise((resolve, reject) => {
      if (file && file.size < maxFileSize) {
        resolve(true);
      } else {
        reject('The file is too large');
      }
    });
    return promise;
  }
}
