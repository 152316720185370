import { TransformDate } from '@app/shared/helpers/transform-date';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GoogleCalendarService {

  constructor(private http: HttpClient) { }


  addGoogleCalendar(Title: string, projectUID: string, taskUID: string, dueDate: Date) {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const formatDate = TransformDate(dueDate);
    const time = '09:00';
    const dueDateReplace = new Date(formatDate + ' ' + time);
    const data = {
      Title,
      Timezone: timeZone,
      DueDate: dueDateReplace
    };
    return this.http.post(`${environment.baseUrl}api/project/${projectUID}/task/${taskUID}/gcal`, data);
  }

  setGoogleCalendar(authCode: string) {
    return this.http.put(`${environment.baseUrl}api/calendar/google`, {AuthCode: authCode});
  }
}
