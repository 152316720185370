
<div class="shopify-login-wrapper">
  <img src="/assets/images/jarvis-shopify.png"/>
  <p> To connect Jarvis with Shopify: </p>
  <p> Enter your Shopify username USERNAME.myshopify.com even if you use a custom domain like name-of-shop.com. </p>
  <form (ngSubmit) = "submitForm(username.value)">
    <div class="form-group">
      <label class="label"> Username</label>
      <input type="text" class="form-control" #username />
    </div>
    <app-spinner-button [options]="spinnerButtonOptions">
    </app-spinner-button>
    <button
      type="button"
      class="btn btn-cancel"
      (click)="activeModal.hide()"
    >
      Cancel
    </button>
  </form>
</div>
