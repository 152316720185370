<svg
xmlns="http://www.w3.org/2000/svg"
width="16"
height="16"
viewBox="0 0 16 16"
>
<g
  id="prefix__Group_605"
  data-name="Group 605"
  transform="translate(-813 -376)"
>
  <g
    id="prefix__Group_62"
    data-name="Group 62"
    transform="translate(-7779 2775)"
  >
    <g
      id="prefix__Ellipse_39"
      data-name="Ellipse 39"
      transform="translate(8592 -2399)"
      style="stroke: #96989b; fill: none"
    >
      <circle cx="8" cy="8" r="8" style="stroke: none" />
      <circle cx="8" cy="8" r="7.5" style="fill: none" />
    </g>
  </g>
  <text
    id="prefix___"
    data-name="!"
    transform="translate(819 377)"
    style="
      fill: #96989b;
      font-size: 12px;
      font-family: Roboto-Regular, Roboto;
    "
  >
    <tspan x=".456" y="11">!</tspan>
  </text>
</g>
</svg>
