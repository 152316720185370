import { environment } from "./../../../environments/environment";
import { Injectable } from "@angular/core";
import {
  Task,
  TaskResponse,
  TaskStatus,
  TaskStatusResponse,
} from "@app/models/task";
import { HttpClient } from "@angular/common/http";
import { Subject, BehaviorSubject } from "rxjs";
import { tap } from "rxjs/operators";
import { GuestService } from "./guest.service";
import { CollaboratorsResponse } from "@app/models/collaborators";

@Injectable({
  providedIn: "root",
})
export class TaskService {
  projectCarousel$ = new Subject<string>();
  getTaskSubject = new Subject<null>();
  createTaskSubject = new Subject<null>();
  deleteSubject = new Subject<string>();
  upgradeSubject = new Subject<null>();
  updateStatus = new Subject<null>();
  updateTaskStatus$ = new Subject<{ id: string | number; statusID: number }>();
  createStatus = new Subject<null>();
  archiveSubject = new Subject<string>();
  unArchiveSubject = new Subject<string>();
  isDraggedSubject = new BehaviorSubject<boolean>(true);
  disableTimer = new BehaviorSubject<boolean>(false);
  filterSubject = new Subject<null>();
  modeSubject = new Subject<number>();
  taskMenuSubject = new BehaviorSubject<boolean>(false);
  taskCreateBtnSubject = new BehaviorSubject<boolean>(false);
  updateTaskAssignee$ = new Subject<{
    taskUID: string;
    assigneeUID: string;
    assigneeName: string;
    assigneeAvatarURL: string;
  }>();
  projectDataSubject = new Subject<string>();
  removeFilterSubject = new Subject<null>();
  constructor(private http: HttpClient, private guestService: GuestService) {}

  createTask(data: Task, UID: string) {
    return this.http
      .post<Task>(`${environment.baseUrl}api/project/${UID}/task`, data)
      .pipe(
        tap(() => {
          this.createTaskSubject.next();
        })
      );
  }

  getArchiveTasks(UID: string) {
    const link = window.location.pathname.slice(
      1,
      window.location.pathname.length
    );
    const path = this.guestService.isGuest ? link : `api/project/${UID}`;
    return this.http.get<TaskResponse>(
      `${environment.baseUrl}${path}/task/archive`
    );
  }

  getTasks(UID: string) {
    const link = window.location.pathname.slice(
      1,
      window.location.pathname.length
    );
    const path = this.guestService.isGuest ? link : `api/project/${UID}`;
    return this.http.get<TaskResponse>(`${environment.baseUrl}${path}/task`);
  }

  deleteTask(id: string, UID: string) {
    return this.http
      .delete<Task>(environment.baseUrl + "api/project/" + UID + "/task/" + id)
      .pipe(
        tap(() => {
          this.deleteSubject.next(id);
        })
      );
  }

  getTask(id: string, UID: string) {
    const link = window.location.pathname.slice(
      1,
      window.location.pathname.length
    );
    const path = this.guestService.isGuest ? link : `api/project/${UID}`;
    return this.http.get<Task>(`${environment.baseUrl}${path}/task/${id}`);
  }

  getCollaborators(projectUID: string, taskUID: string) {
    const link = window.location.pathname.slice(
      1,
      window.location.pathname.length
    );
    const path = this.guestService.isGuest ? link : `api/project/${projectUID}`;
    return this.http.get<CollaboratorsResponse>(
      `${environment.baseUrl}${path}/task/${taskUID}/collaborators`
    );
  }

  addCollaborators(
    projectUID: string,
    taskUID: string,
    CollaboratorUIDs: string[]
  ) {
    return this.http.post<null>(
      `${environment.baseUrl}api/project/${projectUID}/task/${taskUID}/collaborators`,
      { CollaboratorUIDs }
    );
  }

  updateTask(data: Task, id: string, UID: string) {
    return this.http
      .put<Task>(
        environment.baseUrl + "api/project/" + UID + "/task/" + id,
        data
      )
      .pipe(
        tap((res) => {
          this.upgradeSubject.next();
        })
      );
  }

  changeTaskPosition(
    id: string,
    UID: string,
    taskOrder: { Status: number; PrevOrder: string; NextOrder: string }
  ) {
    return this.http.patch(
      environment.baseUrl + "api/project/" + UID + "/task/" + id,
      taskOrder
    );
  }

  taskStatus(projectUID: string) {
    const link = window.location.pathname.slice(
      1,
      window.location.pathname.length
    );
    const path = this.guestService.isGuest ? link : `api/project/${projectUID}`;
    return this.http.get<TaskStatusResponse>(
      `${environment.baseUrl}${path}/taskStatus`
    );
  }

  changeTaskStatus(
    id: number,
    projectUID: string,
    data: { Name: string; PrevOrder: string; NextOrder: string }
  ) {
    return this.http.put<null>(
      environment.baseUrl + "api/project/" + projectUID + "/taskStatus/" + id,
      data
    );
  }

  updateStatusForTask(
    projectUID: string,
    taskUID: string | number,
    statusID: number
  ) {
    return this.http
      .patch(
        `${environment.baseUrl}api/project/${projectUID}/task/${taskUID}`,
        { Status: statusID }
      )
      .pipe(
        tap(() => {
          this.updateTaskStatus$.next({ id: taskUID, statusID: statusID });
        })
      );
  }

  updateTaskStatus(
    id: number,
    projectUID: string,
    value: string,
    statusCategory: number
  ) {
    return this.http
      .patch<null>(
        environment.baseUrl + "api/project/" + projectUID + "/taskStatus/" + id,
        { Name: value, Category: statusCategory }
      )
      .pipe(
        tap(() => {
          this.updateStatus.next();
        })
      );
  }

  createTaskStatus(value: string, projectUID: string) {
    return this.http
      .post<TaskStatus>(
        environment.baseUrl + "api/project/" + projectUID + "/taskStatus",
        { Name: value, Category: 1 }
      )
      .pipe(
        tap(() => {
          this.createStatus.next();
        })
      );
  }

  deleteTaskStatus(id: number, projectUID: string) {
    return this.http
      .delete<null>(
        environment.baseUrl + "api/project/" + projectUID + "/taskStatus/" + id
      )
      .pipe(
        tap(() => {
          this.createStatus.next();
        })
      );
  }

  unarchiveTasks(projectUID: string, taskUID: string) {
    return this.http
      .patch<{ StatusName: string; Status: number }>(
        environment.baseUrl +
          "api/project/" +
          projectUID +
          "/task/" +
          taskUID +
          "/unarchive",
        null
      )
      .pipe(
        tap(() => {
          this.unArchiveSubject.next(taskUID);
        })
      );
  }

  archiveTasks(projectUID: string, taskUID: string) {
    return this.http
      .patch<null>(
        environment.baseUrl +
          "api/project/" +
          projectUID +
          "/task/" +
          taskUID +
          "/archive",
        null
      )
      .pipe(
        tap(() => {
          this.archiveSubject.next(taskUID);
        })
      );
  }

  updateTaskAssignee(
    projectUID: string,
    taskUID: string,
    assigneeData: {
      assigneeUID: string;
      assigneeName: string;
      assigneeAvatarURL: string;
    }
  ) {
    return this.http.put(
      `${environment.baseUrl}api/project/${projectUID}/task/${taskUID}/assignee`,
      { AssigneeUID: assigneeData.assigneeUID }
    )
    .pipe(
      tap(() => {
        this.updateTaskAssignee$.next({ taskUID, ...assigneeData, });
      })
    );
  }

  copyTaskLink(projectKey: string, taskKey: number) {
    const link = `${window.location.origin}/task/${projectKey}-${taskKey}`;
    const el = document.createElement("textarea");
    el.value = link;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  }
}
