import { AuthService } from '@app/shared/services/auth.service';
import { Component, OnInit } from '@angular/core';
import * as jwt_decode from 'jwt-decode';
@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {
  timer: number;
  constructor() {}

  ngOnInit() {
  }
}
