import { Attachment } from '@app/models/attachment';
import { Component, OnInit, Input } from '@angular/core';
import { Task } from '@app/models/task';

@Component({
  selector: 'app-chat-attachments',
  templateUrl: './chat-attachments.component.html',
  styleUrls: ['./chat-attachments.component.scss']
})
export class ChatAttachmentsComponent implements OnInit {
  @Input() attachments: Attachment[];
  attachemntsExt: {attachmentId: string , extensionName: string}[] = [];
  @Input() task: Task;
  constructor() { }

  ngOnInit(): void {
    const defaultExtension = 'bin';
    this.attachments.map( item => {
       const extension =  item.AttachmentFileName.split('.').pop().slice(0, 4);
       if (!item.AttachmentFileName.includes('.')) {
        this.attachemntsExt.push({attachmentId: item.UID, extensionName: defaultExtension});
      } else {
        this.attachemntsExt.push({attachmentId: item.UID, extensionName: extension});
      }
    });
  }

}
