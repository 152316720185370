import { Subscription } from 'rxjs';
import { ChatComponent } from '@app/chat//chat/chat.component';
import { Component, OnInit, Input, Renderer2 } from '@angular/core';
import { GuestService } from '@app/shared/services/guest.service';
import { BsModalService } from 'ngx-bootstrap/modal';



@Component({
  selector: 'app-chat-button',
  templateUrl: './chat-button.component.html',
  styleUrls: ['./chat-button.component.scss']
})
export class ChatButtonComponent implements OnInit {
 @Input() img: string;
 @Input() status: boolean;
 isGuest = false;
 subscription: Subscription[] = [];
 closeResult: string;

 constructor( private modalService: BsModalService, private guestService: GuestService, private renderer: Renderer2) {
 }

  ngOnInit() {
    this.isGuest = this.guestService.isGuest;
  }

  unsubscribe() {
    this.subscription.forEach(subscription => subscription.unsubscribe());
    this.subscription = [];
  }

  openModal() {
      this.subscription.push(this.modalService.onHide.subscribe( () => {
        this.status = false;
        this.renderer.removeClass(document.body, 'chat-open');
        this.unsubscribe();
      }));

      this.subscription.push(this.modalService.onShow.subscribe( () => {
        this.renderer.addClass(document.body, 'chat-open');
        setTimeout(() => {
          const elem = document.getElementById('msg-reverse');
          elem.scrollTop = elem.scrollHeight;
        }, 0);
      }));
      const userMessages = JSON.parse(sessionStorage.getItem('userMessages'));
      const botMessages = JSON.parse(sessionStorage.getItem('botMessages'));
      const bubblesList = JSON.parse(sessionStorage.getItem('bubblesList'));
      const userMessageObj = JSON.parse(sessionStorage.getItem('userMessageObj'));
      const bsModalRef = this.modalService.show(ChatComponent, {
      class: 'chat-modal modal-lg modal-dialog-scrollable',
    }
      );
      if (userMessages) {
      const sliceUserMessages = userMessages.slice((userMessages.length - 30), userMessages.length);
      const sliceBotMessages  = botMessages.slice((botMessages.length - 30), botMessages.length);
      bsModalRef.content.userMessages = sliceUserMessages;
      bsModalRef.content.botMessages = sliceBotMessages;
      bsModalRef.content.bubblesList = bubblesList;
      bsModalRef.content.userMessageObj = userMessageObj;
      }

      setTimeout(() => {
     bsModalRef.content.state = 'fade-in';
    }, 100);
      this.status = !this.status;
  }
}
