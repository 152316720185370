import { Component, Input, OnInit } from '@angular/core';
import { ProjectService } from '@app/shared/services/project.service';

@Component({
  selector: 'app-project-create-templates',
  templateUrl: './project-create-templates.component.html',
  styleUrls: ['./project-create-templates.component.scss']
})
export class ProjectCreateTemplatesComponent implements OnInit {
  @Input() projectUID: string;
  isTemplateEdit = false;
  isTemplateSaved = false;
  isDisabled = false;
  constructor(private projectService: ProjectService) { }

  ngOnInit(): void {
  }

  saveTemplate(templateName: string) {
    this.isDisabled = true;
    this.projectService.saveTemplate(this.projectUID, templateName).subscribe(res => {
      this.isTemplateSaved = true;
      this.isDisabled = false;
    }, error => this.isDisabled = false)
  }

}
