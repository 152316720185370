import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-today-default-view-svg',
  templateUrl: './today-default-view-svg.component.html',
  styleUrls: ['./today-default-view-svg.component.scss']
})
export class TodayDefaultViewSvgComponent implements OnInit {
  @Input() isManagerMode: boolean;
  constructor() { }

  ngOnInit(): void {
  }

}
