import { ToastrService } from 'ngx-toastr';
import { Error } from '@app/enums/errors';
import { ShareLinkService } from '@app/shared/services/share-link.service';
import { Component, OnInit, Input } from '@angular/core';
import { Project } from '@app/models/project';

@Component({
  selector: 'app-shareable-link',
  templateUrl: './shareable-link.component.html',
  styleUrls: ['./shareable-link.component.scss']
})
export class ShareableLinkComponent implements OnInit {
  isGeneratedLink = false;
  isCopy = false;
  generatedLink: string;
  @Input() projectUID: string;
  @Input() isProject: boolean;
  @Input() projects: Project[];
  constructor(private shareLinkService: ShareLinkService, private toast: ToastrService) { }

  ngOnInit(): void {
  }

  changeProjectUID(event: Event) {
    const radioButton = (event.target as HTMLInputElement);
    if (radioButton.checked) {
         this.projectUID = radioButton.value;
       }
  }

  generateLink(name: string) {
    if (name === '') {
      return;
    }
    this.shareLinkService.getLink(this.projectUID, name).subscribe((res: string) => {
       this.generatedLink = res;
       this.isGeneratedLink = true;
    }, error => {
      if (error.error.ErrorCode === Error.ErrorCodeUserNameExists ) {
        this.toast.warning('User with this name already exists');
      }
    });
  }

  copyGeneratedLink() {
    this.isCopy = true;
    this.shareLinkService.copyGeneratedLink(this.generatedLink);
    setTimeout(() => {
      this.isCopy = false;
    }, 2000);
  }

}
