import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mentionReplace'
})
export class MentionReplacePipe implements PipeTransform {

  transform(value: string): string {
    if (!value) {
      return;
    }
    const regex = /@\[[^\]\[\{\}%]*\]\[[a-zA-Z0-9]{8}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{12}\]/g;
    const regexArray = value.match(regex);
    if (regexArray) {
    regexArray.forEach( (regexItem: string) => {
      const regexName = /\[+(.*?)\]+/;
      const regexNameArray = regexItem.match(regexName);
      const name = `<span class ="mention"> ${regexNameArray[1]} </span>`;
      value = value.replace(regexItem, name);
    });
  }
    return value;
  }

}
