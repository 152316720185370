import { VideoRecordModalComponent } from '@app/shared/video-record-modal/video-record-modal.component';
import { Component } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';



@Component({
  selector: 'app-video-record',
  templateUrl: './video-record.component.html',
  styleUrls: ['./video-record.component.scss']
})
export class VideoRecordComponent {

  constructor(private modalService: BsModalService) {}

  async startRecording() {
    const mediaConstraints = {
      video: { width: 1480, height: 920, displaySurface: 'window', logicalSurface: true },
      audio: true
    };
    try {
      // tslint:disable-next-line: no-any
      const mediaDevices = await (navigator.mediaDevices as any).getDisplayMedia(mediaConstraints);
      this.modalService.show(VideoRecordModalComponent, {class: 'custom-modal modal-lg video-record',
        initialState: {stream: mediaDevices} as any
          });
      document.getElementsByClassName('video-record')[0].parentElement.style.backgroundColor = 'rgba(100, 112, 130, 0.4)';
    } catch (err) {
    }
  }

}
