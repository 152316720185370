import { Pipe, PipeTransform } from "@angular/core";
import { Project } from "@app/models/project";
import { Task } from "@app/models/task";

@Pipe({
  name: "projectsSort",
})
export class projectsSortPipe implements PipeTransform {

  transform(value: Project[], key: string, isActive: boolean): Project[] {
    if (!key) {
      return value.sort((a, b) => {
        const dateA = new Date(a.UpdatedAt);
        const dateB = new Date(b.UpdatedAt);
        return dateB.getTime() - dateA.getTime();
      });
    }
    if (isActive) {
      return value.sort((a, b) => {
        const nameA = a[key].toUpperCase();
        const nameB = b[key].toUpperCase();
        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }
        return 0;
      });
    } else {
      return value.sort((a, b) => {
        const nameA = a[key].toUpperCase();
        const nameB = b[key].toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
    }
  }
}
