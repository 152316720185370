export enum NotificationType {
  COMMENT = 1,
  ATTACHMENT,
  UPDATE,
  ASSIGNEE,
  CHANGESTATUS,
  MENTION,
  MENTIONDESC,
  REMINDER,
  REMINDERNOTMODIFIED
}
