<div
  *ngIf="!editMode"
  (click)="!isGuest ? editMode = true : editMode = false"
  [ngClass]="{ 'is-placeholder': isAdd, 'grid-column-bg': isGrid, 'text-gray-400': isCheked }"
  class="form-control cell"
>
  {{ isAdd ? placeholder : data }}
</div>
<input
  *ngIf="editMode"
  (focusout)="onFocusOut()"
  (focus) = "onFocus()"
  [(ngModel)]="data"
  class="form-control inline-input font-14"
  placeholder=" {{ placeholder }}"
  [appFocus]="true"
  type="string"
  (keydown.enter)="submitTitle($event)"
  (focusout)="editMode = false"
/>
