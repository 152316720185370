import { Pipe, PipeTransform } from '@angular/core';
import { Project } from '@app/models/project';

@Pipe({
  name: 'filterTemplates'
})
export class FilterTemplatesPipe implements PipeTransform {

  transform(value: Project[], isTemplate: boolean): Project[] {
    if (!value) {
      return null;
    }
    if (isTemplate) {
      value = value.filter(({IsTemplate}) => IsTemplate === true);
    } else {
      value = value.filter(({IsTemplate}) => IsTemplate === false);
    }
    return value;
  }

}
