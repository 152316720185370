import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ProjectArrayResponse } from '@app/models/user';

@Injectable({
  providedIn: 'root',
})
export class UserProjectService {
  constructor(private http: HttpClient) {}


  getUserProjects(userUID: string) {
    return this.http.get<ProjectArrayResponse>(`${environment.baseUrl}api/user/${userUID}/project`);
  }

  removeUsersfromProject(projectUID: string, userUID: string) {
    return this.http.delete(
      `${environment.baseUrl}api/project/${projectUID}/user/${userUID}`
    );
  }

  addProject(projectUID: string, userUID: string) {
    return this.http.put(
      `${environment.baseUrl}api/project/${projectUID}/user/${userUID}`,
      null
    );
  }

  addUserstoProject(projectUID: string, UserUIDs: string[]) {
    return this.http.post(
      `${environment.baseUrl}api/project/${projectUID}/user`,
      {UserUIDs}
    );
  }
}
