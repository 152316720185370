import { ProductResponse, Inventory, Conditions } from '@app/models/shopify';
import { Observable } from 'rxjs';
import { ShopifyService } from '@app/shared/services/shopify.service';
import { FormBuilder, FormArray, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { SpinnerButtonOptions } from '@app/models/spinnerButtonOptions';

@Component({
  selector: 'app-shopify-details',
  templateUrl: './shopify-details.component.html',
  styleUrls: ['./shopify-details.component.scss']
})
export class ShopifyDetailsComponent implements OnInit, AfterViewInit {
  spinnerButtonOptions: SpinnerButtonOptions = {
    active: false,
    text: 'Save changes',
    fullWidth: false,
    disabled: false,
  };
  @Input() items: FormArray;
  @Input() projectForm: FormGroup;
  @Input() shopifyID: string;
  @Input() isEditMode: boolean;
  @Input() projectID: string;
  @Input() submitted: boolean;
  inventory: Inventory[] = [{ID: 1, Name: 'Less than'}, {ID: 2, Name: 'More than'}];
  shopifyProducts$: Observable<ProductResponse>;


  constructor(private formBuilder: FormBuilder, private shopifyService: ShopifyService) { }

  ngOnInit(): void {
    this.shopifyProducts$ = this.shopifyService.getShopifyProduct(this.shopifyID).pipe(map( res => {
      const products = [];
      res.Products.map(item => {
        const variants = item.Variants.map( val => {
        return {
             FullName: val.title ? `${item.Title}-${val.title}` : item.Title,
             ID: item.ID,
             VariantID: val.id,
             Title: item.Title,
           };
        });
        variants.forEach((variant) => {
          products.push(variant);
        });
      });
      return {Products: products};
    }));
    this.getShopifyConditions();
  }

  changeProduct(item, i: number) {
    this.t.controls[i].patchValue({Title: item.FullName, VariantID: item.VariantID}, {emitEvent: false});
  }

  ngAfterViewInit(): void {
  }

  createItem() {
    return this.formBuilder.group({
      UID: '',
      TriggerID: [1],
      VariantID: [null],
      ProductID: [null, Validators.required],
      Title: [null, Validators.required],
      ConditionID: [null, Validators.required],
      Quantity: [null, Validators.required],
    });
  }

  get f() {
    return this.projectForm.controls;
  }

  validate(i: number, formControlName: string) {
    const errors = (this.t.controls[i] as FormGroup).controls[formControlName].errors;
    let isError: boolean;
    if (errors) {
      if (errors.required) {
        isError = true;
      } else {
        isError = false;
      }
    }
    return isError;
  }

  get t() {
    return this.projectForm.get('Conditions') as FormArray;
  }

  addItem() {
    this.items = this.projectForm.get('Conditions') as FormArray;
    this.items.push(this.createItem());
  }

  removeItem(i: number) {
    if (this.isEditMode) {
      const id = (this.t.controls[i] as FormGroup).controls.UID.value;
      if (id !== '') {
          this.shopifyService.deleteShopifyConditions(this.projectID, id).subscribe( () => {
            this.items.removeAt(i);
          });
        } else {
          this.items.removeAt(i);
        }
      } else {
        this.items.removeAt(i);
      }
  }

  getShopifyConditions() {
    if (this.isEditMode) {
    this.shopifyService.getShopifyConditions(this.projectID).subscribe(res => {
      for (const item of res) {
        this.items = this.projectForm.get('Conditions') as FormArray;
        this.items.push(this.formBuilder.group({
          UID: [item.UID],
          VariantID: [item.VariantID],
          TriggerID: [1],
          ProductID: [item.ProductID, Validators.required],
          Title: [item.ProductTitle, Validators.required],
          FullName: [item.ProductID],
          ConditionID: [item.ConditionID, Validators.required],
          Quantity: [item.Quantity, Validators.required],
        }));
      }
   });
 }
}

  resetCondition() {
    this.items.clear();
    this.getShopifyConditions();
  }

  saveConditions() {
    this.submitted = true;
    if (this.projectForm.invalid) {
      return;
    }
    let conditions: Conditions[];
    conditions = this.t.controls.map((item, i) => {
      const newConditions = {...item.value, Index: i};
      return newConditions;
    });
    const data =  conditions;
    this.spinnerButtonOptions.active = true;
    this.shopifyService.addShopifyConditions(this.projectID, data).subscribe(res => {
      this.submitted = false;
      this.spinnerButtonOptions.active = false;
      res.map(item => {
        if (item.UID !== '') {
          this.t.controls[item.Index].patchValue({UID: item.UID });
        }
      });
    },
    error => this.spinnerButtonOptions.active = false);
  }

}
