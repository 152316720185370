import { FormArray, AbstractControl } from '@angular/forms';
import { Component, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-task-create-checklist',
  templateUrl: './task-create-checklist.component.html',
  styleUrls: ['./task-create-checklist.component.scss']
})
export class TaskCreateChecklistComponent implements OnInit {
  isOpen = false;
  checklist: string[] = [];
  @Output() checklistEvent: EventEmitter<string[]> = new EventEmitter<string[]>();
  constructor() { }

  ngOnInit(): void {
  }

  openChecklist() {
    this.isOpen = true;
  }

  addItem(newValue: string) {
    if (!newValue) {
      return;
    }
    this.checklist = [...this.checklist, newValue];
    this.checklistEvent.emit(this.checklist);
  }

  updateItem(newValue: string, i: number) {
    if (!newValue) {
      return;
    }
    const newItems =  this.checklist.map( (item, index) => {
        if (index === i) {
          const element = newValue;
          return element;
        }
        return item;
    });
    this.checklist = newItems;
    this.checklistEvent.emit(this.checklist);
  }

  removeItem(i: number) {
    this.checklist = this.checklist.filter( (item, index) => index !==  i);
    this.checklistEvent.emit(this.checklist);
  }

}
