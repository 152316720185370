<form
  class="d-flex flex-column overflow-hidden"
  [formGroup]="feedbackForm"
  (ngSubmit)="submitForm()"
>
  <div
    class="modal-header border-none mt-4 flex-column align-items-center justify-content-center"
  >
    <p class="font-18 text-primary mb-2">
      Give us Feedback about Jarvis Feature
    </p>
    <p>Let us know how we can improve your Jarvis experience.</p>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label class="label"> Your message </label>
      <textarea
        type="text"
        class="form-control"
        formControlName="Message"
        placeholder="Enter message"
        rows="5"
        [ngClass]="{ 'is-invalid': f['Message']?.errors && submitted }"
      >
      </textarea>
      <div *ngIf="submitted && f['Message']?.errors" class="invalid-feedback">
        <div *ngIf="f['Message']?.errors.required">
          This field can not be empty
        </div>
      </div>
    </div>
    <div class="form group">
      <label class="label">Attachments</label>
      <div
        class="uploadfilecontainer"
        (click)="files.click()"
        appDragDrop
        (fileDropped)="addFile($event)"
      >
        <input
          hidden
          type="file"
          #files
          (change)="addFile($event.target)"
          [disabled]="loading"
        />
        <div class="spinner-border text-primary" role="status" *ngIf="loading">
          <span class="sr-only">Loading...</span>
        </div>
        <p class="upload-text" *ngIf="!loading">Choose files to upload</p>
      </div>
      <ul class="attachments-list">
        <li *ngIf="fileData"
          class="attachments-item d-flex align-items-center"
        >
          <div class="attachment-box"></div>
          <a
            href="{{ fileData.AttachmentURL }}"
            target="blank"
            class="text-primary"
          >
            {{ fileData.AttachmentFileName | shorten: 35 }}
          </a>
          <span class="d-none">
            <i class="fas fa-times" (click) = "deleteAttachment()"></i>
          </span>
        </li>
      </ul>
    </div>
    <p class="font-12 text-gray-600 mt-5">
      Thanks for taking the time to give us feedback. Though we can’t review and
      respond to every submission, we do use feedback like yours to improve
      Jarvis experience for everyone.
    </p>
  </div>
  <div class="modal-footer border-none justify-content-center align-items-center">
    <app-spinner-button [options]="spinnerButtonOptions"> </app-spinner-button>
    <button
      type="button"
      class="btn btn-cancel"
      (click)="activeModal.hide()"
    >
      Cancel
    </button>
  </div>
</form>
