import { ProjectArrayResponse } from './../../models/user';
import { GuestService } from './guest.service';

import { environment } from './../../../environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Project, ProjectResponse, SingleProject } from '@app/models/project';
import { Subject, BehaviorSubject, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Error } from '@app/enums/errors';

@Injectable({
  providedIn: 'root'
})

export class ProjectService {
  iconColoros: string[] = ['#F67F6C', '#F8B195', '#FBC18E', '#C06C84', '#F67280', '#A79FDC',
  '#8AC6D1', '#BBDED6', '#98C5EC', '#74BEC1', '#87A8D0', '#6797CE', '#7CE1ED', '#B3CF78',
  '#5363C8', '#082D5D', '#47C9D8', '#FF9B9B', '#A2C5F6'];
  currentIconColors: string[] = [];
  createSubject = new Subject<null>();
  deleteSubject = new Subject<string>();
  upgradeSubject = new Subject<null>();
  projects$ = new Subject<Project[]>();
  unarchiveProjects$ = new Subject<string>();
  archiveProjects$ = new Subject<string>();
  projectMenuSubject = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient, private guestService: GuestService, private toastService: ToastrService) { }

  getProjects() {
    const resetColors = this.iconColoros;
    return this.http.get<ProjectResponse>(environment.baseUrl + 'api/project').pipe(tap( res => {
      res.data.forEach( item => {
        this.currentIconColors.push(item.Icon);
      });
      this.iconColoros = this.iconColoros.filter(val => !this.currentIconColors.includes(val));
      if (this.iconColoros.length < 1) {
        this.iconColoros = resetColors;
      }
    }));
  }

  getTemplates() {
    return this.http.get<ProjectResponse>(`${environment.baseUrl}api/project/template`);
  }

  saveTemplate(projectUID: string, templateName: string) {
    return this.http.post(`${environment.baseUrl}api/project/${projectUID}/saveTemplate`, {TemplateName: templateName}).pipe(
      tap(() => this.upgradeSubject.next()),
      catchError(this.handleError.bind(this)));
  }

  getProject(id: string) {
    const link = window.location.pathname.slice(1, window.location.pathname.length);
    const path = this.guestService.isGuest ? link : `api/project/${id}`;
    return this.http.get<SingleProject>(`${environment.baseUrl}${path}`);
  }

  getProjectView(userUID: string) {
    return this.http.get<ProjectArrayResponse>(`${environment.baseUrl}api/user/${userUID}/project/view`);
  }

  createProject(data: Project, templateUID: string) {
    const urlPath = templateUID ? `api/project/${templateUID}/fromTemplate` : `api/project`;
    return this.http.post<Project>(`${environment.baseUrl}${urlPath}`, data).pipe(tap( () => {
      this.createSubject.next();
   }),
   catchError(this.handleError.bind(this)));
   }

  deleteProject(id: string) {
    return this.http.delete<Project>(environment.baseUrl + 'api/project/' + id).pipe(tap( () => {
      this.deleteSubject.next(id);
   }),
   catchError(this.handleError.bind(this)));
  }

  updateProject(data: Project, id: string) {
    return this.http.put<Project>(environment.baseUrl + 'api/project/' + id, data).pipe(tap( () => {
      this.upgradeSubject.next();
   }),
   catchError(this.handleError.bind(this)));
  }

  projectIconColor(): string {
      const icon = this.iconColoros[Math.floor(Math.random() * this.iconColoros.length)];
      return icon;
  }

  getArchiveProjects() {
     return this.http.get<ProjectResponse>(`${environment.baseUrl}api/project/archive`);
  }

  archiveProject(projectUID: string) {
    return this.http.post(`${environment.baseUrl}api/project/${projectUID}/archive`, null).pipe(
      tap(() => this.archiveProjects$.next(projectUID)),
      catchError(this.handleError.bind(this)));
  }

  unarchiveProject(projectUID: string) {
    return this.http.post(`${environment.baseUrl}api/project/${projectUID}/unarchive`, null).pipe(
      tap(() => this.unarchiveProjects$.next(projectUID)),
      catchError(this.handleError.bind(this)));
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    switch (error?.error.ErrorCode) {
      case Error.ProjectKeyExist:
      errorMessage = 'That key is already taken.';
      break;
      case Error.ProjectNameExist:
      errorMessage = 'That name is already taken.';
      break;
      case Error.ErrorCodeNotAllowed:
      errorMessage = 'Not enough permissions to perform this action.';
      break;
      default:
      errorMessage = 'Something went wrong. Please try again later or contact our support';
    }
    this.toastService.warning(errorMessage, 'Warning');
    return throwError(errorMessage);
  }
}
