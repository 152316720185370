import { Router } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {
  @Input() title: string;
  @Input() text: string;
  @Input() subtext: string;
  @Input() backgroundColor: string;
  constructor(private router: Router) {
    this.title = `404`;
    this.text =    `Sorry, page you’re looking for does not exist.`;
    this.subtext = `Visit our homepage or contact our support.`;
  }

  ngOnInit() {
  }
}
