<svg
version="1.1"
id="Layer_1"
width = "28"
height = "28"
xmlns="http://www.w3.org/2000/svg"
xmlns:xlink="http://www.w3.org/1999/xlink"
x="23px"
y="23px"
viewBox="0 0 122.88 122.88"
style="enable-background: new 0 0 122.88 122.88"
xml:space="preserve"
>
<style type="text/css">

              .st0{fill:#188038;}
              .st1{fill:#1967D2;}
              .st2{fill:#1A73E8;}
              .st3{fill:#F72A25;}
              .st4{fill:#FBBC04;}
              .st5{fill:#FFFFFF;}
              .st6{fill:#34A853;}
              .st7{fill:#4285F4;}

</style>
<g>
  <polygon
    class="st5"
    points="93.78,29.1 29.1,29.1 29.1,93.78 93.78,93.78 93.78,29.1"
  />
  <polygon
    class="st3"
    points="93.78,122.88 122.88,93.78 93.78,93.78 93.78,122.88"
  />
  <polygon
    class="st4"
    points="122.88,29.1 93.78,29.1 93.78,93.78 122.88,93.78 122.88,29.1"
  />
  <polygon
    class="st6"
    points="93.78,93.78 29.1,93.78 29.1,122.88 93.78,122.88 93.78,93.78"
  />
  <path
    class="st0"
    d="M0,93.78v19.4c0,5.36,4.34,9.7,9.7,9.7h19.4v-29.1H0L0,93.78z"
  />
  <path
    class="st1"
    d="M122.88,29.1V9.7c0-5.36-4.34-9.7-9.7-9.7h-19.4v29.1H122.88L122.88,29.1z"
  />
  <path
    class="st7"
    d="M93.78,0H9.7C4.34,0,0,4.34,0,9.7v84.08h29.1V29.1h64.67V0L93.78,0z"
  />
  <path
    class="st2"
    d="M42.37,79.27c-2.42-1.63-4.09-4.02-5-7.17l5.61-2.31c0.51,1.94,1.4,3.44,2.67,4.51 c1.26,1.07,2.8,1.59,4.59,1.59c1.84,0,3.41-0.56,4.73-1.67c1.32-1.12,1.98-2.54,1.98-4.26c0-1.76-0.7-3.2-2.09-4.32 c-1.39-1.12-3.14-1.67-5.22-1.67H46.4v-5.55h2.91c1.79,0,3.31-0.48,4.54-1.46c1.23-0.97,1.84-2.3,1.84-3.99 c0-1.5-0.55-2.7-1.65-3.6s-2.49-1.35-4.18-1.35c-1.65,0-2.96,0.44-3.93,1.32c-0.97,0.88-1.7,2-2.12,3.24l-5.55-2.31 c0.74-2.09,2.09-3.93,4.07-5.52c1.98-1.59,4.51-2.39,7.58-2.39c2.27,0,4.32,0.44,6.13,1.32c1.81,0.88,3.23,2.1,4.26,3.65 c1.03,1.56,1.54,3.31,1.54,5.25c0,1.98-0.48,3.65-1.43,5.03c-0.95,1.37-2.13,2.43-3.52,3.16v0.33c1.79,0.74,3.36,1.96,4.51,3.52 c1.17,1.58,1.76,3.46,1.76,5.66c0,2.2-0.56,4.16-1.67,5.88c-1.12,1.72-2.66,3.08-4.62,4.07c-1.96,0.99-4.17,1.49-6.62,1.49 C47.41,81.72,44.79,80.91,42.37,79.27L42.37,79.27L42.37,79.27z M76.83,51.43l-6.16,4.45l-3.08-4.67l11.05-7.97h4.24v37.6h-6.05 V51.43L76.83,51.43z"
  />
</g>
</svg>
