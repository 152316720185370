import { CommentsService } from '@app/shared/services/comments.service';
import { Component, OnInit, Input } from '@angular/core';
import { BotResponseGroup } from '@app/models/chat';
import { StateCode, ChatTypes } from '@app/enums/chat';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-chat-message',
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.scss'],
})
export class ChatMessageComponent implements OnInit {
  @Input() botMessages: BotResponseGroup;
  @Input() taskKey: number;
  @Input() projectKey: string;
  constructor(public activeModal: BsModalRef, private commentService: CommentsService) {}

  get stateCode() {
    return StateCode;
  }

  get chatType() {
    return ChatTypes;
  }

  getLinks() {
    if (this.botMessages) {
      const botType = this.botMessages.botResponse.Type;
      const botStateCode = this.botMessages.botResponse.StateCode;
      switch (true) {
        case botType === this.chatType.PROJECT &&
          botStateCode === this.stateCode.STORED:
          this.taskKey = null;
          this.projectKey = this.botMessages.botResponse.Data.Key;
          break;
        case botType === this.chatType.TASK &&
          botStateCode === this.stateCode.STORED:
          this.taskKey = this.botMessages.botResponse.Data.TaskKey;
          this.projectKey = this.botMessages.botResponse.Data.ProjectKey;
          break;
        case botType === this.chatType.COMMENT &&
          botStateCode === this.stateCode.STORED:
          this.taskKey = this.botMessages.botResponse.Data.Task.TaskKey;
          this.projectKey = this.botMessages.botResponse.Data.Task.ProjectKey;
          break;
        case botType === this.chatType.ATTACHMENT &&
          botStateCode === this.stateCode.STORED:
          this.taskKey = this.botMessages.botResponse.Data.TaskKey;
          this.projectKey = this.botMessages.botResponse.Data.ProjectKey;
          break;
        default:
          this.taskKey = null;
          this.projectKey = null;
          break;
      }
    }
  }

  ngOnInit(): void {
    this.getLinks();
  }

  hideModal() {
    const botType = this.botMessages.botResponse.Type;
    const botStateCode = this.botMessages.botResponse.StateCode;
    if (botType === this.chatType.ATTACHMENT &&
      botStateCode === this.stateCode.STORED) {
        setTimeout(() => {
          this.commentService.isCommentSubject.next(false);
        }, 0);
      }
    this.activeModal.hide();
  }
}
