<label class="label mt-3"> Checklist </label>
<div class="mt-3">
<a
  class="create-checklist"
  *ngIf="!isOpen"
  (click)="openChecklist()"
>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13.267"
    height="14.219"
    viewBox="0 1 13.267 11.219"
    class="mr-2"
  >
    <defs>
      <style>
        .cls-1 {
          fill: #818181;
        }
      </style>
    </defs>
    <g id="Group_1792" transform="translate(-2.832 -4.003)">
      <g id="Group_1790" transform="translate(2.832 4.003)">
        <path
          id="Path_2926"
          d="M1726.711 1409.132H1716.4a.99.99 0 0 1-.99-.99V1397.9a.99.99 0 0 1 .99-.99h7.106a.991.991 0 0 1 0 1.981h-6.115v8.256h8.326v-2.024a.991.991 0 0 1 1.981 0v3.015a.991.991 0 0 1-.987.994z"
          class="cls-1 svg-checklist"
          transform="translate(-1715.413 -1396.913)"
        />
        <path
          id="Path_2927"
          d="M1742.6 1415.949a.988.988 0 0 1-.7-.29l-2.7-2.7a.99.99 0 0 1 1.4-1.4l2.04 2.039 4.6-4.136a.99.99 0 1 1 1.324 1.473l-5.3 4.764a.988.988 0 0 1-.664.25z"
          class="cls-1 svg-checklist"
          transform="translate(-1735.62 -1407.486)"
        />
      </g>
    </g>
  </svg>
  <span> Create checklist </span>
</a>
</div>
<div class="checklist-wrapper" *ngIf="isOpen">
  <div class="d-flex flex-column mt-2">
    <div
      class="d-flex align-items-center mb-3"
      *ngFor="let item of checklist; let i = index"
    >
      <app-inline-edit
        class="w-100"
        [data]="item"
        (focusOut)="updateItem($event, i)"
      >
      </app-inline-edit>
      <img
        src="/assets/images/x.png"
        width="8px"
        height="8px"
        class="ml-2 cursor-pointer"
        (click)="removeItem(i)"
        alt="x"
      />
    </div>
    <div class="d-flex align-items-center">
      <app-inline-edit
        class="w-100"
        [isAdd]="true"
        [placeholder] = "'Add item'"
        (focusOut)="addItem($event)"
      >
      </app-inline-edit>
    </div>
  </div>
</div>
