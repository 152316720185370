import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-hint-icon',
  templateUrl: './hint-icon.component.html',
  styleUrls: ['./hint-icon.component.scss']
})
export class HintIconComponent implements OnInit {
@Input() width = 20;
@Input() height = 15;
  constructor() { }

  ngOnInit(): void {
  }

}
