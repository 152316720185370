import { GuestService } from './guest.service';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { User } from '@app/models/user';

@Injectable({
  providedIn: 'root',
})
export class GuestResolver implements Resolve<User> {
  private currentGuestSubject: BehaviorSubject<User>;
  constructor(private http: HttpClient, private guestService: GuestService) {
    this.currentGuestSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentGuest'))
    );
  }
  get guestUser() {
    return this.currentGuestSubject.value;
  }
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<User> | Promise<User> {
    const link = window.location.pathname;
    const isGuestLink = link.startsWith('/shared');
    if (isGuestLink) {
      this.guestService.isGuest = true;
      return this.guestService.getSharedUser().pipe(
        map((user) => {
          localStorage.setItem('currentGuest', JSON.stringify(user));
          this.guestService.currentGuestSubject.next(user);
          return user;
        })
      );
    } else {
      this.guestService.isGuest = false;
      localStorage.removeItem('currentGuest');
    }
  }
}
