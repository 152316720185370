import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component, OnInit } from '@angular/core';
import { ShopifyService } from '@app/shared/services/shopify.service';
import { SpinnerButtonOptions } from '@app/models/spinnerButtonOptions';

@Component({
  selector: 'app-shopify-login',
  templateUrl: './shopify-login.component.html',
  styleUrls: ['./shopify-login.component.scss']
})
export class ShopifyLoginComponent implements OnInit {
  spinnerButtonOptions: SpinnerButtonOptions = {
    active: false,
    text: 'Continue',
    fullWidth: false,
    disabled: false,
  };
  constructor(public activeModal: BsModalRef,
              private shopifyService: ShopifyService) { }

  ngOnInit(): void {
  }


  submitForm(username: string) {
    this.shopifyService.loginShopify(username);
  }

}
