import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { User } from '@app/models/user';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';
import { format } from 'date-fns';
import { Error } from '@app/enums/errors';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<User>;
  public isResendEmail = new Subject<null>();
  public errorSubject: Subject<string> = new Subject<string>();

  constructor(private http: HttpClient, private router: Router, private toastService: ToastrService) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUser'))
    );
  }

  get currentUser() {
    return this.currentUserSubject.value;
  }

  checkEmail(email: string) {
    return this.http.get(environment.baseUrl + 'user/check/' + email).pipe(
      catchError(this.handleError.bind(this))
    );
  }

  register(emailString: string, data: User): Observable<User> {
    return this.http.post<User>(`${environment.baseUrl}user/register/${emailString}`, data, { withCredentials: true });
  }

  confirmEmail(UID: string) {
    return this.http.patch(environment.baseUrl + 'user/confirm/' + UID, null);
  }

  resendEmail(email: string) {
    return this.http.put(environment.baseUrl + 'user/resend/' + email, null);
  }

  login(data: User) {
    const httpOptions = {
      headers: new HttpHeaders(),
      withCredentials: true,
      observe: 'response' as 'response',
    };
    return this.http.post<User>(environment.baseUrl + 'user/login', data, httpOptions).pipe(
      map(user => {
        if (user.body && user.body.Token) {
          localStorage.setItem('currentUser', JSON.stringify(user.body));
          this.currentUserSubject.next(user.body);
          const patternOffSet = `xxx`;
          const timeZoneOffset = format(new Date(), patternOffSet);
          localStorage.setItem('timeZoneOffSet', timeZoneOffset);
        }
        return user;
      }),
      catchError(this.handleError.bind(this))
    );
  }

  refreshToken(userUID: string) {
    return this.http.post<{Token: string}>(environment.baseUrl + 'user/renew', {userUID}, {withCredentials: true});
  }

  changePassword(userUID: string, data: {OldPassword: string, NewPassword: string}) {
    return this.http.patch<null>(environment.baseUrl + 'api/user/' + userUID, data);
  }

  resetPassword(email: string) {
    return this.http.put(environment.baseUrl + 'user/resetPassword/' + email, null).pipe(
      catchError(this.handleError.bind(this))
    );
  }

  getNewPassword(resetPasswordToken: string, NewPassword: string ) {
    return this.http.patch(environment.baseUrl + 'user/resetPassword/' + resetPasswordToken, { NewPassword }).pipe(
      catchError(this.handleError.bind(this))
    );
  }

  logout(isRenew: boolean) {
    this.router.navigate(['/login']);
    if (isRenew) {
      this.clearStorage();
    } else {
      this.http.post(`${environment.baseUrl}api/user/logout`, null).subscribe(() => {
        this.clearStorage();
      });
    }
  }

  clearStorage() {
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }

  updateTimeZone(currentTime: string) {
    return this.http.patch(`${environment.baseUrl}api/user/timezone`, { CurrentTime: currentTime});
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    switch (error?.error.ErrorCode) {
      case Error.ErrorCodeWrongCredentials:
      errorMessage = 'Wrong email or password.';
      break;
      case Error.ErrorCodeEmailNotConfirmed:
      errorMessage = 'Your email address is not verified.';
      break;
      case Error.ErrorCodeNotFound:
      errorMessage = `User with given email doesn't exist`;
      break;
      case Error.ErrorCodeUserNotExist:
      errorMessage = 'User does not exist or is disabled.';
      break;
      case Error.ErrorCodeValidation:
      errorMessage = `Can't use same url twice for resetting token. Please go to forgot password page again`;
      break;
      default:
      errorMessage = 'Something went wrong';
    }
    this.toastService.warning(errorMessage, 'Warning');
    return throwError(errorMessage);
  }
}
