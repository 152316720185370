<div class="bot-notifications">
  <div class="bot-notifications-scroll scroll scroll-auto">
    <div
      class="bot-notifications-box"
      aria-labelledby="homepage-placeholder"
    >
      <div class="mark-all-as-read" (click)="readAllNotif($event)">
        Mark all as read
      </div>
      <ng-container
        *ngFor="let notificationGroup of notificationGroups; let first = last"
      >
        <div
          class="bot-notifications-box-content my-3"
          *ngIf="notificationGroup.data.length > 0"
        >
          <div class="bot-notifications-box-date">
            {{ notificationGroup.name }}
          </div>
        </div>
        <div
          *ngFor="let notification of notificationGroup.data"
          class="notification position-relative"
          [ngStyle]="{
            'background-color': notification.IsRead ? '' : '#f5faff'
          }"
        >
          <a
            [routerLink]="[
              '/task',
              notification.Content.ProjectKey +
                '-' +
                notification.Content.TaskKey
            ]"
            (click)="markAsRead(notification.Content, notification.ID); activeModal.hide()"
            class="d-flex"
          >
            <diV class="notification-user-img mr-3">
              <app-no-avatar
                *ngIf = "notification.Content.Type !== notificationType.REMINDER"
                [avatarURL]="notification.Content.UserAvatarURL"
                [userName]="notification.Content.UserName"
                [width]="32"
                [height]="32"
              >
              </app-no-avatar>
            </diV>
            <div class="notifications-info">
              <ng-container [ngSwitch]="notification.Content.Type">
                <div *ngSwitchCase="notificationType.COMMENT">
                  <p class="mb-2 text-black">
                    {{ notification.Content.UserName }} commented on task
                    {{ notification.Content.ProjectKey }}
                    -
                    {{ notification.Content.TaskKey }}
                  </p>
                </div>
                <div *ngSwitchCase="notificationType.ATTACHMENT">
                  <p class="mb-2 text-black">
                    {{ notification.Content.UserName }} added attachment on
                    task {{ notification.Content.ProjectKey }} -
                    {{ notification.Content.TaskKey }}
                  </p>
                </div>
                <div *ngSwitchCase="notificationType.UPDATE">
                  <p class="mb-2 text-black">
                    {{ notification.Content.UserName }} updated task
                    {{ notification.Content.ProjectKey }} -
                    {{ notification.Content.TaskKey }}
                  </p>
                </div>
                <div *ngSwitchCase="notificationType.ASSIGNEE">
                  <p class="mb-2 text-black">
                    {{ notification.Content.UserName }} assigned task
                    {{ notification.Content.ProjectKey }} -
                    {{ notification.Content.TaskKey }} to you
                  </p>
                </div>
                <div *ngSwitchCase="notificationType.CHANGESTATUS">
                  <p class="mb-2 text-black">
                    {{ notification.Content.UserName }} moved task from
                    {{ notification.Content.PrevStatusName }} to
                    {{ notification.Content.NewStatusName }}
                  </p>
                </div>
                <div *ngSwitchCase="notificationType.MENTION">
                  <p class="mb-2 text-black">
                    {{ notification.Content.UserName }} mentioned you in task
                    {{ notification.Content.ProjectKey }} -
                    {{ notification.Content.TaskKey }}
                  </p>
                </div>
                <div *ngSwitchCase="notificationType.MENTIONDESC">
                  <p class="mb-2 text-black">
                    {{ notification.Content.UserName }} mentioned you in task
                    description {{ notification.Content.ProjectKey }} -
                    {{ notification.Content.TaskKey }}
                  </p>
                </div>
              </ng-container>
              <div class="d-flex align-items-center mb-1 line-height-1">
                <img
                  [src]="[
                    '/assets/images/types/' +
                      notification.Content.TaskTypeIconID +
                      '.png'
                  ]"
                  width="17px"
                  height="17px"
                  class="mr-2"
                  alt="type-icon"
                />
                <span class="text-primary font-14">
                  {{ notification.Content.ProjectKey }} -
                  {{ notification.Content.TaskKey }}
                  {{ notification.Content.TaskTitle }}
                </span>
              </div>
              <span class="time d-block">{{
                notification.CreatedAt | dateAgo
              }}</span>
            </div>
            <div
              *ngIf="!notification.IsRead"
              (click)="
                markAsRead(notification.Content, notification.ID);
                $event.stopPropagation();
                $event.preventDefault()
              "
              class="radio-item"
            ></div>
          </a>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="text-center py-2 font-14">
  <a class="text-primary cursor-pointer" (click)="openHeaderNotif()">View All</a>
</div>
</div>
