import { BsModalService } from 'ngx-bootstrap/modal';
import { Error } from '@app/enums/errors';
import { GuestService } from '@app/shared/services/guest.service';
import { ToastrService } from 'ngx-toastr';
import { Role } from '@app/enums/role';
import { MentionService } from '@app/shared/services/mention.service';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { Comment } from '@app/models/comment';
import { Subscription } from 'rxjs';
import { AuthService } from '@app/shared/services/auth.service';
import { Component, OnInit, OnDestroy, ViewChild, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { CommentsService } from '@app/shared/services/comments.service';
import { ChangeEvent, CKEditorComponent } from '@ckeditor/ckeditor5-angular/ckeditor.component';
import * as InlineEditor from '@app/ckEditor/build/ckeditor';
import { User } from '@app/models/user';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit, OnDestroy {
  @Input() projectId: string;
  @Input() taskId: string;
  @Input() users: User[];
  @Input() size: number;
  @Output() showComments = new EventEmitter<number>();
  @ViewChild('commentsRef') commentsRef: ElementRef<HTMLDivElement>;
  @ViewChild('editor') editor: CKEditorComponent;
  @ViewChild('editorCreate') editorCreate: CKEditorComponent;
  isGuest = false;
  isComment = true;
  public Editor = InlineEditor;
  currentUserId: string;
  currentUserRole: number;
  currentUserName: string;
  currentAvatarUrl: string;
  subscription: Subscription[] = [];
  sub: Subscription;
  commentChangesSaved = false;
  commentValue = '';
  updatedCommentValue: string;
  comments: Comment[];
  public config = this.mentionService.config;

  editorValue: string;
  editorId: string;
  showEditor = false;
  public onChange( { editor }: ChangeEvent ) {
    this.editorValue = editor.getData();
  }

  get roleType() {
    return Role;
  }

  constructor( private commentService: CommentsService,
               private authService: AuthService,
               private router: Router,
               private mentionService: MentionService,
               private guestService: GuestService,
               private modalService: BsModalService,
               private toast: ToastrService) {
                const newConfig = {
                  ...this.config,
                  placeholder: 'Add comment'
                };
                this.config = newConfig;
                }

  ngOnInit(): void {
    this.isGuest = this.guestService.isGuest;
    this.currentUserId = this.isGuest ? this.guestService.guestUser.UID : this.authService.currentUser.UserUID;
    this.currentUserRole = this.isGuest ? this.guestService.guestUser.Role : this.authService.currentUser.Role;
    this.currentUserName = this.isGuest ? this.guestService.guestUser.Name : this.authService.currentUser.Name;
    this.currentAvatarUrl = this.isGuest ? this.guestService.guestUser.AvatarURL : this.authService.currentUser.AvatarURL;
    this.subscription.push(this.commentService.isCommentSubject.subscribe((res) => {
        this.isComment = res;
  }));
    this.subscription.push(this.commentService.updateCommentSubject.subscribe(() => {
      this.getComments();
  }));
    this.subscription.push(this.router.events
  .pipe(filter((event: RouterEvent) => event instanceof NavigationEnd))
  .subscribe(() => {
    setTimeout(() => {
      this.getComments();
    }, 0);
  }));
    this.getComments();
    this.checkChanges();
}

  sendComment(comment: string) {
    if (!comment) {
     return;
    }
    const commentApi = this.mentionService.replaceMentionNames(comment);
    const commentObj = {
      Comment: commentApi
    };
    comment = '';
    this.commentService
      .createComment(this.projectId, this.taskId, commentObj)
      .subscribe(newComment => {
        this.commentChangesSaved = true;
        sessionStorage.removeItem(`${this.projectId}-${this.taskId}-comment`);
        const createdComment = {
          ...newComment,
          AvatarURL: this.isGuest ? null : this.authService.currentUser.AvatarURL,
          UserName: this.isGuest ? this.guestService.guestUser.Name : this.authService.currentUser.Name
        };
        this.comments = [...this.comments, createdComment];
        this.commentService.showCommentIcon$.next(this.taskId);
        this.commentValue = '';
        this.showEditor = false;
      });
  }

  getComments() {
    this.commentService
      .getComments(this.projectId, this.taskId)
      .subscribe(res => {
        this.comments = res.data;
        if (this.comments.length > 0 && this.isComment) {
          this.showComments.emit(1);
          this.mutationObserver();
        } else {
          this.showComments.emit(2);
        }
        this.checkUnsavedChanges();
      });
  }

  deleteComment(commentId: string) {
    this.commentService
      .deleteComment(this.projectId, this.taskId, commentId)
      .subscribe( () => {
        this.comments = this.comments.filter(item => item.UID !== commentId);
        if (this.comments.length < 1) {
          this.commentService.hideCommentIcon$.next(this.taskId);
        }
      },
      error => {
        if (error.error.ErrorCode === Error.ErrorCodeNotAllowed) {
          this.toast.warning(`Can't delete comment from another user`, 'Warning!');
           }
      }
      );
  }

  checkChanges() {
    this.sub = this.modalService.onHidden.subscribe( (res: string | null) => {
      if (!this.commentChangesSaved && this.commentValue) {
        sessionStorage.setItem(`${this.projectId}-${this.taskId}-comment`,  this.commentValue);
      }
      this.unsubscribe();
    });
  }

  checkUnsavedChanges() {
    const getComment = sessionStorage.getItem(`${this.projectId}-${this.taskId}-comment`);
    if (getComment) {
      this.showEditor = true;
      this.commentValue = getComment;
    }
  }

  unsubscribe() {
    this.sub.unsubscribe();
  }

  editComment(commentId: string) {
    setTimeout(() => {
      this.updatedCommentValue = this.editor.data;
      this.editor.editorInstance.editing.view.focus();
    }, 0);
    this.editorId = commentId;
  }

  updateComments(commentId: string) {
    if (!this.editorValue || this.editorValue === this.updatedCommentValue) {
      this.editorId = null;
      return;
    }

    const commentApi = this.mentionService.replaceMentionNames(this.editorValue);
    const commentObj = {
      Comment: commentApi
    };
    this.commentService.updateComment(this.projectId, this.taskId, commentId, commentObj).subscribe( () => {
      setTimeout(() => {
        this.editorId = null;
      }, 300);
    });
  }

  trackByFn(index: number, item: Comment) {
    return item.UID;
  }


  mutationObserver() {
    const commentsContent =  this.commentsRef.nativeElement;
    const options = {
      childList: true
     };
    const observer = new MutationObserver( () => {
      commentsContent.scrollTop = commentsContent.scrollHeight;
     });
    observer.observe(this.commentsRef.nativeElement, options);
  }

  openCommentEditor() {
    this.showEditor = true;
    this.commentChangesSaved = false;
    const commentsContent =  this.commentsRef.nativeElement;
    setTimeout(() => {
      this.editorCreate.editorInstance.editing.view.focus();
      commentsContent.scrollTop = commentsContent.scrollHeight;
    }, 0);
  }

  closeCommentEditor() {
    this.showEditor = false;
    this.commentChangesSaved = true;
    sessionStorage.removeItem(`${this.projectId}-${this.taskId}-comment`);
  }

  ngOnDestroy() {
    this.subscription.forEach(subscription => subscription.unsubscribe());
  }
}
