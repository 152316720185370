import { Role } from '@app/enums/role';
import { GuestService } from '@app/shared/services/guest.service';
import { Content } from '@app/models/notification';
import { FeedbackComponent } from '@app/layout/shared/feedback/feedback.component';
import { ProjectService } from '@app/shared/services/project.service';
import { Project } from '@app/models/project';
import { TaskService } from '@app/shared/services/task.service';
import { Notification, NotificationGroups } from '@app/models/notification';
import { NotificationsService } from '@app/shared/services/notifications.service';
import { takeUntil, switchMap, repeatWhen, retryWhen, delay } from 'rxjs/operators';
import { UserService } from '@app/shared/services/user.service';
import { SearchComponent } from '@app/layout/shared/search/search.component';
import { ProfileComponent } from '@app/layout/shared/profile/profile.component';
import { AuthService } from '@app/shared/services/auth.service';
import {
  Component,
  OnInit,
  OnDestroy,
  HostListener,
  ViewChild,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationType } from '@app/enums/notifications';
import { Subject, timer, Subscription, EMPTY } from 'rxjs';
import { TaskCreateComponent } from '@app/layout/tasks/task-create/task-create.component';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown/bs-dropdown.directive';
import { Jarvis } from '@app/enums/jarvis';
import { ReminderData } from '@app/models/reminder';
import { BsModalService } from 'ngx-bootstrap/modal';
import { differenceInDays, format } from 'date-fns';
import { ProjectDetailsComponent } from '@app/layout/projects/project-details/project-details.component';
import { User } from '@app/models/user';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChild('dropdown') notifDropdown: BsDropdownDirective;
  width = window.innerWidth;
  avatar: string;
  loading = false;
  isRead = false;
  isCollapsed = false;
  showTaskMenu = false;
  showProjects = false;
  showCreateBtn = false;
  notificationsEnd = false;
  reminderData: ReminderData;
  projectUID: string;
  currentName: string;
  currentRole: number;
  subscription: Subscription[] = [];
  userId: string;
  start = 0;
  private readonly stopTimer = new Subject<void>();
  private readonly startTimer = new Subject<void>();
  destroy$ = new Subject<null>();
  destroyMenu$ = new Subject<null>();
  notifications: Notification[] = [];
  notifLength: number;
  unreadNotif: boolean;
  time: string;
  projects: Project[];
  projectId: string;
  isDragged: boolean;
  isGuest = false;
  isPremium: boolean;

  notificationGroups: NotificationGroups[];
  notificationDays = [
    {
      name: 'Today',
      start: -1,
      end: 1
    },
    {
      name: 'Yesterday',
      start: 0,
      end: 2
    },
    {
      name: 'Older',
      start: 1,
      end: 14
    }
  ];
  @HostListener('window:resize', ['$event'])
  onResize(event) {
   this.width = event.target.innerWidth;
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    if (this.authService.currentUser) {
      this.taskService.isDraggedSubject.next(false);
      this.stopTimer.next();
      this.timerNotifications(50000);
    }
    if (this.isGuest) {
      this.startTimer.next();
    }
  }

  @HostListener('window:blur', ['$event'])
  onBlur(event: any): void {
    if (this.authService.currentUser) {
      const time = 60*1000*5
      this.stopTimer.next();
      this.timerNotifications(time);
      this.taskService.isDraggedSubject.next(true);
       }
     if (this.isGuest) {
         this.stopTimer.next();
       }
  }

  get notificationType() {
    return NotificationType;
  }

  get roleType() {
    return Role;
  }

  constructor(private router: Router,
              private route: ActivatedRoute,
              private taskService: TaskService,
              private projectService: ProjectService,
              private authService: AuthService,
              private userService: UserService,
              private modalService: BsModalService,
              private guestService: GuestService,
              private notificationsService: NotificationsService) {
              }

  ngOnInit() {
    this.isGuest = this.guestService.isGuest;
    this.checkNotificationParams();
    this.notificationsService.openNotif$.pipe(
      takeUntil(this.destroyMenu$)
    ).subscribe( () => {
      setTimeout(() => {
        this.notifDropdown.show();
      });
    });
    this.notificationsService.readNotif$.pipe(
      takeUntil(this.destroyMenu$)
    ).subscribe( (res) => {
      this.markAsRead(res.notification, res.id, true);
    });
    this.notificationsService.readAllNotif$.pipe(
      takeUntil(this.destroyMenu$)
    ).subscribe( (event) => {
      this.readAllNotif(event);
    });
    this.taskService.disableTimer.pipe(
      takeUntil(this.destroyMenu$)
    ).subscribe( res => {
      setTimeout(() => {
      this.isDragged = res;
      }, 0);
    });
    this.taskService.taskMenuSubject.pipe(
      takeUntil(this.destroyMenu$)
    ).subscribe( res => {
      setTimeout(() => {
      this.showTaskMenu = res;
      }, 0);
    });
    this.taskService.taskCreateBtnSubject.pipe(
      takeUntil(this.destroyMenu$)
    ).subscribe( res => {
      setTimeout(() => {
      this.showCreateBtn = res;
      }, 0);
    });
    this.projectService.projectMenuSubject.pipe(
      takeUntil(this.destroyMenu$)
    ).subscribe( res => {
      setTimeout(() => {
      this.showProjects = res;
      }, 0);
    });
    this.taskService.projectDataSubject.pipe(
      takeUntil(this.destroyMenu$)
    ).subscribe( res => {
      this.projectUID = res;
    });
    this.userService.userStorageUpdate.pipe(takeUntil(this.destroyMenu$)).subscribe(() => {
      setTimeout(() => {
        this.avatar = this.authService.currentUser.AvatarURL;
      }, 0);
    });
    this.checkIsGuest(this.isGuest);
    this.getNotifications();
    this.timerNotifications(50000);
    this.getProjects();
    this.projectService.projects$.pipe(
      takeUntil(this.destroyMenu$)
    ).subscribe( res => {
      this.projects = res;
    });
  }

  checkIsGuest(isGuest: boolean) {
     if (isGuest) {
        this.currentRole = this.guestService.guestUser.Role;
        this.avatar = this.guestService.guestUser.AvatarURL;
        this.currentName = this.guestService.guestUser.Name;
        this.userId = this.guestService.guestUser.UserUID;
        this.guestRefreshTask();
     } else {
      this.avatar = this.authService.currentUser.AvatarURL;
      this.currentName = this.authService.currentUser.Name;
      this.userId = this.authService.currentUser.UserUID;
      this.currentRole = this.authService.currentUser.Role;
     }
  }

  checkNotificationParams() {
    this.route.queryParamMap.subscribe( params => {
      if (params.get('notification')) {
        setTimeout(() => {
          this.notifDropdown.show();
        });
      }
    });
  }


  getProjects() {
    if (this.router.url !== '/project') {
      this.projectService.getProjects().subscribe ( res => {
        this.projects = res.data;
        this.loading = false;
      });
    }
  }

  timerNotifications(time: number) {
    if (!this.isGuest) {
    this.subscription.push(timer(100, time).pipe(
       switchMap(() => this.notificationsService.getNotifications(this.userId, 0)),
       takeUntil(this.stopTimer),
       retryWhen((errors$) => {
        this.taskService.isDraggedSubject.next(true);
        return errors$.pipe(
           delay(50000)
         );
        }),
     )
     .subscribe( (res) =>  {
       this.notifLength = JSON.parse(localStorage.getItem(`notifLength-${this.userId}`));
       res.meta.Membership === Jarvis.BASIC ? this.isPremium = false : this.isPremium = true;
       this.changeIcon();
       this.updateUserStorage(res.meta.Role);
       const appVersion = localStorage.getItem('appVersion');
       if (appVersion !== res.meta.AppVersion) {
        window.location.reload();
      }
       const currentTimeZone = localStorage.getItem('timeZoneOffSet');
       const patternOffSet = `xxx`;
       const timeZoneOffset = format(new Date(), patternOffSet);
       if (currentTimeZone !== timeZoneOffset) {
         const pattern = `yyyy-MM-dd'T'HH:mm:ssxxx`;
         const outputTime = format(new Date(), pattern);
         this.authService.updateTimeZone(outputTime).subscribe( () => {
           localStorage.setItem('timeZoneOffSet', timeZoneOffset);
         });
      }
       if (this.router.url.includes('project/') && !this.isDragged) {
         this.taskService.isDraggedSubject.next(false);
         this.taskService.getTaskSubject.next();
      } else {
        this.taskService.isDraggedSubject.next(true);
      }
       let UpdatedArray = res.data;
       const ids = this.notifications.map( c => c.ID);
       UpdatedArray = UpdatedArray.filter(({ID}) => !ids.includes(ID));
       this.notifications = [...UpdatedArray, ...this.notifications];
       this.createNotificationGroups();
       this.unreadNotif = res.meta.Unread;
       this.playSound(res.meta.Count);
       this.setNotifLengthToStorage(res.meta.Count);
    }
    ));
  }
  }

  changeIcon() {
    const favIcon: HTMLLinkElement = document.querySelector('#appIcon');
    if (this.isPremium) {
      favIcon.href = '/assets/images/logo-gold.png';
    } else {
      favIcon.href = '/assets/images/logo-image.png';
    }
  }

  guestRefreshTask() {

    this.subscription.push(timer(100, 50000).pipe(
      switchMap(() => {
        this.taskService.getTaskSubject.next();
        return EMPTY;
      }),
      takeUntil(this.stopTimer),
      repeatWhen(() => this.startTimer),
    ).subscribe());
  }

  logout() {
    this.authService.logout(false);
  }

  openModal() {
    this.modalService.show(ProfileComponent, {
      class: 'modal-lg custom-modal modal-dialog-scrollable'
    });
  }

  openFeedbackModal() {
    this.modalService.show(FeedbackComponent, {
      class: 'modal-lg custom-modal modal-dialog-scrollable'
    });
  }

  getNotifications() {
    this.loading = true;
    this.notificationsService.getNotifications(this.userId, this.start).subscribe( res => {
      this.notifications = [
        ...this.notifications,
        ...res.data
      ];
      localStorage.setItem('appVersion', res.meta.AppVersion);
      this.start = this.start + 21;
      this.loading = false;
      this.createNotificationGroups();
      this.handleNoMoreNotif(res.data);
      this.unreadNotif = res.meta.Unread;
    });
  }

  readAllNotif(event: Event) {
    event.stopPropagation();
    this.notificationsService.readAllNotif(this.userId, this.notifications).subscribe(() => {
      this.unreadNotif = false;
    });
  }

  markAsRead(notification: Content, id: number, routerActive: boolean) {
    if (routerActive) {
      if (notification.Type === NotificationType.REMINDER) {
        this.reminderData = {
          showReminder: true,
          projectKey: notification.ProjectKey,
          taskKey: notification.TaskKey,
          taskStatusID: notification.TaskStatusID,
          taskIconID: notification.TaskTypeIconID,
          taskTitle: notification.TaskTitle,
          progress: notification.Progress ? notification.Progress : 0,
          firstReminder: notification.FirstReminder,
          isStatusReminder:  notification.IsStatusReminder,
          DayFrequency: notification.DayFrequency,
          doneStatusID: notification.DoneStatusID,
          doneStatusName: notification.DoneStatusName,
          nextStatusID: notification.NextStatusID,
          nextStatusName: notification.NextStatusName
        };
     } else {
       this.router.navigate([  '/task',
       notification.ProjectKey +
         '-' +
         notification.TaskKey]);
     }
  }
    this.notificationsService.markAsRead(this.userId, this.notifications, notification, id).subscribe((res) => {
      if (res) {
        this.unreadNotif = res?.Unread;
      }
      if (notification.Type === NotificationType.REMINDER) {
        this.notifications = this.notifications.filter(({ID}) =>  ID !== id);
        this.createNotificationGroups();
      }
  });
}

  sumUnreadNotif(): number {
    let totalNotif = 0;
    this.notificationGroups.map(item => {
    const unread = item.data.reduce((total, currentItem) => {
          if (currentItem.IsRead === false) {
            total++;
          }
          return total;
      }, 0);
    totalNotif += unread;
    });
    return totalNotif;

  }

  createNotificationGroups() {
  const duplicates = this.notifications.filter((item, index, array) => {
    return index !== array.findIndex(t => {
      return t.Content.TaskKey === item.Content.TaskKey &&
             t.Content.UserName === item.Content.UserName &&
             t.IsRead === item.IsRead;
    });
 });
  this.notificationGroups =  this.notificationDays.map(({ name, start, end }) => (
      {
        name,
        data: this.notifications.filter((item, index, self) => {
          return index === self.findIndex((t) => {
            return t.Content.TaskKey === item.Content.TaskKey &&
            t.IsRead === item.IsRead &&
            t.Content.UserName === item.Content.UserName
            &&  differenceInDays(new Date(), new Date(t.CreatedAt)) > start &&
            differenceInDays(new Date(), new Date(t.CreatedAt)) < end;
          });
        }).map(thing => {
          duplicates.forEach(t => {
               if (thing.Content.TaskKey === t.Content.TaskKey &&
                  thing.Content.UserName === t.Content.UserName &&
                  thing.Content.Type !== NotificationType.REMINDER &&
                  thing.Content.Type !== NotificationType.REMINDERNOTMODIFIED &&
                  thing.IsRead === t.IsRead) {
                     if (thing.Content.Type !== t.Content.Type) {
                        thing.Content.Type = NotificationType.UPDATE;
                        return thing;
                     } else {
                       return thing;
                     }
                  }
            });
          return thing;
        }),
      }),
     );
  }

  openSearch() {
    this.modalService.show(SearchComponent, {
      class: 'modal-lg custom-modal modal-dialog-scrollable'
    });
  }

  openCreateTask() {
    this.modalService.show(TaskCreateComponent, {
      class: 'modal-lg custom-modal modal-dialog-scrollable',
      ignoreBackdropClick: true,
      keyboard: false,
      initialState: {projectUID: this.projectUID}
    });
  }

  openProjectDetails(project: Project) {
    this.modalService.show(ProjectDetailsComponent, {
      animated: true,
      class: 'modal-lg custom-modal custom-modal-bg modal-dialog-scrollable',
      initialState: {project}
     });
  }

  handleNoMoreNotif(data: Notification[]) {
    if (data.length < 21) {
      this.notificationsEnd = true;
    } else {
      this.notificationsEnd = false;
    }
  }

  onScroll() {
    if (!this.notificationsEnd) {
      this.getNotifications();
    }
  }

  changeProject(projectUID: string) {
    if (this.projectUID !== projectUID) {
      this.taskService.projectCarousel$.next(projectUID);
    }
  }


  updateUserStorage(userRole: number) {
    const user = JSON.parse(localStorage.getItem('currentUser')) as User;
    if (user.Role !== userRole) {
       user.Role = userRole;
       localStorage.removeItem('currentUser');
       localStorage.setItem('currentUser', JSON.stringify(user));
    }
  }

  playSound(notifLength: number) {
    if (!this.notifLength && this.unreadNotif) {
      const audio = new Audio('./assets/images/ping.mp3');
      audio.play();
      return;
    }
    if (this.notifLength < notifLength) {
    const audio = new Audio('./assets/images/ping.mp3');
      audio.play();
    }
  }

  setNotifLengthToStorage(notificationsLength: number) {
    localStorage.setItem(`notifLength-${this.userId}`, JSON.stringify(notificationsLength));
  }


  ngOnDestroy() {
    this.destroyMenu$.next();
    this.destroy$.next();
    this.subscription.forEach(subscription => subscription.unsubscribe());
  }
}
