<div class="comments-content scroll scroll-auto" #commentsRef [ngStyle] ="{'height': 'calc(100vh - ' + size + 'rem)'}" id="comments-content">
<div
    class="task-comments-box mt-3"
    *ngFor="let comment of comments; trackBy: trackByFn"
  >
    <div class="task-comments-img mr-3">
      <app-no-avatar
        [avatarURL]= "isGuest ? null : comment.AvatarURL"
        [width]="40"
        [height]="40"
        [userName]="comment.UserName"
      ></app-no-avatar>
    </div>
    <div class="task-comments-box-content w-100">
      <p class="comment-date d-sm-none d-block">{{ comment.CreatedAt | date: 'h:mm:a dd/MM/y'}}</p>
      <div class="d-flex justify-content-between">
      <p >{{ comment.UserName }}</p>
      <p class="comment-date d-sm-block d-none">{{ comment.CreatedAt | date: 'h:mm:a dd/MM/y'}}</p>
     </div>
      <div *ngIf="editorId === comment.UID">
        <ckeditor
          #editor
          (change)="onChange($event)"
          [editor]="Editor"
          [config]="config"
          [data]="comment.Comment | mentionReplace"
        ></ckeditor>
        <ul class="list-inline mt-2">
          <li class="list-inline-item">
            <button
              type="button"
              class="btn btn-primary py-2 px-4"
              (click)="updateComments(comment.UID)"
            >
              Save
            </button>
          </li>
          <li class="list-inline-item">
            <button
              class="cursor-pointer border-none btn btn-outline-secondary"
              (click)="editorId = null"
            >
              Cancel
            </button>
          </li>
        </ul>
      </div>
      <div *ngIf="editorId !== comment.UID" class="commented-text">
        <p
          class="mb-0 font-14" *ngIf ="comments"
          [innerHTML]="comment.Comment.trim() | mentionReplace"
        ></p>
        <span class="edited-comment" *ngIf = comment.IsEdited>(edited)</span>
      </div>
    </div>
    <ng-container *ngIf="editorId !== comment.UID">
      <div class="dropdown h-25" *ngIf = "currentUserId === comment.UserUID || currentUserRole !== roleType.Member">
        <span dropdown>
          <a href id="basic-link" dropdownToggle (click)="false" class="dropdown-comment"
             aria-controls="basic-link-dropdown">
             <img src="/assets/images/dots.png" alt="dots" />
          </a>
          <ul id="basic-link-dropdown" *dropdownMenu class="dropdown-menu dropdown-menu-right"
              role="menu" aria-labelledby="basic-link">
            <li>
              <a *ngIf="currentUserId === comment.UserUID"
              (click)="editComment(comment.UID)"
              class="dropdown-item cursor-pointer"
            >
              Edit comment
            </a>
            <a *ngIf="currentUserId === comment.UserUID || currentUserRole === roleType.SuperAdmin || currentUserRole === roleType.Admin"
              (click)="deleteComment(comment.UID)"
              class="dropdown-item cursor-pointer"
            >
              Delete comment
            </a>
            </li>
          </ul>
        </span>
      </div>
    </ng-container>
  </div>
  <div class="w-100 mt-4 pr-sm-5 d-flex">
    <app-no-avatar
    [avatarURL]="currentAvatarUrl"
    [userName] = "currentUserName"
    [width]="44"
    [height]="44"
    class="mr-3"
  ></app-no-avatar>
    <input
      *ngIf="!showEditor"
      type="text"
      (focus) = "openCommentEditor()"
      class="form-control"
      placeholder="Add comment"
    />
    <div *ngIf="showEditor" class="w-100">
      <ckeditor
        #editorCreate
        [editor]="Editor"
        [config]="config"
        [(ngModel)]="commentValue"
      ></ckeditor>
      <ul class="list-inline mt-3">
        <li class="list-inline-item">
          <button
            type="button"
            class="btn btn-primary"
            (click)="sendComment(commentValue)"
          >
            Save
          </button>
        </li>
        <li class="list-inline-item">
          <button
            class="cursor-pointer border-none btn btn-outline-secondary"
            (click)="closeCommentEditor()"
          >
            Cancel
          </button>
        </li>
      </ul>
    </div>
  </div>
</div>

