export enum Error {
  ErrorCodeDefault = 0,
  ErrorCodeInvalidJSONBody = 3,
  ErrorCodeAPINotFound = 4,
  ErrorCodeXTenantEmpty = 5,
  ErrorCodeDatabaseReferentialIntegrity = 10,
  ErrorCodeDefaultTypes = 30,
  ErrorCodeDatabaseUniqueKeyViolation  = 11,
  ErrorCodeInvalidTextRepresentation = 12,
  ErrorCodeWrongCredentials = 20,
  ErrorCodeEmailNotConfirmed = 22,
  ErrorCodeLeadExist = 23,
  ErrorCodeNotFound = 30,
  ErrorCodeOneAdminsitrator = 32,
  ErrorCodeInviteUserExist = 33,
  ErrorCodeSomethingWrong = 35,
  ErrorCodeUserNotExist = 37,
  ErrorCodeValidation = 40,
  ErrorCodeUserNameExists  = 43,
  ErrorCodeNotAllowed  = 44,
  ProjectNameExist = 46,
  ProjectKeyExist = 47,
  ErrorCodeNotInvited = 49,
  ErrorCodeNothingToConfirm = 54.

}


export enum Status {
  ErrorStatusAuthFailed = 424,
  ErrorStatusForbidden = 403,
  ErrorStatusNotFound = 404,
  ErrorStatusServerError = 500
}
