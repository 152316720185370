<div [formGroup]="projectForm" class="scroll scroll-auto" [ngClass] ="{'shopify-scroll': isEditMode}">
  <div formArrayName="Conditions" *ngFor="let item of t.controls; let i = index">
    <div [formGroupName]="i">
      <div class="d-sm-flex mb-2 position-relative pr-2">
        <div class="flex-product">
      <label class="label">Choose one</label>
      <ng-select
        formControlName="ProductID"
        [items]="(shopifyProducts$ | async)?.Products"
        bindValue="ID"
        (change) = "changeProduct($event, i)"
        appendTo="body"
        [clearable] ="false"
        bindLabel="FullName"
        placeholder="Choose"
        [ngClass] = "{'is-invalid': validate(i, 'ProductID') && submitted }"
      >
      </ng-select>
      <div *ngIf="validate(i, 'ProductID') && submitted" class="invalid-feedback">
        Field is required
      </div>
    </div>
    <div class="flex-inventory">
      <label class="label">In inventory</label>
      <ng-select
      formControlName="ConditionID"
      [items]="inventory"
      bindValue="ID"
      appendTo="body"
      [clearable] ="false"
      bindLabel="Name"
      placeholder="Choose"
      [ngClass] = "{'is-invalid': validate(i, 'ConditionID') && submitted }"
    >
    </ng-select>
    <div *ngIf="validate(i, 'ConditionID') && submitted" class="invalid-feedback">
      Field is required
    </div>
    </div>
    <div class="flex-quantity">
      <label class="label">Quantity</label>
      <input type="number" formControlName="Quantity" class="form-control" placeholder="Quantity"
       [ngClass] = "{'is-invalid': validate(i, 'Quantity') && submitted }"
      />
      <div *ngIf="validate(i, 'Quantity') && submitted" class="invalid-feedback">
        Field is required
      </div>
    </div>
    <input hidden formControlName="Title"/>
    <input hidden formControlName="VariantID"/>
    <a type="button" class="delete-condition mt-3 text-gray-600 mr-2" (click)="removeItem(i)">x</a>
    </div>
  </div>
</div>
<a type="button" class="btn btn-shopify mt-3" (click)="addItem()">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.758"
    height="15.758"
    viewBox="0 0 14.758 14.758"
  >
    <defs>
      <style>
        .cls-x {
          fill: #ffffff;
          stroke: #ffffff;
          stroke-linecap: round;
          stroke-width: 2px;
        }
      </style>
    </defs>
    <g
      id="Group_72"
      data-name="Group 72"
      transform="translate(-771.25 -280.25)"
    >
      <g id="Group_48" data-name="Group 48" transform="translate(772 281)">
        <path
          id="Line_8"
          d="M0 0v13.258"
          class="cls-x"
          data-name="Line 8"
          transform="translate(6.629)"
        />
        <path
          id="Line_9"
          d="M0 0h13.258"
          class="cls-x"
          data-name="Line 9"
          transform="translate(0 6.629)"
        />
      </g>
    </g>
  </svg>
</a>
  <div class="shopify-actions mt-3" *ngIf ="isEditMode">
    <app-spinner-button [options]="spinnerButtonOptions" (click) ="saveConditions()"> </app-spinner-button>
     <a class="btn btn-cancel text-gray-600" (click) = "resetCondition()"> Cancel</a>
  </div>
</div>
