import { Pipe, PipeTransform } from '@angular/core';
import { Task } from '@app/models/task';

@Pipe({
  name: 'sortTasks'
})
export class SortTasksPipe implements PipeTransform {

  transform(value: Task[]): Task[] {
    return value.sort((a, b) => {
      const orderA = a.Order;
      const orderB = b.Order;
      if (orderA < orderB) {
        return -1;
      }
      if (orderA > orderB) {
        return 1;
      }
      return 0;
    });
  }

}
