import { AddToProjectComponent } from '../layout/shared/add-to-project/add-to-project.component';
import { ChangeColorDirective } from './directive/changeColor.directive';
import { DelayDragDirective } from './directive/delayDrag.directive';
import { ProjectsSlicePipe } from './pipes/projects-slice.pipe';
import { CommonModule } from '@angular/common';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { FocusDirective } from './directive/focus.directive';
import { NgModule } from '@angular/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalModule } from 'ngx-bootstrap/modal';
import { HttpClientModule } from '@angular/common/http';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { ToastrModule } from 'ngx-toastr';
import { NgSelectModule } from '@ng-select/ng-select';
import { LoadingImageSpinnerComponent } from './loading-image-spinner/loading-image-spinner.component';
import { ShortenPipe } from './pipes/shorten.pipe';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { UserAcronymPipe } from './pipes/user-acronym.pipe';
import { DropdownDirective } from './directive/dropdown.directive';
import { DisableControlDirective } from './directive/disable-control.directive';
import { FilterPipe } from './pipes/filter.pipe';
import { NoAvatarComponent } from '@app/layout/shared/no-avatar/no-avatar.component';
import { ArchivePipe } from './pipes/archive.pipe';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { SortTasksPipe } from './pipes/sort-tasks.pipe';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { LoadingNotifSpinnerComponent } from './loading-notif-spinner/loading-notif-spinner.component';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { DragDropDirective } from './directive/drag-drop.directive';
import { NotFoundComponent } from '@app/layout/shared/not-found/not-found.component';
import { MentionReplacePipe } from './pipes/mention-replace.pipe';
import { LoadingDNDSpinnerComponent } from './loading-dnd-spinner/loading-dnd-spinner.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { VideoRecordComponent } from './video-record/video-record.component';
import { ShareableLinkComponent } from '@app/layout/shared/shareable-link/shareable-link.component';
import { VideoRecordModalComponent } from './video-record-modal/video-record-modal.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { InlineEditComponent } from '@app/layout/shared/inline-edit/inline-edit.component';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { SpinnerButtonComponent } from './spinner-button/spinner-button.component';
import { RangeSliderComponent } from '@app/layout/shared/range-slider/range-slider.component';
import { DateTransformPipe } from './pipes/date-transform.pipe';
import { ChangeDateColorDirective } from './directive/changeDateColor.directive';
import { CountProgressPipe } from './pipes/count-progress.pipe';
import { CurrentUserPipe } from './pipes/current-user.pipe';
import { ColorPickerModule } from 'ngx-color-picker';
import { FilterTemplatesPipe } from './pipes/filter-templates.pipe';
import { projectsSortPipe } from './pipes/projects-sort.pipe';
import { SortTasksListPipe } from './pipes/sort-tasks-list.pipe';
import { TodaySortPipe } from './pipes/today-sort.pipe';
import { InviteUserFooterComponent } from '@app/layout/shared/invite-user-footer/invite-user-footer.component';
import { FilterTasksPipe } from './pipes/filter-tasks.pipe';
import { SvgIconsModule } from './svg-icons.module';
import { SortCurrentUserAsFirstPipe } from './pipes/sort-current-user-as-first.pipe';


export function provideSwal() {
  return import('sweetalert2').then(({ default: Swal }) => Swal.mixin({
      heightAuto: false,
      buttonsStyling: false,
      showCancelButton: true,
      confirmButtonText: 'Yes, delete',
      cancelButtonText: 'No, cancel',
      focusCancel: true,
      customClass: {
        container: 'sweet-alert',
        popup: 'sweet-alert-popup',
        title: 'sweet-alert-title',
        content: 'sweet-alert-content',
        confirmButton: 'btn btn-primary sweet-alert-confirm',
        cancelButton: 'btn sweet-alert-cancel'
      }
  }));
}


@NgModule({
  declarations: [
   FocusDirective,
   LoadingSpinnerComponent,
   LoadingImageSpinnerComponent,
   ShortenPipe,
   UserAcronymPipe,
   DropdownDirective,
   DisableControlDirective,
   FilterPipe,
   NoAvatarComponent,
   ArchivePipe,
   SortTasksPipe,
   LoadingNotifSpinnerComponent,
   DateAgoPipe,
   DragDropDirective,
   DelayDragDirective,
   ChangeColorDirective,
   ChangeDateColorDirective,
   NotFoundComponent,
   MentionReplacePipe,
   ProjectsSlicePipe,
   LoadingDNDSpinnerComponent,
   VideoRecordComponent,
   ShareableLinkComponent,
   VideoRecordModalComponent,
   InlineEditComponent,
   SpinnerButtonComponent,
   RangeSliderComponent,
   DateTransformPipe,
   CountProgressPipe,
   CurrentUserPipe,
   FilterTemplatesPipe,
   projectsSortPipe,
   SortTasksListPipe,
   TodaySortPipe,
   InviteUserFooterComponent,
   FilterTasksPipe,
   AddToProjectComponent,
   SortCurrentUserAsFirstPipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    HttpClientModule,
    SweetAlert2Module.forRoot({provideSwal}),
    ToastrModule.forRoot(),
    AccordionModule.forRoot(),
    CKEditorModule,
    InfiniteScrollModule,
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ProgressbarModule.forRoot(),
    CollapseModule.forRoot(),
    ModalModule.forRoot(),
    ColorPickerModule,
    SvgIconsModule
  ],
  exports: [
    FocusDirective,
    DropdownDirective,
    DisableControlDirective,
    LoadingSpinnerComponent,
    LoadingImageSpinnerComponent,
    CommonModule,
    NoAvatarComponent,
    FormsModule,
    ReactiveFormsModule,
    ShortenPipe,
    FilterPipe,
    UserAcronymPipe,
    ArchivePipe,
    SortTasksPipe,
    HttpClientModule,
    SweetAlert2Module,
    NgSelectModule,
    ToastrModule,
    AccordionModule,
    CKEditorModule,
    InfiniteScrollModule,
    LoadingNotifSpinnerComponent,
    LoadingDNDSpinnerComponent,
    DateAgoPipe,
    DragDropDirective,
    NotFoundComponent,
    MentionReplacePipe,
    ProjectsSlicePipe,
    BsDropdownModule,
    VideoRecordComponent,
    ShareableLinkComponent,
    DelayDragDirective,
    ChangeColorDirective,
    ChangeDateColorDirective,
    BsDatepickerModule,
    ProgressbarModule,
    InlineEditComponent,
    CollapseModule,
    ModalModule,
    TooltipModule,
    SpinnerButtonComponent,
    RangeSliderComponent,
    DateTransformPipe,
    CountProgressPipe,
    CurrentUserPipe,
    ColorPickerModule,
    FilterTemplatesPipe,
    projectsSortPipe,
    SortTasksListPipe,
    TodaySortPipe,
    InviteUserFooterComponent,
    FilterTasksPipe,
    AddToProjectComponent,
    SvgIconsModule,
    SortCurrentUserAsFirstPipe
  ],
})
export class SharedModule {}
