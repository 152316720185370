import { Directive, HostListener, Input, HostBinding } from '@angular/core';

// tslint:disable-next-line: directive-selector
@Directive({ selector: `[delayDrag]` })
export class DelayDragDirective {
  @Input('delayDrag') public dragDelay: string | number;

  private touchTimeout: NodeJS.Timeout;

  @HostBinding('class.delay-drag-lifted')
  private draggable = false;

  get delay() {
    return (typeof this.dragDelay === 'number')
      ? this.dragDelay
      : 200;
  }

  @HostListener('touchstart', ['$event'])
  private onTouchStart(evt: Event): void {
    this.touchTimeout = setTimeout(() => {
      this.draggable = true;
    }, this.delay);
  }

  @HostListener('touchmove', ['$event'])
  private onTouchMove(evt: Event): void {
    if (!this.draggable) {
      evt.stopPropagation();
      clearTimeout(this.touchTimeout);
    }
  }

  @HostListener('touchend', ['$event'])
  private onTouchEnd(evt: Event): void {
    clearTimeout(this.touchTimeout);
    this.draggable = false;
  }
}
