import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component, AfterViewInit, ElementRef, ViewChild, OnInit } from '@angular/core';
import { UploadFilesService } from '@app/shared/services/upload-files.service';
import * as RecordRTC from 'recordrtc';

@Component({
  selector: 'app-video-record-modal',
  templateUrl: './video-record-modal.component.html',
  styleUrls: ['./video-record-modal.component.scss']
})
export class VideoRecordModalComponent implements OnInit, AfterViewInit {
  isPreviewVideo = false;
  isRecordingStoped = false;
  videoDuration = '00:00';
  countDownDuration = '3';
  timerInterval: NodeJS.Timeout;
  isRecording = false;
  recordedFile: File;
  private stream: MediaStream;
  // tslint:disable-next-line: no-any
  private recordRTC: any;
  @ViewChild('video') video: ElementRef<HTMLVideoElement>;
  @ViewChild('videoPreview') videoPreview: ElementRef<HTMLVideoElement>;

  constructor(private fileUpload: UploadFilesService,  private activeModal: BsModalRef ) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    const video: HTMLVideoElement = this.video.nativeElement;
    video.muted = false;
    video.controls = true;
    video.autoplay = false;
    this.successCallback(this.stream);
  }

  toggleControls() {
    const video: HTMLVideoElement = this.video.nativeElement;
    const videoPreview: HTMLVideoElement = this.videoPreview.nativeElement;
    video.muted = !video.muted;
    video.controls = !video.controls;
    video.autoplay = !video.autoplay;
    videoPreview.currentTime = 9999999999;
    setTimeout(() => {
      videoPreview.currentTime = 0;
    }, 300);
  }

  successCallback(stream: MediaStream) {
    this.countDownTimer();
    const options = {
      mimeType: 'video/webm\;codecs=vp9',
      bitsPerSecond: 128000,
      disableLogs: true,
      timeSlice: 1000
    };
    this.stream = stream;
    this.recordRTC = RecordRTC(stream, options);
    setTimeout(() => {
    this.recordRTC.startRecording();
    this.recordingTimer();
  }, 3000);
    this.recordDuration();
    const video: HTMLVideoElement = this.video.nativeElement;
    video.srcObject = stream;
    this.toggleControls();
    stream.getVideoTracks()[0].onended = (item) => {
      this.stopRecording();
    };
}

  countDownTimer() {
    const countDownDate = new Date().getTime() + 3000;
    const x = setInterval(() => {
      const now =  new Date().getTime();
      const distance = countDownDate - now;
      const seconds = Math.round((distance % (1000 * 60)) / 1000) as string | number;
      this.countDownDuration = `${seconds}`;
      if (distance < 0) {
        clearInterval(x);
        this.isRecording = true;
      }
    }, 1000);
  }

  recordingTimer() {
    const thirtySeconds  = 1000 * 30;
    const countDownDate = new Date().getTime();
    this.timerInterval = setInterval(() => {
      const now =  new Date().getTime();
      const distance = now - countDownDate;
      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)) as string | number;
      let seconds = Math.floor((distance % (1000 * 60)) / 1000) as string | number;
      minutes = minutes < 10 ? '0' + minutes : minutes;
      seconds = seconds < 10 ? '0' + seconds : seconds;
      this.videoDuration = `${minutes}:${seconds}`;
      if (distance >= thirtySeconds) {
        clearInterval(this.timerInterval);
      }
    }, 1000);
  }

  processVideo() {
    const recordRTC = this.recordRTC;
    this.toggleControls();
    const recordedBlob = recordRTC.getBlob();
    this.recordedFile  = new File([recordedBlob], 'video.mp4', {type: 'video/mp4'});
    recordRTC.getDataURL( (dataURL: string) => {
      setTimeout(() => {
        this.videoPreview.nativeElement.src = dataURL;
        this.isRecordingStoped = true;
      });
    });
  }

 recordDuration() {
   const thirtySeconds  = 1000 * 30;
   this.recordRTC.setRecordingDuration(thirtySeconds).onRecordingStopped(this.stopRecording.bind(this));
 }

  stopRecording() {
    this.isPreviewVideo = true;
    const recordRTC = this.recordRTC;
    recordRTC.stopRecording(this.processVideo.bind(this));
    const stream = this.stream;
    stream.getAudioTracks().forEach(track => track.stop());
    stream.getVideoTracks().forEach(track => track.stop());
    clearInterval(this.timerInterval);
  }

  uploadRecordedFile() {
    this.fileUpload.recordVideoUpload.next(this.recordedFile);
    this.activeModal.hide();
  }

  CancelRecording() {
    this.activeModal.hide();
    this.recordRTC.reset();
  }

  download() {
    this.recordRTC.save('video.mp4');
  }
}
