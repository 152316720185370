import { format } from 'date-fns';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown/bs-dropdown.directive';
import { RecursService } from '@app/shared/services/recurs.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { TransformDate } from '@app/shared/helpers/transform-date';

@Component({
  selector: 'app-task-recurs',
  templateUrl: './task-recurs.component.html',
  styleUrls: ['./task-recurs.component.scss']
})
export class TaskRecursComponent implements OnInit {
  @Input() taskUID: string;
  @Input() projectUID: string;
  @Input() data: {
    Start: Date;
    Period: string;
  };
  @ViewChild('dropdown') dropdown: BsDropdownDirective;
  recursTime = [ {id: 'off', label: 'Never'}, {id: 'd', label: 'Day(s)'}, {id: 'w', label: 'Week(s)'},
  {id: 'm', label: 'Month(s)'}, {id: 'y', label: 'Year(s)'}];
  currentDate = new Date();
  isActive = false;
  isHidden =  false;
  isDropup = false;
  recursForm: FormGroup;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
     if (window.innerWidth < 577) {
       this.isDropup  = true;
     } else {
      this.isDropup = false;
    }
  }

  constructor(private formBuilder: FormBuilder, private recurService: RecursService) { }

  ngOnInit(): void {
    let num: string;
    let letr: string;
    if (window.innerWidth < 577) {
      this.isDropup = true;
    } else {
      this.isDropup = false;
    }
    this.createForm();
    if (this.data.Period && this.data.Start) {
      this.isActive = true;
      num = this.data.Period.match(/\d+/g)[0];
      letr = this.data.Period.match(/[a-zA-Z]+/g)[0];
      this.recursForm.patchValue({
        Start: this.data.Start,
        Period: letr,
        PeriodOffset: +num
      });
    } else {
      this.recursForm.patchValue({
        Start: null,
        Period: 'off',
        PeriodOffset: null
      });
      const periodID = this.recursForm.get('Period').value;
      this.checkErrors(periodID);
    }
  }

  checkIsActive() {
    const period = this.recursForm.get('Period').value;
    const start = this.recursForm.get('Start').value;
    if (period !== 'off' && start) {
      this.isActive = true;
    } else {
      this.isActive = false;
    }
  }

  createForm() {
    this.recursForm = this.formBuilder.group({
      Start: [null, Validators.required],
      Period: [null, Validators.required],
      PeriodOffset: [null, Validators.required]
    });
  }

  changePeriod(event: {}) {
    const recurs = event as {id: string, label: string};
    this.checkErrors(recurs.id);
  }

  checkErrors(periodID: string ) {
    const recursStart = this.recursForm.get('Start');
    const recursPeriodOffSet = this.recursForm.get('PeriodOffset');
    if (periodID === 'off') {
      this.isHidden = true;
      recursStart.setValue(null);
      recursPeriodOffSet.setValue(null);
      recursStart.setErrors(null);
      recursPeriodOffSet.setErrors(null);
    } else {
      this.isHidden = false;
      if (recursStart.value === null && recursPeriodOffSet.value === null) {
        recursStart.setErrors(Validators.required);
        recursPeriodOffSet.setErrors(Validators.required);
      }
    }
  }

  saveRecurs() {
    if (this.recursForm.invalid) {
      return;
    }
    const pattern = `yyyy-MM-dd'T'HH:mm:ssxxx`;
    if (this.recursForm.value.Period !== 'off') {
      const data = {
        Start: format(new Date(this.recursForm.value.Start), pattern),
        Period: this.recursForm.value.PeriodOffset + this.recursForm.value.Period
      };
      this.recurService.sendRecurs(this.projectUID, this.taskUID, data).subscribe(() => {
        this.dropdown.hide();
      });
    } else {
      this.recurService.deleteRecurs(this.projectUID, this.taskUID).subscribe(() => {
        this.dropdown.hide();
      });
    }
  }
}
