import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { ChatButtonComponent } from './chat-button/chat-button.component';
import { FormsModule } from '@angular/forms';
import { JarvisComponent } from './jarvis/jarvis.component';
import { ChatComponent } from './chat/chat.component';
import { SharedModule } from '@app/shared/shared.module';
import { ChatNotificationsComponent } from './chat-notifications/chat-notifications.component';
import { ChatCommentsComponent } from './chat-comments/chat-comments.component';
import { ChatAttachmentsComponent } from './chat-attachments/chat-attachments.component';
import { ChatAddAttachmentComponent } from './chat-add-attachment/chat-add-attachment.component';
import { ChatMessageComponent } from './chat-message/chat-message.component';
import { ChatCountAttachNotifComponent } from './chat-count-attach-notif/chat-count-attach-notif.component';
import { ChatProjectListComponent } from './chat-project-list/chat-project-list.component';
import { ChatTaskListComponent } from './chat-task-list/chat-task-list.component';
import { ChatUserListComponent } from './chat-user-list/chat-user-list.component';

@NgModule({
  declarations: [
    ChatButtonComponent,
    ChatComponent,
    JarvisComponent,
    ChatNotificationsComponent,
    ChatCommentsComponent,
    ChatAttachmentsComponent,
    ChatAddAttachmentComponent,
    ChatMessageComponent,
    ChatCountAttachNotifComponent,
    ChatProjectListComponent,
    ChatTaskListComponent,
    ChatUserListComponent,
  ],
  imports: [SharedModule, FormsModule, RouterModule],
  exports: [
    ChatButtonComponent,
    ChatComponent,
    JarvisComponent,
    ChatNotificationsComponent,
  ],
  entryComponents: [ChatComponent],
})
export class JarvisModule {}
