import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TenantResolver implements Resolve<boolean> {
  constructor(private http: HttpClient) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> {
    const domain = window.location.hostname;
    let tenant: string;
    const tenatArray =  domain.split('.');
    if (tenatArray[0] === 'www') {
      tenant = tenatArray[1];
    } else {
      tenant = tenatArray[0];
    }
    if (!state.url.includes('/tenant')) {
    return this.http.get<boolean>(`${environment.baseUrl}open/tenant/${tenant}`);
    }
  }
}
