import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, AbstractControl, FormGroup, Validators } from '@angular/forms';
import { UploadFilesService } from '@app/shared/services/upload-files.service';
import { FeedbackService } from '@app/shared/services/feedback.service';
import { AuthService } from '@app/shared/services/auth.service';
import { SpinnerButtonOptions } from '@app/models/spinnerButtonOptions';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent implements OnInit {
  fileData: {
    AttachmentURL: string;
    AttachmentFileName: string;
  };
  Attachments = [];
  feedbackForm: FormGroup;
  submitted = false;
  spinnerButtonOptions: SpinnerButtonOptions = {
    active: false,
    text: 'Send',
    fullWidth: false,
    disabled: false,
  };
  loading = false;

  get f(): { [key: string]: AbstractControl } {
    return this.feedbackForm.controls;
  }

  constructor(
    public activeModal: BsModalRef,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private feedbackService: FeedbackService,
    private fileUploadService: UploadFilesService,
    private toast: ToastrService
  ) {}

  ngOnInit(): void {
    this.createForm();
  }


  createForm() {
    this.feedbackForm = this.formBuilder.group({
      Message: ['', Validators.required],
      AttachmentURL: [null]
    });
  }

  async addFile(event) {
    const file = event.files[0] as File;
    try {
      await this.fileUploadService.maxFileSize(file);
      this.uploadAttachment(file);
    } catch (error) {
      this.toast.warning(error);
    }
  }

  uploadAttachment(file: File) {
    this.loading = true;
    this.fileUploadService.fileUpload(file).subscribe(res => {
      this.fileData = {
        AttachmentURL: res.URL,
        AttachmentFileName: file.name
      };
      this.Attachments =  [...this.Attachments, this.fileData];
      this.loading = false;
    },
     error => {
       this.loading = false;
     });
  }

  deleteAttachment() {
    this.fileData = null;
  }

  submitForm() {
    this.submitted = true;
    if (this.feedbackForm.invalid) {
      return;
    }
    this.spinnerButtonOptions.active = true;
    const data = this.feedbackForm.value;
    const userId = this.authService.currentUser.UserUID;
    this.feedbackForm.value.AttachmentURL = this.fileData;
    this.feedbackService.sendFeedback(userId, data).subscribe( () => {
      this.activeModal.hide();
      this.submitted = false;
      this.spinnerButtonOptions.active = false;
    },
    error => {
      this.spinnerButtonOptions.active = false;
    });
  }
}
