import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TransformDate } from '@app/shared/helpers/transform-date';
import { ToastrService } from 'ngx-toastr';
import { TypeService } from '@app/shared/services/type.service';
import { TaskTypesComponent } from '@app/layout/tasks/task-types/task-types.component';
import { MentionService } from '@app/shared/services/mention.service';
import { ProjectService } from '@app/shared/services/project.service';
import { AttachmentsService } from '@app/shared/services/attachments.service';
import { concatMap } from 'rxjs/operators';
import { UploadFilesService } from '@app/shared/services/upload-files.service';
import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  Renderer2,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, AbstractControl, FormControl } from '@angular/forms';
import { TaskService } from '@app/shared/services/task.service';
import { Task } from '@app/models/task';
import { User } from '@app/models/user';
import { UserService } from '@app/shared/services/user.service';
import { Project } from '@app/models/project';
import { Type } from '@app/enums/types';
import { Priority } from '@app/enums/priorities';
import * as InlineEditor from '@app/ckEditor/build/ckeditor';
import { AuthService } from '@app/shared/services/auth.service';
import { TaskType } from '@app/models/type';
import { Subscription } from 'rxjs';
import { SpinnerButtonOptions } from '@app/models/spinnerButtonOptions';
import { format } from 'date-fns';

@Component({
  selector: 'app-task-create',
  templateUrl: './task-create.component.html',
  styleUrls: ['./task-create.component.scss'],
})
export class TaskCreateComponent implements OnInit, OnDestroy {
  @ViewChild('files') filesRef: ElementRef<HTMLInputElement>;
  public Editor = InlineEditor;
  public configDesc = this.mentionService.config;
  loading = false;
  currentDate = new Date();
  loadingSelect = false;
  currentUser: User;
  isRecursPeriodError = false;
  types: TaskType[];
  recursTime = [ {id: 'off', label: 'Never'}, {id: 'd', label: 'Day(s)'}, {id: 'w', label: 'Week(s)'},
  {id: 'm', label: 'Month(s)'}, {id: 'y', label: 'Year(s)'}];
  priorities = [
    { Name: 'Low', UID: 1 },

    { Name: 'Moderate', UID: 2 },

    { Name: 'High', UID: 3 },
  ];
  fileData: {
    AttachmentURL: string;
    AttachmentFileName: string;
  };
  Attachments = [];
  attachemntsExt: { extensionName: string }[] = [];
  taskForm: FormGroup;
  task: Task;
  projectUID: string;
  projects: Project[];
  persons: User[];
  subscription: Subscription[] = [];
  showPersons = false;
  index: number;
  isRecursDateHidden = false;
  submitted = false;
  spinnerButtonOptions: SpinnerButtonOptions = {
    active: false,
    text: 'Create task',
    fullWidth: false,
    disabled: false,
  };
  constructor(
    private taskService: TaskService,
    private typeService: TypeService,
    private projectService: ProjectService,
    public closeModal: BsModalRef,
    private renderer: Renderer2,
    private fileUploadService: UploadFilesService,
    private attachmentService: AttachmentsService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private modalService: BsModalService,
    private toast: ToastrService,
    private mentionService: MentionService,
    private authService: AuthService
  ) {
    this.currentUser = this.authService.currentUser;
  }

  ngOnInit() {
    this.subscription.push(
      this.typeService.createType$.subscribe((res) => {
        this.types = [...this.types, res];
      })
    );
    this.subscription.push(
      this.typeService.deleteType$.subscribe((id) => {
        this.types = this.types.filter((item) => item.ID !== id);
      })
    );
    this.subscription.push(
      this.typeService.updateType$.subscribe((res) => {
        const updatedTypes =  this.types.map(type => {
          if (type.ID === res.id) {
            const updatedType = {...type, Name: res.Name};
            return updatedType;
          }
          return type;
        });
        this.types = updatedTypes;
      })
    );
    this.getProjects();
    this.createForm();
    this.getTaskTypes();
    if (this.projectUID) {
    this.getPersons();
    }
  }

  ngOnDestroy() {
    this.subscription.forEach((subscription) => subscription.unsubscribe());
  }

  changeProject(projectUID: {}) {
      this.projectUID = projectUID as string;
      if (this.projectUID) {
      this.getPersons();
    }
  }

  getProjects() {
    this.projectService.getProjects().subscribe((res) => {
      this.projects = res.data;
      if (this.projectUID) {
        this.f.Project.setValue(this.projectUID);
      }
    });
  }

  getPersons() {
    this.loadingSelect = true;
    this.userService.getusersProject(this.projectUID).subscribe((res) => {
      this.persons = res.data;
      const defaultAvatar = '/assets/images/default-avatar.png';
      const Unassigned = {
        UID: null,
        Name: 'Unassigned',
        AvatarURL: defaultAvatar,
      } as User;
      this.persons = [Unassigned, ...this.persons];
      this.mentionService.users = this.persons;
      this.loadingSelect = false;
    });
  }

  openTaskTypeModal() {
    const modal = this.modalService.show(TaskTypesComponent, {
      class: 'task-type-modal modal-md modal-dialog-scrollable',
    });
    document.getElementsByClassName(
      'task-type-modal'
    )[0].parentElement.style.backgroundColor = 'rgba(100, 112, 130, 0.4)';
    modal.content.types = this.types;
  }

  getTaskTypes() {
    this.typeService.getTypes().subscribe((res) => {
      this.types = res.data;
      this.f.TaskType.setValue(this.types[0].ID);
    });
  }

  get taskType() {
    return Type;
  }

  get taskPriority() {
    return Priority;
  }

  createForm() {
    const priorityMedium = this.priorities[1].UID;
    this.taskForm = this.formBuilder.group({
      Project: [null, Validators.required],
      Title: ['', Validators.required],
      TaskType: [null, Validators.required],
      Priority: [priorityMedium, Validators.required],
      DueDate: [null],
      AssigneeUID: [null],
      Description: [''],
      RecurrencePeriod: [null],
      RecurrenceStart: [null],
      Checklist: new FormArray([])
    });
  }

  addCheckList(newArray: string[]) {
    this.t.clear();
    newArray.forEach( item => this.t.push(new FormControl(item)));
  }

  async addFile(event) {
    const file = event.files[0] as File;
    try {
      await this.fileUploadService.maxFileSize(file);
      this.uploadAttachment(file);
    } catch (error) {
      this.toast.warning(error);
    }
  }

  uploadAttachment(file: File) {
    this.loading = true;
    this.fileUploadService.fileUpload(file).subscribe(
      (res) => {
        this.fileData = {
          AttachmentURL: res.URL,
          AttachmentFileName: file.name,
        };
        this.getExtension(this.fileData);
        this.Attachments = [...this.Attachments, this.fileData];
        this.loading = false;
        this.renderer.setProperty(this.filesRef.nativeElement, 'value', '');
      },
      (error) => {
        this.loading = false;
        this.renderer.setProperty(this.filesRef.nativeElement, 'value', '');
      }
    );
  }

  deleteAttachment(index: number) {
    this.Attachments.splice(index, 1);
    this.attachemntsExt.splice(index, 1);
  }

  getExtension(attachments: {
    AttachmentURL: string;
    AttachmentFileName: string;
  }) {
    const defaultExtension = 'bin';
    const extension = attachments.AttachmentFileName.split('.')
      .pop()
      .slice(0, 4);
    if (!attachments.AttachmentFileName.includes('.')) {
      this.attachemntsExt.push({ extensionName: defaultExtension });
    } else {
      this.attachemntsExt.push({ extensionName: extension });
    }
  }

  get f() {
    return this.taskForm.controls;
  }

  get t() {
    return this.f.Checklist as FormArray;
  }

  submitForm(recursNumber: string) {
    this.submitted = true;
    if (this.taskForm.invalid) {
      return;
    }
    this.projectUID = this.f.Project.value;
    const pattern = `yyyy-MM-dd'T'HH:mm:ssxxx`;
    if (this.taskForm.value.RecurrencePeriod !== 'off' && recursNumber) {
    this.taskForm.get('RecurrencePeriod').setValue(recursNumber + this.taskForm.value.RecurrencePeriod);
    } else {
      this.taskForm.get('RecurrencePeriod').setValue(null);
    }
    const data = this.taskForm.value as Task;
    this.spinnerButtonOptions.active = true;
    this.taskForm.value.Description = this.mentionService.replaceMentionNames(
      this.taskForm.value.Description
    );
    this.taskForm.value.DueDate = TransformDate(this.f.DueDate.value);
    if (this.taskForm.value.RecurrenceStart) {
      this.taskForm.value.RecurrenceStart = format(
        this.taskForm.value.RecurrenceStart,
        pattern
      );
    }
    if (this.Attachments.length > 0) {
      this.taskService
        .createTask(data, this.projectUID)
        .pipe(
          concatMap((res) => {
            this.spinnerButtonOptions.active = false;
            this.closeModal.hide();
            this.taskForm.reset();
            this.submitted = false;
            return this.attachmentService.uploadAttachment(
              this.projectUID,
              res.UID,
              this.Attachments
            );
          })
        )
        .subscribe();
    } else {
      this.taskService.createTask(data, this.projectUID).subscribe(() => {
        this.spinnerButtonOptions.active = false;
        this.closeModal.hide();
        this.taskForm.reset();
        this.submitted = false;
      });
    }
  }

  changeRecursPeriod(event: {}, recursNumber: string) {
    this.checkErrors(recursNumber);
  }
  changeRecursStart(date: Date, recursNumber: string) {
    this.checkErrors(recursNumber);
  }

  checkErrors(recursNumber: string) {
    const recursStart = this.taskForm.get('RecurrenceStart');
    const recursPeriod = this.taskForm.get('RecurrencePeriod');
    switch(true) {
      case recursPeriod.value === 'off':
        this.isRecursDateHidden = true;
        this.isRecursPeriodError = false;
        recursStart.setErrors(null);
        recursPeriod.setErrors(null);
      break;
      case recursNumber && recursPeriod.value !== null && recursStart.value !== null:
        this.isRecursDateHidden = false;
        this.isRecursPeriodError = false;
        recursStart.setErrors(null);
        recursPeriod.setErrors(null);
      break;
      case recursNumber && recursPeriod.value !== null && recursStart.value === null:
        this.isRecursDateHidden = false;
        this.isRecursPeriodError = false;
        recursStart.setErrors([Validators.required]);
        recursPeriod.setErrors(null);
      break;
      case recursNumber && recursPeriod.value === null && recursStart.value === null:
        this.isRecursDateHidden = false;
        this.isRecursPeriodError = true;
        recursPeriod.setErrors([Validators.required]);
        recursStart.setErrors([Validators.required]);
      break;
      case !recursNumber && recursPeriod.value !== null && recursStart.value === null:
        this.isRecursDateHidden = false;
        this.isRecursPeriodError = true;
        recursPeriod.setErrors([Validators.required]);
        recursStart.setErrors([Validators.required]);
      break;
      case (!recursNumber || recursPeriod.value === null) && recursStart.value !== null:
        this.isRecursDateHidden = false;
        this.isRecursPeriodError = true;
        recursPeriod.setErrors([Validators.required]);
      break;
    default:
    this.isRecursDateHidden = false;
    this.isRecursPeriodError = false;
    recursStart.setErrors(null);
    recursPeriod.setErrors(null);
   }
 }
}

