<div class="wrap">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <app-header></app-header>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row mobile-margin">
      <div class="col-12 mobile-padding">
        <div class="homepage-content scroll">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
    <div class="jarvis-chat">
      <app-jarvis></app-jarvis>
    </div>
  </div>
</div>
