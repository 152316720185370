import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Priority } from '@app/enums/priorities';
import { Task } from '@app/models/task';

interface GroupedTasks {
  Status: number;
  StatusName: string;
  StatusOrder: string;
  Tasks: any[];
}

interface ChatTask {
  GroupedTasks: GroupedTasks[];
  ProjectIcon: string;
  ProjectKey: string;
}

@Component({
  selector: 'app-chat-task-list',
  templateUrl: './chat-task-list.component.html',
  styleUrls: ['./chat-task-list.component.scss']
})

export class ChatTaskListComponent implements OnInit {
  @Input() tasks: ChatTask;
  @Output() getTask: EventEmitter<string> = new EventEmitter<string>();
  isOpen: boolean;
  accordionStatus: string;

  constructor() { }

  ngOnInit(): void {
  }

  get taskPriority() {
    return Priority;
  }

  selectElement(projectKey: string, taskKey: string) {
    const message = `select task ${projectKey}-${taskKey}`;
    this.getTask.emit(message);
  }

  isOpenChange(isOpen: boolean, accStatus: string) {
    this.isOpen = isOpen;
    this.accordionStatus = accStatus;
  }

}
