<img
  [src]="avatarURL"
  *ngIf="avatarURL !== null; else noAvatar"
  class="avatar"
  [width]="width"
  [height]="height"
/>
<ng-template #noAvatar>
  <div
    *ngIf="userName !== 'Unassigned'; else noAvatarUID"
    class="no-avatar"
    [ngStyle]="{
      width: width + 'px',
      height: height + 'px',
      'font-size': font + 'px',
      'background-color': background
    }"
  >
    <span>{{ userName | userAcronym | uppercase }} </span>
  </div>
  <ng-template #noAvatarUID>
    <img
      src="/assets/images/default-avatar.png"
      class="avatar"
      [width]="width"
      [height]="height"
    />
  </ng-template>
</ng-template>
