<form
  class="d-flex flex-column overflow-hidden"
  [formGroup]="changePasswordForm"
  (ngSubmit)="changePassword()">
  <div class="modal-header border-none">
    <h4 class="modal-title" id="modal-basic-title">Change Password</h4>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label class="label">Old Password</label>
      <div class="row">
        <div class="col-lg-10 col-11">
          <input
            type="password"
            (focusout) = "submittedOldPassword = true"
            (focus) = "submittedOldPassword = false"
            [appFocus]="true"
            class="form-control"
            formControlName="OldPassword"
            placeholder="Type old password"
            [ngClass]="{'is-invalid':f['OldPassword']?.errors && submittedOldPassword }">
            <div *ngIf = "f['OldPassword'].errors && submittedOldPassword" class="invalid-feedback">
               <div class="pl-2 pt-1" *ngIf= "f['OldPassword'].errors.required">
                  This field can not be empty
               </div>
            </div>
        </div>
      </div>
    </div>
    <div class="form-group">
      <label class="label">New password</label>
      <div class="row">
        <div class="col-lg-10 col-11">
          <input
            type="password"
            #newPassword
            (focusout) = "submittedNewPassword = true"
            (focus) = "submittedNewPassword = false"
            class="form-control"
            formControlName="NewPassword"
            placeholder="Type new password"
            [ngClass]="{
              'is-invalid':f['NewPassword']?.errors && submittedNewPassword
               }"/>
          <div class="progress-content pl-2 pr-4">
            <div *ngIf = "f['NewPassword'].invalid"
            [ngClass] = "{'error-feedback':f['NewPassword']?.errors && submittedNewPassword  }">
              <span class="showError"> Must contain at least 6 characters </span>
           </div>
        </div>
        </div>
        <div class="col-1 pl-0">
          <div class="validForm-icon">
            <i *ngIf="!f['NewPassword'].invalid" class="far fa-check-circle check-icon"></i>
            <i *ngIf="f['NewPassword'].invalid && submittedNewPassword" class="far fa-times-circle times-icon"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group">
      <label class="label">Repeat new password </label>
      <div class="row">
        <div class="col-lg-10 col-11">
          <input
            type="password"
            (focusout) = "submittedRepeatPassowrd = true"
            (focus) = "submittedRepeatPassowrd = false"
            class="form-control"
            formControlName="RepeatPassword"
            placeholder="Type new password"
            [ngClass]="{
              'is-invalid': f['RepeatPassword']?.errors && submittedRepeatPassowrd
               }"/>
            <div *ngIf = "f['RepeatPassword'].invalid" [ngClass] = "{'error-feedback': f['RepeatPassword']?.errors && submittedRepeatPassowrd  }">
              <span class="showError pl-2"> Password must match </span>
           </div>
        </div>
        <div class="col-1 pl-0">
          <div class="validForm-icon">
            <i *ngIf="!f['RepeatPassword'].invalid" class="far fa-check-circle check-icon"></i>
            <i *ngIf="f['RepeatPassword'].invalid && submittedRepeatPassowrd" class="far fa-times-circle times-icon"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer border-none">
    <app-spinner-button [options]="spinnerButtonOptions"> </app-spinner-button>
    <button
      type="button"
      class="btn btn-cancel"
      (click)="changePasswordModal.hide()">
      Cancel
    </button>
  </div>
</form>
