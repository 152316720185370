import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-arrow-down-svg',
  templateUrl: './arrow-down-svg.component.html',
  styleUrls: ['./arrow-down-svg.component.scss']
})
export class ArrowDownSvgComponent implements OnInit {
  @Input() width = 19;
  @Input() height = 10;
  @Input() color = '#389bff';
  @Input() strokeWidth = 2

  constructor() { }

  ngOnInit(): void {
  }

}
