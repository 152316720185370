import { TaskService } from '@app/shared/services/task.service';
import { Component, Input, OnInit } from '@angular/core';
import { Collaborators } from '@app/models/collaborators';
import { User } from '@app/models/user';
import { GuestService } from '@app/shared/services/guest.service';

@Component({
  selector: 'app-task-collaborators',
  templateUrl: './task-collaborators.component.html',
  styleUrls: ['./task-collaborators.component.scss']
})
export class TaskCollaboratorsComponent implements OnInit {
  collaborators: Collaborators[] = [];
  collaboratorsUIDs: string[] = [];
  @Input() projectId: string;
  @Input() taskId: string;
  @Input() persons: User[];
  isGuest: boolean;
  constructor(private taskService: TaskService, private guestService: GuestService) { }

  ngOnInit(): void {
    this.isGuest = this.guestService.isGuest;
    this.getCollaborators();
  }

  getCollaborators() {
    this.taskService.getCollaborators(this.projectId, this.taskId).subscribe( res => {
      this.collaborators = res.data;
      this.collaborators.forEach( item => {
        this.collaboratorsUIDs = [...this.collaboratorsUIDs, item.UserUID];
        this.changeCollaboratorPos();
      });
    });
  }

  onShown(): void {
    setTimeout(() => {
      this.collaborators.forEach((item) => {
        const checkUser = document.getElementById(
          'check' + item.UserUID
        ) as HTMLInputElement;
        if (checkUser) {
          checkUser.checked = true;
        }
      });
    }, 0);
  }

  chooseUsersList(event: Event, user: User) {
    const isChecked = (event.target as HTMLInputElement).checked;
    const collaboratorUser: Collaborators = {
        TaskUID: this.taskId,
        UserUID: user.UID,
        UserName: user.Name,
        UserAvatarURL: user.AvatarURL
    };
    if (isChecked) {
      this.collaboratorsUIDs = [...this.collaboratorsUIDs, collaboratorUser.UserUID];
      this.taskService.addCollaborators(this.projectId, this.taskId, this.collaboratorsUIDs).subscribe(() => {
        this.collaborators = [...this.collaborators, collaboratorUser];
        this.changeCollaboratorPos();
      });
    } else {
      this.collaboratorsUIDs = this.collaboratorsUIDs.filter((item) => item !== user.UID);
      this.taskService.addCollaborators(this.projectId, this.taskId, this.collaboratorsUIDs).subscribe( () => {
        this.collaborators = this.collaborators.filter(
          ({UserUID}) => UserUID !== collaboratorUser.UserUID
        );
        this.changeCollaboratorPos();
      });
    }
  }

  changeCollaboratorPos() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser')) as User;
    this.collaborators.map( item => {
      if (item.UserUID === currentUser.UserUID) {
        this.collaborators = this.collaborators.filter(({UserUID}) => UserUID !== item.UserUID);
        this.collaborators.splice(3, 0, item);
      }
    });
  }
}
