import { ToastrService } from 'ngx-toastr';
import { TaskTypesComponent } from '@app/layout/tasks/task-types/task-types.component';
import { TypeService } from '@app/shared/services/type.service';
import { MentionService } from '@app/shared/services/mention.service';
import { Component, OnInit, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { TaskService } from '@app/shared/services/task.service';
import { UserService } from '@app/shared/services/user.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged, concatMap } from 'rxjs/operators';
import { Task, TaskStatus } from '@app/models/task';
import { User } from '@app/models/user';
import { Priority } from '@app/enums/priorities';
import { Router } from '@angular/router';
import { SweetAlertArrayOptions, SweetAlertOptions } from 'sweetalert2';
import * as InlineEditor from '@app/ckEditor/build/ckeditor';
import { ChangeEvent, CKEditorComponent } from '@ckeditor/ckeditor5-angular/ckeditor.component';
import { TaskType } from '@app/models/type';
import { EMPTY } from 'rxjs';
import { GoogleCalendarService } from '@app/shared/services/google-calendar.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Status } from '@app/enums/errors';
import { ShopifyInventoryComponent } from '@app/layout/shopify/shopify-inventory/shopify-inventory.component';
import { TransformDate } from '@app/shared/helpers/transform-date';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown/bs-dropdown.directive';
enum mode {
  COMMENTS = 1,
  ATTACHMENTS,
}

@Component({
  selector: 'app-task-share-details',
  templateUrl: './task-share-details.component.html',
  styleUrls: ['./task-share-details.component.scss'],
})
export class TaskShareDetailsComponent implements OnInit {
  @ViewChild('titleRef') titleRef: ElementRef;
  @ViewChild('editor') editor: CKEditorComponent;
  @ViewChild('dropdown') dropdown: BsDropdownDirective;
  public Editor = InlineEditor;
  public editorValue: string;
  public editorInitialValue: string;
  public configDesc = this.mentionService.config;
  public configComment = this.mentionService.config;
  editorPlaceholder = 'Add description';
  currentDate = new Date();
  initialTitle: string;
  showEditorBtn = false;
  showDescEditor = false;
  descChangesSaved = false;
  isEditMode = false;
  isCalendarDisabled: boolean;
  showCalendarMessage: boolean;
  IsEventAdded: boolean;
  isGCalLoggedIn: boolean;
  isFirstChange = false;
  isReminderActive = false;
  currentCalendarDate: Date;
  isBtnDisabled = true;
  taskId: string;
  projectId: string;
  assigneeObj: {
    UID?: string;
    Name?: string;
    AvatarURL?: string;
  } = {};
  creatorObj: {
    UID?: string;
    Name?: string;
    AvatarURL?: string;
  } = {};
  typeId: number;
  taskTypeName: string;
  taskForm: FormGroup;
  persons: User[] = [];
  taskStatus: TaskStatus[];
  types: TaskType[];
  swalOptionsDelete: SweetAlertArrayOptions = [
    'Delete task',
    'Are you sure you want to delete this task?',
  ];
  swalOptionsArchive: SweetAlertOptions = {
    title: 'Archive task',
    text: 'Are you sure you want to archive this task?',
    confirmButtonText: 'Yes, archive',
  };
  swalOptionsUnarchive: SweetAlertOptions = {
    title: 'Unarchive task',
    text: 'Are you sure you want to unarchive this task?',
    confirmButtonText: 'Yes, unarchive',
  };
  priorities = [
    { Name: 'Low', UID: 1 },

    { Name: 'Moderate', UID: 2 },

    { Name: 'High', UID: 3 }
  ];
  dataRecurs: {
    Start: Date;
    Period: string;
  };
  mode = 0;
  public onChange({ editor }: ChangeEvent) {
    this.editorValue = editor.getData();
  }
  constructor(
    public taskService: TaskService,
    public typeService: TypeService,
    public userService: UserService,
    public formBuilder: FormBuilder,
    public modalService: BsModalService,
    public renderer: Renderer2,
    public router: Router,
    public mentionService: MentionService,
    public googleCalendarService: GoogleCalendarService,
    public toast: ToastrService,
  ) {
    const newConfig = {
      ...this.configDesc,
      placeholder: 'Enter comment'
    };
    this.configComment = newConfig;
  }

  ngOnInit(): void {
  }

  createForm() {
    this.taskForm = this.formBuilder.group({
      Title: ['', { validators: [Validators.required], updateOn: 'submit' }],
      Status: ['', { validators: [Validators.required], updateOn: 'blur' }],
      Priority: ['', { validators: [Validators.required], updateOn: 'blur' }],
      DueDate: [null, { updateOn: 'blur' }],
      AssigneeUID: [null, { updateOn: 'blur' }],
      TaskType: [null, { validators: [Validators.required], updateOn: 'blur' }],
      Description: ['', { updateOn: 'submit' }],
    });
  }

  changeType(res: {}) {
    const typeID = res as number;
    this.types.map( type => {
      if (type.ID === typeID) {
        this.typeId = type.IconID;
        this.taskTypeName = type.Name;
      }
    });
  }

  toggleDueDate() {
      this.isCalendarDisabled = false;
      this.showCalendarMessage = false;
      this.enableDisableCalendar();
  }

  enableDisableCalendar() {
    const currentFormDate = TransformDate(this.f.DueDate.value);
    const currentCalendarDate = TransformDate(this.currentCalendarDate);
    if (this.f.DueDate.value && !this.IsEventAdded || (this.IsEventAdded && currentFormDate !== currentCalendarDate)) {
      this.isCalendarDisabled = false;
    } else {
      this.isCalendarDisabled = true;
    }
  }

  getTaskTypes() {
    this.typeService.getTypes().subscribe(res => {
      this.types = res.data;
    });
  }

  autoHeight(elem: HTMLTextAreaElement) {
    elem.style.height = '48px';
    elem.style.height = (elem.scrollHeight) + 'px';
  }

  editTitle(el: HTMLDivElement) {
    this.isEditMode = true;
    if (this.titleRef.nativeElement) {
      this.initialTitle = this.taskForm.get('Title').value.trim();
      this.titleRef.nativeElement.value = this.taskForm.get('Title').value.trim();
      this.renderer.setStyle(this.titleRef.nativeElement, 'height', el.scrollHeight + 'px');
    }
    setTimeout(() => {
      this.titleRef.nativeElement.focus();
    }, 0);
  }

  openTaskTypeModal() {
    const modal = this.modalService.show(TaskTypesComponent, {
      class: 'task-type-modal modal-md modal-dialog-scrollable'
    });
    document.getElementsByClassName('task-type-modal')[0].parentElement.style.backgroundColor = 'rgba(100, 112, 130, 0.4)';
    modal.content.types = this.types;
  }

  get f() {
    return this.taskForm.controls;
  }

  get taskPriority() {
    return Priority;
  }

  get modeValue() {
    return mode;
  }

  displayAttachment() {
    this.mode = mode.ATTACHMENTS;
  }

  displayComments() {
    this.mode  = mode.COMMENTS;
  }

  assignee(res: {}) {
    const person = res as User;
    this.assigneeObj = {
      UID:  person.UID,
      Name: person.Name,
      AvatarURL: person.AvatarURL
    };
  }

  getAssignee(task: Task) {
    this.assigneeObj = {
      UID: task.AssigneeUID,
      Name: task.AssigneeName,
      AvatarURL: task.AssigneeAvatarURL,
    };
  }

  addGoogleCalendar() {
    this.googleCalendarService.addGoogleCalendar(this.f.Title.value, this.projectId, this.taskId, this.f.DueDate.value).subscribe(res => {
      this.isCalendarDisabled = true;
      this.showCalendarMessage = true;
      this.IsEventAdded  = true;
    }, (error: HttpErrorResponse) => {
      switch (error.status) {
        case Status.ErrorStatusAuthFailed:
          window.location.href = error.error.Cause;
          break;
        case Status.ErrorStatusServerError:
          this.toast.warning('An error occured. If it continues happening contact support: office@jarvis.management');
          break;
        default:
        this.toast.warning('Something went wrong, please try again');
      }
    });
  }

  getPersons() {
    this.userService.getusersProject(this.projectId).subscribe((res) => {
      this.persons = res.data;
      const defaultAvatar = '/assets/images/default-avatar.png';
      const Unassigned = {
        UID: null,
        Name: 'Unassigned',
        AvatarURL: defaultAvatar,
      } as User;
      this.persons = [Unassigned, ...this.persons];
      this.mentionService.users = this.persons;
    });
  }

  getTaskStatus() {
    this.taskService.taskStatus(this.projectId).subscribe((res) => {
      this.taskStatus = res.data;
    });
  }

  onChanges() {
    this.taskForm.valueChanges
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(
          (a: Task, b: Task) => JSON.stringify(a) === JSON.stringify(b)
        ),
        concatMap((res) => {
          if (this.taskForm.invalid) {
            return EMPTY;
          }
          const data = this.taskForm.value as Task;
          this.taskForm.value.DueDate = TransformDate(this.f.DueDate.value);
          this.taskForm.value.AssigneeUID = this.assigneeObj.UID;
          return this.taskService.updateTask(data, this.taskId, this.projectId);
        })
      )
      .subscribe();
  }

  preventRemoveBorder() {
    const editor = document.querySelector('.editorDesc');
    this.renderer.addClass(editor, 'editorBorder');
  }

  showEditor(event: Event) {
    this.descChangesSaved = false;
    const link = (event.target) as HTMLElement;
    if (link.hasAttribute('rel')) {
      event.stopPropagation();
    } else {
      this.showDescEditor = true;
      if (this.editorInitialValue !== this.editorPlaceholder) {
      this.f.Description.setValue(this.editorInitialValue, {emitEvent: false});
      } else {
        this.f.Description.setValue('');
      }
      setTimeout(() => {
        this.editor.editorInstance.editing.view.focus();
      });
    }
  }

  closeEditor() {
    this.showDescEditor = false;
    this.descChangesSaved = true;
    this.editorValue = this.editorInitialValue;
    sessionStorage.removeItem(`${this.projectId}-${this.taskId}-desc`);
  }

  closeEditTitle() {
    this.isEditMode = false;
    this.taskForm.get('Title').setValue(this.initialTitle, {emitEvent: false});
  }

  saveDesc(event: Event) {
    event.preventDefault();
    if ((this.editorValue !== this.editorInitialValue) && this.editorValue !== this.editorPlaceholder) {
      const data = this.taskForm.value as Task;
      this.taskForm.value.Description = this.mentionService.replaceMentionNames(this.editorValue);
      this.taskForm.value.DueDate = TransformDate(this.f.DueDate.value);
      this.taskService
        .updateTask(data, this.taskId, this.projectId)
        .subscribe(() => {
          this.editorInitialValue = this.mentionService.replaceMentionNames(this.editorValue);
          this.showDescEditor = false;
          this.descChangesSaved = true;
          sessionStorage.removeItem(`${this.projectId}-${this.taskId}-desc`);
        });
    } else {
      this.editorInitialValue !== this.editorPlaceholder ? this.editorInitialValue = this.editorValue :
      this.editorInitialValue = this.editorPlaceholder;
      this.showDescEditor = false;
    }
  }

  saveTitle(event: Event) {
    event.preventDefault();
    const newTitleValue = this.titleRef.nativeElement.value;
    if (this.initialTitle !== newTitleValue && newTitleValue !== '') {
      this.taskForm.get('Title').setValue(newTitleValue, {emitEvent: false});
      const data = this.taskForm.value as Task;
      this.taskForm.value.AssigneeUID = this.assigneeObj.UID;
      this.taskForm.value.DueDate = TransformDate(this.f.DueDate.value);
      this.taskService
        .updateTask(data, this.taskId, this.projectId)
        .subscribe(() => {
          this.initialTitle = newTitleValue;
          this.isEditMode = false;
        });
    } else {
      this.isEditMode = false;
    }
  }

  showComments(isComment: number) {
     this.mode = isComment;
  }

  openShopifyModal(quantity: string, isModal: boolean) {
    this.modalService.show(ShopifyInventoryComponent, {
      class: 'change-password-modal modal-sm modal-dialog-scrollable',
      initialState: {
        projectID: this.projectId,
        taskID: this.taskId,
        quantity: +quantity,
        isModal
      }
    });
    document.getElementsByClassName('shopify-login')[0].parentElement.style.backgroundColor = 'rgba(100, 112, 130, 0.4)';
   }

   toggleDisableBtn(event: Event) {
     const input = (event.target as HTMLInputElement).value;
     input ? this.isBtnDisabled = false : this.isBtnDisabled = true;
   }

   toggleReminder(isActive: boolean) {
    this.isReminderActive = isActive;
    if (this.isReminderActive) {
      this.dropdown.hide();
    }
  }
}
