import { GuestService } from './guest.service';
import { ToastrService } from 'ngx-toastr';
import { Subject, throwError } from 'rxjs';
import { TaskType, TaskTypeResponse } from '@app/models/type';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap, catchError } from 'rxjs/operators';
import { Error, Status } from '@app/enums/errors';

@Injectable({
  providedIn: 'root',
})
export class TypeService {
  createType$ = new Subject<TaskType>();
  deleteType$ = new Subject<number>();
  updateType$ = new Subject<{id: number, Name: string, IconID: number}>();

  constructor(
    private http: HttpClient,
    private toastService: ToastrService,
    private guestService: GuestService
  ) {}

  getTypes() {
    const link = window.location.pathname.slice(
      1,
      window.location.pathname.length
    );
    const path = this.guestService.isGuest ? link : `api`;
    return this.http.get<TaskTypeResponse>(
      `${environment.baseUrl}${path}/taskType`
    );
  }

  getType(id: number) {
    return this.http.get(`${environment.baseUrl}api/taskType/${id}`);
  }

  createType(data: TaskType) {
    return this.http
      .post<TaskType>(`${environment.baseUrl}api/taskType`, data)
      .pipe(
        tap((res) => {
          this.createType$.next(res);
        })
      );
  }

  updateType(id: number, data: {Name: string, IconID: number}) {
    return this.http.put(`${environment.baseUrl}api/taskType/${id}`, data).pipe(
      tap(() => this.updateType$.next({id, ...data}))
    );
  }

  deleteType(id: number) {
    return this.http.delete(`${environment.baseUrl}api/taskType/${id}`).pipe(
      tap(() => {
        this.deleteType$.next(id);
      }),
      catchError(this.handleError.bind(this))
    );
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    switch (true) {
      case error.error?.ErrorCode === Error.ErrorCodeDatabaseReferentialIntegrity:
        errorMessage = `This type can be deleted only when there are no tasks with this type`;
        break;
      case error.error?.ErrorCode === Error.ErrorCodeDefaultTypes:
        errorMessage = `Default task types can't be removed`;
        break;
      case error.status === Status.ErrorStatusForbidden:
        errorMessage = `Not enough permissions to do this action`;
        break;
      default:
        errorMessage = `Something went wrong. Please try again later`;
        break;
    }
    this.toastService.warning(errorMessage, 'Warning');
    return throwError(errorMessage);
  }
}
