<div class="flex flex-column d-flex" style="height: calc(100vh - 5rem);">
  <div class="modal-header">
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="scroll scroll-auto" id="msg-reverse">
    <div class="msg-reverse">
      <div class="modal-body">
        <div class="bot-text" [@firstMsg]="state">
          <p>
            How can i help you today?
          </p>
        </div>
        <div *ngFor="let userMsg of userMessages; let i = index">
          <div class="currentUser" [@messageUser] *ngIf="userMsg.Message">
            <p>{{ userMsg.Message }}</p>
          </div>
          <ng-container>
            <div [ngSwitch]="botMessages[i]?.botResponse?.Type">
              <div *ngSwitchCase="chatType.PLAINTEXT">
                <div
                  class="d-flex align-items-center show-report-message"
                  [@messageBot]
                >
                  <app-chat-message [botMessages]="botMessages[i]">
                  </app-chat-message>
                </div>
              </div>
              <div *ngSwitchCase="chatType.TASK">
                <ng-container
                  *ngIf="
                    botMessages[i].botResponse.Data &&
                    botMessages[i].botResponse.StateCode > stateCode.NOMESSAGE
                  "
                >
                  <div
                    class="d-flex align-items-center"
                    [ngClass] = "{ 'show-report-message': !botMessages[i].botResponse.Data.UID }"
                    [@messageBot]
                  >
                    <div class="bot-task">
                      <div class="task-title">
                        <div>
                          <img
                          [tooltip]="
                              botMessages[i].botResponse.Data.TypeName
                            "
                            [adaptivePosition] = "false"
                            placement="bottom"
                            [ngClass]="{
                              'task-type-border':
                                botMessages[i].botResponse.Data.TypeIconID ===
                                taskTypes.TASK_TYPE
                            }"
                            class="task-type"
                            [src]="[
                              '/assets/images/types/' +
                                botMessages[i].botResponse.Data.TypeIconID +
                                '.png'
                            ]"
                          />
                        </div>
                        <p>{{ botMessages[i].botResponse.Data.Title }}</p>
                      </div>
                      <div class="task-project">
                        <div
                          class="project-color"
                          [ngStyle]="{
                            'background-color':
                              botMessages[i].botResponse.Data.ProjectIcon
                          }"
                        ></div>
                        <span class="task-label">Project:</span>
                        <p>
                          {{
                            botMessages[i].botResponse.Data.ProjectName
                              ? botMessages[i].botResponse.Data.ProjectName
                              : "empty"
                          }}
                        </p>
                      </div>
                      <hr class="project-border-bottom" />
                      <div class="task-status">
                        <span class="task-label">Status:</span>
                        <p>
                          {{
                            botMessages[i].botResponse.Data.StatusName
                              ? botMessages[i].botResponse.Data.StatusName
                              : "empty"
                          }}
                        </p>
                      </div>
                      <div class="task-description">
                        <div class="task-details">
                          <div class="task-priority mr-4">
                            <div
                              class="mr-2 priority-color"
                              [ngStyle]="{
                                'background-color':
                                  botMessages[i].botResponse.Data.Priority ===
                                  taskPriority.LOW
                                    ? '#a6db43'
                                    : botMessages[i].botResponse.Data
                                        .Priority === taskPriority.HIGH
                                    ? 'red'
                                    : 'orange'
                              }"
                            ></div>
                            <p>
                              <span class="task-label">Priority:</span>
                              {{
                                botMessages[i].botResponse.Data.Priority ===
                                taskPriority.LOW
                                  ? "Low"
                                  : botMessages[i].botResponse.Data.Priority ===
                                    taskPriority.HIGH
                                  ? "High"
                                  : "Moderate"
                              }}
                            </p>
                          </div>
                          <div class="task-due">
                            <p>
                              <span class="task-label">Due:</span>
                              {{
                                botMessages[i].botResponse.Data.DueDate
                                  ? [
                                      botMessages[i].botResponse.Data.DueDate
                                        | date: "yyyy/MM/dd"
                                    ]
                                  : "empty"
                              }}
                            </p>
                          </div>
                        </div>
                        <hr class="divider" />
                        <div class="task-description-content">
                          <span class="task-label">Description:</span>
                          <p
                            class="description"
                            #descRef
                            [innerHTML]="
                              botMessages[i].botResponse.Data.Description
                                ? [
                                    botMessages[i].botResponse.Data.Description
                                      | shorten: 70
                                  ]
                                : 'empty'
                            "
                          ></p>
                          <a
                            *ngIf="
                              botMessages[i].botResponse.Data.Description &&
                              botMessages[i].botResponse.Data.Description
                                .length > 70
                            "
                            class="cursor-pointer read-more-btn"
                            (click)="expandText(descRef, i, $event, 'active')"
                          >
                            Read more
                          </a>
                        </div>
                      </div>
                      <div class="task-assignee">
                        <label></label>
                        <p>
                          <span class="task-label">Assignee:</span>
                          {{
                            botMessages[i].botResponse.Data.AssigneeName
                              ? botMessages[i].botResponse.Data.AssigneeName
                              : "empty"
                          }}
                        </p>
                      </div>
                    </div>
                    <ng-container
                      *ngTemplateOutlet="dropdown; context: { index: i }"
                    ></ng-container>
                    <app-chat-count-attach-notif
                     (sendMessageEvent) = "sendAttachComments($event)"
                     [taskData] = "{
                      taskUID: botMessages[i].botResponse.Data.UID,
                      numOfAttach: botMessages[i].botResponse.Data.NumOfAttachments,
                      numOfComments: botMessages[i].botResponse.Data.NumOfComments
                    }">

                    </app-chat-count-attach-notif>
                  </div>
                  <ng-container
                    *ngTemplateOutlet="reportMsg; context: { index: i }"
                  ></ng-container>
                </ng-container>
                <div [@messageBot]>
                  <app-chat-message [botMessages]="botMessages[i]">
                  </app-chat-message>
                </div>
              </div>
              <div *ngSwitchCase="chatType.TASKLIST">
                <div
                  class="d-flex align-items-center show-report-message"
                  [@messageBot]
                >
                  <div
                    class="bot-accordion"
                    *ngIf="botMessages[i].botResponse.Data"
                  >
                   <app-chat-task-list
                   [tasks] = "botMessages[i].botResponse.Data"
                   (getTask) = "selectElement($event)"
                   >
                   </app-chat-task-list>
                  </div>
                  <ng-container
                    *ngTemplateOutlet="dropdown; context: { index: i }"
                  ></ng-container>
                </div>
                <ng-container
                  *ngTemplateOutlet="reportMsg; context: { index: i }"
                ></ng-container>
                <div [@messageBot]>
                  <app-chat-message [botMessages]="botMessages[i]">
                  </app-chat-message>
                </div>
              </div>
              <div *ngSwitchCase="chatType.PROJECT">
                <ng-container
                  *ngIf="
                    botMessages[i].botResponse.Data &&
                    botMessages[i].botResponse.StateCode > stateCode.NOMESSAGE
                  "
                >
                  <div
                    [@messageBot]
                    class="d-flex align-items-center show-report-message"
                  >
                    <div class="bot-project">
                      <div class="project-title">
                        <div
                          class="project-issue"
                          [ngStyle]="{
                            'background-color':
                              botMessages[i].botResponse.Data.Icon
                          }"
                        ></div>
                        <p>
                          <span class="project-label">Project:</span
                          >{{ botMessages[i].botResponse.Data.Name }}
                        </p>
                      </div>
                      <div class="project-key">
                        <p>
                          <span class="project-label">Key:</span>
                          {{ botMessages[i].botResponse.Data.Key }}
                        </p>
                      </div>
                      <div class="project-description">
                        <div class="project-description-content">
                          <span class="project-label">Description:</span>
                          <p class="description" #descRef>
                            {{
                              botMessages[i].botResponse.Data.Description
                                ? [
                                    botMessages[i].botResponse.Data.Description
                                      | shorten: 70
                                  ]
                                : "empty"
                            }}
                          </p>
                          <a
                            *ngIf="
                              botMessages[i].botResponse.Data.Description &&
                              botMessages[i].botResponse.Data.Description
                                .length > 70
                            "
                            class="cursor-pointer read-more-btn"
                            (click)="expandText(descRef, i, $event, 'active')"
                          >
                            Read more
                          </a>
                        </div>
                      </div>
                      <div class="project-lead">
                        <p>
                          <span class="project-label">Lead:</span>
                          {{
                            botMessages[i].botResponse.Data.LeadName
                              ? botMessages[i].botResponse.Data.LeadName
                              : "empty"
                          }}
                        </p>
                      </div>
                    </div>
                    <ng-container
                      *ngTemplateOutlet="dropdown; context: { index: i }"
                    ></ng-container>
                  </div>
                  <ng-container
                    *ngTemplateOutlet="reportMsg; context: { index: i }"
                  ></ng-container>
                </ng-container>
                <div [@messageBot]>
                  <app-chat-message [botMessages]="botMessages[i]">
                  </app-chat-message>
                </div>
              </div>
              <div *ngSwitchCase="chatType.PROJECTLIST">
                <div
                  class="d-flex align-items-center show-report-message"
                  *ngIf="botMessages[i].botResponse.Data" [@messageBot]
                >
                  <div class="bot-accordion">
                    <app-chat-project-list
                    [projects]= "botMessages[i].botResponse.Data"
                    (getProject) = "selectElement($event)"
                    >
                    </app-chat-project-list>
                  </div>
                  <ng-container
                    *ngTemplateOutlet="dropdown; context: { index: i }"
                  ></ng-container>
                </div>
                <ng-container
                  *ngTemplateOutlet="reportMsg; context: { index: i }"
                ></ng-container>
                <div [@messageBot]>
                  <app-chat-message [botMessages]="botMessages[i]">
                  </app-chat-message>
                </div>
              </div>
              <div *ngSwitchCase="chatType.INVITEDUSER">
                <div
                  class="d-flex align-items-center show-report-message"
                  *ngIf="
                    botMessages[i].botResponse.StateCode !== stateCode.ADDED &&
                    botMessages[i].botResponse.StateCode >= stateCode.NOMESSAGE
                  "
                  [@messageBot]
                >
                  <div class="bot-user">
                    <div class="user-info">
                      <p class="mb-4 font-weight-bold">
                        {{ botMessages[i].botResponse.Data.Name }}
                      </p>
                      <p>
                        <span class="user-label"> Email: </span>
                        {{ botMessages[i].botResponse.Data.Email }}
                      </p>
                    </div>
                    <div class="cancel-invitation cursor-pointer"
                    (click)="cancelInvitation(botMessages[i].botResponse.Data.ID, i, 'true')"
                  >
                  <p> Cancel Invitation </p>
                    </div>
                  </div>
                  <ng-container
                    *ngTemplateOutlet="dropdown; context: { index: i }"
                  >
                  </ng-container>
                </div>
                <ng-container
                  *ngTemplateOutlet="reportMsg; context: { index: i }"
                ></ng-container>
                <div [@messageBot] *ngIf="!isCanceledInvitation">
                  <app-chat-message [botMessages]="botMessages[i]">
                  </app-chat-message>
                </div>
              </div>
              <div *ngSwitchCase="chatType.USER">
                <ng-container
                  *ngIf="
                    botMessages[i].botResponse.Data &&
                    botMessages[i].botResponse.StateCode > stateCode.NOMESSAGE
                  "
                >
                  <div
                    class="d-flex align-items-center show-report-message"
                    [@messageBot]
                  >
                    <div class="bot-user">
                      <div class="user-title">
                        <app-no-avatar
                        [avatarURL] = "botMessages[i].botResponse.Data.AvatarURL"
                        [userName] = "botMessages[i].botResponse.Data.Name"
                        [width]= "58"
                        [height] = "58"
                        class="mr-3">
                        </app-no-avatar>
                        <p class="font-weight-bold">
                          {{ botMessages[i].botResponse.Data.Name }}
                        </p>
                      </div>
                      <div class="user-info">
                        <p class="mb-4">
                          <span class="user-label"> Email: </span>
                          {{ botMessages[i].botResponse.Data.Email }}
                        </p>
                        <p>
                          <span class="user-label"> Position: </span>
                          {{
                            botMessages[i].botResponse.Data.Position
                              ? botMessages[i].botResponse.Data.Position
                              : "empty"
                          }}
                        </p>
                      </div>
                      <div class="user-role">
                        <span class="user-label"> Role: </span>
                        <img
                          src="/assets/images/chat-role/{{
                            botMessages[i].botResponse.Data.Role
                          }}.png"
                          alt="role"
                          class="mr-2"
                        />
                        <p>
                          {{
                            botMessages[i].botResponse.Data.Role === role.Member
                              ? "Member"
                              : botMessages[i].botResponse.Data.Role ===
                                role.Admin
                              ? "Admin"
                              : botMessages[i].botResponse.Data.Role ===
                                role.SuperAdmin
                              ? "Super Admin"
                              : "Guest"
                          }}
                        </p>
                      </div>
                      <div class="user-description">
                        <div class="user-description-content">
                          <span class="user-label">Bio:</span>
                          <p class="description" #descRef>
                            {{
                              botMessages[i].botResponse.Data.Bio
                                ? [
                                    botMessages[i].botResponse.Data.Bio
                                      | shorten: 70
                                  ]
                                : "empty"
                            }}
                          </p>
                          <a
                            *ngIf="
                              botMessages[i].botResponse.Data.Bio &&
                              botMessages[i].botResponse.Data.Bio.length > 70
                            "
                            class="cursor-pointer read-more-btn"
                            (click)="expandText(descRef, i, $event, 'active')"
                          >
                            Read more
                          </a>
                        </div>
                      </div>
                      <div class="user-projects">
                        <div class="d-flex align-items-start">
                          <span class="user-label mr-5"> Projects:</span>
                          <ng-container
                            *ngIf="botMessages[i].botResponse.Data.Projects"
                          >
                            <div
                              class="d-flex"
                              *ngIf="
                                botMessages[i].botResponse.Data.Projects
                                  .length <= 5
                              "
                            >
                              <div
                                class="mr-3"
                                *ngFor="
                                  let project of botMessages[
                                    i
                                  ].botResponse.Data.Projects.slice(0, 5)
                                "
                              >
                                <div
                                  class="project-icon"
                                  [ngStyle]="{
                                    'background-color': project.ProjectIcon
                                  }"
                                ></div>
                                <p class="text-center">
                                  {{ project.ProjectKey }}
                                </p>
                              </div>
                            </div>
                            <accordion
                              *ngIf="
                                botMessages[i].botResponse.Data.Projects
                                  .length > 5
                              "
                              [isAnimated]="true"
                              [closeOthers]="true"
                              class="w-100"
                            >
                              <accordion-group
                                panelClass="accordion-user"
                                (isOpenChange)="
                                  isOpenChange($event, 'openProject')
                                "
                              >
                                <div
                                  accordion-heading
                                  class="d-flex justify-content-between align-items-center"
                                >
                                  <div class="d-flex">
                                    <div
                                      class="mr-3"
                                      *ngFor="
                                        let project of botMessages[
                                          i
                                        ].botResponse.Data.Projects.slice(0, 5)
                                      "
                                    >
                                      <div
                                        class="project-icon"
                                        [ngStyle]="{
                                          'background-color':
                                            project.ProjectIcon
                                        }"
                                      ></div>
                                      <p class="text-center">
                                        {{ project.ProjectKey }}
                                      </p>
                                    </div>
                                  </div>
                                  <i
                                    [ngClass]="{
                                      'rotate-arrow':
                                        isOpen === true &&
                                        accordionStatus === 'openProject'
                                    }"
                                    class="fas fa-caret-right font-18"
                                  ></i>
                                </div>
                                <div class="accordion-content">
                                  <div class="d-flex">
                                    <div
                                      class="mr-3"
                                      *ngFor="
                                        let project of botMessages[
                                          i
                                        ].botResponse.Data.Projects.slice(
                                          5,
                                          botMessages[i].botResponse.Data
                                            .Projects.length
                                        )
                                      "
                                    >
                                      <div
                                        class="project-icon"
                                        [ngStyle]="{
                                          'background-color':
                                            project.ProjectIcon
                                        }"
                                      ></div>
                                      <p class="text-center">
                                        {{ project.ProjectKey }}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </accordion-group>
                            </accordion>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                    <ng-container
                      *ngTemplateOutlet="dropdown; context: { index: i }"
                    >
                    </ng-container>
                  </div>
                  <ng-container
                    *ngTemplateOutlet="reportMsg; context: { index: i }"
                  ></ng-container>
                </ng-container>
                <div [@messageBot]>
                  <app-chat-message [botMessages]="botMessages[i]">
                  </app-chat-message>
                </div>
              </div>
              <div *ngSwitchCase="chatType.INVITEDUSERLIST">
                <div
                  class="d-flex align-items-center show-report-message"
                  *ngIf="botMessages[i].botResponse.Data"
                  [@messageBot]
                >
                  <div class="users-list">
                    <p class="users-list-title">Invited Members</p>
                    <div
                      class="users-list-content py-3"
                      *ngFor="let user of botMessages[i].botResponse.Data"
                    >
                      <div class="users-list-info">
                        <p class="mb-2 font-weight-bold">{{ user.Name }}</p>
                        <p>{{ user.Email }}</p>
                      </div>
                      <div class="cancel-invitation">
                        <a
                          class="cursor-pointer"
                          (click)="cancelInvitation(user.ID, i, 'false')"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12.121"
                            height="12.121"
                            viewBox="0 0 12.121 12.121"
                          >
                            <defs>
                              <style>
                                .prefix__cls-1 {
                                  fill: none;
                                  stroke: #fff;
                                  stroke-width: 3px;
                                }
                              </style>
                            </defs>
                            <g
                              id="prefix__Group_923"
                              data-name="Group 923"
                              transform="translate(-794.439 -456.439)"
                            >
                              <path
                                id="prefix__Line_67"
                                d="M0 0L10 10"
                                class="prefix__cls-1"
                                data-name="Line 67"
                                transform="translate(795.5 457.5)"
                              />
                              <path
                                id="prefix__Line_68"
                                d="M10 0L0 10"
                                class="prefix__cls-1"
                                data-name="Line 68"
                                transform="translate(795.5 457.5)"
                              />
                            </g>
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>
                  <ng-container
                    *ngTemplateOutlet="dropdown; context: { index: i }"
                  ></ng-container>
                </div>
                <ng-container
                  *ngTemplateOutlet="reportMsg; context: { index: i }"
                ></ng-container>
                <div [@messageBot]>
                  <app-chat-message [botMessages]="botMessages[i]">
                  </app-chat-message>
                </div>
              </div>
              <div *ngSwitchCase="chatType.USERLIST">
                <div
                  class="d-flex align-items-center show-report-message"
                  *ngIf="botMessages[i].botResponse.Data"
                  [@messageBot]
                >
                 <app-chat-user-list
                 [users] = "botMessages[i].botResponse.Data"
                 (getUser) = "selectElement($event)"
                 >
                 </app-chat-user-list>
                  <ng-container
                    *ngTemplateOutlet="dropdown; context: { index: i }"
                  ></ng-container>
                </div>
                <ng-container
                  *ngTemplateOutlet="reportMsg; context: { index: i }"
                ></ng-container>
                <div [@messageBot]>
                  <app-chat-message [botMessages]="botMessages[i]">
                  </app-chat-message>
                </div>
              </div>
              <div *ngSwitchCase="chatType.HELP">
                <div
                  class="d-flex align-items-center show-report-message"
                  [@messageBot]
                >
                  <div class="bot-accordion">
                    <accordion [isAnimated]="true" [closeOthers]="true">
                      <accordion-group
                        panelClass="accordion-list"
                        (isOpenChange)="isOpenChange($event, 'createTask')"
                      >
                        <div
                          accordion-heading
                          class="d-flex justify-content-between align-items-center"
                        >
                          <span class="accordion-title">
                            Create task
                          </span>
                          <i
                            [ngClass]="{
                              'rotate-arrow':
                                isOpen === true &&
                                accordionStatus === 'createTask'
                            }"
                            class="fas fa-caret-right font-18"
                          ></i>
                        </div>
                        <div class="accordion-content">
                          <p class="font-15">
                            Create a task for myself "Example task", description
                            "This is an example of description", type bug and
                            set priority to high.
                          </p>
                        </div>
                        <div class="accordion-content">
                          <p class="font-15">
                            Tell Katy to get a milk.
                          </p>
                        </div>
                      </accordion-group>
                      <accordion-group
                        panelClass="accordion-list"
                        (isOpenChange)="isOpenChange($event, 'createProject')"
                      >
                        <div
                          accordion-heading
                          class="d-flex justify-content-between align-items-center"
                        >
                          <span class="accordion-title">
                            Create project
                          </span>
                          <i
                            [ngClass]="{
                              'rotate-arrow':
                                isOpen === true &&
                                accordionStatus === 'createProject'
                            }"
                            class="fas fa-caret-right font-18"
                          ></i>
                        </div>
                        <div class="accordion-content">
                          <p class="font-15">
                            Create a project 'Example', key EXM and set lead to
                            Kyle
                          </p>
                        </div>
                      </accordion-group>
                      <accordion-group
                      panelClass="accordion-list"
                      (isOpenChange)="isOpenChange($event, 'createProject')"
                    >
                      <div
                        accordion-heading
                        class="d-flex justify-content-between align-items-center"
                      >
                        <span class="accordion-title">
                          Invite user
                        </span>
                        <i
                          [ngClass]="{
                            'rotate-arrow':
                              isOpen === true &&
                              accordionStatus === 'createProject'
                          }"
                          class="fas fa-caret-right font-18"
                        ></i>
                      </div>
                      <div class="accordion-content">
                        <p class="font-15">
                          Invite user "Mike" with email "msurname@company.com"
                        </p>
                      </div>
                    </accordion-group>
                      <accordion-group
                        panelClass="accordion-list"
                        (isOpenChange)="
                          isOpenChange($event, 'getTaskOrProject')
                        "
                      >
                        <div
                          accordion-heading
                          class="d-flex justify-content-between align-items-center"
                        >
                          <span class="accordion-title">
                            Get the data
                          </span>
                          <i
                            [ngClass]="{
                              'rotate-arrow':
                                isOpen === true &&
                                accordionStatus === 'getTaskOrProject'
                            }"
                            class="fas fa-caret-right font-18"
                          ></i>
                        </div>
                        <div class="accordion-content">
                          <p class="font-15">
                            Get tasks for Karina
                          </p>
                        </div>
                        <div class="accordion-content">
                          <p class="font-15">
                            Get my archived tasks
                          </p>
                        </div>
                        <div class="accordion-content">
                          <p class="font-15">
                            Get tasks with assignee Arnold and type bug.
                          </p>
                        </div>
                        <div class="accordion-content">
                          <p class="font-15">
                            Get projects
                          </p>
                        </div>
                        <div class="accordion-content">
                          <p class="font-15">
                            Get users
                          </p>
                        </div>
                        <div class="accordion-content">
                          <p class="font-15">
                            Select task JAR-234
                          </p>
                        </div>
                        <div class="accordion-content">
                          <p class="font-15">
                            Select project PRO
                          </p>
                        </div>
                        <div class="accordion-content">
                          <p class="font-15">
                            Select user Laura
                          </p>
                        </div>
                      </accordion-group>
                      <accordion-group
                        panelClass="accordion-list"
                        (isOpenChange)="isOpenChange($event, 'utility')"
                      >
                        <div
                          accordion-heading
                          class="d-flex justify-content-between align-items-center"
                        >
                          <span class="accordion-title">
                            Utility actions
                          </span>
                          <i
                            [ngClass]="{
                              'rotate-arrow':
                                isOpen === true && accordionStatus === 'utility'
                            }"
                            class="fas fa-caret-right font-18"
                          ></i>
                        </div>
                        <div class="accordion-content">
                          <p class="font-15">
                            Confirm; Cancel; Help; Get notifications
                          </p>
                        </div>
                      </accordion-group>
                      <accordion-group
                        panelClass="accordion-list"
                        (isOpenChange)="
                          isOpenChange($event, 'splitCreatingObjects')
                        "
                      >
                        <div
                          accordion-heading
                          class="d-flex justify-content-between align-items-center"
                        >
                          <span class="accordion-title">
                            Guided commands (dialogue):
                          </span>
                          <i
                            [ngClass]="{
                              'rotate-arrow':
                                isOpen === true &&
                                accordionStatus === 'splitCreatingObjects'
                            }"
                            class="fas fa-caret-right font-18"
                          ></i>
                        </div>
                        <p> In cases when there is not enough data supplied a guided dialogue is started. </p>
                        <div class="accordion-content">
                          <p class="font-15">
                            Create a task with title "Example"
                          </p>
                        </div>
                        <div class="accordion-content">
                          <p class="font-15">
                            Set priority to low.
                          </p>
                        </div>
                        <div class="accordion-content">
                          <p class="font-15">
                            Set description to Something.
                          </p>
                        </div>
                        <div class="accordion-content">
                          <p class="font-15">
                            Yes. (to confirm saving)
                          </p>
                        </div>
                      </accordion-group>
                      <accordion-group
                      panelClass="accordion-list"
                      (isOpenChange)="
                        isOpenChange($event, 'splitCreatingObjects')
                      "
                    >
                      <div
                        accordion-heading
                        class="d-flex justify-content-between align-items-center"
                      >
                        <span class="accordion-title">
                        Edit (dialogue)
                        </span>
                        <i
                          [ngClass]="{
                            'rotate-arrow':
                              isOpen === true &&
                              accordionStatus === 'splitCreatingObjects'
                          }"
                          class="fas fa-caret-right font-18"
                        ></i>
                      </div>
                      <div class="accordion-content">
                        <p class="font-15">
                          Select JAR-234 (or click on the task in the task list)
                        </p>
                      </div>
                      <div class="accordion-content">
                        <p class="font-15">
                          Add comment "Please inform me when you finish the first part"
                        </p>
                      </div>
                    </accordion-group>
                    </accordion>
                  </div>
                  <ng-container
                    *ngTemplateOutlet="dropdown; context: { index: i }"
                  ></ng-container>
                </div>
                <ng-container
                  *ngTemplateOutlet="reportMsg; context: { index: i }"
                ></ng-container>
              </div>
              <div *ngSwitchCase="chatType.NOTIFICATIONS">
                <ng-container
                  *ngIf="
                    botMessages[i].botResponse.Data &&
                    botMessages[i].botResponse.StateCode >= stateCode.NOMESSAGE
                  "
                >
                  <div
                    class="d-flex align-items-center show-report-message"
                    [@messageBot]
                  >
                    <app-chat-notifications [botMessages]="botMessages[i]">
                    </app-chat-notifications>
                    <ng-container
                      *ngTemplateOutlet="dropdown; context: { index: i }"
                    ></ng-container>
                  </div>
                  <ng-container
                    *ngTemplateOutlet="reportMsg; context: { index: i }"
                  ></ng-container>
                </ng-container>
                <div [@messageBot]>
                  <app-chat-message [botMessages]="botMessages[i]">
                  </app-chat-message>
                </div>
              </div>
              <div *ngSwitchCase="chatType.COMMENT">
                <ng-container
                  *ngIf="
                    botMessages[i].botResponse.Data &&
                    botMessages[i].botResponse.StateCode >= stateCode.NOMESSAGE
                  "
                >
                  <div
                    class="d-flex align-items-center show-report-message"
                    [@messageBot]
                  >
                    <ng-container
                      *ngTemplateOutlet="dropdown; context: { index: i }"
                    ></ng-container>
                  </div>
                  <ng-container
                    *ngTemplateOutlet="reportMsg; context: { index: i }"
                  ></ng-container>
                </ng-container>
                <div [@messageBot]>
                  <app-chat-message [botMessages]="botMessages[i]">
                  </app-chat-message>
                </div>
              </div>
              <div *ngSwitchCase="chatType.COMMENTLIST">
                <ng-container
                  *ngIf="
                    botMessages[i].botResponse.Data &&
                    botMessages[i].botResponse.StateCode >= stateCode.NOMESSAGE
                  "
                >
                  <div
                    class="d-flex align-items-center show-report-message"
                    [@messageBot]
                  >
                    <app-chat-comments
                      [comments]="botMessages[i].botResponse.Data.Comments"
                      [task]="botMessages[i].botResponse.Data.Task"
                    >
                    </app-chat-comments>
                    <ng-container
                      *ngTemplateOutlet="dropdown; context: { index: i }"
                    ></ng-container>
                  </div>
                  <ng-container
                    *ngTemplateOutlet="reportMsg; context: { index: i }"
                  ></ng-container>
                </ng-container>
                <div [@messageBot]>
                  <app-chat-message [botMessages]="botMessages[i]">
                  </app-chat-message>
                </div>
              </div>
              <div *ngSwitchCase="chatType.ATTACHMENT">
                <ng-container
                  *ngIf="
                    botMessages[i].botResponse.Data &&
                    botMessages[i].botResponse.StateCode >=
                      stateCode.NOMESSAGE &&
                    botMessages[i].botResponse.StateCode !== stateCode.STORED
                  "
                >
                  <div
                    class="d-flex align-items-center show-report-message"
                    [@messageBot]
                  >
                    <app-chat-add-attachment
                      [attachment]="botMessages[i].botResponse.Data.Attachment"
                      [task]="botMessages[i].botResponse.Data.Task"
                    >
                    </app-chat-add-attachment>
                    <ng-container
                      *ngTemplateOutlet="dropdown; context: { index: i }"
                    ></ng-container>
                  </div>
                  <ng-container
                    *ngTemplateOutlet="reportMsg; context: { index: i }"
                  ></ng-container>
                </ng-container>
                <div [@messageBot]>
                  <app-chat-message [botMessages]="botMessages[i]">
                  </app-chat-message>
                </div>
              </div>
              <div *ngSwitchCase="chatType.ATTACHMENTLIST">
                <ng-container
                  *ngIf="
                    botMessages[i].botResponse.Data &&
                    botMessages[i].botResponse.StateCode >= 0
                  "
                >
                  <div
                    class="d-flex align-items-center show-report-message"
                    [@messageBot]
                  >
                    <app-chat-attachments
                      [attachments]="
                        botMessages[i].botResponse.Data.Attachments
                      "
                      [task]="botMessages[i].botResponse.Data.Task"
                    >
                    </app-chat-attachments>
                    <ng-container
                      *ngTemplateOutlet="dropdown; context: { index: i }"
                    ></ng-container>
                  </div>
                  <ng-container
                    *ngTemplateOutlet="reportMsg; context: { index: i }"
                  ></ng-container>
                </ng-container>
                <div [@messageBot]>
                  <app-chat-message [botMessages]="botMessages[i]">
                  </app-chat-message>
                </div>
              </div>
              <div *ngSwitchDefault>
                <div class="bot-text" *ngIf="botMessages[i]">
                  <p>{{ botMessages[i].botMessage }}</p>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <div [ngClass]="{ 'd-none': !dots }" id="jumping-dots">
          <span class="dot"></span>
          <span class="dot"></span>
          <span class="dot"></span>
        </div>
      </div>
    </div>
  </div>
  <ul class="d-flex flex-wrap bubbles-list mt-2" #bubbleList id="bubbleList">
    <li
      class="bubble-item"
      (click)="sendBubbleMsg(bubble)"
      *ngFor="let bubble of bubblesList"
    >
      {{ bubble.text }}
    </li>
  </ul>
  <div class="modal-footer flex-nowrap">
    <input
      #sendMsg
      ngbAutofocus
      type="text"
      placeholder="Type something for Jarvis..."
      [(ngModel)]="message"
      (keyup)="changeButton()"
      (keyup.enter)="send()"
      class="form-control send-msg mr-3 text-white"
    />
    <button type="button" (click)="send()" class="btn btn-send" #btnSend>
      <svg
        width="27px"
        height="22px"
        viewBox="0 0 27 22"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <title>F0842283-5DE5-4FEA-942E-7A321AD77C83</title>
        <desc>Created with sketchtool.</desc>
        <g
          id="Page-1"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <g
            id="task-manager-with-jarvis"
            transform="translate(-1310.000000, -1054.000000)"
            stroke="#FFFFFF"
            stroke-width="2"
          >
            <g id="Group-2" transform="translate(1288.000000, 1032.000000)">
              <g
                id="Send"
                transform="translate(31.000000, 33.000000) rotate(45.000000) translate(-31.000000, -33.000000) translate(19.000000, 21.000000)"
              >
                <polygon
                  id="Stroke-363"
                  points="24 -1.77635684e-15 0 10.2790091 8.57142857 15.4185137 13.7142857 23.9843546"
                ></polygon>
                <path d="M24,0 L8.57142857,15.4185137" id="Stroke-364"></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </button>
  </div>

  <ng-template #dropdown let-i="index">
    <div class="dropdown report-message">
    <span dropdown>
      <a href id="basic-link" dropdownToggle (click)="false"
         aria-controls="basic-link-dropdown">
         <img src="/assets/images/dots.png" alt="dots" />
      </a>
      <ul id="basic-link-dropdown" *dropdownMenu class="dropdown-menu"
          role="menu" aria-labelledby="basic-link">
        <li>
          <a class="dropdown-item cursor-pointer" (click)="reportMessage(i)">
            Report
          </a>
        </li>
      </ul>
    </span>
  </div>
  </ng-template>
  <ng-template #reportMsg let-i="index">
    <div *ngIf="i === report" class="report-answer">
      Message successfully reported. We will resolve this problem as soon as
      possible.
    </div>
  </ng-template>
</div>
