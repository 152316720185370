import { Pipe, PipeTransform } from '@angular/core';
import { User } from '@app/models/user';

@Pipe({
  name: 'sortCurrentUserAsFirst'
})
export class SortCurrentUserAsFirstPipe implements PipeTransform {

  transform(value: User[], currentUserUID: string): User[] {
    if (!value) {
      return null;
    }

    const users = value.reduce((total, currentItem) => {
      if (currentUserUID === currentItem.UID) {
        total = [currentItem, ...total];
      } else {
        total = [...total, currentItem];
      }
      return total;
   }, [])

   return users;
  }

}
