import { NgSelectComponent } from '@ng-select/ng-select';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Error } from '@app/enums/errors';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '@app/shared/services/user.service';
import { ChangePasswordComponent } from '@app/layout/shared/change-password/change-password.component';
import { AuthService } from '@app/shared/services/auth.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl} from '@angular/forms';
import { User } from '@app/models/user';
import { UploadFilesService } from '@app/shared/services/upload-files.service';
import { SpinnerButtonOptions } from '@app/models/spinnerButtonOptions';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  @ViewChild('changeImg') changeImg: ElementRef<HTMLImageElement>;
  @ViewChild('myselect') myselect: NgSelectComponent;
  fileUID: string;
  userUID: string;
  loading = false;
  isReminderDeleted = false;
  timeValues = [{id: 'off', name: 'Off', disabled: true}, {id: '00:00', name: '00:00' }, {id: '01:00', name: '01:00'},
  {id: '02:00', name: '02:00'}, {id: '03:00', name: '03:00' }, {id: '04:00', name: '04:00'}, {id: '05:00', name: '05:00' },
  {id: '06:00', name: '06:00'}, {id: '07:00', name: '07:00' }, {id: '08:00', name: '08:00'}, {id: '09:00', name: '09:00' },
  {id: '10:00', name: '10:00'}, {id: '11:00', name: '11:00' }, {id: '12:00', name: '12:00'}, {id: '13:00', name: '13:00' },
  {id: '14:00', name: '14:00'}, {id: '15:00', name: '15:00' }, {id: '16:00', name: '16:00'}, {id: '17:00', name: '17:00' },
  {id: '18:00', name: '18:00'}, {id: '19:00', name: '19:00' }, {id: '20:00', name: '20:00'}, {id: '21:00', name: '21:00' },
  {id: '22:00', name: '22:00'}, {id: '23:00', name: '23:00' },
];
  specialChar = `[ , ], { , } and %`;
  user: User;
  profileForm: FormGroup;
  submitted = false;
  avatar: string;
  currentName: string;
  currentEmail: string;
  spinnerButtonOptions: SpinnerButtonOptions = {
    active: false,
    text: 'Save profile',
    fullWidth: false,
    disabled: false,
  };

  constructor(
    public activeModal: BsModalRef,
    private authService: AuthService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private uploadFileService: UploadFilesService,
    private toast: ToastrService,
    private modalService: BsModalService
  ) {
    this.userUID = this.authService.currentUser.UserUID;
    this.avatar = this.authService.currentUser.AvatarURL;
  }

  ngOnInit() {
    this.createForm();
    this.getUser();
  }

  createForm(): void {
    this.profileForm = this.formBuilder.group({
      Name: ['', Validators.required],
      Email: ['', [Validators.required, Validators.email]],
      AvatarURL: [null],
      Position: [''],
      Bio: [''],
      NotifyOnWeekends: [null],
      WorkdayStart: [null],
      WorkdayEnd: [null]
    }
    );
  }

  get f(): { [key: string]: AbstractControl } {
    return this.profileForm.controls;
  }

  getUser() {
    this.userService.getUser(this.userUID).subscribe( user => {
      this.user = user;
      this.currentName = this.user.Name;
      this.profileForm.patchValue(this.user);
      if (!this.user.WorkdayStart && !this.user.WorkdayEnd) {
        this.profileForm.get('WorkdayEnd').setValue('off');
        this.profileForm.get('WorkdayStart').setValue('off');
        this.isReminderDeleted = true;
      }
    });
  }

  changePassword() {
    this.modalService.show(ChangePasswordComponent, {
      class: 'change-password-modal modal-md modal-dialog-scrollable'
    });
    document.getElementsByClassName('change-password-modal')[0].parentElement.style.backgroundColor = 'rgba(100, 112, 130, 0.4)';
  }

  submitForm() {
    this.submitted = true;
    if (this.profileForm.invalid) {
      return;
    }
    this.f.AvatarURL.setValue(this.avatar);
    this.f.Name.setValue(this.f.Name.value.trim());
    this.checkMutedReminder();
    const data = this.profileForm.value as User;
    this.spinnerButtonOptions.active = true;
    this.userService.updateUserProfile(this.userUID, data).subscribe(() => {
      this.spinnerButtonOptions.active = false;
      this.authService.currentUser.AvatarURL = this.avatar;
      this.authService.currentUser.Name = this.f.Name.value;
      localStorage.setItem('currentUser', JSON.stringify(this.authService.currentUser));
      this.submitted = false;
      this.activeModal.hide();
    },
    error => {
      if (error.error.ErrorCode === Error.ErrorCodeDatabaseUniqueKeyViolation)  {
        this.toast.warning('That email is already taken.', 'Warning!');
      }
      if (error.error.ErrorCode === Error.ErrorCodeUserNameExists) {
        this.toast.warning('That Username is already taken.', 'Warning!');
      }
      this.spinnerButtonOptions.active = false;
    });
  }

  checkMutedReminder() {
    const workDayStart =  this.profileForm.get('WorkdayStart').value;
    const workDayEnd = this.profileForm.get('WorkdayEnd').value;
    if (workDayStart === 'off' && workDayEnd === 'off') {
      this.profileForm.get('WorkdayEnd').setValue(null);
      this.profileForm.get('WorkdayStart').setValue(null);
     }
  }

  addImage(event) {
    const file = event.target.files[0] as File;
    const reader = new FileReader();
    reader.onload = e => {
      this.avatar = reader.result as string;
    };
    reader.readAsDataURL(file);
    this.upload(file);
  }

  upload(file: File) {
    this.spinnerButtonOptions.disabled = true;
    this.loading = true;
    this.uploadFileService.fileUpload(file)
      .subscribe(result => {
        this.avatar = result.URL;
        this.spinnerButtonOptions.disabled = false;
        this.loading = false;
    });
  }

  clearReminder() {
    this.isReminderDeleted = !this.isReminderDeleted;
    if (this.isReminderDeleted) {
      this.profileForm.get('WorkdayStart').setValue(this.timeValues[0].id);
      this.profileForm.get('WorkdayEnd').setValue(this.timeValues[0].id);
    } else {
      this.profileForm.get('WorkdayStart').setValue('09:00');
      this.profileForm.get('WorkdayEnd').setValue('17:00');
    }
  }
}
