import { retry, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotificationResponse, Notification, NotificationGroups, Content } from '@app/models/notification';
import { NotificationType } from '@app/enums/notifications';
import { Subject } from 'rxjs';
import { differenceInDays } from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  openNotif$ = new Subject<null>();
  readAllNotif$ = new Subject<Event>();
  readNotif$ = new Subject<{notification: Content, id: number}>();
  notificationGroups: NotificationGroups[];
  notificationDays = [
    {
      name: 'Today',
      start: -1,
      end: 1
    },
    {
      name: 'Yesterday',
      start: 0,
      end: 2
    },
    {
      name: 'Older',
      start: 1,
      end: 14
    }
  ];
  unreadCount: number;

  constructor(private http: HttpClient) { }

getNotifications(userId: string, start: number) {
  let params = new HttpParams();
  params = params.append('offset', JSON.stringify(start));
  return this.http.get<NotificationResponse>(environment.baseUrl + 'api/notification/' + userId, {params}).pipe(
    retry(1)
  );
}

readAllNotif(userId: string, notifications: Notification[]) {
  return this.http.post<null>(environment.baseUrl + 'api/notification/' + userId + '/markRead', null).pipe(tap(() => {
    notifications.forEach(item => {
      item.IsRead = true;
    });
    this.unreadCount = 0;
  }));
}

markAsRead(userId: string, notifications: Notification[], notification: Content, id: number) {
  let newArray: number[] = [];
  notifications.map(item => {
   if (item.Content.TaskKey === notification.TaskKey &&
     item.Content.UserName === notification.UserName) {
   if (item.IsRead === false) {
     newArray = [...newArray, item.ID];
   }
 }
  });
  newArray = newArray.filter(( el, index, self) => self.indexOf(el) === index);
  return this.http.patch<{Unread: boolean}>(environment.baseUrl + 'api/notification/' + userId + '/markRead', {NotificationIDs: newArray} )
  .pipe(
    tap(() => {
    notifications.map(item => {
         if (item.ID === id) {
           if (item.IsRead === false) {
             this.unreadCount--;
           }
         }
         newArray.forEach(el => {
           if (item.ID === el) {
             if (item.IsRead === false) {
               item.IsRead = true;
             }
           }
         });
       });
  }));
}

createNotifGroups(notifications: Notification[] ) {
  const duplicates = notifications.filter((item, index, array) => {
    return index !== array.findIndex(t => {
      return t.Content.TaskKey === item.Content.TaskKey &&
             t.Content.UserName === item.Content.UserName &&
             t.IsRead === item.IsRead;
    });
 });
  this.notificationGroups =  this.notificationDays.map(({ name, start, end }) => (
      {
        name,
        data: notifications.filter((item, index, self) => {
          return index === self.findIndex((t) => {
            return t.Content.TaskKey === item.Content.TaskKey &&
            t.IsRead === item.IsRead &&
            t.Content.UserName === item.Content.UserName
            &&  differenceInDays(new Date(), new Date(t.CreatedAt)) > start &&
            differenceInDays(new Date(), new Date(t.CreatedAt)) < end;
          });
        }).map(thing => {
          duplicates.forEach(t => {
               if (thing.Content.TaskKey === t.Content.TaskKey &&
                  thing.Content.UserName === t.Content.UserName &&
                  thing.IsRead === t.IsRead) {
                     if (thing.Content.Type !== t.Content.Type) {
                        thing.Content.Type = NotificationType.UPDATE;
                        return thing;
                     } else {
                       return thing;
                     }
                  }
            });
          return thing;
        }),
      }),
     );
  return this.notificationGroups;
}

}
