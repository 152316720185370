<div class="count-wrapper" *ngIf = "taskData.taskUID">
  <div class="attach-comments" (click) = "sendMessage('get attachments')">
    <svg xmlns="http://www.w3.org/2000/svg" width="18.307" height="19.423" viewBox="0 0 18.307 19.423">
      <defs>
          <clipPath id="clip-path">
              <path id="Rectangle_286" d="M0 0H18.307V19.424H0z" class="cls-1" data-name="Rectangle 286"/>
          </clipPath>
          <style>
              .cls-1{fill:#fff}
          </style>
      </defs>
      <g id="Group_1151" data-name="Group 1151">
          <g id="Group_155" data-name="Group 155">
              <g id="Group_154" clip-path="url(#clip-path)" data-name="Group 154">
                  <path id="Path_13" d="M289.732 394.479a4.734 4.734 0 0 1-3.35-1.363 4.632 4.632 0 0 1 0-6.6l9.8-9.657a3.543 3.543 0 0 1 5.065.147c1.487 1.465 1.182 3.35-.22 4.732l-9.2 9.073a2.245 2.245 0 0 1-3.137 0 2.168 2.168 0 0 1 0-3.092l6.235-6.145a.545.545 0 0 1 .762 0 .524.524 0 0 1 0 .75l-6.235 6.146a1.113 1.113 0 0 0 0 1.589 1.152 1.152 0 0 0 1.613 0l9.205-9.071c.967-.953 1.271-2.194.22-3.233a2.483 2.483 0 0 0-3.544-.145l-9.8 9.657a3.581 3.581 0 0 0 0 5.1 3.711 3.711 0 0 0 5.178 0l9.8-9.657a.543.543 0 0 1 .761 0 .525.525 0 0 1 0 .75l-9.8 9.657a4.735 4.735 0 0 1-3.35 1.363zm0 0" class="cls-1" data-name="Path 13" transform="translate(-285 -375.481)"/>
              </g>
          </g>
      </g>
  </svg>
  <div class="count-attach-comments">
    <div class="count-attach-comments-value"> {{taskData.numOfAttach}} </div>
  </div>
  </div>
  <div class="attach-comments" (click) = "sendMessage('get comments')">
  <svg xmlns="http://www.w3.org/2000/svg" width="16.642" height="15.967" viewBox="0 0 16.642 15.967">
    <defs>
        <style>
            .cls-1{fill:#fff}
        </style>
    </defs>
    <g id="Group_1149" data-name="Group 1149">
        <path id="Path_14" d="M176.724 259h-10.806a2.921 2.921 0 0 0-2.918 2.918v6.665a2.921 2.921 0 0 0 2.918 2.918h6.882l3.295 3.3a.584.584 0 0 0 1-.413v-2.905a2.922 2.922 0 0 0 2.55-2.895v-6.665a2.921 2.921 0 0 0-2.921-2.923zm1.751 9.583a1.753 1.753 0 0 1-1.751 1.751h-.216a.584.584 0 0 0-.584.584v2.057l-2.47-2.47a.583.583 0 0 0-.413-.171h-7.125a1.753 1.753 0 0 1-1.751-1.751v-6.666a1.753 1.753 0 0 1 1.751-1.751h10.807a1.753 1.753 0 0 1 1.751 1.751zm0 0" class="cls-1" data-name="Path 14" transform="translate(-163 -259)"/>
        <path id="Path_15" d="M231.166 329.808h-8.59a.584.584 0 0 0 0 1.167h8.59a.584.584 0 0 0 0-1.167zm0 0" class="cls-1" data-name="Path 15" transform="translate(-218.55 -325.676)"/>
        <path id="Path_16" d="M231.166 385.808h-8.59a.584.584 0 1 0 0 1.167h8.59a.584.584 0 0 0 0-1.167zm0 0" class="cls-1" data-name="Path 16" transform="translate(-218.55 -378.408)"/>
    </g>
</svg>
<div class="count-attach-comments">
  <div class="count-attach-comments-value"> {{taskData.numOfComments}}</div>
</div>
  </div>
</div>
