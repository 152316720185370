import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shorten'
})
export class ShortenPipe implements PipeTransform {

  transform(value: string, limit: number): string {
    if (!value) {
      return null;
    }
    if (value.length > limit && value !== null) {
      return value.substr(0, limit) + ' ...';
    }
    return value;
  }

}
