<div class="bot-comments scroll-chat scroll-auto">
  <div class="bot-comments-title">
    Comments for task {{task.ProjectKey}}-{{task.TaskKey}}
  </div>
  <div
    class="bot-comments-box"
    *ngFor="let comment of comments"
  >
    <div class="bot-comments-avatar">
      <app-no-avatar
        [avatarURL]="comment.AvatarURL"
        [userName]="comment.UserName"
        [width]="51"
        [height]="51"
      >
      </app-no-avatar>
    </div>
    <div class="d-flex flex-column">
      <div class="bot-comments-username">
        {{ comment.UserName }}
      </div>
      <div class="bot-comments-text" [innerHTML]="comment.Comment"></div>
      <div class="bot-comments-edited" *ngIf = "comment.IsEdited"> (edited) </div>
    </div>
    <div class="bot-comments-date">
      {{ comment.CreatedAt | date: "h:mm:a dd/MM/y" }}
    </div>
  </div>
</div>
