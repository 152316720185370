import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Role } from '@app/enums/role';
import { User } from '@app/models/user';

@Component({
  selector: 'app-chat-user-list',
  templateUrl: './chat-user-list.component.html',
  styleUrls: ['./chat-user-list.component.scss']
})
export class ChatUserListComponent implements OnInit {
  @Input() users: User[];
  @Output() getUser: EventEmitter<string> = new EventEmitter<string>();
  constructor() { }

  ngOnInit(): void {
  }

  get role() {
    return Role;
  }

  selectElement(userName: string) {
    const message = `select user ${userName}`;
    this.getUser.emit(message);
  }

}
