<button
  class="btn btn-primary btn-spinner-button"
  type="submit"
  [disabled]="options.disabled || options.active"
  [ngClass]="{ 'w-100': options.fullWidth, 'active': options.active }"
>
  <div class="btn-spinner-wrapper">
    <span class="btn-text" [ngClass] = "{'active': options.active}"> {{ options.text }}</span>
    <span
      class="spinner-border spinner-border-sm spinner" [ngClass] ="{'active': options.active}"
      role="status"
      aria-hidden="true"
    ></span>
  </div>
</button>
