<svg
  xmlns="http://www.w3.org/2000/svg"
  [ngStyle]="{'width.px': width}"
  [ngStyle]="{'height.px': height}"
  viewBox="0 0 19.935 10.967"
>
  <path
    fill="none"
    [style.stroke] ="color"
    stroke-linecap="round"
    stroke-linejoin="round"
    [style.stroke-width.px]="strokeWidth"
    d="M2822.344 8011.647l8.967 8.967 8.967-8.967"
    transform="translate(-2821.344 -8010.647)"
  />
</svg>
