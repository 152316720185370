import { GuestService } from '@app/shared/services/guest.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Checklist, ChecklistResponse } from '@app/models/checklist';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChecklistService {

  constructor(private http: HttpClient, private guestService: GuestService) { }
  focusAddChecklist$ = new Subject<boolean>();
  updateProgress$ = new Subject<{id: string, progress: number}>();

  getChecklist(projectUID: string, taskUID: string) {
    const link = window.location.pathname.slice(1, window.location.pathname.length);
    const path = this.guestService.isGuest ? link : `api/project/${projectUID}`;
    return this.http.get<ChecklistResponse>(`${environment.baseUrl}${path}/task/${taskUID}/checklist`);
  }

  addChecklist(projectUID: string, taskUID: string, data: {Title: string}) {
    return this.http.post<Checklist>(`${environment.baseUrl}api/project/${projectUID}/task/${taskUID}/checklist`, data);
  }

  updateChecklist(projectUID: string, taskUID: string, checklistUID: string, data: {Title: string, IsComplete: boolean}) {
    return this.http.put<{Progress: number}>
    (`${environment.baseUrl}api/project/${projectUID}/task/${taskUID}/checklist/${checklistUID}`, data);
  }

  deleteChecklist(projectUID: string, taskUID: string, checklistUID: string) {
    return this.http.delete<{Progress: number}>
    (`${environment.baseUrl}api/project/${projectUID}/task/${taskUID}/checklist/${checklistUID}`);
  }
}
