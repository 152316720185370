import { AuthService } from '@app/shared/services/auth.service';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, filter, take, switchMap, finalize } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(
    null
  );
  constructor(private authService: AuthService) {}
  // tslint:disable-next-line: no-any
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        if (req.url.includes('renew') || req.url.includes('login')) {
          if (req.url.includes('renew')) {
            this.authService.logout(true);
            this.refreshTokenInProgress = false;
          }
          return throwError(err);
        }
        if (err.status === 401 && err.error.ErrorCode === 20) {
          return throwError(err);
        }
        if (err.status !== 401) {
          return throwError(err);
        }
        if (this.refreshTokenInProgress) {
          return this.refreshTokenSubject.pipe(filter(result => result !== null),
          take(1),
          switchMap(() => next.handle(this.addAuthenticationToken(req)))
          );
        } else {
          this.refreshTokenInProgress = true;
          this.refreshTokenSubject.next(null);
          return this.authService.refreshToken(this.authService.currentUser.UserUID).pipe(switchMap(res => {
            this.authService.currentUser.Token = res.Token;
            localStorage.setItem(
              'currentUser',
              JSON.stringify(this.authService.currentUser)
            );
            this.refreshTokenInProgress = false;
            this.refreshTokenSubject.next(res.Token);
            return next.handle(this.addAuthenticationToken(req));
          }), finalize(() => {
            this.refreshTokenInProgress = false;
          }));
        }
      }));
    }
  // tslint:disable-next-line: no-any
  addAuthenticationToken(request: HttpRequest<any>) {
    const accessToken = this.authService.currentUser.Token;

    if (!accessToken) {
        return request;
    }

    return request.clone({
        setHeaders: {
            Authorization:  `Bearer ${accessToken}`
        }
    });
  }
}
