import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-notification-svg',
  templateUrl: './notification-svg.component.html',
  styleUrls: ['./notification-svg.component.scss']
})
export class NotificationSvgComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
