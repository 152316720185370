import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { User } from '@app/models/user';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GuestService {
  currentGuestSubject: BehaviorSubject<User>;
  isGuest = false;

  constructor(private http: HttpClient) {
    this.currentGuestSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentGuest'))
    );
  }

  get guestUser() {
    return this.currentGuestSubject.value;
  }

  getSharedUser() {
    if (this.isGuest) {
      const link = window.location.pathname.slice(1, window.location.pathname.length);
      return this.http.get<User>(`${environment.baseUrl}${link}/userData`);
    }
  }

  getUserFromLocalStorage(): User {
    if (this.isGuest) {
      return JSON.parse(localStorage.getItem('currentGuest'))
    }
    return JSON.parse(localStorage.getItem('currentUser'))
  }
}
