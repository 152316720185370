import { Injectable } from '@angular/core';
import { User } from '@app/models/user';

@Injectable({
  providedIn: 'root'
})
export class MentionService {
  users: User[];
  userGroups: {
    key: string;
    value: string;
  }[];
  public config = {
    placeholder: 'Enter description',
    link: {
      addTargetToExternalLinks: true
    },
    mention: {
      feeds: [
          {
              marker: '@',
              feed: this.getFeedItems.bind(this),
              itemRenderer: this.customItemRenderer.bind(this),
              minimumCharacters: 0
          }
      ]
    }
  };

  constructor() {
  }

  getFeedItems( queryText: string, users: User[] ) {
    return new Promise( resolve => {
      setTimeout( () => {
        const searchString = queryText.toLowerCase();
        const itemsToDisplay = this.users.map( item => ({id: `@${item.Name}`, ...item }));
        const removeUnassigned = itemsToDisplay.slice(1, itemsToDisplay.length);
        const newItems = removeUnassigned
              .filter( item => {
                return (
                  item.Name.toLowerCase().includes( searchString )
              );
              })
              .slice(0, 10 );

        resolve( newItems );
      }, 100 );
  } );
  }

  customItemRenderer( item: User ) {
    const itemElement = document.createElement( 'div' );
    const avatar = document.createElement( 'img' );
    const userNameElement = document.createElement( 'span' );
    const noAvatar =  document.createElement( 'div' );
    let acronym = item.Name;
    if (acronym.trim().indexOf(' ') !== -1) {
      acronym = acronym.split(/\s/).slice(0, 2).reduce((response, word) => response += word.slice(0, 1), '');
    } else {
      acronym = acronym.substring(0, 2);
    }
    noAvatar.classList.add('mention-noAvatar');
    noAvatar.textContent = acronym;
    avatar.src = `${ item.AvatarURL }`;
    avatar.classList.add('mention-img');
    itemElement.classList.add( 'mention-item' );
    itemElement.id = `[${ item.UID }]`;
    userNameElement.classList.add('mention-name');
    userNameElement.textContent = `${ item.Name } `;
    if (item.AvatarURL !== null) {
      itemElement.appendChild( avatar );
      itemElement.appendChild( userNameElement );
    } else {
      itemElement.appendChild( noAvatar );
      itemElement.appendChild( userNameElement );
    }
    return itemElement;
  }

  replaceMentionNames(comment: string) {
    this.createPersonGroups();
    let newComment = comment;
    this.userGroups.forEach((item) => {
        const mentionRegExp = new RegExp(item.key, 'g');
        newComment = newComment.replace(mentionRegExp, item.value);
      });
    return newComment;
    }

    createPersonGroups() {
      this.userGroups = this.users.map((item => {
        return (
          {
            key: `<span class="mention" data-mention="@${item.Name}">@${item.Name}</span>`,
            value: `@[${item.Name}][${item.UID}]`
          }
        );
      }));
    }
}
