import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {

  constructor(private http: HttpClient) { }


  sendFeedback(userId: string, data: {Message: string, AvatarURL: string[]}) {
    return this.http.post(`${environment.baseUrl}api/user/${userId}/feedback`, data);
  }
}
