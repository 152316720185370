<svg xmlns="http://www.w3.org/2000/svg" [ngStyle]="{'width.px': width}" [ngStyle]="{'height.px': height}" viewBox="0 0 12.313 12.313">
  <defs>
      <style>
          .plus-sign{fill:none;stroke:#fff;stroke-linecap:round;stroke-width:1.5px}
      </style>
  </defs>
  <g id="Group_2237" transform="translate(-768.996 -277.996)">
      <g id="Group_48" transform="translate(769.996 278.996)">
          <path id="Line_8" d="M0 0L0 10.312" class="plus-sign" [style.stroke] ="color" transform="translate(5.156)"/>
          <path id="Line_9" d="M0 0L10.312 0" class="plus-sign"  [style.stroke] = "color" transform="translate(0 5.156)"/>
      </g>
  </g>
</svg>
