import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  Renderer2,
  ElementRef,
  HostListener,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { Error } from '@app/enums/errors';
import { ReminderService } from '@app/shared/services/reminder.service';

@Component({
  selector: 'app-task-reminder',
  templateUrl: './task-reminder.component.html',
  styleUrls: ['./task-reminder.component.scss'],
})
export class TaskReminderComponent implements OnInit {
  @ViewChild('inputValue') numberOfDays: ElementRef<number>;
  @ViewChildren('el') el: QueryList<ElementRef>;
  @Input() isTask: boolean;
  @Input() isReminderActive: boolean;
  @Input() isStatusReminder: boolean;
  @Input() focusReminder: boolean;
  @Input() statusID: number;
  @Input() days: number;
  initialDays: number;
  @Input() statusName: string;
  @Input() projectID: string;
  @Output() toggleReminder: EventEmitter<boolean> = new EventEmitter<boolean>();
  isOpen = false;
  isReminderDelete = false;
  constructor(private reminderService: ReminderService) {}

  ngOnInit(): void {
    this.initialDays = this.days;
    if (!this.days && !this.focusReminder) {
      this.isReminderDelete = true;
    }
  }

  setReminder(days: number) {
    if (!this.days) {
      return;
    }
    if (this.isTask) {
      this.setTaskReminder(days);
    } else {
      this.setStatusReminder(days);
    }
  }

  setTaskReminder(days: number) {
    if (!this.initialDays || this.isStatusReminder) {
      this.reminderService.setReminderForTask(this.projectID, this.statusID, this.statusName, days, null)
      .subscribe( () => {
          this.isReminderActive = true;
          this.isStatusReminder = false;
          this.clearReminder();
          this.initialDays = days;
        },
        (error) => (this.days = this.initialDays)
      );
    } else {
      this.reminderService.updateReminderForTask(this.projectID, this.statusID, days)
      .subscribe( () => {
          this.isReminderActive = true;
          this.clearReminder();
        },
        (error) => (this.days = this.initialDays)
      );
    }
  }

  setStatusReminder(days: number) {
    if (!this.initialDays) {
      this.reminderService.setReminderForStatus(this.projectID, this.statusID, this.statusName, days)
      .subscribe( () => {
        this.isReminderActive = false;
        this.clearReminder();
        },
        (error) => (this.days = this.initialDays)
      );
    } else {
      this.reminderService.updateReminderForStatus(this.projectID, this.statusID, days)
      .subscribe( () => {
        this.isReminderActive = false;
        this.clearReminder();
        },
        (error) =>  {
          if (error.error.ErrorCode === Error.ErrorCodeNotFound) {
            this.reminderService.setReminderForStatus(this.projectID, this.statusID, this.statusName, days)
            .subscribe( () => {
                this.isReminderActive = true;
                this.clearReminder();
              },
              (err) => (this.days = this.initialDays)
            );
          }
        }
      );
    }
  }

  deleteReminder() {
    this.isReminderDelete = !this.isReminderDelete;
    if (this.isReminderDelete) {
      this.days = null;
    }
    if (this.isReminderDelete && this.initialDays !== null) {
      if (this.isTask) {
        if (!this.isStatusReminder) {
        this.reminderService
          .deleteReminderForTask(this.projectID, this.statusID)
          .subscribe(() => {
            this.days = null;
            this.initialDays = null;
            this.isReminderDelete = true;
            this.isReminderActive = false;
            this.clearReminder();
          });
        } else {
          this.setTaskReminder(0);
        }
      } else {
        this.reminderService
          .deleteReminderForStatus(this.projectID, this.statusID)
          .subscribe(() => {
            this.days = null;
            this.isReminderDelete = true;
            this.clearReminder();
          });
      }
    }
  }

  clearReminder() {
    this.focusReminder = false;
    this.toggleReminder.emit(this.isReminderActive);
  }
}
