import { GuestService } from '@app/shared/services/guest.service';
import { TaskService } from '@app/shared/services/task.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserService } from '@app/shared/services/user.service';
import {
  Component,
  OnInit,
  Input,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { User } from '@app/models/user';
import { NgSelectComponent } from '@ng-select/ng-select';
import { TaskFilters } from '@app/enums/filters';
import { AuthService } from '@app/shared/services/auth.service';
import { format, startOfWeek, endOfWeek} from 'date-fns';

@Component({
  selector: 'app-task-menu',
  templateUrl: './task-menu.component.html',
  styleUrls: ['./task-menu.component.scss']
})
export class TaskMenuComponent implements OnInit, OnDestroy {
  @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;
  name: string;
  isChangeEvent = false;
  loadingSelect = false;
  isGuest = false;
  displayMode = 1;
  myTaskActive = false;
  assigneeActive = false;
  selectActive = false;
  subscription: Subscription[] = [];
  dueThisWeekActive = false;
  userName: string;
  avatarURL: string;
  persons: User[];
  projectUID: string;
  constructor(
    private userService: UserService,
    private taskService: TaskService,
    private authService: AuthService,
    private guestService: GuestService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit() {
    this.isGuest = this.guestService.isGuest;
    this.subscription.push(this.taskService.projectDataSubject.subscribe( res => {
      this.projectUID = res;
      this.getPersons();
    }));
    this.subscription.push(
      this.userService.userStorageUpdate.subscribe(() => {
        this.getPersons();
      }));
    this.subscription.push(
        this.taskService.removeFilterSubject.subscribe(() => {
          this.removeFilters();
        }));
    this.getGuests(this.isGuest);
    this.route.queryParamMap.subscribe(params => {
      const value = params.get('taskView');
      value === 'list' ? this.displayMode = 2 : this.displayMode = 1;
    })
  }

  getGuests(isGuest: boolean) {
    if (isGuest) {
        this.name = this.guestService.guestUser.UID;
    } else {
      this.name = this.authService.currentUser.UserUID;
    }
  }

  changeView(mode: number) {
    this.displayMode = mode;
    let taskView: string;
    mode === 1 ? taskView = 'grid' : taskView = 'list';
    this.router.navigate([], {relativeTo: this.route, queryParams: { taskView }, queryParamsHandling: 'merge'})
    this.taskService.modeSubject.next(this.displayMode);
  }

  removeFilters() {
    this.assigneeActive = false;
    this.userName = '';
    this.selectActive = false;
    this.ngSelectComponent.clearModel();
    this.myTaskActive = false;
    this.dueThisWeekActive = false;
  }

  filter(mode: string) {
    switch (mode) {
      case TaskFilters.MyTask:
        this.assigneeActive = false;
        this.userName = '';
        this.selectActive = false;
        this.ngSelectComponent.clearModel();
        this.myTaskActive = !this.myTaskActive;
        this.router.navigate([], {
          queryParams: { name: this.myTaskActive ? this.name : null },
          queryParamsHandling: 'merge',
          relativeTo: this.route
        });
        break;
      case TaskFilters.DueThisWeek:
          const startWeek = format(startOfWeek(new Date(), {weekStartsOn: 1}), 'yyyy-MM-dd');
          const endWeek = format(endOfWeek(new Date(), {weekStartsOn: 1}), 'yyyy-MM-dd');
          this.dueThisWeekActive = !this.dueThisWeekActive;
          this.router.navigate([], {
            queryParams: {
              start: this.dueThisWeekActive ? startWeek : null,
              end: this.dueThisWeekActive ? endWeek : null
            },
            queryParamsHandling: 'merge',
            relativeTo: this.route
          });
    }
    setTimeout(() => {
      this.taskService.filterSubject.next();
    }, 0);
  }

  deselectUser(event: Event) {
    const isSearchBox = (event.target as HTMLInputElement).hasAttribute('role');
    if (!this.isChangeEvent && !isSearchBox) {
      this.ngSelectComponent.clearModel();
      this.clear();
    }
    this.isChangeEvent = false;
  }

  chooseAssignee(event: {}) {
    this.isChangeEvent = true;
    if (event === undefined) {
      this.clear();
    }
    const person = event as User;
    if (person) {
      this.router.navigate([], {
        queryParams: { name: person.UID },
        queryParamsHandling: 'merge',
        relativeTo: this.route
      });
      setTimeout(() => {
        this.taskService.filterSubject.next();
      }, 0);
      this.avatarURL = person.AvatarURL;
      this.myTaskActive = false;
      this.userName = person.Name;
      this.assigneeActive = false;
      this.selectActive = true;
    }
  }

  openSelect() {
    this.assigneeActive = !this.assigneeActive;
    this.ngSelectComponent.open();
    setTimeout(() => {
      this.ngSelectComponent.focus();
    });
  }

  clear() {
    this.router.navigate([], {
      queryParams: { name: null },
      queryParamsHandling: 'merge',
      relativeTo: this.route
    });
    setTimeout(() => {
      this.taskService.filterSubject.next();
    }, 0);
    this.assigneeActive = false;
    this.selectActive = false;
    this.userName = '';
  }

  getPersons() {
    this.loadingSelect = true;
    this.userService.getusersProject(this.projectUID).subscribe(res => {
      this.persons = res.data;
      this.loadingSelect = false;
    });
  }

  ngOnDestroy() {
    this.subscription.forEach( subscription => subscription.unsubscribe());
  }
}
