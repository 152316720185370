import { TaskStatus } from "./../../../models/task";
import { debounceTime, distinctUntilChanged, skip } from "rxjs/operators";
import { ChecklistService } from "@app/shared/services/checklist.service";
import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import { Checklist } from "@app/models/checklist";
import { GuestService } from "@app/shared/services/guest.service";
import { ReminderService } from "@app/shared/services/reminder.service";
import { Subject, Subscription } from "rxjs";
import { Category } from "@app/enums/statusCategory";

@Component({
  selector: "app-task-checklist",
  templateUrl: "./task-checklist.component.html",
  styleUrls: ["./task-checklist.component.scss"],
})
export class TaskChecklistComponent implements OnInit, OnDestroy {
  checklist: Checklist[];
  isOpen = false;
  isInProgress: boolean;
  isGuest: boolean;
  showPopup = false;
  isCheckListUpdate = false;
  isPopupInCache: boolean;
  currentItemValue: string;
  subscription: Subscription;
  modelChanged: Subject<number> = new Subject<number>();
  statusID: number;
  @Input() statuses: TaskStatus[];
  @Input() currentStatusID: number;
  @Input() currentStatusCategory: number;
  @Input() progressCount: number;
  @Input() projectId: string;
  @Input() taskId: string;
  constructor(
    private checklistService: ChecklistService,
    private guestService: GuestService,
    private reminderService: ReminderService
  ) {}

  ngOnInit(): void {
    this.isPopupInCache = JSON.parse(
      localStorage.getItem(`${this.taskId}-isPopupShowed`)
    );
    this.isGuest = this.guestService.isGuest;
    this.getChecklist();
    this.subscription = this.modelChanged
      .pipe(skip(2), debounceTime(300), distinctUntilChanged())
      .subscribe((res) => {
        this.switchPopUp(res);
        this.showPopupDialog(res);
        this.progressCount = res;
        const data = {
          Progress: this.progressCount,
          Message: null,
        };
        this.reminderService
          .reportProgress(this.projectId, this.taskId, data)
          .pipe(debounceTime(1000), distinctUntilChanged())
          .subscribe(() => {
            this.checklistService.updateProgress$.next({
              id: this.taskId,
              progress: res,
            });
            this.isCheckListUpdate = false;
          });
      });
  }

  getChecklist() {
    this.checklistService
      .getChecklist(this.projectId, this.taskId)
      .subscribe((res) => {
        this.checklist = res.data;
        if (this.checklist?.length > 0) {
          this.isOpen = true;
        }
      });
  }

  updateProgress(progressValue: number) {
    if (!this.isCheckListUpdate) {
      this.modelChanged.next(progressValue);
    }
    this.isCheckListUpdate = false;
  }

  addItem(newItemValue: string) {
    if (!newItemValue) {
      return;
    }
    this.checklistService
      .addChecklist(this.projectId, this.taskId, { Title: newItemValue })
      .subscribe((res) => {
        this.checklist = [...this.checklist, res];
        this.manageProgress(res.Progress);
      });
  }

  updateItem(
    Title: string,
    IsComplete: boolean,
    checklistUID: string,
    type: string
  ) {
    const data = {
      Title,
      IsComplete,
    };
    this.checklistService
      .updateChecklist(this.projectId, this.taskId, checklistUID, data)
      .subscribe((res) => {
        this.manageProgress(res.Progress);
      });
  }

  removeItem(checklistUID: string) {
    this.checklistService
      .deleteChecklist(this.projectId, this.taskId, checklistUID)
      .subscribe((res) => {
        this.checklist = this.checklist.filter(
          ({ UID }) => UID !== checklistUID
        );
        this.manageProgress(res.Progress);
      });
  }

  getProgress() {
    const checkedItems = this.checklist.filter(
      ({ IsComplete }) => IsComplete === true
    );
    this.progressCount = Math.round(
      (100 / this.checklist.length) * checkedItems.length
    );
  }

  openChecklist() {
    this.isOpen = true;
    setTimeout(() => {
      this.checklistService.focusAddChecklist$.next(true);
    }, 0);
  }

  manageProgress(progress: number) {
    this.switchPopUp(progress);
    this.showPopupDialog(progress);
    this.progressCount = progress;
    this.checklistService.updateProgress$.next({ id: this.taskId, progress });
    this.isCheckListUpdate = true;
  }

  showPopupDialog(newProgress: number) {
    const currentStatusIndex = this.statuses.findIndex(
      ({ ID }) => ID === this.currentStatusID
    );
    const inProgressStatusIndex = this.statuses.findIndex((status) =>
      this.isInProgress
        ? status.Category === Category.INPROGRESS
        : status.Category === Category.DONE
    );
    if (
      currentStatusIndex < inProgressStatusIndex &&
      this.currentStatusCategory !== Category.DONE &&
      !this.isPopupInCache &&
      newProgress > this.progressCount
    ) {
      this.showPopup = true;
    }
  }

  switchPopUp(progress: number) {
    if (progress !== 100) {
      const { ID: statusID } = this.statuses.find(
        (task) => task.Category === Category.INPROGRESS
      );
      this.statusID = statusID;
      return (this.isInProgress = true);
    }
    const { ID: statusID } = this.statuses.find(
      (task) => task.Category === Category.DONE
    );
    this.statusID = statusID;
    this.isInProgress = false;
  }

  savePopupToStorage() {
    this.isPopupInCache = true;
    localStorage.setItem(
      `${this.taskId}-isPopupShowed`,
      JSON.stringify("true")
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
