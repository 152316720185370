import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-plus-sign-svg',
  templateUrl: './plus-sign-svg.component.html',
  styleUrls: ['./plus-sign-svg.component.scss']
})
export class PlusSignSvgComponent implements OnInit {
 @Input() width = 12;
 @Input() height = 12;
 @Input() color = 'white';
  constructor() { }

  ngOnInit(): void {
  }

}
