<div class="task-menu">
  <ul class="task-menu-content mr-5">
    <li class="mr-6">
      <a
        class="task-menu-links cursor-pointer"
        (click)="changeView(1)"
        [ngClass]="{ active: displayMode === 1 }"
        tooltip="Grid"
        [delay] = "800"
        placement="bottom"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          id="prefix__Group_802"
          width="23.038"
          height="23.037"
          data-name="Group 802"
          viewBox="0 0 23.038 23.037"
        >
          <defs>
            <style>
              .prefix__cls-grid,
              .prefix__cls-grid-3 {
                fill: none;
              }
              .prefix__cls-grid {
                stroke: #96989b;
                stroke-width: 2px;
              }
              .prefix__cls-grid-2 {
                stroke: none;
              }
            </style>
          </defs>
          <g
            id="prefix__Rectangle_783"
            class="prefix__cls-grid svg-stroke"
            data-name="Rectangle 783"
            transform="translate(12.799 12.799)"

          >
            <rect
              width="10.239"
              height="10.239"
              class="prefix__cls-grid-2"
              rx="3"
            />
            <rect
              width="8.239"
              height="8.239"
              x="1"
              y="1"
              class="prefix__cls-grid-3"
              rx="2"
            />
          </g>
          <g
            id="prefix__Rectangle_786"
            class="prefix__cls-grid svg-stroke"
            data-name="Rectangle 786"
            transform="translate(0 12.799)"
          >
            <rect
              width="10.239"
              height="10.239"
              class="prefix__cls-grid-2"
              rx="3"
            />
            <rect
              width="8.239"
              height="8.239"
              x="1"
              y="1"
              class="prefix__cls-grid-3"
              rx="2"
            />
          </g>
          <g
            id="prefix__Rectangle_784"
            class="prefix__cls-grid svg-stroke"
            data-name="Rectangle 784"
            transform="translate(12.799)"
          >
            <rect
              width="10.239"
              height="10.239"
              class="prefix__cls-grid-2"
              rx="3"
            />
            <rect
              width="8.239"
              height="8.239"
              x="1"
              y="1"
              class="prefix__cls-grid-3"
              rx="2"
            />
          </g>
          <g
            id="prefix__Rectangle_785"
            class="prefix__cls-grid svg-stroke"
            data-name="Rectangle 785"
          >
            <rect
              width="10.239"
              height="10.239"
              class="prefix__cls-grid-2"
              rx="3"
            />
            <rect
              width="8.239"
              height="8.239"
              x="1"
              y="1"
              class="prefix__cls-grid-3"
              rx="2"
            />
          </g>
        </svg>
      </a>
    </li>
    <li class="d-sm-block d-none">
      <a
        class="task-menu-links cursor-pointer"
        (click)="changeView(2)"
        [ngClass]="{ active: displayMode === 2 }"
        tooltip="List"
        [delay] = "800"
        placement="bottom"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          id="prefix__list"
          width="26.213"
          height="22.903"
          viewBox="0 0 26.213 22.903"
        >
          <defs>
            <style>
              .prefix__cls-list {
                fill: #96989b;
              }
            </style>
          </defs>
          <g id="prefix__List-2" data-name="List">
            <path
              id="prefix__Path_958"
              d="M132.912 61.67l15.564.016a.819.819 0 1 0 0-1.638l-15.564-.016a.819.819 0 1 0 0 1.638z"
              class="prefix__cls-list svg-active"
              data-name="Path 958"
              transform="translate(-123.099 -57.599)"
            />
            <path
              id="prefix__Path_959"
              d="M148.716 180.23l-15.564-.016a.819.819 0 0 0 0 1.638l15.564.016a.819.819 0 1 0 0-1.638z"
              class="prefix__cls-list svg-active"
              data-name="Path 959"
              transform="translate(-123.322 -169.598)"
            />
            <path
              id="prefix__Path_960"
              d="M148.716 300.533l-15.564-.016a.819.819 0 0 0 0 1.638l15.564.016a.819.819 0 1 0 0-1.638z"
              class="prefix__cls-list svg-active"
              data-name="Path 960"
              transform="translate(-123.322 -281.709)"
            />
            <path
              id="prefix__Path_961"
              d="M3.268 24.3a3.268 3.268 0 1 0 3.268 3.268A3.268 3.268 0 0 0 3.268 24.3zm-.008 4.908a1.638 1.638 0 1 1 0-3.277 1.638 1.638 0 0 1 0 3.277z"
              class="prefix__cls-list svg-active"
              data-name="Path 961"
              transform="translate(0 -24.301)"
            />
            <path
              id="prefix__Path_962"
              d="M3.268 144.6a3.268 3.268 0 1 0 3.268 3.268 3.268 3.268 0 0 0-3.268-3.268zm-.008 4.907a1.638 1.638 0 1 1 0-3.277 1.638 1.638 0 1 1 0 3.277z"
              class="prefix__cls-list svg-active"
              data-name="Path 962"
              transform="translate(0 -136.413)"
            />
            <path
              id="prefix__Path_963"
              d="M3.268 264.667a3.268 3.268 0 1 0 3.268 3.268 3.268 3.268 0 0 0-3.268-3.268zm-.008 4.907a1.638 1.638 0 1 1 0-3.277 1.638 1.638 0 1 1 0 3.277z"
              class="prefix__cls-list svg-active"
              data-name="Path 963"
              transform="translate(0 -248.3)"
            />
          </g>
        </svg>
      </a>
    </li>
  </ul>
  <div class="task-menu-content">
    <app-tasks-users-filter [projectUID]= "projectUID"></app-tasks-users-filter>
  </div>
  <ul class="task-menu-content d-none">
    <li class="mr-6">
      <a
        class="task-menu-links cursor-pointer"
        (click)="filter('myTask')"
        [ngClass]="{ active: myTaskActive }"
        tooltip="Only my tasks"
        [delay] = "800"
        placement="bottom"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19.171"
          height="22.667"
          viewBox="0 0 19.171 22.667"
        >
          <defs>
            <style>
              .prefix__cls-myTasks {
                fill: #96989b;
                stroke: #96989b;
                stroke-width: 0.5px;
              }
            </style>
          </defs>
          <g
            id="prefix__Group_790"
            data-name="Group 790"
            transform="translate(.25 .25)"
          >
            <path
              id="prefix__Path_952"
              d="M59.966 57.931A4.966 4.966 0 1 0 55 52.966a4.971 4.971 0 0 0 4.966 4.965zm0-8.739a3.774 3.774 0 1 1-3.774 3.774 3.778 3.778 0 0 1 3.774-3.774z"
              class="prefix__cls-myTasks svg-active svg-stroke"
              data-name="Path 952"
              transform="translate(-50.63 -48)"
            />
            <path
              id="prefix__Path_951"
              d="M9.335 202A9.346 9.346 0 0 0 0 211.335a.6.6 0 0 0 1.192 0 8.144 8.144 0 0 1 16.287 0 .6.6 0 0 0 1.192 0A9.346 9.346 0 0 0 9.335 202z"
              class="prefix__cls-myTasks svg-active svg-stroke"
              data-name="Path 951"
              transform="translate(0 -189.765)"
            />
          </g>
        </svg>
        <span class="task-menu-text d-none"> Only my tasks </span>
      </a>
    </li>
    <li class="mr-6">
      <a
        class="task-menu-links cursor-pointer"
        (click)="openSelect()"
        [ngClass]="{ active: selectActive || assigneeActive }"
        tooltip="Assignee"
        [delay] = "800"
        placement="bottom"
      >
        <svg
          *ngIf="!selectActive; else avatar"
          xmlns="http://www.w3.org/2000/svg"
          width="30.943"
          height="22.988"
          viewBox="0 0 30.943 22.988"
        >
          <defs>
            <style>
              .prefix__cls-assignee {
                fill: #96989b;
                stroke: #96989b;
                stroke-width: 0.5px;
              }
            </style>
          </defs>
          <g
            id="prefix__Group_791"
            data-name="Group 791"
            transform="translate(.25 .25)"
          >
            <path
              id="prefix__Path_939"
              d="M60.038 58.075A5.038 5.038 0 1 0 55 53.038a5.043 5.043 0 0 0 5.038 5.037zm0-8.866a3.829 3.829 0 1 1-3.829 3.829 3.833 3.833 0 0 1 3.829-3.829z"
              class="prefix__cls-assignee svg-active svg-stroke"
              data-name="Path 939"
              transform="translate(-50.567 -48)"
            />
            <path
              id="prefix__Path_940"
              d="M225.038 58.075A5.038 5.038 0 1 0 220 53.038a5.043 5.043 0 0 0 5.038 5.037zm0-8.866a3.829 3.829 0 1 1-3.829 3.829 3.833 3.833 0 0 1 3.829-3.829z"
              class="prefix__cls-assignee svg-active svg-stroke"
              data-name="Path 940"
              transform="translate(-202.267 -48)"
            />
            <path
              id="prefix__Path_941"
              d="M225.6 206.424a.6.6 0 1 0 .957-.739 9.474 9.474 0 0 0-12.794-2.067.6.6 0 1 0 .677 1 8.265 8.265 0 0 1 11.16 1.8z"
              class="prefix__cls-assignee svg-active svg-stroke"
              data-name="Path 941"
              transform="translate(-196.289 -189.587)"
            />
            <path
              id="prefix__Path_942"
              d="M9.471 202A9.482 9.482 0 0 0 0 211.471a.6.6 0 0 0 1.209 0 8.262 8.262 0 0 1 16.524 0 .6.6 0 0 0 1.209 0A9.482 9.482 0 0 0 9.471 202z"
              class="prefix__cls-assignee svg-active svg-stroke"
              data-name="Path 942"
              transform="translate(0 -189.587)"
            />
          </g>
        </svg>
        <ng-template #avatar>
          <div>
            <app-no-avatar
              [avatarURL]="avatarURL"
              [userName]="userName"
              [width]="31"
              [height]="31"
            >
            </app-no-avatar>
          </div>
        </ng-template>
        <span class="task-menu-text d-none">
          {{ userName ? userName : "Assignee" }}
        </span>
      </a>
    </li>
    <div class="select-dropdown">
      <ng-select
        (change)="chooseAssignee($event)"
        (clear)="clear()"
        (close)="assigneeActive = false"
        [ngStyle]="{ display: assigneeActive ? 'block' : 'none' }"
        appearance="outline"
        [loadingText] = "'loading...'"
        [loading] = "loadingSelect"
        (click) ="deselectUser($event)"
        [searchable]="true"
        [clearable]="true"
        class="select"
      >
      <ng-container *ngIf = !loadingSelect>
        <ng-option [value]="person" *ngFor="let person of persons">
          <div class="d-flex align-items-center">
            <app-no-avatar
              class="mr-2"
              [avatarURL]="person.AvatarURL"
              [userName]="person.Name"
              [width]="32"
              [height]="32"
            >
            </app-no-avatar>
            {{ person.Name }}
          </div>
        </ng-option>
      </ng-container>
      </ng-select>
    </div>
    <li>
      <a
        class="task-menu-links cursor-pointer"
        (click)="filter('dueThisWeek')"
        [ngClass]="{ active: dueThisWeekActive }"
        tooltip="Due this week"
        [delay] = "800"
        placement="bottom"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          id="prefix__clock"
          width="26.187"
          height="27.093"
          viewBox="0 0 26.187 27.093"
        >
          <defs>
            <style>
              .prefix__cls-dueThisWeek {
                fill: #96989b;
              }
            </style>
          </defs>
          <path
            id="prefix__Path_956"
            d="M28.792 1.586h-5.233V.8a.8.8 0 0 0-1.6 0v.788H17.3V.8a.8.8 0 0 0-1.6 0v.788h-4.469V.8a.8.8 0 0 0-1.6 0v.788H4.2a.8.8 0 0 0-.8.8V20.43a.8.8 0 0 0 .8.8h4.9a7.592 7.592 0 0 0 14.786 0h4.9a.8.8 0 0 0 .8-.8V2.385a.8.8 0 0 0-.8-.8zM9.634 3.184v.722a.8.8 0 0 0 1.6 0v-.722H15.7v.722a.8.8 0 0 0 1.6 0v-.722h4.666v.722a.8.8 0 0 0 1.6 0v-.722H28v3.265H5V3.184zM16.5 25.5a6 6 0 1 1 6-6 6 6 0 0 1-6 6zm7.591-5.864v-.133A7.594 7.594 0 0 0 8.9 19.5v.133H5V8.047h22.993v11.585h-3.905z"
            class="prefix__cls-dueThisWeek svg-active"
            data-name="Path 956"
            transform="translate(-3.404)"
          />
          <path
            id="prefix__Path_957"
            d="M97.368 117.64v-3.864a.8.8 0 0 0-1.6 0v4.194a.8.8 0 0 0 .234.565l1.836 1.836a.8.8 0 0 0 1.13-1.129z"
            class="prefix__cls-dueThisWeek svg-active"
            data-name="Path 957"
            transform="translate(-83.476 -97.94)"
          />
        </svg>
        <span class="task-menu-text d-none"> Due this week </span>
      </a>
    </li>
  </ul>
</div>
