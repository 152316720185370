import { Pipe, PipeTransform } from '@angular/core';
import { User } from '@app/models/user';

@Pipe({
  name: 'currentUser'
})
export class CurrentUserPipe implements PipeTransform {

  transform(value: User[], userUID: string): User[] {
    if (!value) {
      return null;
    }
    value = value.filter(({UID}) =>  UID !== userUID);
    return value;
  }

}
