<div
    class="attachments-content scroll scroll-auto" [ngStyle] ="{'height': 'calc(100vh - ' + size + 'rem)'}">
    <div class="task-details-attachments">
      <ul class="attachments-list">
        <li
          class="attachments-item d-flex align-items-center"
          *ngFor="let attachment of attachments let i = index"
        >
        <img
        src="/assets/images/attach-icon.png"
        alt="icon"
        class="attachment-box"
      />
      <div class="attachment-extension">{{ attachemntsExt[i].extensionName | uppercase  }}</div>
          <a
            href="{{ attachment.AttachmentURL }}"
            target="blank"
            class="text-primary"
          >
            {{ attachment.AttachmentFileName | shorten: 25 }}
          </a>
          <span class="d-none" (click) = "deleteAttachment(attachment.UID)" *ngIf="!isGuest">
            <i class="fas fa-times"></i>
          </span>
        </li>
      </ul>
      <div class="uploadfilecontainer" (click)="files.click();" appDragDrop (fileDropped)="addFile($event)" *ngIf = "!isGuest">
        <input hidden type="file" #files (change)="addFile($event.target)" [disabled] ="loading">
        <div class="spinner-border text-primary" role="status" *ngIf ="loading">
          <span class="sr-only">Loading...</span>
        </div>
        <p class="upload-text" *ngIf ="!loading"> Choose files to upload </p>
      </div>
    </div>
  </div>
