<form
  class="d-flex flex-column overflow-hidden"
  [ngStyle]="{ height: '100vh' }"
  *ngIf="taskForm"
  [formGroup]="taskForm"
  (ngSubmit)="submitForm(recursNumber.value)"
>
  <div class="modal-header border-bottom-none">
    <h4 class="modal-title" id="modal-basic-title">Create new task</h4>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-8">
        <div class="form-group">
          <label class="label">Project</label>
          <ng-select
            appearance="outline"
            (change)="changeProject($event)"
            [searchable]="false"
            [clearable]="false"
            class="select"
            placeholder= "Choose project"
            formControlName="Project"
            [ngClass]="{ 'is-invalid': submitted && f['Project']?.errors }"
          >
            <ng-option [value]="project.UID" *ngFor="let project of projects | filterTemplates: false">
              <div class="d-flex align-items-center">
                <div
                  class="project-color mr-2"
                  [ngStyle]="{ 'background-color': project.Icon }"
                ></div>
                {{ project.Name }}
              </div>
            </ng-option>
          </ng-select>
          <div *ngIf="submitted && f['Project']?.errors" class="invalid-feedback">
            <div *ngIf="f['Project']?.errors.required">Project is required</div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label class="label">Task title</label>
          <input
            type="text"
            [appFocus]="true"
            formControlName="Title"
            class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f['Title']?.errors }"
            placeholder="Enter title"
          />
          <div *ngIf="submitted && f['Title']?.errors" class="invalid-feedback">
            <div *ngIf="f['Title']?.errors.required">Title is required</div>
          </div>
        </div>
      </div>
    </div>
    <hr class="mt-2" />
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label class="label"> Type </label>
          <ng-select
            appearance="outline"
            [searchable]="false"
            [clearable]="false"
            class="select"
            formControlName="TaskType"
            [ngClass]="{ 'is-invalid': submitted && f['TaskType']?.errors }"
          >
            <ng-template ng-footer-tmp>
              <a class="text-primary create-new" (click)="openTaskTypeModal()"
                >Manage task types</a
              >
            </ng-template>
            <ng-option [value]="type.ID" *ngFor="let type of types">
              <div class="task-types-options">
                <img
                  class="mr-2 type-icons"
                  [src]="['/assets/images/types/' + type.IconID + '.png']"
                />
                {{ type.Name }}
              </div>
            </ng-option>
          </ng-select>
          <div
            *ngIf="submitted && f['TaskType']?.errors"
            class="invalid-feedback"
          >
            <div *ngIf="f['TaskType']?.errors.required">Type is required</div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-8">
        <div class="form-group">
          <label class="label"> Assignee </label>
          <ng-select
            appearance="outline"
            [searchable]="true"
            [loading]="loadingSelect"
            [loadingText]="'Loading...'"
            [clearable]="false"
            class="select"
            bindValue="test"
            formControlName="AssigneeUID"
            placeholder="Choose"
          >
            <ng-container *ngIf="!loadingSelect">
              <ng-option [value]="person.UID" *ngFor="let person of persons">
                <div class="d-flex align-items-center">
                  <app-no-avatar
                    class="mr-2"
                    [avatarURL]="person.AvatarURL"
                    [userName]="person.Name"
                    [width]="32"
                    [height]="32"
                  >
                  </app-no-avatar>
                  {{ person.Name }}
                </div>
              </ng-option>
            </ng-container>
          </ng-select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label class="label"> Description: </label>
          <ckeditor
            [ngClass] = "{'ckeditor-content': f['Description']?.value }"
            [config]="configDesc"
            [editor]="Editor"
            formControlName="Description"
          ></ckeditor>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <app-task-create-checklist
          (checklistEvent) = "addCheckList($event)"
          >
          </app-task-create-checklist>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label class="label"> Due to: </label>
          <input
            formControlName="DueDate"
            class="form-control"
            placeholder="{{ currentDate | date: 'yyyy-MM-dd' }}"
            type="text"
            bsDatepicker
            [minDate]="currentDate"
            [bsConfig]="{
              dateInputFormat: 'YYYY-MM-DD',
              isAnimated: true,
              containerClass: 'theme-dark-blue',
              adaptivePosition: true,
              customTodayClass: 'custom-today-class'
            }"
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label class="label">Recurs every</label>
          <div class="d-flex">
          <input
          type="number"
          min="1"
          step="1"
          oninput="validity.valid||(value='');"
          (input) ="checkErrors(recursNumber.value)"
          pattern="^[0-9]+"
          #recursNumber
          class="form-control recurs-input mr-1 px-2"
          [ngClass] = "{'d-none': isRecursDateHidden,
          'is-invalid': submitted && f['RecurrencePeriod']?.errors }"
          placeholder="Num"
        />
          <ng-select
            container="body"
            formControlName="RecurrencePeriod"
            appearance="outline"
            [items]="recursTime"
            (change) = "changeRecursPeriod($event, recursNumber.value)"
            bindLabel="label"
            bindValue="id"
            appendTo="body"
            placeholder="Choose time"
            [searchable]="false"
            [clearable]="false"
            class="select-recurs"
            [ngClass]="{
              'is-invalid': submitted && f['RecurrencePeriod']?.errors
            }"
          >
          </ng-select>
        </div>
          <div
            *ngIf="submitted && f['RecurrencePeriod']?.errors"
          >
            <div *ngIf="f['RecurrencePeriod']?.errors" [ngClass]= "{'invalid-recurs': isRecursPeriodError}">
              RecurrencePeriod is required
            </div>
          </div>
          <p class="font-10 text-gray-500">
            This task will be recurrent according to the set options.
          </p>
        </div>
      </div>
      <div class="col-md-4" [ngClass] = "{'d-none': isRecursDateHidden }">
        <div class="form-group">
          <label class="label"> Start day: </label>
          <input
            formControlName="RecurrenceStart"
            class="form-control datepicker"
            placeholder="{{ currentDate | date: 'yyyy-MM-dd' }}"
            (bsValueChange)="changeRecursStart($event, recursNumber.value)"
            type="text"
            [minDate]="currentDate"
            [ngClass]="{
              'is-invalid': submitted && f['RecurrenceStart']?.errors
            }"
            bsDatepicker
            [bsConfig]="{
              dateInputFormat: 'YYYY-MM-DD',
              isAnimated: true,
              containerClass: 'theme-default',
              adaptivePosition: true,
              showWeekNumbers: false,
              customTodayClass: 'custom-today-class'
            }"
          />
          <div
            *ngIf="submitted && f['RecurrenceStart']?.errors"
            class="invalid-feedback"
          >
            <div *ngIf="f['RecurrenceStart']?.errors">
              RecurrenceStart is required
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label class="label"> Priority </label>
          <ng-select
            [dropdownPosition]="'top'"
            appearance="outline"
            [searchable]="false"
            [clearable]="false"
            class="select"
            formControlName="Priority"
            placeholder="Choose"
            [ngClass]="{ 'is-invalid': submitted && f['Priority']?.errors }"
          >
            <ng-option
              [value]="priority.UID"
              *ngFor="let priority of priorities"
            >
              <div class="d-flex align-items-center">
                <div
                  class="mr-2 priority-color"
                  [ngStyle]="{
                    'background-color':
                      priority.UID === taskPriority.LOW
                        ? '#a6db43'
                        : priority.UID === taskPriority.HIGH
                        ? 'red'
                        : 'orange'
                  }"
                ></div>
                {{ priority.Name }}
              </div>
            </ng-option>
          </ng-select>
          <div
            *ngIf="submitted && f['Priority']?.errors"
            class="invalid-feedback"
          >
            <div *ngIf="f['Priority']?.errors.required">
              Priority is required
            </div>
          </div>
        </div>
        <div class="form group mb-md-0 mb-2">
          <label class="label">Creator</label>
          <div class="d-flex align-items-center">
            <app-no-avatar
              class="mr-3"
              [avatarURL]="currentUser.AvatarURL"
              [userName]="currentUser.Name"
              [width]="44"
              [height]="44"
            >
            </app-no-avatar>
            <p class="text-gray-300 mb-0">{{ currentUser.Name }}</p>
          </div>
        </div>
      </div>
      <div class="col-md-6 offset-md-2">
        <div class="form group">
          <label class="label">Attachments</label>
          <div
            class="uploadfilecontainer"
            (click)="files.click()"
            appDragDrop
            (fileDropped)="addFile($event)"
          >
            <input
              hidden
              type="file"
              #files
              (change)="addFile($event.target)"
              [disabled]="loading"
            />
            <div
              class="spinner-border text-primary"
              role="status"
              *ngIf="loading"
            >
              <span class="sr-only">Loading...</span>
            </div>
            <p class="upload-text" *ngIf="!loading">Choose files to upload</p>
          </div>
          <ul class="attachments-list">
            <li
              class="attachments-item d-flex align-items-center"
              *ngFor="let attachment of Attachments; let i = index"
            >
              <img
                src="/assets/images/attach-icon.png"
                alt="icon"
                class="attachment-box"
              />
              <div class="attachment-extension">
                {{ attachemntsExt[i].extensionName | uppercase }}
              </div>
              <a
                href="{{ attachment.AttachmentURL }}"
                target="blank"
                class="text-primary"
              >
                {{ attachment.AttachmentFileName | shorten: 20 }}
              </a>
              <span class="d-none">
                <i class="fas fa-times" (click)="deleteAttachment(i)"></i>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-8"></div>
    </div>
  </div>
  <div class="modal-footer border-none">
    <app-spinner-button [options]="spinnerButtonOptions"> </app-spinner-button>
    <button type="button" class="btn btn-cancel" (click)="closeModal.hide()">
      Cancel
    </button>
  </div>
</form>
