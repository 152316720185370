import { GoogleCalendarRedirectComponent } from './authentication/google-calendar-redirect/google-calendar-redirect.component';
import { TenantResolver } from './shared/services/tenant-resolver.service';
import { HomepageComponent } from '@app/layout/homepage/homepage.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthGuard } from './shared/guards/auth.guard';
import { NotFoundComponent } from './layout/shared/not-found/not-found.component';
import { TaskDetailFullComponent } from './layout/tasks/task-detail-full/task-detail-full.component';
import { ShareLinkGuard } from './shared/guards/share-link.guard';
import { GuestResolver } from './shared/services/guest-resolver.service';


const routes: Routes = [
  { path: 'google', component:  GoogleCalendarRedirectComponent},
  { path: '', redirectTo: '/today', pathMatch: 'full', resolve: {tenantExist: TenantResolver} },
  { path: '',  loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthModule),
  resolve: {tenantExist: TenantResolver},
  runGuardsAndResolvers: 'always'},
  { path: '', component: HomepageComponent, canActivate: [AuthGuard], children:
      [
        { path: 'today', loadChildren: () => import('./layout/today/today.module').then(m => m.TodayModule)},
        { path: 'project', loadChildren: () => import('./layout/projects/project.module').then(m => m.ProjectModule)},
        { path: 'users', loadChildren: () => import('./layout/users/user.module').then(m => m.UserModule)},
        { path: 'project/:id',  loadChildren: () => import('./layout/tasks/task.module').then(m => m.TaskModule)},
        { path: 'pricing', loadChildren: () => import('./layout/pricing/pricing.module').then(m => m.PricingModule)},
        { path: 'task/:taskId', component: TaskDetailFullComponent},
      ]
  },
  { path: '', component: HomepageComponent, canActivate: [ShareLinkGuard], children: [
    { path: 'shared/:string', loadChildren: () => import('./layout/tasks/task.module').then(m => m.TaskModule),
    resolve: {userGuest: GuestResolver}},
  ]
},
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
